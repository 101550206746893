<template>
  <TabsTrigger v-bind="forwardedProps" :class="['tabs-trigger', props.class]">
    <span class="content">
      <slot />
    </span>
  </TabsTrigger>
</template>

<script setup lang="ts">
import { TabsTrigger, type TabsTriggerProps, useForwardProps } from "radix-vue";
import { type HTMLAttributes } from "vue";
import { usePropsExcludingClass } from "../ui-utils";

const props = defineProps<
  TabsTriggerProps & { class?: HTMLAttributes["class"]; tooltipContent?: string }
>();

const forwardedProps = useForwardProps(usePropsExcludingClass(props));
</script>

<style scoped lang="scss">
.tabs-trigger {
  display: inline-flex;
  padding: 0.1rem 0.75rem;
  font-size: 0.8rem;
  transition: all 0.2s ease-in-out;
  background-color: var(--bg-color-2);

  min-width: 70px;

  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &[data-state="active"] {
    background-color: var(--accent-color-blue);
    box-shadow:
      0 1px 3px 0 rgba(0, 0, 0, 0.1),
      0 1px 2px 0 rgba(0, 0, 0, 0.06);
    cursor: pointer;
    &:hover {
      background-color: var(--accent-color-blue);
    }
  }

  &:not([data-state="active"]):hover {
    background-color: var(--bg-color-3);
  }
}

.content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
