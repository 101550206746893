<template>
  <div class="measurement-cards os-host-flexbox" :data-testid="`measurements-${type}-list`">
    <Accordion
      type="multiple"
      :model-value="openedGroups"
      @update:model-value="emits('update:opened-groups', $event)"
    >
      <AccordionItem v-for="group in groups" :key="group.name" :value="group.name">
        <template #header>
          {{ group.name }}
        </template>

        <template #content>
          <template v-for="measurement in group.measurements" :key="measurement.id">
            <MeasurementCard
              :id="`measurement-card-${measurement.id}`"
              :is-expanded="isMeasurementCardExpanded[measurement.id]"
              :measurement="measurement"
              :study="study"
              :highlighted-measurement-id="highlightedMeasurementId"
              :hovered-measurement-value-id="hoveredMeasurementValueId"
              :reference-range-set-name="referenceRangeSetName"
              :patient-metrics="patientMetrics"
              :visible-frames="visibleFrames"
              @update:is-expanded="updateMeasurementCardExpanded(measurement.id, $event)"
              @update:measurement-display-option="
                updateMeasurementDisplayOption(measurement.id, $event)
              "
              @jump-to-value="onJumpToValue"
            />
          </template>
        </template>
      </AccordionItem>
    </Accordion>
  </div>
</template>

<script setup lang="ts">
import { Accordion, AccordionItem } from "@/components/ui/accordion";
import MeasurementCard from "@/measurements/MeasurementCard.vue";
import { StudyMeasurementDisplayOption } from "../../../../../backend/src/studies/study-measurement-enums";
import { TabEmits, TabProps } from "../utils/tabs";

defineProps<TabProps>();
const emits = defineEmits<TabEmits>();

function updateMeasurementCardExpanded(id: string, value: boolean) {
  emits("update:is-measurement-card-expanded", id, value);
}

function updateMeasurementDisplayOption(id: string, value: StudyMeasurementDisplayOption) {
  emits("update:measurement-display-option", id, value);
}
</script>

<style scoped lang="scss">
.highlight-match {
  background-color: var(--accent-color-1);
  border-radius: 3px;
  padding: 0 3px;
}
</style>
