import {
  findMeasurementGroups,
  MeasurementFunctions,
  MeasurementStructures,
} from "@/measurements/measurement-helpers";
import type { StudyMeasurement } from "@/utils/study-data";
import { getMeasurementDisplayName } from "../../../../../backend/src/measurements/measurement-display";
import { TabType } from "./tabs";

const structureOrder: MeasurementStructures[] = [
  MeasurementStructures.LeftVentricle,
  MeasurementStructures.RightVentricle,
  MeasurementStructures.LeftAtrium,
  MeasurementStructures.RightAtrium,
  MeasurementStructures.AorticValve,
  MeasurementStructures.LeftVentricularOutflowTract,
  MeasurementStructures.MitralValve,
  MeasurementStructures.TricuspidValve,
  MeasurementStructures.PulmonicValve,
  MeasurementStructures.RightVentricularOutflowTract,
  MeasurementStructures.Aorta,
  MeasurementStructures.PulmonaryArtery,
  MeasurementStructures.InferiorVenaCava,
  MeasurementStructures.PulmonaryVeins,
  MeasurementStructures.RightCarotidArtery,
  MeasurementStructures.LeftCarotidArtery,
  MeasurementStructures.Other,
  MeasurementStructures.CustomMeasurements,
];

const functionOrder: MeasurementFunctions[] = [
  MeasurementFunctions.LeftVentricularSystolicFunction,
  MeasurementFunctions.StrainMeasurements,
  MeasurementFunctions.LeftVentricleDimensions,
  MeasurementFunctions.LeftVentricularDiastolicFunction,
  MeasurementFunctions.RightVentricularSystolicFunction,
  MeasurementFunctions.RightVentricleDimensions,
  MeasurementFunctions.AtriaDimensions,
  MeasurementFunctions.AorticValve,
  MeasurementFunctions.AorticValveInsufficiency,
  MeasurementFunctions.MitralValve,
  MeasurementFunctions.MitralValveRegurgitation,
  MeasurementFunctions.TricuspidValve,
  MeasurementFunctions.TricuspidValveRegurgitation,
  MeasurementFunctions.PulmonicValve,
  MeasurementFunctions.PulmonicValveInsufficiency,
  MeasurementFunctions.GreatVessels,
  MeasurementFunctions.VascularVelocities,
  MeasurementFunctions.VascularRatios,
  MeasurementFunctions.Other,
  MeasurementFunctions.CustomMeasurements,
];

export function groupMeasurementsByFunctionAndStructure(measurements: StudyMeasurement[]) {
  const functionGroups = new Map<MeasurementFunctions, StudyMeasurement[]>();
  const structureGroups = new Map<MeasurementStructures, StudyMeasurement[]>();

  measurements.forEach((measurement) => {
    const groups = findMeasurementGroups(measurement.name);
    addMeasurementToGroups(groups.functions, functionGroups, measurement);
    addMeasurementToGroups(groups.structures, structureGroups, measurement);
  });

  return { functionGroups, structureGroups };
}

function addMeasurementToGroups<T extends MeasurementFunctions | MeasurementStructures>(
  groupNames: T[],
  groupMap: Map<T, StudyMeasurement[]>,
  measurement: StudyMeasurement
) {
  groupNames.forEach((groupName) => {
    if (!groupMap.has(groupName)) {
      groupMap.set(groupName, []);
    }
    groupMap.get(groupName)?.push(measurement);
  });
}

export function createSortedGroupArray<T extends MeasurementStructures | MeasurementFunctions>(
  groups: Map<T, StudyMeasurement[]>,
  type: TabType
): { name: T; measurements: StudyMeasurement[] }[] {
  const orderArray = (type === TabType.Structure ? structureOrder : functionOrder) as T[];

  return Array.from(groups.entries())
    .filter(([_, measurements]) => measurements.length > 0)
    .sort((a, b) => {
      const indexA = orderArray.indexOf(a[0]);
      const indexB = orderArray.indexOf(b[0]);
      if (indexA === -1 && indexB === -1) return a[0].localeCompare(b[0]);

      return indexA === -1 ? 1 : indexB === -1 ? -1 : indexA - indexB;
    })
    .map(([name, measurements]) => ({
      name,
      measurements: sortMeasurementsAlphabetically(measurements),
    }));
}

export function sortMeasurementsAlphabetically(measurements: StudyMeasurement[]) {
  return [...measurements].sort((a, b) =>
    getMeasurementDisplayName(a, "unindexed").localeCompare(
      getMeasurementDisplayName(b, "unindexed")
    )
  );
}
