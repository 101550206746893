<template>
  <BaseClipViewer
    :canvas-rect="canvasRect"
    :study="study"
    :grid-item="gridItem"
    :canvas-height="canvasElement?.height"
    :canvas-width="canvasElement?.width"
    :clip-aspect-ratio="gridItem.aspectRatio.value"
    @update:canvas-rect="updateCanvasRect"
    @set-canvas-dimensions="setCanvasDimensions"
    @step-frame="onStepFrame"
    @scrub="onScrub"
  >
    <template #canvases>
      <canvas
        ref="webglCanvasElement"
        data-testid="webgl-canvas"
        style="z-index: 1"
        :style="canvasPositionStyles"
      />

      <canvas
        ref="measurementCanvasElement"
        data-testid="measurement-canvas"
        style="z-index: 2"
        :style="canvasPositionStyles"
      />

      <!-- The top canvas (where crosshairs are drawn) receives all mouse events -->
      <canvas
        ref="canvasElement"
        data-testid="crosshairs-canvas"
        style="z-index: 3"
        :style="canvasPositionStyles"
        @mousedown="(e) => gridItem.onCanvasMouseDown(e)"
        @mousemove="(e) => gridItem.onCanvasMouseMove(e)"
      />
    </template>

    <template v-if="gridItem.series.nrrdProcessState === NRRDProcessState.Completed" #imageControls>
      <DropdownWidget
        class="slice-direction-dropdown"
        :class="{ disabled: windowingControlDisabled }"
        data-testid="slice-direction-dropdown"
        :model-value="gridItem.sliceDirection.value"
        :items="[
          { value: CTSliceDirection.Axial, text: 'Axial' },
          { value: CTSliceDirection.Coronal, text: 'Coronal' },
          { value: CTSliceDirection.Sagittal, text: 'Sagittal' },
        ]"
        :disabled="windowingControlDisabled"
        @update:model-value="updateSliceDirection"
      />

      <div
        v-if="crosshairsStore.state === CrosshairsStoreState.Active"
        class="crosshair-color-indicator"
        :style="{ 'background-color': getColorForSliceDirection(gridItem.sliceDirection.value) }"
      />
    </template>

    <template #scrubberItems>
      <ScrubberMeasurementIndicators :study="study" :grid-item="gridItem" />
    </template>

    <template #overlays>
      <ClipViewerMeasurementLabels
        v-if="
          measurementCanvasElement !== null &&
          gridItem.clip?.id !== undefined &&
          (showMeasurements || isMeasuring)
        "
        :study="study"
        :study-clip-id="gridItem.clip?.id"
        :measurement-labels="gridItem.measurementLabels.value ?? []"
        :canvas-element="measurementCanvasElement"
        :canvas-rect="canvasRect"
        :plane="gridItem.currentPlane.value"
        @scroll-to-measurement="
          (measurementId, openMeasurementPane) =>
            emits('scroll-to-measurement', measurementId, openMeasurementPane)
        "
        @highlight-measurement-card="emits('highlight-measurement-card', $event)"
        @measurement-value-hovered="emits('measurement-value-hovered', $event)"
      />
    </template>
  </BaseClipViewer>
</template>

<script setup lang="ts">
import { isMeasuring, restartMeasuring } from "@/measurements/measurement-tool-state";
import { useEventListener } from "@vueuse/core";
import { WebGLRenderer } from "three";
import { computed, onMounted, ref, watch } from "vue";
import { isNullOrUndefined } from "../../../../backend/src/shared/type-utils";
import { NRRDProcessState } from "../../../../backend/src/studies/study-clip-processed-files";
import DropdownWidget from "../../components/DropdownWidget.vue";
import { Study } from "../../utils/study-data";
import ClipViewerMeasurementLabels from "../ClipViewerMeasurementLabels.vue";
import { crosshairsStore, CrosshairsStoreState } from "../ct/ct-crosshairs";
import { getColorForSliceDirection } from "./../ct/ct-crosshairs-renderer";
import { CTSliceDirection } from "./../ct/ct-slice-direction";
import BaseClipViewer from "./BaseClipViewer.vue";
import { CanvasContainerRect } from "./clip-renderer-2d";
import { CTClipsGridItem } from "./clips-grid-item";
import ScrubberMeasurementIndicators from "./ScrubberMeasurementIndicators.vue";

interface Props {
  study: Study;
  gridItem: CTClipsGridItem;
  showMeasurements: boolean;
}

interface Emits {
  (event: "scroll-to-measurement", measurementId: string, openMeasurementPane: boolean): void;
  (event: "highlight-measurement-card", measurementId: string): void;
  (event: "measurement-value-hovered", measurementValueId: string | null): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const canvasElement = ref<HTMLCanvasElement | null>(null);
const webglCanvasElement = ref<HTMLCanvasElement | null>(null);
const measurementCanvasElement = ref<HTMLCanvasElement | null>(null);

let webglRenderer: WebGLRenderer | null = null;

const canvasRect = ref({ top: 0, left: 0, width: 0, height: 0 });

const canvasPositionStyles = computed(() => ({
  top: `${canvasRect.value.top.toFixed(0)}px`,
  left: `${canvasRect.value.left.toFixed(0)}px`,
  width: `${canvasRect.value.width.toFixed(0)}px`,
  height: `${canvasRect.value.height.toFixed(0)}px`,
}));

function setupRenderer(): void {
  if (webglCanvasElement.value) {
    if (webglRenderer === null) {
      webglRenderer = new WebGLRenderer({ canvas: webglCanvasElement.value });
    }

    const crosshairsCtx = canvasElement.value?.getContext("2d");
    const measurementCtx = measurementCanvasElement.value?.getContext("2d");

    if (
      canvasElement.value === null ||
      measurementCanvasElement.value === null ||
      isNullOrUndefined(measurementCtx) ||
      isNullOrUndefined(crosshairsCtx)
    ) {
      return;
    }

    props.gridItem.provideRenderer({
      gridItem: props.gridItem,
      webglRenderer,
      canvasRect: canvasRect.value,
      measurementCanvas: measurementCanvasElement.value,
      measurementCtx,
      crosshairsCanvas: canvasElement.value,
      crosshairsCtx,
    });
  }
}

function updateCanvasRect(rect: CanvasContainerRect): void {
  // Shift the canvas off the top by 32px when in CT viewers so the controls don't overlap any clinical
  // content. The same distance is taken off the width to preserve the canvas aspect ratio.
  canvasRect.value = {
    top: rect.top + 32,
    left: rect.left + 16,
    width: rect.width - 32,
    height: rect.height - 32,
  };
}

function setCanvasDimensions(dims: { width: number; height: number }): void {
  if (canvasElement.value === null || measurementCanvasElement.value === null) {
    return;
  }

  canvasElement.value.width = dims.width;
  canvasElement.value.height = dims.height;

  measurementCanvasElement.value.width = dims.width;
  measurementCanvasElement.value.height = dims.height;
}

function updateSliceDirection(newSliceDirection: string): void {
  const gridItem = props.gridItem;
  gridItem.sliceDirection.value = newSliceDirection as CTSliceDirection;

  if (isMeasuring.value) {
    restartMeasuring({ studyClipId: gridItem.clip.id, region: undefined });
  }
}

function onStepFrame(delta: number): void {
  const gridItem = props.gridItem;
  const newSliceNumber = (gridItem.sliceNumber.value + delta) % gridItem.maxSliceNumber.value;

  gridItem.sliceNumber.value =
    newSliceNumber < 0 ? newSliceNumber + gridItem.maxSliceNumber.value : newSliceNumber;
}

function onScrub(xFraction: number): void {
  const gridItem = props.gridItem;
  gridItem.sliceNumber.value = Math.floor(xFraction * gridItem.maxSliceNumber.value);
}

useEventListener(document, "mouseup", () => props.gridItem.onCanvasMouseUp());

onMounted(() => {
  setupRenderer();
});

watch(
  () => props.gridItem,
  () => {
    setupRenderer();
  }
);

watch(props.gridItem.sliceDirection, () => {
  updateCanvasRect(canvasRect.value);
});

//
// Crosshairs
//

const windowingControlDisabled = computed(
  () =>
    crosshairsStore.value.state === CrosshairsStoreState.Active &&
    crosshairsStore.value.models[props.gridItem.sliceDirection.value].series.id ===
      props.gridItem.series.id
);
</script>

<style scoped lang="scss">
canvas {
  position: absolute;
}

.ct-mode-wrapper {
  grid-area: 1 / 1;
}

.ct-mode-btn {
  display: flex;
  z-index: 3;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: var(--accent-color-1);
  background-color: var(--bg-color-2);
  border-radius: var(--border-radius);
  border: 1px solid var(--accent-color-1);
  height: 12px;
  width: 60px;
  padding: 4px 8px;
  margin: 8px 8px 8px auto;

  &.processing {
    width: 80px;
  }

  &:hover,
  &.selected {
    color: var(--accent-color-2);
    background-color: var(--bg-color-3);
  }

  &.selected {
    border: 1px solid var(--accent-color-2);
  }
}

.windowing-block {
  display: flex;
  gap: 1px;
  background-color: var(--border-color-1);
  border: 1px solid var(--border-color-1);
  border-radius: var(--border-radius);
  overflow: hidden;

  .windowing-item {
    display: flex;
    background-color: var(--bg-color-2);
    padding: 0px 4px;
    align-items: center;

    &:not(input) {
      cursor: default;
    }
  }
}

.windowing-input {
  height: 20px;
  width: 30px;
  padding: 0px 4px;
  border: 0;
  text-align: right;
  cursor: text;

  &:hover,
  &:focus {
    background-color: var(--bg-color-2);
  }
}

.preset-item {
  &:hover {
    color: var(--accent-color-2);
  }
}

.slice-direction-dropdown {
  height: 22px;

  :deep(select) {
    line-height: 14px;
    height: 14px;
  }
}

.crosshair-color-indicator {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  border: 2px solid var(--border-color-1);
}
</style>
