<template>
  <div class="settings-title">Global Admin Roles</div>

  <div class="user-roles">
    <div class="role-list">
      <RouterLink
        v-for="role in userAdminRoleList"
        :key="role.id"
        :to="{ name: 'settings-global-admin-roles-edit', params: { id: role.id } }"
        class="role-link"
      >
        <button :class="{ disabled: !role.isEnabled }" :data-testid="`role-${role.name}`">
          {{ role.name === "" ? "(no name)" : role.name }}
        </button>
      </RouterLink>
    </div>

    <RouterView />
  </div>
</template>

<script setup lang="ts">
import router from "@/router";
import { useUserAdminRoleList } from "@/utils/user-roles-list";
import { useFocus } from "@vueuse/core";
import { onMounted, ref, watch } from "vue";
import { GlobalAdminRoleType } from "../../backend/src/user/roles/user-role-type";

const userAdminRoleList = useUserAdminRoleList();

watch(userAdminRoleList, async () => {
  await loadDefaultRole();
});

onMounted(async () => {
  await loadDefaultRole();
});

// Open the built-in system admin role by default
async function loadDefaultRole() {
  if (router.currentRoute.value.name === "settings-global-admin-roles") {
    const superAdminRole = userAdminRoleList.value.find(
      (role) => role.type === GlobalAdminRoleType.SuperGlobalAdmin
    );

    if (superAdminRole !== undefined) {
      await openRole(superAdminRole.id);
    }
  }
}

async function openRole(roleId: string): Promise<void> {
  await router.push({ name: "settings-global-admin-roles-edit", params: { id: roleId } });
}

const newRoleNameElement = ref<HTMLInputElement | null>(null);
useFocus(newRoleNameElement, { initialValue: true });
</script>
