import type { ComputedRef, Ref } from "vue";
import type { MeasurementName } from "../../../../backend/src/measurements/measurement-names";
import type {
  StudyMeasurementBatchChangeRequestDto,
  StudyMeasurementBatchChangeResponseDto,
} from "../../../../backend/src/studies/dto/study-measurement-batch-change.dto";
import type { StudyMeasurementValueCreateRequestDto } from "../../../../backend/src/studies/dto/study-measurement-value-create.dto";
import type { StudyMeasurementValueDeleteRequestDto } from "../../../../backend/src/studies/dto/study-measurement-value-delete.dto";
import type { StudyMeasurementValueUpdateRequestDto } from "../../../../backend/src/studies/dto/study-measurement-value-update.dto";
import type { StudyClip, StudyClipRegion, StudyMeasurementValue } from "../../utils/study-data";

/**
 * This interface describes a measurement tool such as linear measurement, volume measurement, and
 * ejection fraction. It allows the underlying tool implementation to describe how it needs the
 * measurement UI to appear, respond appropriately to input events on the relevant clip viewers, and
 * draw its current state onto the canvas.
 *
 * The clip viewers and measurement UI in the application then don't need to concern themselves
 * with the specific details of how any given measurement tool functions, they only need to
 * pass in relevant input events and ensure that the UI reflects the current state requested by this
 * interface via the various Ref's and ComputedRef's it exposes.
 */
export interface MeasurementTool {
  /**
   * The name to display for this tool in the measurement UI when it is active. If this is set to
   * "null" then the measurement tool should be considered inactive.
   */
  displayName: string;

  /**
   * The ID of the study clip that this measurement tool is currently working on. Will be an empty
   * string if no clip is active/selected.
   */
  studyClipId: string;

  /**
   * The clip region that this measurement tool was created with, detailing region boundaries,
   * physical deltas, and region type.
   */
  region: StudyClipRegion | undefined;

  /**
   * If this measurement tool supports the creation of measurements with a custom name, then this
   * will be set to the specific custom measurement that can be created and named via the customName
   * field.
   */
  customMeasurementName?: MeasurementName;

  /*
   * UI content and interactions.
   */

  /**
   * Whether the 'Save' button that allows the user to save the measurement should be visible. If
   * this is false then a 'Next' button will be visible instead.
   */
  isSaveButtonVisible: ComputedRef<boolean>;

  /** When the 'Save' button is visible, this specifies whether it should be shown as enabled. */
  isSaveButtonEnabled: ComputedRef<boolean>;

  /** When the save button is visible, this is the tooltip to show for it. */
  saveButtonTooltip?: ComputedRef<string>;

  /** When the 'Next' button is visible, this specifies whether it should be shown as enabled. */
  isNextButtonEnabled?: ComputedRef<boolean>;

  /** When the 'Next' button is visible, this specifies the tooltip to show for it on hover. */
  nextButtonTooltip?: ComputedRef<string>;

  /** Whether the 'Back' button should be shown to the user. */
  isBackButtonVisible?: ComputedRef<boolean>;

  /** The help text to show on the measurement toolbar. */
  helpText: ComputedRef<string>;

  /**
   * Details of the items to show in the measurement toolbar. This can include values, buttons,
   * icons, can have several different states, and can also be clickable.
   */
  toolbarItems: ComputedRef<ToolbarItem[]>;

  /**
   * Set to true when the measurement tool is doing some asynchronous internal processing and so a
   * spinner should be shown in the UI.
   */
  isProcessing?: Ref<boolean>;

  /** Called when the 'Next' button is clicked so that the measurement tool can update. */
  onNextButtonClick?: () => void;

  /** Called when the 'Back' button is clicked so that the measurement tool can update. */
  onBackButtonClick?: () => void;

  /**
   * Used to stop VTI measurements from automatically placing the first point when a region is
   * selected, so that the midline can be shown after region is selected but before point place.
   */
  shouldPassMousedownAfterRegionSelection: boolean;

  /*
   * Clip viewer interactions.
   */

  /**
   * The indicators to show on the scrubber bar for the clip being measured. This is used to show
   * ES/ED markers.
   */
  scrubberIndicators?: ComputedRef<ScrubberIndicator[]>;

  /**
   * The 2D points on the clip that are interactive and should show an appropriate cursor when the
   * mouse is over them. The points are x,y pairs and are stored in the normalized 0-1 range.
   */
  interactivePoints: ComputedRef<number[]>;

  /**
   * Returns the measurement labels to show on the given canvas for the clip being measured.
   *
   * TODO: it may be possible to return these labels with normalized x/y positions in order to avoid
   * needing the canvas at all, which would mean this could be turned into a computed.
   */
  getMeasurementLabels: (canvas: HTMLCanvasElement) => MeasurementLabel[];

  /**
   * When this measurement tool needs to be redrawn it will call all the functions in this array.
   * This is used to notify clip viewers about the need for a redraw.
   */
  requestRedrawHandlers: Set<() => void>;

  /**
   * When this measurement tool needs to change the current frame of the study clip it's working on
   * it will call all the functions in this set.
   */
  frameChangeHandlers: Set<(frameNumber: number) => void>;

  /** Returns whether changing of the clip or frame is allowed. */
  isChangeAllowedOf: (target: "clip" | "frame") => boolean;

  /** Draws this measurement tool's current state onto the canvas for the active clip. */
  drawToCanvas: (canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D) => void;

  /** Called when the mouse is clicked on the clip's canvas so the measurement tool can react. */
  onCanvasMouseDown: (pt: number[]) => void;

  /**
   * Called when the mouse is moved over the clip's canvas so the measurement tool can react.
   * If true is returned then the mouse move event will not propagate to any other potential
   * interactions on the clip viewer.
   */
  onCanvasMouseMove: (pt: number[]) => boolean;

  /** Called when the mouse is released over the clip's canvas so the measurement tool can react. */
  onCanvasMouseUp: (pt: number[]) => void;

  /** Called when the current frame of the clip being measured changes. */
  onFrameChange: (frameNumber: number) => void;

  /*
   * Measurement saving.
   */

  /**
   * The selected measurement name for this measurement. If this is set to a `Custom*` measurement
   * name then this measurement will be saved with the `customName` set with this tool.
   */
  measurementName: Ref<MeasurementName | undefined>;

  /**
   * The custom name string to use for this measurement, if the measurement name is set to
   * `Custom*`.
   */
  customName: Ref<string>;

  /**
   * The associated measurements for this measurement tool. This will only be set when the
   * `measurementName` value is set to a measurement that has associated measurements (not all
   * measurements do).
   */
  associatedMeasurements: ComputedRef<Partial<Record<string, AssociatedMeasurement>>>;

  /**
   * Toggles whether or not a associated measurement is enabled and should be saved along with
   * the main measurement.
   */
  toggleAssociatedMeasurement: (name: string) => void;

  /** Returns the list of measurement names that this measurement tool's output can be saved as. */
  getCreatableMeasurementNames: () => MeasurementName[];

  /**
   * Returns the list of measurement change requests to send to the backend in order to save the
   * changes made by this measurement tool.
   */
  getMeasurementChangeRequests: () => MeasurementToolBatchChangeRequest;

  /**
   * Returns whether this measurement tool can be recreated for use with the given clip and region.
   */
  isMeasurableOnStudyClip: (clip: StudyClip, region?: StudyClipRegion) => boolean;

  /**
   * The batch ID of measurements that are currently being edited by this measurement tool,
   */
  editingMeasurementBatchId: Ref<string | null>;

  /**
   * Loads a previously saved measurement value into this measurement tool's state.
   */
  loadSavedMeasurement: (value: MeasurementToolRecreationDetails) => void;

  /**
   * Updates the region the measurement tool is working on. This is ONLY relevant for measurements
   * on a fake CT study clip region, as it allows the slice number to be changed by generating a
   * new fake region on a different slice plane position. Throws an error on regular measurements.
   */
  updateRegion?: (region: StudyClipRegion) => void;

  /** Whether the measurement name is fixed and can't be edited for this measurement. */
  isMeasurementNameFixed: Ref<boolean>;

  /** The list of callbacks to run when the measurement tool successfully saves its output. */
  onSaved: ((saveResponse: StudyMeasurementBatchChangeResponseDto) => void)[];

  /** The list of callbacks to run when the measurement tool destroyed. */
  onDestroy: (() => void)[];
}

/** Describes an item to place in the measurement UI toolbar. */
export interface ToolbarItem {
  /** The label to show next to the toolbar item. */
  label?: string;

  /** The text to show in the toolbar item. */
  text?: string;

  /** The icon to show in the toolbar item. */
  icon?: string;

  /** Whether to show the toolbar item as highlighted. */
  highlighted?: boolean;

  /** Whether to show the toolbar item's value as invalid. */
  invalid?: boolean;

  /** The tooltip text to show on hover of the toolbar item's value. */
  tooltip?: string;

  /**
   * For toolbar items that provide an onClick handler, i.e they act as buttons, this value
   * specifies whether the button should appear enabled or disabled. Defaults to enabled if not
   * specified.
   */
  enabled?: boolean;

  /**
   * Whether or not to show a checkbox in front of the text in this toolbar item, and whether it
   * should show checked or not. This checkbox is visual only - its state is managed by what
   * creates the toolbar item and the checking behaviour should be handled in this item's onClick().
   */
  checkbox?: {
    checked: boolean;
  };

  /** The function to call when the toolbar item is clicked. */
  onClick?: () => void;
}

/** Describes an indicator to place on the clip being measured's scrubber. */
export interface ScrubberIndicator {
  /** The frame of the current clip to show the indicator on. */
  frame: number;

  /** The label to show for the indicator, typically 2-3 characters maximum. */
  label: string;

  /** The color to use for the indicator and its label. */
  color: string;
}

/** Describes a measurement label to show on the clip viewer while the measurement is underway. */
export interface MeasurementLabel {
  measurementName: MeasurementName;
  measurementValue: StudyMeasurementValue;
  x: number;
  y: number;
  alignment?: "center-above" | "center-below" | "left" | "right";
}

/**
 * Describes a single measurement creation request in a format that can be sent to the backend to be
 * saved.
 */
export type MeasurementToolMeasurementCreationRequest = StudyMeasurementValueCreateRequestDto & {
  value: number;
};

/**
 * Describes a single measurement update request   in a format that can be sent to the backend to be
 * saved.
 */
export type MeasurementToolMeasurementUpdateRequest = StudyMeasurementValueUpdateRequestDto;

/**
 * Describes a single measurement delete request in a format that can be sent to the backend to be
 * saved.
 */
export type MeasurementToolMeasurementDeleteRequest = StudyMeasurementValueDeleteRequestDto;

/**
 * Describes a single measurement value and the measurement name it is for to recreate the
 * measurement tool with for editing a previously saved measurement.
 */
export type MeasurementToolRecreationDetails = StudyMeasurementValue & {
  measurementName: MeasurementName;
  customName?: string;
};

/**
 * Describes a single measurement value with the measurement name and measurement ID that it is for.
 */
export type MeasurementValueWithMeasurementNameAndId = StudyMeasurementValue & {
  measurementName: MeasurementName;
  measurementId: string;
};

/** The request to send to the backend to update measurements in a study. */
export type MeasurementToolBatchChangeRequest = StudyMeasurementBatchChangeRequestDto & {
  creates?: MeasurementToolMeasurementCreationRequest[];
};

export type AssociatedMeasurement = StudyMeasurementValueCreateRequestDto & {
  /** Measurements created by tools can't have null values */
  value: number;

  /**
   * Whether this associated measurement is enabled and should be saved. Don't set this ref
   * directly, instead toggle it with the function provided on the tool in order to ensure redraws
   * happen correctly.
   */
  enabled: Ref<boolean>;

  /**
   * The ID of the measurement value this associated measurement represents during measurement
   * editing. This is used to ensure changing the name of the main measurement also changes the
   * name of associated measurements appropriately.
   */
  measurementValueId: Ref<string>;
};

/** The input methods that a measurement can be created with. */
export enum MeasurementCreationMethod {
  ClickToPlacePoints = "clickToPlacePoints",
  ClickAndDrag = "clickAndDrag",
}
