import { isEqual } from "lodash";
import { Box3, Line3, Plane, Vector2, Vector3 } from "three";
import type { MeasurementPlane, StudyClipRegion } from "../studies/study-clip-region";
import { RegionType, RegionUnit, SpatialFormat } from "../studies/study-clip-region";

enum CTSliceDirection {
  Axial = "Axial",
  Coronal = "Coronal",
  Sagittal = "Sagittal",
}

function createAxialPlane(zPosition: number): MeasurementPlane {
  return {
    normal: [0, 0, 1],
    point: [0, 0, zPosition],
  };
}

function createCoronalPlane(yPosition: number): MeasurementPlane {
  return {
    normal: [0, 1, 0],
    point: [0, yPosition, 0],
  };
}

function createSagittalPlane(xPosition: number): MeasurementPlane {
  return {
    normal: [1, 0, 0],
    point: [xPosition, 0, 0],
  };
}

export function getDirectionForPlane(plane: MeasurementPlane): CTSliceDirection {
  if (isEqual(plane.normal, [0, 0, 1])) {
    return CTSliceDirection.Axial;
  }

  if (isEqual(plane.normal, [0, 1, 0])) {
    return CTSliceDirection.Coronal;
  }

  if (isEqual(plane.normal, [1, 0, 0])) {
    return CTSliceDirection.Sagittal;
  }

  throw new Error("Oblique planes are not supported at present");
}

export function getBox3Edges(box: Box3): Line3[] {
  const low = box.min;
  const high = box.max;

  const corner1 = new Vector3(low.x, low.y, low.z);
  const corner2 = new Vector3(high.x, low.y, low.z);
  const corner3 = new Vector3(low.x, high.y, low.z);
  const corner4 = new Vector3(low.x, low.y, high.z);

  const corner5 = new Vector3(high.x, high.y, low.z);
  const corner6 = new Vector3(high.x, low.y, high.z);
  const corner7 = new Vector3(low.x, high.y, high.z);
  const corner8 = new Vector3(high.x, high.y, high.z);

  const edges = [
    [corner1, corner2],
    [corner1, corner3],
    [corner1, corner4],
    [corner2, corner5],
    [corner2, corner6],
    [corner3, corner5],
    [corner3, corner7],
    [corner4, corner6],
    [corner4, corner7],
    [corner5, corner8],
    [corner6, corner8],
    [corner7, corner8],
  ].map(([start, end]) => new Line3(start, end));

  return edges;
}

export function getPlaneIntersectionsWithBox3(plane: Plane, box: Box3): Vector3[] {
  const edges = getBox3Edges(box);

  const intersections = edges
    .map((edge) => {
      const intersection = new Vector3();
      plane.intersectLine(edge, intersection);
      return intersection;
    })
    .filter((v1, i, arr) => arr.findIndex((v2) => v1.equals(v2)) === i)
    .filter((intersection) => plane.distanceToPoint(intersection) === 0);

  return intersections;
}

export function intersectionsTo2DSpace(planeNormal: Vector3, intersections: Vector3[]) {
  // Center the 2D coordinate system on the first intersection vector
  const origin = intersections[0];

  // The x-axis will be projected on the first to second intersection
  const xAxisUnitVector = intersections[1].clone().sub(origin).normalize();

  // The y-axis will be the cross product of the normal and x-axis
  const yAxisUnitVector = planeNormal.clone().cross(xAxisUnitVector).normalize();

  // Represent each intersection in the 2D coordinate system
  const points = intersections.map((intersection) => {
    const x = intersection.clone().sub(origin).dot(xAxisUnitVector);
    const y = intersection.clone().sub(origin).dot(yAxisUnitVector);

    return new Vector2(x, y);
  });

  return points;
}

export function createMockStudyClipRegion(
  volume: { RASDimensions: [number, number, number] },
  plane: MeasurementPlane
): StudyClipRegion {
  if (
    ![
      [1, 0, 0],
      [0, 1, 0],
      [0, 0, 1],
    ].some((p) => isEqual(p, plane.normal))
  ) {
    throw new Error("Oblique planes are not supported at present");
  }

  // Represent the volume as a Box3. The RAS dimensions of the volume account for the pixel spacing
  // so e.g. a 250x250x140 RAS dimensions = 25cm x 25cm x 14cm. This allows the physical deltas to
  // be easily calculated as 1px in the RAS dimension = 1mm in the physical dimension.
  const volumeBox = new Box3(new Vector3(0, 0, 0), new Vector3(...volume.RASDimensions));

  // Represent the plane as a Three.js Plane
  const threejsPlane = new Plane().setFromNormalAndCoplanarPoint(
    new Vector3(...plane.normal),
    new Vector3(...plane.point)
  );

  const intersectionsOfPlaneWithVolume = getPlaneIntersectionsWithBox3(threejsPlane, volumeBox);

  const points = intersectionsTo2DSpace(
    new Vector3(...plane.normal),
    intersectionsOfPlaneWithVolume
  );

  // Find the bounding box of the 2D points in order to find
  const xValues = points.map((point) => point.x);
  const yValues = points.map((point) => point.y);

  const left = Math.min(...xValues);
  const right = Math.max(...xValues);
  const top = Math.min(...yValues);
  const bottom = Math.max(...yValues);

  const width = right - left;
  const height = bottom - top;

  return {
    type: RegionType.CTMockRegion,
    spatialFormat: SpatialFormat.TwoDimensional,
    flags: 0,
    xDirectionUnit: RegionUnit.Centimeters,
    yDirectionUnit: RegionUnit.Centimeters,
    physicalDeltaX: 0.1,
    physicalDeltaY: 0.1,
    top: 0,
    left: 0,
    right: width,
    bottom: height,
    plane,
  };
}

export function createPlaneFromSliceDirectionAndNumber(
  direction: CTSliceDirection,
  sliceNumber: number
): MeasurementPlane {
  const planeGenerator = {
    [CTSliceDirection.Axial]: createAxialPlane,
    [CTSliceDirection.Coronal]: createCoronalPlane,
    [CTSliceDirection.Sagittal]: createSagittalPlane,
  };

  return planeGenerator[direction](sliceNumber);
}

export function createRegionForCTSeries(
  volume: { RASDimensions: [number, number, number] },
  direction: CTSliceDirection,
  sliceNumber: number
): StudyClipRegion {
  const plane = createPlaneFromSliceDirectionAndNumber(direction, sliceNumber);

  return createMockStudyClipRegion(volume, plane);
}
