import { findRegionForContour } from "../measurements/measurement-helpers";
import { createMockStudyClipRegion } from "../measurements/measurement-plane";

export enum RegionType {
  None = "none",
  Tissue = "tissue",
  ColorFlow = "colorFlow",
  PWSpectralDoppler = "pwSpectralDoppler",
  CWSpectralDoppler = "cwSpectralDoppler",
  DopplerMeanTrace = "dopplerMeanTrace",
  DopplerModeTrace = "dopplerModeTrace",
  DopplerMaxTrace = "dopplerMaxTrace",
  VolumeTrace = "volumeTrace",
  ECGTrace = "ecgTrace",
  PulseTrace = "pulseTrace",
  PhonocardiogramTrace = "phonocardiogramTrace",
  GrayBar = "grayBar",
  ColorBar = "colorBar",
  IntegratedBackscatter = "integratedBackscatter",
  AreaTrace = "areaTrace",
  AreaOverTimeDerivative = "areaOverTimeDerivative",
  Other = "other",

  // This region type isn't in the DICOM spec but it's useful to mark mock CT study clip regions
  // which are used for creating 2D measurements along a slice plane
  CTMockRegion = "ctMockRegion",
}

export enum SpatialFormat {
  None = "none",
  TwoDimensional = "twoDimensional",
  MMode = "mMode",
  Spectral = "spectral",
  Waveform = "waveform",
  Graphics = "graphics",
}

export enum RegionUnit {
  None = "none",
  Percent = "percent",
  Decibels = "decibels",
  Centimeters = "centimeters",
  Seconds = "seconds",
  Hertz = "hertz",
  DecibelsPerSecond = "decibelsPerSecond",
  CentimetersPerSecond = "centimetersPerSecond",
  CentimetersSquared = "centimetersSquared",
  CentimetersSquaredPerSecond = "centimetersSquaredPerSecond",
  CentimetersCubed = "centimetersCubed",
  CentimetersCubedPerSecond = "centimetersCubedPerSecond",
  Degrees = "degrees",
}

export interface StudyClipRegion {
  type: RegionType;
  spatialFormat: SpatialFormat;
  flags: number;
  referencePixelX?: number;
  referencePixelY?: number;
  xDirectionUnit: RegionUnit;
  yDirectionUnit: RegionUnit;
  physicalDeltaX: number;
  physicalDeltaY: number;
  left: number;
  top: number;
  right: number;
  bottom: number;

  // Not part of the DICOM spec but it's needed to store the plane that the mock StudyClipRegion
  // is on when we save a measurement on a CT series
  plane?: MeasurementPlane;
}

// Define a plane which CT measurements are taken on by its normal vector and a point on the plane.
// This is used to create a mock study clip region for CT measurements.
export interface MeasurementPlane {
  normal: number[];
  point: number[];
}

export function findClipRegionOrCreateMockRegion(
  clip: { width: number | null; height: number | null; regions: StudyClipRegion[] },
  series: { spacing: number[] | null; dimensions: number[] | null },
  contour: number[],
  plane: MeasurementPlane | undefined
): StudyClipRegion | undefined {
  if (plane !== undefined && series.spacing !== null && series.dimensions !== null) {
    const RASDimensions = series.dimensions.map((d, i) => d * (series.spacing?.[i] ?? 0)) as [
      number,
      number,
      number,
    ];

    return createMockStudyClipRegion(
      {
        RASDimensions,
      },
      plane
    );
  }

  return findRegionForContour(clip, clip.regions, contour);
}
