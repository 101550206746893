<template>
  <div class="settings-title">Manage Global Admins</div>

  <div class="top-row">
    <FilterInput v-model="userSearchTerm" placeholder="Search" />
  </div>

  <div class="users-table">
    <div class="header">
      <div>Name</div>
      <div>Email</div>
      <div />
      <div>Last Seen</div>
      <div>Roles</div>
    </div>

    <div class="header-line" />
    <UserList
      :filtered-users="convertGlobalAdminUsersToDisplayUsers(filteredUsers)"
      :role-list="userAdminRoleList"
      user-route="settings-global-admin-users-view"
      role-route="settings-global-admin-roles-edit"
    />
  </div>

  <ActivityOverlay v-if="activityText" :text="activityText" />
</template>

<script setup lang="ts">
import { GlobalAdminUserGetManyResponseDto } from "@/../../backend/src/tenants/global-admin/dto/global-admin-user-get-many.dto";
import { addNotification } from "@/utils/notifications";
import { useFocus } from "@vueuse/core";
import axios, { type AxiosResponse } from "axios";
import { computed, onMounted, ref } from "vue";
import ActivityOverlay from "../components/ActivityOverlay.vue";
import FilterInput from "../components/FilterInput.vue";
import { useUserAdminRoleList } from "../utils/user-roles-list";
import UserList from "./components/UserList.vue";
import { convertGlobalAdminUsersToDisplayUsers } from "./global-admin";

const activityText = ref("");

const userAdminRoleList = useUserAdminRoleList();

const users = ref<GlobalAdminUserGetManyResponseDto>([]);

const newUserEmailInput = ref<HTMLInputElement>();
useFocus(newUserEmailInput, { initialValue: true });

const userSearchTerm = ref("");

// User search by name and email
const filteredUsers = computed(() => {
  const searchTerm = userSearchTerm.value.toLowerCase();

  return users.value
    .filter(
      (user) =>
        user.name.toLowerCase().includes(searchTerm) ||
        user.email.toLowerCase().includes(searchTerm)
    )
    .sort((a, b) => `${a.name}${a.email}`.localeCompare(`${b.name}${b.email}`));
});

async function fetchUsers(): Promise<void> {
  activityText.value = "Loading";

  let usersResponse: AxiosResponse<GlobalAdminUserGetManyResponseDto> | undefined = undefined;
  try {
    usersResponse = await axios.get<GlobalAdminUserGetManyResponseDto>(`/api/global/users`);
  } catch {
    addNotification({ type: "error", message: "Failed loading user list" });
    return;
  } finally {
    activityText.value = "";
  }

  users.value = usersResponse.data;
}

onMounted(fetchUsers);
</script>
