import Flatten from "@flatten-js/core";
import { v4 as uuidv4 } from "uuid";
import type { StudyMeasurementValueResponseDto } from "../studies/dto/study-get-one.dto";
import { RegionType, RegionUnit, type StudyClipRegion } from "../studies/study-clip-region";
import { StudyMeasurementValueSource } from "../studies/study-measurement-enums";
import type { MeasurementName } from "./measurement-names";
import type { MeasurementUnit } from "./measurement-units";
import { convertMeasurementUnit, getInternalUnitForMeasurementName } from "./measurement-units";

export const TRANSIENT_MEASUREMENT_VALUE_ID = "transient";

export function createTransientValue(
  name: MeasurementName,
  contour: number[] | undefined,
  value: number,
  unit: MeasurementUnit,
  frame: number | undefined
): StudyMeasurementValueResponseDto {
  const newValue = convertMeasurementUnit(value, unit, getInternalUnitForMeasurementName(name));
  if (newValue === null) {
    throw Error("Unable to convert measurement units");
  }

  return {
    id: TRANSIENT_MEASUREMENT_VALUE_ID,
    measurementTool: null,
    measurementCreationBatchId: uuidv4(),
    calculationFormula: null,
    calculationOutputUnit: null,
    calculationVariables: null,
    calculationInputs: [],
    frame: frame ?? null,
    contour: contour ?? null,
    plane: null,
    value: newValue,
    source: StudyMeasurementValueSource.Manual,
    selected: true,
    studyClipId: "",
    createdById: "",
    measurementId: "",
    createdAt: null,
    apiKeyName: null,
    lastUpdatedAt: null,
    lastUpdatedById: "",
  };
}

/** Creates a Flatten.js polygon from the given set of points. */
export function createFlattenPolygon(points: number[]): Flatten.Polygon {
  const flattenPoints = [];
  for (let i = 0; i < points.length; i += 2) {
    flattenPoints.push(Flatten.point(points[i], points[i + 1]));
  }

  return new Flatten.Polygon(flattenPoints);
}

/** Returns whether a point is in a specific clip region. */
export function isPointInRegion(
  clip: { width: number | null; height: number | null },
  region: StudyClipRegion,
  pt: number[]
): boolean {
  // CT mock regions by definition are the entire canvas dimensions, so all points that are on the
  // canvas are in the region
  if (region.type === RegionType.CTMockRegion) {
    return true;
  }

  if (region.xDirectionUnit === RegionUnit.None || region.yDirectionUnit === RegionUnit.None) {
    return false;
  }

  const regionPolygon = new Flatten.Polygon([
    Flatten.point(region.left, region.top),
    Flatten.point(region.left, region.bottom),
    Flatten.point(region.right, region.bottom),
    Flatten.point(region.right, region.top),
  ]);

  const point = Flatten.point(pt[0] * (clip.width ?? 0), pt[1] * (clip.height ?? 0));

  return regionPolygon.contains(point);
}

/**
 * Finds the region that a specific contour is inside. All points of the contour must be in a region
 * for it be be returned.
 */
export function findRegionForContour(
  clip: { width: number | null; height: number | null },
  regions: StudyClipRegion[],
  contour: number[]
): StudyClipRegion | undefined {
  function isContourInRegion(region: StudyClipRegion): boolean {
    return contour.every((_, idx) => {
      if (idx % 2 === 1) {
        return true;
      }

      return isPointInRegion(clip, region, contour.slice(idx, idx + 2));
    });
  }

  return regions.find(isContourInRegion);
}
