<template>
  <svg
    :style="{
      '--progress': Math.max(0, Math.min(100, props.progressPercentage)),
    }"
    class="circular-progress"
    width="25"
    height="50"
    viewBox="0 0 250 250"
  >
    <circle class="bg"></circle>
    <circle class="fg"></circle>
  </svg>
</template>
<script setup lang="ts">
interface Props {
  progressPercentage: number;
}

const props = defineProps<Props>();
</script>

<style scoped lang="scss">
// Adapted from https://www.30secondsofcode.org/css/s/circular-progress-bar/
.circular-progress {
  --size: 250px;
  --half-size: calc(var(--size) / 2);
  --stroke-width: 30px;
  --radius: calc((var(--size) - var(--stroke-width)) / 2);
  --circumference: calc(var(--radius) * pi * 2);
  --dash: calc((var(--progress) * var(--circumference)) / 100);
}

.circular-progress circle {
  cx: var(--half-size);
  cy: var(--half-size);
  r: var(--radius);
  stroke-width: var(--stroke-width);
  fill: none;
  stroke-linecap: round;
}

.circular-progress circle.bg {
  stroke: #ddd;
}

.circular-progress circle.fg {
  transform: rotate(-90deg);
  transform-origin: var(--half-size) var(--half-size);
  stroke-dasharray: var(--dash) calc(var(--circumference) - var(--dash));
  transition: stroke-dasharray 0.3s linear 0s;
  stroke: #5394fd;
}
</style>
