<template>
  <div v-if="reportState" class="container" :class="[reportState.status, displayMode]">
    <div class="cell" :class="[reportState.status, displayMode]">
      <FontAwesomeIcon
        :icon="reportState.status === 'preliminaryReportApproved' ? 'pen' : reportState.icon"
        class="icon"
        :class="[reportState.status, displayMode]"
      />
      <div style="display: flex; flex-direction: column; gap: 2px">
        <div>{{ bannerText }}</div>
      </div>
    </div>

    <div class="dropdown-containers">
      <div v-if="study.reports.length > 1" style="margin-left: auto; flex-basis: 240px">
        <StudyReportsDropdown
          v-model="selectedReportId"
          :variant="
            reportState.status === 'preliminaryReportApproved'
              ? StudyReportStatus.ReportInProgress
              : reportState.status
          "
          :reports="study.reports"
          style="margin-left: auto"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { computed } from "vue";
import { StudyReportType } from "../../../backend/src/studies/study-report-type";
import { Study, StudyReport, StudyReportDescription } from "../utils/study-data";
import { useUserList } from "../utils/users-list";
import { getStudyReportStateDetails, StudyReportStatus } from "./report-status";
import StudyReportsDropdown from "./StudyReportsDropdown.vue";

interface Props {
  study: Study;
  report: StudyReport | null;
  displayMode: StudyReportType;
}

const selectedReportId = defineModel("selectedReportId", {
  type: String,
  required: true,
});

const selectedReportDescription = computed((): StudyReportDescription | undefined => {
  return props.study.reports.find((report) => report.id === selectedReportId.value);
});

const props = defineProps<Props>();

const { userList } = useUserList();

const reportState = computed(() =>
  getStudyReportStateDetails(props.study.reports, userList.value, selectedReportId.value)
);

const bannerText = computed(() => {
  if (selectedReportDescription.value && selectedReportDescription.value.id !== props.report?.id) {
    if (
      selectedReportDescription.value.type === StudyReportType.Final ||
      selectedReportDescription.value.type === StudyReportType.Amendment
    ) {
      return "Signed off report";
    }

    return "Preliminary report";
  }

  if (reportState.value?.status === StudyReportStatus.ReportFinalized) {
    return "Signed off report";
  }

  if (reportState.value?.status === StudyReportStatus.AmendmentInProgress) {
    return "Amending report";
  }

  if (
    reportState.value?.status === StudyReportStatus.ReportInProgress ||
    reportState.value?.status === StudyReportStatus.PreliminaryReportApproved
  ) {
    return props.displayMode === StudyReportType.Final
      ? "Editing final report"
      : "Editing preliminary report";
  }

  return reportState.value?.textDescription;
});
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.amendmentInProgress {
    background-color: var(--accent-bg-color-purple-2);
  }

  &.reportFinalized {
    background-color: var(--report-banner-finalized-bg-color);
  }

  &.reportInProgress,
  &.preliminaryReportApproved {
    &.preliminary {
      background-color: var(--bg-color-5);
    }
    &.final {
      background-color: var(--report-banner-editing-bg-color);
    }
    background-color: var(--bg-color-5);
  }
}

.cell {
  display: flex;
  padding: 16px 16px;
  gap: 8px;
  font-weight: 700;

  &.amendmentInProgress {
    background-color: var(--accent-bg-color-purple-2);
  }

  &.reportFinalized {
    background-color: var(--report-banner-finalized-bg-color);
  }

  &.reportInProgress,
  &.preliminaryReportApproved {
    &.preliminary {
      background-color: var(--bg-color-5);
    }
    &.final {
      background-color: var(--report-banner-editing-bg-color);
    }
    background-color: var(--bg-color-5);
  }
}

.dropdown-containers {
  display: flex;
  gap: 8px;
  padding-right: 8px;
}

.icon {
  &.amendmentInProgress {
    color: var(--accent-color-purple);
  }

  &.reportFinalized {
    color: #00e175;
  }

  &.reportInProgress,
  &.preliminaryReportApproved {
    &.preliminary {
      color: #797979;
    }
    &.final {
      color: #7299ff;
    }
    color: #797979;
  }
}
</style>
