import axios from "axios";
import type { Ref } from "vue";
import { ref } from "vue";
import type { Permission } from "../../../backend/src/auth/permissions/permissions";
import type { GlobalAdminRole } from "../../../backend/src/user/roles/global-admin-role.entity";
import { RoleType } from "../../../backend/src/user/roles/user-role-type";

export interface UserRoleListEntry {
  id: string;
  name: string;
  type: RoleType;
  permissions: Permission[];
  isEnabled: boolean;
}

// Normal user
const userRoleList = ref<UserRoleListEntry[]>([]);
let isLoadInProgress = false;
const isLoaded = ref(false);

export function useUserRoleList(): Ref<UserRoleListEntry[]> {
  if (!isLoaded.value && !isLoadInProgress) {
    void loadUserRoles();
  }

  return userRoleList;
}

async function loadUserRoles(): Promise<void> {
  isLoadInProgress = true;

  try {
    userRoleList.value = (await axios.get<UserRoleListEntry[]>(`/api/user-roles`)).data;

    userRoleList.value = userRoleList.value.filter(
      (role) => role.type !== RoleType.SystemControlledGuest
    );

    isLoaded.value = true;
  } finally {
    isLoadInProgress = false;
  }
}

// Admin user
const adminUserRoleList = ref<GlobalAdminRole[]>([]);
let isAdminLoadInProgress = false;
const isAdminLoaded = ref(false);

export function useUserAdminRoleList(): Ref<GlobalAdminRole[]> {
  if (!isAdminLoaded.value && !isAdminLoadInProgress) {
    void loadAdminUserRoles();
  }

  return adminUserRoleList;
}

async function loadAdminUserRoles(): Promise<void> {
  isAdminLoadInProgress = true;

  try {
    adminUserRoleList.value = (await axios.get<GlobalAdminRole[]>(`/api/global/admin-roles`)).data;

    isAdminLoaded.value = true;
  } finally {
    isAdminLoadInProgress = false;
  }
}
