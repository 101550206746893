<template>
  <Modal
    style="width: 480px"
    center-screen
    title="Export Study List"
    @header-button-click="emits('close')"
  >
    <strong>Refine studies</strong>

    <div class="grid">
      <DatePicker
        v-model="studyDateFilter"
        mode="date"
        is-range
        :max-date="new Date()"
        placeholder="Study date"
        background-color="var(--bg-color-1)"
      />

      <input v-model="performedByFilter" placeholder="Performed by" class="study-list-filter" />

      <UserDropdown
        v-model="assignedUserFilter"
        placeholder="Assigned to"
        class="study-list-filter"
      />

      <input v-model="referredByFilter" placeholder="Referred by" class="study-list-filter" />

      <div />

      <span class="clear-button" @click="clearFilters">Clear</span>
    </div>

    <div class="button-container">
      <button class="export-csv-button accented" @click="exportCSV">
        <FontAwesomeIcon icon="file-export" />
        Export CSV
      </button>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { saveAs } from "file-saver";
import { DateTime } from "luxon";
import { ref } from "vue";
import DatePicker from "../components/DatePicker.vue";
import Modal from "../components/Modal.vue";
import UserDropdown from "../components/UserDropdown.vue";
import { addNotification } from "../utils/notifications";

interface Props {
  studyListFilters: {
    studyDateFilter: { start: Date; end: Date } | null;
    assignedUserFilter: string;
  };
}

interface Emits {
  (event: "close"): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const studyDateFilter = ref<{ start: Date; end: Date } | null>(
  props.studyListFilters.studyDateFilter
);
const assignedUserFilter = ref(props.studyListFilters.assignedUserFilter);
const performedByFilter = ref("");
const referredByFilter = ref("");

async function exportCSV() {
  try {
    const query = new URLSearchParams();
    if (studyDateFilter.value) {
      query.append(
        "takenAtBefore",
        `${DateTime.fromJSDate(studyDateFilter.value.end).toFormat("yyyy-MM-dd")}T23:59:59`
      );
      query.append(
        "takenAtAfter",
        `${DateTime.fromJSDate(studyDateFilter.value.start).toFormat("yyyy-MM-dd")}T00:00:00`
      );
    }

    query.append("assignedUserId", assignedUserFilter.value);
    query.append("performedByFilter", performedByFilter.value);
    query.append("referredByFilter", referredByFilter.value);

    const response = await fetch(`/api/studies/csv?${query.toString()}`);

    const body = response.body;
    if (!body || !response.ok) {
      return;
    }

    const reader = body.getReader();
    const decoder = new TextDecoder();

    let csv = "";
    while (true) {
      const { done, value } = await reader.read();
      if (done) {
        break;
      }

      csv += decoder.decode(value);
    }

    saveAs(new Blob([csv]), "studies.csv");
  } catch {
    addNotification({ type: "error", message: "Failed exporting CSV" });
  }
}

function clearFilters() {
  studyDateFilter.value = null;
  performedByFilter.value = "";
  assignedUserFilter.value = "";
  referredByFilter.value = "";
}
</script>

<style scoped lang="scss">
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
}

.clear-button {
  color: var(--text-color-1);
  margin-left: auto;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: var(--text-color-2);
  }
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.export-csv-button {
  width: 120px;
}
</style>
