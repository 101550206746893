export const TOO_MANY_USERS = "too_many_users";
export const USER_NOT_FOUND = "user_not_found";
export const SSO_NOT_ENABLED = "sso_not_enabled";

export enum AuthFactor {
  EmailPassword = "email-password",
  Microsoft = "microsoft",
  MFA = "mfa",
  StudyShareLink = "study-share-link",
  GlobalAdmin = "global-admin",
}
