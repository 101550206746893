<template>
  <TabsContent class="tabs-content" :class="[props.class]" v-bind="delegatedProps">
    <slot />
  </TabsContent>
</template>

<script setup lang="ts">
import { TabsContent, type TabsContentProps } from "radix-vue";
import { type HTMLAttributes } from "vue";
import { usePropsExcludingClass } from "../ui-utils";

const props = defineProps<TabsContentProps & { class?: HTMLAttributes["class"] }>();
const delegatedProps = usePropsExcludingClass(props);
</script>

<style scoped lang="scss">
.tabs-content {
  display: flex;
  flex-direction: column;

  &:focus-visible {
    outline: 2px solid var(--ring-color);
    outline-offset: 2px;
    box-shadow: 0 0 0 2px var(--background-color);
  }
}
</style>
