<template>
  <div ref="chartContainerElement">
    <canvas ref="lineChartElement"></canvas>
  </div>
</template>

<script setup lang="ts">
import { useResizeObserver } from "@vueuse/core";
import {
  CategoryScale,
  Chart,
  ChartConfiguration,
  Decimation,
  Filler,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { computed, ref, watch, watchEffect } from "vue";
import { formatDateTime } from "../../../backend/src/shared/date-time-utils";
import { getThemeValue } from "../themes";
import { ThemeProperty } from "../themes/theme";

Chart.register(
  LineElement,
  PointElement,
  LineController,
  CategoryScale,
  LinearScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip
);

interface Props {
  samples: {
    date: Date | string;
    value: number;
  }[];
  label: string;
}

const props = defineProps<Props>();

let activityChart: Chart | undefined = undefined;

const chartContainerElement = ref<HTMLElement>();
useResizeObserver(chartContainerElement, () => activityChart?.resize());

const lineChartElement = ref<HTMLCanvasElement>();

const chartLabels = computed(() => props.samples.map((s) => formatDateTime(s.date)));

const chartData = computed(() => props.samples.map((s) => s.value));

const yAxisMax = computed(() => {
  const maxSampleValue = Math.max(...props.samples.map((s) => s.value));

  // Round up to the next multiple of 10
  return maxSampleValue + (10 - (maxSampleValue % 10));
});

const datasetColor = computed(() => getThemeValue(ThemeProperty.ButtonAccentedActiveColor));
const axisColor = computed(() => getThemeValue(ThemeProperty.TextColor1));
const gridLineColor = "#000a";

const chartConfig = computed<ChartConfiguration>(() => ({
  type: "line",
  data: {
    datasets: [{ backgroundColor: datasetColor.value, borderColor: datasetColor.value, data: [] }],
  },
  options: {
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: { color: gridLineColor, borderColor: axisColor.value },
        ticks: { color: axisColor.value },
      },
      y: {
        min: 0,
        grid: { color: gridLineColor, borderColor: axisColor.value },
        ticks: { color: axisColor.value },
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        labels: { color: axisColor.value },
        onClick: (): void => undefined,
      },
      tooltip: {
        backgroundColor: "black",
        boxPadding: 8,
        cornerRadius: 2,
      },
    },
  },
}));

function createChart(): void {
  activityChart?.destroy();

  if (!lineChartElement.value) {
    return;
  }

  activityChart = new Chart(lineChartElement.value, chartConfig.value);
  updateChartData();
}

watchEffect(createChart);

function updateChartData(): void {
  if (activityChart === undefined) {
    return;
  }

  activityChart.data.labels = chartLabels.value;
  activityChart.data.datasets[0].data = chartData.value;
  activityChart.options.scales!.y!.max = yAxisMax.value;
  activityChart.data.datasets[0].label = props.label;

  activityChart.update();
}

watch(() => [props.samples], updateChartData);
</script>
