<template>
  <AccordionItem v-bind="forwarded" :class="['accordion-item', $attrs.class]">
    <AccordionHeader class="accordion-header">
      <AccordionTrigger class="accordion-trigger">
        <slot name="header" />
        <FontAwesomeIcon icon="chevron-right" class="accordion-chevron" />
      </AccordionTrigger>
    </AccordionHeader>
    <AccordionContent class="accordion-content">
      <slot name="content" />
    </AccordionContent>
  </AccordionItem>
</template>

<script setup lang="ts">
/**
 * Todo: We may consider moving header,trigger,content to be separate components such that their styles can be customized more easily.
 * As this fits the measurements pane requirements for now. Though, it can be made more generic if we ever need it elsewhere.
 */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import type { AccordionItemProps } from "radix-vue";
import {
  AccordionContent,
  AccordionHeader,
  AccordionItem,
  AccordionTrigger,
  useForwardProps,
} from "radix-vue";

const props = defineProps<AccordionItemProps>();
const forwarded = useForwardProps(props);
</script>

<style scoped lang="scss">
.accordion-item {
  overflow: hidden;
  margin-top: 1px;

  button {
    padding: 0.5rem;
    &:hover {
      // Unfortunately we have to add styles to disable stock styling - an anti-pattern we should avoid
      background-color: var(--bg-color-3);

      color: var(--text-color-2);
    }
  }
}

.accordion-item:first-child {
  margin-top: 0;
}

.accordion-header {
  display: flex;
  margin: 0;
  border: none;
  border-radius: 0;
  background-color: var(--bg-color-3);
}

.accordion-trigger {
  font-family: inherit;
  background-color: transparent;
  height: 36px;
  flex: 1;
  display: flex;
  font-size: 13px;
  font-weight: 550;
  color: var(--accent-color-1);
  justify-content: space-between;
}

.accordion-chevron {
  transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
}

.accordion-trigger[data-state="open"] > .accordion-chevron {
  transform: rotate(90deg);
}

.accordion-content {
  overflow: hidden;
}

.accordion-content[data-state="open"] {
  animation: slideDown 300ms cubic-bezier(0.87, 0, 0.13, 1);
}
.accordion-content[data-state="closed"] {
  animation: slideUp 300ms cubic-bezier(0.87, 0, 0.13, 1);
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
}
</style>
