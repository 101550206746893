<template>
  <div class="user-roles-toggles">
    <Checkbox
      v-for="role in userRoleList"
      :key="role.id"
      :model-value="modelValue.includes(role.id)"
      :data-testid="`role-toggle-${role.name}`"
      :enabled="isEnabled(role)"
      @update:model-value="toggleRole(role.id)"
    >
      <div class="role-name" :class="{ disabled: !role.isEnabled }">{{ role.name }}</div>
    </Checkbox>
  </div>
</template>

<script setup lang="ts">
import { GlobalAdminRole } from "../../../backend/src/user/roles/global-admin-role.entity";
import { GlobalAdminRoleType, RoleType } from "../../../backend/src/user/roles/user-role-type";
import Checkbox from "../components/Checkbox.vue";
import { UserRoleListEntry, useUserAdminRoleList, useUserRoleList } from "../utils/user-roles-list";

interface Props {
  modelValue: string[];
  enableAdminToggle: boolean;
  enabled?: boolean;
  globalAdmin?: boolean;
}

interface Emits {
  (event: "update:modelValue", roleIds: string[]): void;
}

const props = withDefaults(defineProps<Props>(), { enabled: true });
const emits = defineEmits<Emits>();

const userRoleList = props.globalAdmin ? useUserAdminRoleList() : useUserRoleList();

function isEnabled(role: UserRoleListEntry | GlobalAdminRole): boolean {
  if (!props.enabled) {
    return false;
  }

  if (props.enableAdminToggle) {
    return true;
  }

  if (Object.values(GlobalAdminRoleType).includes(role.type as GlobalAdminRoleType)) {
    return role.type !== GlobalAdminRoleType.SuperGlobalAdmin;
  }

  return role.type !== RoleType.SystemControlledAdmin;
}

function toggleRole(roleId: string): void {
  let newRoleIds: string[] = [];

  if (props.modelValue.includes(roleId)) {
    newRoleIds = props.modelValue.filter((r) => r !== roleId);
  } else {
    newRoleIds = [...props.modelValue, roleId];
  }

  emits("update:modelValue", newRoleIds);
}
</script>

<style scoped lang="scss">
.user-roles-toggles {
  display: grid;
  grid-template-columns: max-content max-content max-content max-content;
  gap: 8px 32px;
}

.role-name {
  font-weight: bold;
  width: 120px;

  &.disabled {
    opacity: 0.7;
  }
}
</style>
