import { z } from "zod";

export const TenantRoleSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
});

export const TenantGetRolesResponseDtoSchema = z.object({
  roles: z.array(TenantRoleSchema).nonempty(),
});

export type TenantGetRolesResponseDto = z.infer<typeof TenantGetRolesResponseDtoSchema>;
export type TenantRole = z.infer<typeof TenantRoleSchema>;
