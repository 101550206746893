<template>
  <div class="windowing-container">
    <div class="windowing-block">
      <span class="windowing-item">
        <strong>WL</strong>
        <input
          :value="ctSettings.windowLevel.value"
          class="windowing-input"
          data-testid="window-level-input"
          type="number"
          @input="updateWindowLevel"
        />
      </span>
    </div>

    <div class="windowing-block">
      <span class="windowing-item">
        <strong>WW</strong>
        <input
          :value="ctSettings.windowWidth.value"
          class="windowing-input"
          data-testid="window-width-input"
          type="number"
          @input="updateWindowWidth"
        />
      </span>
    </div>

    <Popper placement="top-end" :show="isWindowingPresetsDropdownOpen" :offset-distance="4">
      <div
        ref="rootElement"
        class="windowing-block dropdown"
        :class="{ active: isWindowingPresetsDropdownOpen }"
        @click="toggleWindowingPresetPopper"
      >
        <div class="windowing-item" style="height: 24px; cursor: pointer">
          <FontAwesomeIcon :icon="isWindowingPresetsDropdownOpen ? 'caret-down' : 'caret-up'" />
        </div>
      </div>

      <template #content>
        <div class="presets-menu">
          <div
            v-for="[preset, windowing] in Object.entries(windowingPresets)"
            :key="preset"
            class="preset-item"
            @click="onSetWindowingPreset(windowing)"
          >
            {{ preset }}
          </div>
        </div>
      </template>
    </Popper>
  </div>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { onClickOutside } from "@vueuse/core";
import { ref } from "vue";
import Popper from "../../components/Popper.vue";
import { ctSettings } from "./ct-settings";

const windowingPresets = {
  Default: { windowLevel: 300, windowWidth: 800 },
  Abdomen: { windowLevel: 50, windowWidth: 400 },
  Bone: { windowLevel: 500, windowWidth: 2000 },
  Liver: { windowLevel: 60, windowWidth: 160 },
  Lungs: { windowLevel: -600, windowWidth: 1600 },
  "Soft Tissue": { windowLevel: 50, windowWidth: 350 },
};

const isWindowingPresetsDropdownOpen = ref(false);
const rootElement = ref<HTMLDivElement | null>(null);

function toggleWindowingPresetPopper(): void {
  isWindowingPresetsDropdownOpen.value = !isWindowingPresetsDropdownOpen.value;
}

onClickOutside(rootElement, () => {
  isWindowingPresetsDropdownOpen.value = false;
});

function updateWindowLevel(event: Event): void {
  if (!(event.target instanceof HTMLInputElement)) {
    return;
  }

  const newWindowLevel = parseInt(event.target.value);
  ctSettings.windowLevel.value = !isNaN(newWindowLevel) ? newWindowLevel : 0;
}

function updateWindowWidth(event: Event): void {
  if (!(event.target instanceof HTMLInputElement)) {
    return;
  }

  const newWindowWidth = parseInt(event.target.value);
  ctSettings.windowWidth.value = !isNaN(newWindowWidth) ? newWindowWidth : 0;
}

function onSetWindowingPreset(windowing: { windowLevel: number; windowWidth: number }): void {
  ctSettings.windowLevel.value = windowing.windowLevel;
  ctSettings.windowWidth.value = windowing.windowWidth;
  isWindowingPresetsDropdownOpen.value = false;
}
</script>

<style scoped lang="scss">
.windowing-container {
  display: flex;
  gap: 1px;

  background-color: var(--border-color-1);

  border: 1px solid var(--border-color-1);
  border-radius: var(--border-radius);
  overflow: hidden;
}

.windowing-block {
  height: 22px;
  display: flex;
  padding: 4px;
  background-color: var(--bg-color-1);

  &:hover,
  &:focus-within {
    background-color: var(--bg-color-2);
  }

  &.dropdown {
    cursor: pointer;
    background-color: var(--bg-color-2);

    &:hover,
    &.active {
      background-color: var(--bg-color-3);
    }
  }

  .windowing-item {
    display: flex;
    height: 22px;
    padding: 0px 4px;
    align-items: center;

    &:not(input) {
      cursor: default;
    }
  }
}

.windowing-input {
  width: 30px;
  padding: 0px 0px 0px 8px;
  border: 0;
  text-align: right;
  cursor: text;
  background-color: transparent;
}

:deep(.popper) {
  padding: 0;
}

.presets-menu {
  display: flex;
  flex-direction: column;
  justify-items: center;
  width: 100px;
}

.preset-item {
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;

  &:hover {
    background-color: var(--bg-color-2);
    color: var(--accent-color-2);
  }
}
</style>
