export enum AuditAction {
  StudyCreated = "study:created",
  StudyViewed = "study:viewed",
  StudyEdited = "study:edited",
  StudyReprocessed = "study:reprocessed",
  StudyAssigned = "study:assigned",
  StudyParticipantUpdated = "study:participant-updated",
  StudyDeleted = "study:deleted",

  StudyBilled = "study:billed",
  StudyBillingError = "study:billing-error",

  StudyClipDeleted = "study-clip:deleted",
  StudyClipDownloaded = "study-clip:downloaded",

  StudyReportCreated = "study-report:created",
  StudyReportViewed = "study-report:viewed",
  StudyReportEdited = "study-report:edited",
  StudyReportDeleted = "study-report:deleted",
  StudyReportCompleted = "study-report:completed",
  StudyReportSigned = "study-report:signed",
  StudyReportSignIncorrectPin = "study-report:sign-incorrect-pin",

  StudyListQueried = "study-list:queried",

  StudyFileUploaded = "study:file-uploaded",
  StudyFileUploadError = "study:file-upload-error",

  StudyAttachedFileUploaded = "study-attached-file:uploaded",
  StudyAttachedFileViewed = "study-attached-file:viewed",
  StudyAttachedFileDeleted = "study-attached-file:deleted",

  StudyShareLinkCreated = "study:share-link:created",
  StudyShareLinkUpdated = "study:share-link:updated",
  StudyShareLinkEmailSent = "study:share-link:email-sent",
  StudyShareLinkLocked = "study:share-link:locked",

  StudyClipProcessed = "study-clip:processed",
  StudyClipProcessingError = "study-clip:processing-error",
  StudyClipUnrecognizedMeasurements = "study-clip:unrecognized-measurements",

  StudySeriesNRRDProcessed = "study-series:nrrd-processed",
  StudySeriesNRRDProcessingError = "study-series:nrrd-processing-error",

  StudyUpdatePatientIdentity = "study:update-patient-identity",

  StudyWebhookActivated = "study:webhook-activated",

  SecondaryWindowOpened = "secondary-window:opened",
  SecondaryWindowClosed = "secondary-window:closed",

  StressModeOpened = "stress-mode:opened",
  StressModeClosed = "stress-mode:closed",

  MeasurementCreated = "measurement:created",
  MeasurementCreateFailed = "measurement:create-failed",
  MeasurementEdited = "measurement:edited",
  MeasurementDeleted = "measurement:deleted",
  MeasurementAutomationRun = "measurement:automation-run",

  MeasurementSequenceCreated = "measurement-sequence:created",
  MeasurementSequenceUpdated = "measurement-sequence:updated",
  MeasurementSequenceDeleted = "measurement-sequence:deleted",

  MeasurementCalculationCreated = "measurement-calculation:created",
  MeasurementCalculationUpdated = "measurement-calculation:updated",
  MeasurementCalculationDeleted = "measurement-calculation:deleted",

  ReportTemplateCreated = "report-template:created",
  ReportTemplateUpdated = "report-template:updated",
  ReportTemplateDeleted = "report-template:deleted",
  ReportTemplateVersionCreated = "report-template:version-created",
  ReportTemplateVersionUpdated = "report-template:version-updated",
  ReportTemplateVersionDeleted = "report-template:version-deleted",
  ReportTemplateVersionPublished = "report-template:version-published",

  ReportSentenceGroupCreated = "report-sentence-group:created",
  ReportSentenceGroupUpdated = "report-sentence-group:updated",
  ReportSentenceGroupDeleted = "report-sentence-group:deleted",

  SessionCreated = "session:created",
  SessionCreateFailed = "session:create-failed",
  SessionDestroyed = "session:destroyed",

  UserIdentityCreated = "user-identity:created",
  UserIdentityUpdated = "user-identity:updated",
  UserIdentityPasswordChanged = "user-identity:password-changed",
  UserIdentityPasswordChangeFailed = "user-identity:password-change-failed",
  UserIdentityPasswordResetRequested = "user-identity:password-reset-requested",
  UserIdentityMFAUpdated = "user-identity:mfa-updated",
  UserIdentityLocked = "user-identity:locked",
  UserIdentityUnlocked = "user-identity:unlocked",

  UserInvitationCreated = "user:invited",
  UserInvitationAccepted = "user:invitation:accepted",
  UserInvitationAcceptFailed = "user:invitation:accept-failed",
  UserInvitationCancelled = "user:invite-cancelled",

  UserCreated = "user:created",
  UserDeleted = "user:deleted",
  UserDisabled = "user:disabled",
  UserEnabled = "user:enabled",
  UserPinUpdated = "user:pin-updated",
  UserPinUpdateFailed = "user:pin-update-failed",
  UserPinTooManyAttempts = "user:pin-too-many-attempts",
  UserSignatureUpdated = "user:signature-updated",
  UserOnboarded = "user:onboarded",
  UserApiKeyCreated = "user:api-key:created",
  UserApiKeyUpdated = "user:api-key:updated",
  UserApiKeyDeleted = "user:api-key:deleted",
  UserNameUpdated = "user:name-updated",
  UserDictationEnabledUpdated = "user:dictation-enabled-updated",
  UserDefaultsDisplayOptionsUpdated = "user:defaults-display-options-updated",

  UserRoleCreated = "user-role:created",
  UserRoleUpdated = "user-role:updated",
  UserRoleDeleted = "user-role:deleted",

  AdminUserPermissionsUpdated = "admin:user-permissions-updated",
  AdminUserEdited = "admin:user-edited",

  AdminMFARequiredUpdated = "admin:mfa-required-updated",
  AdminStudySharingEnabledUpdated = "admin:study-sharing-enabled-updated",
  AdminAPIKeyAccessEnabledUpdated = "admin:api-keys-access-enabled-updated",
  AdminPatientIDLabelUpdated = "admin:patient-id-label-updated",
  AdminStudyParticipantLabelUpdated = "admin:study-participant-label-updated",
  AdminEmailSendingEnabledUpdated = "admin:email-sending-enabled-updated",
  AdminSessionTimeoutsUpdated = "admin:session-timeouts-updated",
  AdminPatientNameFormatUpdated = "admin:patient-name-format:updated",
  AdminStudyParticipantRoleUpdated = "admin:study-participant-role-updated",
  AdminUnauthorizedTenantLogin = "admin:unauthorized-tenant-login",

  DicomAssociationAborted = "dicom:association-aborted",
  DicomAssociationAccepted = "dicom:association-accepted",
  DicomAssociationRejected = "dicom:association-rejected",
  DicomAssociationReleased = "dicom:association-released",
  DicomDuplicateIgnored = "dicom:duplicate-ignored",
  DicomMessageReceived = "dicom:message-received",
  DicomMessageResponse = "dicom:message-response",
  DicomQueryUnsupported = "dicom:query-unsupported",
  DicomRetrieved = "dicom:retrieved",
  DicomServerError = "dicom:server-error",

  // This audit action is deprecated and no longer used
  DicomUncompressedFormatDeprecated = "dicom:uncompressed-format",

  DicomClientIdentityCreated = "dicom:client-identity-created",
  DicomClientIdentityUpdated = "dicom:client-identity-updated",
  DicomClientIdentityDeleted = "dicom:client-identity-deleted",

  DicomEndpointCreated = "dicom:endpoint:created",
  DicomEndpointUpdated = "dicom:endpoint:updated",
  DicomEndpointDeleted = "dicom:endpoint:deleted",
  DicomEndpointQueried = "dicom:endpoint:queried",
  DicomEndpointEchoed = "dicom:endpoint:echoed",
  DicomEndpointInstanceRetrieved = "dicom:endpoint:instance-retrieved",
  DicomEndpointInstanceRetrieveError = "dicom:endpoint:instance-retrieve-error",
  DicomEndpointInstanceSendQueued = "dicom:endpoint:instance-send-queued",
  DicomEndpointInstanceSent = "dicom:endpoint:instance-sent",
  DicomEndpointInstanceSendError = "dicom:endpoint:instance-send-error",
  DicomEndpointStorageCommitmentResponseError = "dicom:endpoint:storage-commitment-response:error",
  DicomEndpointStorageCommitmentResponseQueued = "dicom:endpoint:storage-commitment-response:queued",
  DicomEndpointStorageCommitmentResponseSent = "dicom:endpoint:storage-commitment-response:sent",

  EmailSend = "email:send",
  EmailSendError = "email:send-error",

  AgentIntegrationCreated = "integration:agent:created",
  AgentIntegrationDeleted = "integration:agent:deleted",

  HeartBoxIntegrationCreated = "integration:heartbox:created",
  HeartBoxIntegrationUpdated = "integration:heartbox:updated",
  HeartBoxIntegrationDeleted = "integration:heartbox:deleted",

  HL7IntegrationCreated = "integration:hl7:created",
  HL7IntegrationUpdated = "integration:hl7:updated",
  HL7IntegrationDeleted = "integration:hl7:deleted",

  HL7IntegrationRecipientCreated = "integration:hl7:recipient:created",
  HL7IntegrationRecipientUpdated = "integration:hl7:recipient:updated",
  HL7IntegrationRecipientDeleted = "integration:hl7:recipient:deleted",

  HL7MessageDownload = "hl7:message-download",
  HL7MessageSend = "hl7:message-send",
  HL7MessageSendFailed = "hl7:message-send-failed",

  SftpIntegrationCreated = "integration:sftp:created",
  SftpIntegrationUpdated = "integration:sftp:updated",
  SftpIntegrationDeleted = "integration:sftp:deleted",
  SftpIntegrationFileSend = "integration:sftp:file-sent",
  SftpIntegrationFileSendFailed = "integration:sftp:file-send-failed",

  TenantCreated = "tenant:created",
  TenantUpdated = "tenant:updated",

  WebhookIntegrationCreated = "integration:webhook:created",
  WebhookIntegrationUpdated = "integration:webhook:updated",
  WebhookIntegrationDeleted = "integration:webhook:deleted",
  WebhookIntegrationRequestSent = "integration:webhook:request-sent",
  WebhookIntegrationRequestFailed = "integration:webhook:request-failed",

  DicomScheduledProcedureStepCreated = "dicom-scheduled-procedure-step:created",
  DicomScheduledProcedureStepDeleted = "dicom-scheduled-procedure-step:deleted",
  DicomScheduledProcedureStepUpdated = "dicom-scheduled-procedure-step:updated",

  SupportTicketCreated = "support-ticket:created",
  SupportTicketRequestFailed = "support-ticket:request-failed",
}

/** The following audit actions appear as errors in the audit log UI. */
export function isAuditActionError(action: AuditAction): boolean {
  return [
    AuditAction.DicomAssociationRejected,
    AuditAction.DicomEndpointInstanceRetrieveError,
    AuditAction.DicomEndpointInstanceSendError,
    AuditAction.DicomEndpointStorageCommitmentResponseError,
    AuditAction.DicomServerError,
    AuditAction.EmailSendError,
    AuditAction.HL7MessageSendFailed,
    AuditAction.MeasurementCreateFailed,
    AuditAction.SftpIntegrationFileSendFailed,
    AuditAction.SessionCreateFailed,
    AuditAction.StudyClipProcessingError,
    AuditAction.StudySeriesNRRDProcessingError,
    AuditAction.StudyFileUploadError,
    AuditAction.SupportTicketRequestFailed,
    AuditAction.UserInvitationAcceptFailed,
    AuditAction.UserPinUpdateFailed,
    AuditAction.WebhookIntegrationRequestFailed,
    AuditAction.StudyBillingError,
  ].includes(action);
}

/**
 * The following audit log events result in monitoring alerts via Datadog when they occur. In
 * addition to these, some other audit actions trigger monitoring errors in certain circumstances,
 * e.g. the study clip processor reports a monitored error only when the last processing attempt
 * failed, and the DICOM server reports a monitored error when an unknown error occurs.
 */
export function isAuditActionErrorMonitored(action: AuditAction): boolean {
  return [
    AuditAction.EmailSendError,
    AuditAction.HL7MessageSendFailed,
    AuditAction.SftpIntegrationFileSendFailed,
    AuditAction.StudyFileUploadError,
    AuditAction.SupportTicketRequestFailed,
    AuditAction.StudyBillingError,
    AuditAction.AdminUnauthorizedTenantLogin,
  ].includes(action);
}
