<template>
  <TabsList v-bind="forwardedProps" class="tabs-list">
    <slot />
  </TabsList>
</template>

<script setup lang="ts">
import { TabsList, useForwardProps, type TabsListProps } from "radix-vue";
import { type HTMLAttributes } from "vue";
import { usePropsExcludingClass } from "../ui-utils";

const props = defineProps<
  TabsListProps & { class?: HTMLAttributes["class"]; tooltipContent?: string }
>();

const forwardedProps = useForwardProps(usePropsExcludingClass(props));
</script>

<style scoped lang="scss">
.tabs-list {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2rem 0 0.2rem 0.2rem;

  :deep(.tabs-trigger) {
    border: 1.5px solid var(--border-color-1);
    border-right: none;

    &:first-child {
      border-top-left-radius: var(--radius-full);
      border-bottom-left-radius: var(--radius-full);
    }

    &:not(:first-child):not(:last-child) {
      border-radius: 0;
    }

    &:last-child {
      border-top-right-radius: var(--radius-full);
      border-bottom-right-radius: var(--radius-full);
      border-right: 1.5px solid var(--border-color-1);
    }
  }
}
</style>
