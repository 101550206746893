<template>
  <div
    class="clip-thumbnail"
    :data-study-clip-id="clip.id"
    :data-test-uid="clip.sopInstanceUid"
    :data-test-selected="isSelected"
    :data-test-selected-on-secondary-window="isSelectedOnSecondaryWindow"
  >
    <template v-if="clip.processedAt !== null">
      <div
        draggable="true"
        @dragstart="onDragStart($event)"
        @mousemove="isHovered = true"
        @mouseout="isHovered = false"
        @click="emits('click')"
      >
        <div v-if="clip.hasEncapsulatedPDF" class="encapsulated-pdf-logo" crossorigin="anonymous">
          <FontAwesomeIcon icon="file-pdf" />
        </div>
        <img v-else :src="imageUrl" />
      </div>

      <ClipNumberOverlay :clip-number="clipNumber" :clip-count="clipCount" />

      <div class="measurement-icon">
        <FontAwesomeIcon
          v-if="jumpMeasurementValues.length > 0"
          icon="ruler"
          size="lg"
          @click="onMeasurementIconClick"
        />
      </div>
      <div
        v-if="isSelected || isSelectedOnSecondaryWindow || isContextMenuVisible"
        class="selection-box"
        :class="{
          primary: isSelected,
          secondary: isSelectedOnSecondaryWindow,
          dimmed: contextMenuClipId !== null && !isContextMenuVisible,
          'context-menu-visible': isContextMenuVisible,
        }"
      />
    </template>
    <div
      v-else
      class="processing-thumbnail"
      @dragstart="onDragStart($event)"
      @click="emits('click')"
    >
      <LoadingIndicator />
    </div>
  </div>
</template>

<script setup lang="ts">
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import type { Study, StudyClip, StudyMeasurementValue } from "@/utils/study-data";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { computed, ref } from "vue";
import { StudyClipImageDataType } from "../../../backend/src/studies/study-clip-image-data-type";
import { getStudyClipImageDataUrl } from "../study-view/study-clip-image-data";
import ClipNumberOverlay from "./ClipNumberOverlay.vue";

interface Props {
  study: Study;
  clip: StudyClip;
  clipNumber: number;
  clipCount: number;
  isSelected: boolean;
  isSelectedOnSecondaryWindow: boolean;
  contextMenuClipId: string | null;
}

interface Emits {
  (event: "click"): void;
  (event: "measurement-icon-click", measurementValue: StudyMeasurementValue): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const isContextMenuVisible = computed(() => props.contextMenuClipId === props.clip.id);
const isHovered = ref(false);

function onDragStart(evt: DragEvent): void {
  evt.dataTransfer!.dropEffect = "move";
  evt.dataTransfer!.effectAllowed = "move";
  evt.dataTransfer!.setData("clipId", props.clip.id);
}

const imageUrl = computed(() => {
  const hasAnimatedThumbnail =
    props.clip.fps !== null && props.clip.modality !== "CT" && props.clip.modality !== "MR";

  return (
    getStudyClipImageDataUrl(
      props.study,
      props.clip,
      isHovered.value && hasAnimatedThumbnail
        ? StudyClipImageDataType.AnimatedThumbnail
        : StudyClipImageDataType.Thumbnail
    ) ?? ""
  );
});

// Measurement values to cycle around on repeated clicks of the measurement overlay icon
const jumpMeasurementValues = computed(() =>
  props.study.measurements
    .map((measurement) => measurement.values)
    .flat()
    .filter(
      (measurementValue) =>
        measurementValue.studyClipId === props.clip.id &&
        measurementValue.frame !== null &&
        measurementValue.contour !== null
    )
    .sort((a, b) => a.frame! - b.frame!)
);

let lastJumpMeasurementIndex = -1;
function onMeasurementIconClick(): void {
  emits(
    "measurement-icon-click",
    jumpMeasurementValues.value[
      (lastJumpMeasurementIndex += 1) % jumpMeasurementValues.value.length
    ]
  );
}
</script>

<style scoped lang="scss">
.clip-thumbnail {
  position: relative;
  cursor: grab;

  &:hover {
    :deep(.clip-number-overlay) {
      opacity: 1;
    }
  }
}

img,
.encapsulated-pdf-logo {
  display: block;
  width: 100%;

  // Show the thumbnail as a square box prior to the thumbnail image actually loading
  aspect-ratio: 1;
}

.encapsulated-pdf-logo {
  display: grid;
  place-content: center;
  font-size: 24px;
  color: var(--text-color-1);
}

.processing-thumbnail {
  display: grid;
  place-content: center;
  padding: calc(50% - 13px) 0;
}

.measurement-icon {
  position: absolute;
  left: 4px;
  bottom: 7px;
  color: #0f0;
  opacity: 0.5;
  cursor: pointer;
  transition: opacity 100ms ease;

  &:hover {
    opacity: 0.9;
  }
}

.selection-box {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;

  &.secondary {
    border: 2px solid var(--accent-color-hot);
  }

  &.primary {
    border: 2px solid var(--clip-list-selected-clip-border-color);
  }

  &.dimmed {
    filter: brightness(0.4);
  }

  &.context-menu-visible {
    border: 2px solid var(--accent-color-1);
  }

  // When a clip is selected on both the main app and an extra clips secondary window put both
  // highlights on it, one inset inside the other
  &.primary.secondary:not(.context-menu-visible) {
    outline: 2px solid var(--accent-color-hot);
    outline-offset: -4px;
  }
}
</style>
