<template>
  <div v-if="role !== undefined" class="user-role-edit">
    <WarningBlock
      v-if="role.type !== RoleType.TenantControlled"
      data-testid="warning-role-not-editable"
    >
      This role can't be edited because it is controlled by the system
    </WarningBlock>

    <div class="field" :class="{ disabled: !isEditable }">
      <b>Name</b>
      <div style="display: flex; align-items: center">
        <input
          v-model="role.name"
          maxlength="100"
          data-testid="role-name-input"
          @input="emits('save-role')"
        />

        <Tooltip
          v-if="hasUserRoleManagePermission && isEditable && !isGlobalAdminRole"
          :content="
            role.isEnabled ? 'This role must be disabled in order to delete it' : 'Delete role'
          "
          style="margin-left: auto"
          placement="left"
        >
          <button
            :disabled="role.isEnabled"
            data-testid="delete-role-button"
            @click="emits('delete-role')"
          >
            <FontAwesomeIcon icon="trash" />
          </button>
        </Tooltip>
      </div>
    </div>

    <div v-if="!isGlobalAdminRole" class="field" :class="{ disabled: !isEditable }">
      <div class="toggle">
        <b>Enabled</b>
        <ToggleSwitch
          v-model="role.isEnabled"
          data-testid="role-enabled-toggle"
          @update:model-value="emits('save-role')"
        />
      </div>

      <div>
        When this role is disabled no permissions are granted to users who have been assigned the
        role.
      </div>
    </div>

    <div class="field" style="overflow-y: auto">
      <div class="permissions-groups" :class="{ disabled: !isEditable }">
        <div class="column-header">Permission</div>
        <div class="column-header">Details</div>
        <slot>No permissions available</slot>
        <ActivityOverlay v-if="activityText !== ''" :text="activityText" />
      </div>
    </div>
  </div>

  <ActivityOverlay v-if="activityText !== ''" :text="activityText" />
</template>

<script setup lang="ts">
import WarningBlock from "@/components/WarningBlock.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { GlobalAdminRole } from "../../../backend/src/user/roles/global-admin-role.entity";
import { RoleType } from "../../../backend/src/user/roles/user-role-type";
import { hasUserRoleManagePermission } from "../auth/authorization";
import ActivityOverlay from "../components/ActivityOverlay.vue";
import ToggleSwitch from "../components/ToggleSwitch.vue";
import Tooltip from "../components/Tooltip.vue";
import { UserRoleListEntry } from "../utils/user-roles-list";

interface Props {
  id: string;
  isEditable?: boolean;
  isGlobalAdminRole?: boolean;
  activityText: string;
}

interface Emits {
  (event: "save-role"): void;
  (event: "delete-role"): void;
}

const role = defineModel<UserRoleListEntry | GlobalAdminRole | undefined>();

defineProps<Props>();

const emits = defineEmits<Emits>();
</script>
<style scoped lang="scss">
input {
  width: 300px;
}
</style>
