<template>
  <div class="settings-title">
    DICOM Endpoints

    <OutboundSourceIpLabel />
  </div>

  <div class="top-row">
    <FilterInput v-model="filterTerm" placeholder="Search" />
    <button class="accented" style="margin-left: auto" @click="openDicomEndpointModal">
      Create DICOM Endpoint
    </button>
  </div>

  <div class="dicom-endpoints-table">
    <div class="header">
      <div>Name</div>
      <div>Tenant</div>
      <div>SCP AE Title</div>
      <div>SCU AE Title</div>
      <div>Address</div>
      <div>Last Used</div>
    </div>

    <div class="header-line" />

    <div
      v-for="dicomEndpoint in filteredDicomEndpoints"
      :key="dicomEndpoint.id"
      class="grid-table-row"
      @click="viewDicomEndpoint(dicomEndpoint.id)"
    >
      <div>{{ dicomEndpoint.name }}</div>
      <div>{{ allTenants.find((tenant) => tenant.id === dicomEndpoint.tenantId)?.name }}</div>
      <div>{{ dicomEndpoint.scpAeTitle }}</div>
      <div v-if="dicomEndpoint.scuAeTitle !== ''">{{ dicomEndpoint.scuAeTitle }}</div>
      <i v-else>Tenant default</i>
      <code>{{ `${dicomEndpoint.address}:${dicomEndpoint.port}` }}</code>
      <div>{{ formatRelativeTime(dicomEndpoint.lastUsedAt) }}</div>
    </div>
  </div>

  <ActivityOverlay v-if="isLoading" text="Loading" />

  <Modal
    v-if="isCreateDicomEndpointModalVisible"
    title="Create DICOM Endpoint"
    :activity-text="isCreatingDicomEndpoint ? 'Creating endpoint' : ''"
    @header-button-click="isCreateDicomEndpointModalVisible = false"
  >
    <div class="modal-container">
      <b>Tenant</b>
      <DropdownWidget
        v-model="newDicomEndpoint.tenantId"
        :items="allTenants.map((tenant) => ({ value: tenant.id, text: tenant.name }))"
        style="min-height: 30px"
      />
      <b>Name</b>
      <input v-model="newDicomEndpoint.name" type="text" />
      <b>SCP AE Title</b>
      <input v-model="newDicomEndpoint.scpAeTitle" type="text" />
      <b>Retrieve Method</b>
      <DropdownWidget
        v-model="newDicomEndpoint.retrieveMethod"
        :items="
          Object.values(DicomRetrieveMethod).map((method) => ({
            value: method,
            text: method.replace(`C`, `C-`),
          }))
        "
        style="min-height: 30px"
      />
      <b>Address</b>
      <input v-model="newDicomEndpoint.address" type="text" />
      <b>Port</b>
      <input v-model="newDicomEndpoint.port" type="number" @keydown.enter="createDicomEndpoint" />

      <button
        class="accented create-endpoint-button"
        :disabled="!isNewEndpointValid"
        @click="createDicomEndpoint"
      >
        Create DICOM Endpoint
      </button>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import router from "@/router";
import axios from "axios";
import { computed, onMounted, ref } from "vue";
import type { DicomEndpointGetManyResponseDto } from "../../../../../backend/src/dicom/dto/dicom-endpoint-get-many.dto";
import { formatRelativeTime } from "../../../../../backend/src/shared/date-time-utils";
import {
  AE_TITLE_MAX_LENGTH,
  DicomRetrieveMethod,
  PORT_MAX,
  PORT_MIN,
} from "../../../../../backend/src/shared/dicom";
import { DNS_REGEXP } from "../../../../../backend/src/shared/validation";
import { DicomEndpointCreateUpdateRequestDto } from "../../../../../backend/src/tenants/global-admin/dto/dicom-endpoint-create-update.dto";
import ActivityOverlay from "../../../components/ActivityOverlay.vue";
import DropdownWidget from "../../../components/DropdownWidget.vue";
import FilterInput from "../../../components/FilterInput.vue";
import Modal from "../../../components/Modal.vue";
import { useTenants } from "../../../utils/all-tenants";
import { addNotification } from "../../../utils/notifications";
import { getRequestErrorMessage } from "../../../utils/request-helpers";
import OutboundSourceIpLabel from "../OutboundSourceIpLabel.vue";

const isLoading = ref(false);

const allTenants = useTenants();

const dicomEndpoints = ref<DicomEndpointGetManyResponseDto>([]);

async function loadDicomEndpoints(): Promise<void> {
  try {
    dicomEndpoints.value = (
      await axios.get<DicomEndpointGetManyResponseDto>(`/api/global/dicom-endpoints`)
    ).data;
  } catch {
    addNotification({ type: "error", message: "Failed loading list of DICOM endpoints" });
  }
}

onMounted(loadDicomEndpoints);

const filterTerm = ref("");

const isCreateDicomEndpointModalVisible = ref(false);
const isCreatingDicomEndpoint = ref(false);

const defaultDicomEndpoint: DicomEndpointCreateUpdateRequestDto = Object.freeze({
  tenantId: "",
  name: "",
  scpAeTitle: "",
  scuAeTitle: "",
  retrieveMethod: DicomRetrieveMethod.CGET,
  address: "",
  port: 0,
});

const newDicomEndpoint = ref<DicomEndpointCreateUpdateRequestDto>({ ...defaultDicomEndpoint });

// DICOM endpoint search by name, AE title, IP and port
const filteredDicomEndpoints = computed(() => {
  const searchTerm = filterTerm.value.toLowerCase();

  return dicomEndpoints.value.filter(
    (endpoint) =>
      endpoint.name.toLowerCase().includes(searchTerm) ||
      endpoint.scpAeTitle.toLowerCase().includes(searchTerm) ||
      endpoint.scuAeTitle.toLowerCase().includes(searchTerm) ||
      `${endpoint.address}:${endpoint.port.toString()}`.toLowerCase().includes(searchTerm)
  );
});

const isNewEndpointValid = computed(() => {
  const isTenantIdValid = newDicomEndpoint.value.tenantId !== "";
  const isNameValid = newDicomEndpoint.value.name.trim().length !== 0;
  const isScpAeTitleValid =
    newDicomEndpoint.value.scpAeTitle.length > 0 &&
    newDicomEndpoint.value.scpAeTitle.length < AE_TITLE_MAX_LENGTH;
  const isAddressValid = DNS_REGEXP.test(newDicomEndpoint.value.address);
  const isPortValid =
    newDicomEndpoint.value.port >= PORT_MIN && newDicomEndpoint.value.port <= PORT_MAX;

  return isTenantIdValid && isNameValid && isScpAeTitleValid && isAddressValid && isPortValid;
});

async function viewDicomEndpoint(dicomEndpointId: string): Promise<void> {
  await router.push({ name: "settings-dicom-endpoints-view", params: { id: dicomEndpointId } });
}

async function createDicomEndpoint(): Promise<void> {
  if (!isNewEndpointValid.value) {
    return;
  }

  isCreatingDicomEndpoint.value = true;

  try {
    await axios.post(`/api/global/dicom-endpoints`, newDicomEndpoint.value);
  } catch (error) {
    addNotification({
      type: "error",
      message: getRequestErrorMessage(error) ?? "Error creating DICOM endpoint",
    });
    return;
  } finally {
    isCreatingDicomEndpoint.value = false;
  }

  addNotification({ type: "info", message: "Created DICOM endpoint" });

  void loadDicomEndpoints();

  isCreateDicomEndpointModalVisible.value = false;
}

function openDicomEndpointModal(): void {
  isCreateDicomEndpointModalVisible.value = true;
  newDicomEndpoint.value = { ...defaultDicomEndpoint };
}
</script>

<style scoped lang="scss">
.top-row {
  display: grid;
  grid-template-columns: 300px 1fr;
  align-items: center;
}

.dicom-endpoints-table {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
  row-gap: 2px;

  .header {
    font-weight: bold;
    display: contents;

    > * {
      padding-bottom: 8px;
    }
  }

  .header-line {
    grid-area: 2 / 1 / 2 / span 6;
    border-bottom: 2px solid var(--bg-color-3);
  }
}

.modal-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 400px;

  b {
    margin-top: 4px;
  }

  button {
    width: max-content;
    align-self: center;
    margin-top: 8px;
  }
}
</style>
