<template>
  <FontAwesomeIcon :icon="icon" :class="chevronClass" />
</template>
<script setup lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { computed } from "vue";

interface Props {
  isDropdownOpen: boolean;
  icon?: string;
  /**
   * If enabled, the chevron will invert its rotation
   */
  invertRotation?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  icon: "chevron-up",
  invertRotation: false,
});

const chevronClass = computed(() =>
  props.isDropdownOpen === props.invertRotation ? "chevron-rotate-up" : "chevron-rotate-down"
);
</script>
<style scoped lang="scss">
.chevron-rotate-down {
  animation: rotate-180-down 0.5s ease forwards;
}

.chevron-rotate-up {
  animation: rotate-180-up 0.5s ease forwards;
}

@keyframes rotate-180-down {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}

@keyframes rotate-180-up {
  from {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(0deg);
  }
}
</style>
