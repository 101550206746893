<template>
  <div
    v-for="user in filteredUsers"
    :key="user.id"
    class="grid-table-row"
    :class="{ invited: user.inviteExpiresAt !== null, disabled: user.disabledAt !== null }"
    :data-testid="`user-${user.email}`"
    @click="viewUser(user)"
  >
    <div>{{ user.name }}</div>
    <div>{{ user.email }}</div>
    <div>
      <Badge v-if="user.mfaEnabled" class="mfa-badge">MFA</Badge>
    </div>
    <div>{{ user.inviteExpiresAt === null ? formatRelativeTime(user.lastSeenAt) : "" }}</div>
    <BadgeList
      :badges="
        roleList
          .filter((role) => user.roleIds.includes(role.id))
          .map((role) => ({
            text: role.name,
            isEnabled: role.isEnabled,
            routerLinkTo: { name: roleRoute, params: { id: role.id } },
          }))
      "
    />
  </div>
</template>

<script setup lang="ts">
import { formatRelativeTime } from "../../../../backend/src/shared/date-time-utils";
import Badge from "../../components/Badge.vue";
import BadgeList from "../../components/BadgeList.vue";
import router from "../../router";
import { DisplayedUser } from "../global-admin";

interface Props {
  filteredUsers: DisplayedUser[];
  roleList: {
    id: string;
    name: string;
    isEnabled: boolean;
  }[];
  userRoute: string;
  roleRoute: string;
}

const props = defineProps<Props>();

async function viewUser(user: DisplayedUser): Promise<void> {
  await router.push({ name: props.userRoute, params: { id: user.id } });
}
</script>
