<template>
  <div class="settings-title">Report PDF Sending</div>

  <div class="settings-group-title">Report PDF Filenames</div>

  <div class="field">
    <p>
      The following settings are used to configure the file name of reports when downloaded in
      HeartLab, downloaded by an Agent integration or sent over SFTP. Variables can be used in the
      file name by wrapping them in curly braces, e.g.
      <code>&lcub;&lcub; ... &rcub;&rcub;</code>.
    </p>

    <p>The following variables can be used as part of a report name:</p>

    <div class="simple-table">
      <div />
      <div />
      <code>studyId</code>
      <span>UUID of the study</span>
      <code>studyDate</code>
      <span>Date of the study in <code>yyyy-MM-dd</code> format</span>
      <code>studyDateFormatted</code>
      <span>Date of the study in <code>dd MMM yyyy</code> format</span>
      <code>studyDateTime</code>
      <span>Date and time of the study in <code>ddMMyyyyHHmmss</code> format</span>
      <code>studyType</code>
      <span>Type of the study</span>
      <code>patientId</code>
      <span>ID of the patient</span>
      <code>patientBirthdate</code>
      <span>Birthdate of the patient in <code>yyyy-MM-dd</code> format</span>
      <code>patientBirthdateInDMY</code>
      <span>Birthdate of the patient in <code>ddMMyyyy</code> format</span>
      <code>patientFirstName</code>
      <span>First name of the patient</span>
      <code>patientLastName</code>
      <span>Last name of the patient</span>
      <code>completedBy</code>
      <span>Name of the user who completed the study</span>
    </div>
  </div>

  <ReportPDFNameTemplateInput
    v-model:template="inAppDownloadReportNameTemplate"
    template-name="In-app Download"
    @update:template="onUpdateDebounced"
  />

  <ReportPDFNameTemplateInput
    v-model:template="sftpReportNameTemplate"
    template-name="SFTP Sending"
    @update:template="onUpdateDebounced"
  />

  <ReportPDFNameTemplateInput
    v-model:template="agentReportNameTemplate"
    template-name="Agent Download"
    @update:template="onUpdateDebounced"
  />
</template>

<script setup lang="ts">
import { currentTenant } from "@/auth/current-session";
import { addNotification } from "@/utils/notifications";
import { useDebounceFn } from "@vueuse/core";
import axios from "axios";
import { ref } from "vue";
import { StudyReportNameTemplateUpdateDto } from "../../../backend/src/studies/dto/study-report-name-template.dto";
import { getPdfNameTemplateErrors } from "../../../backend/src/studies/study-report-pdf-name-evaluation";
import ReportPDFNameTemplateInput from "./ReportPDFNameTemplateInput.vue";

const inAppDownloadReportNameTemplate = ref(currentTenant.inAppDownloadReportFilenameTemplate);
const sftpReportNameTemplate = ref(currentTenant.sftpSendingReportFilenameTemplate);
const agentReportNameTemplate = ref(currentTenant.agentDownloadReportFilenameTemplate);

async function onUpdateTemplates(): Promise<void> {
  const updates: StudyReportNameTemplateUpdateDto = {};

  if (getPdfNameTemplateErrors(inAppDownloadReportNameTemplate.value) === null) {
    updates.inAppDownloadReportFilenameTemplate = inAppDownloadReportNameTemplate.value;
  }

  if (getPdfNameTemplateErrors(sftpReportNameTemplate.value) === null) {
    updates.sftpSendingReportFilenameTemplate = sftpReportNameTemplate.value;
  }

  if (getPdfNameTemplateErrors(agentReportNameTemplate.value) === null) {
    updates.agentDownloadReportFilenameTemplate = agentReportNameTemplate.value;
  }

  if (Object.keys(updates).length === 0) {
    return;
  }

  try {
    await axios.post("/api/studies/report-pdf-name-template", updates);
    addNotification({
      type: "info",
      message: `Updated ${Object.keys(updates).length === 3 ? "" : "valid "} report PDF name templates`,
    });
  } catch {
    addNotification({
      type: "error",
      message: "Failed to update report PDF name templates",
    });
  }
}

const onUpdateDebounced = useDebounceFn(() => {
  void onUpdateTemplates();
}, 1000);
</script>

<style scoped lang="scss">
.field {
  display: grid;
  gap: 8px;
}

.simple-table {
  display: grid;
  grid-template-columns: minmax(250px, max-content) 1fr;
  gap: 4px 32px;
  align-items: center;
}

code {
  font-size: 0.9em;
  background: var(--bg-color-3);
  width: max-content;
  padding: 1px 3px;
  border-radius: var(--border-radius);

  user-select: text;
  -webkit-user-select: text;

  > span {
    font-style: italic;
  }
}
</style>
