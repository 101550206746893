import type { PatientResponseDto } from "../../../backend/src/patients/dto/patient-get-one.dto";
import { PatientSex } from "../../../backend/src/patients/patient-sex";

export type Patient = PatientResponseDto;

export function getPatientSexDisplayText(patientSex: PatientSex): string {
  return {
    [PatientSex.Male]: "Male",
    [PatientSex.Female]: "Female",
    [PatientSex.Other]: "Other",
    [PatientSex.Unknown]: "",
  }[patientSex];
}
