import type { MathJsInstance } from "mathjs";
import {
  FractionDependencies,
  LN10Dependencies,
  LN2Dependencies,
  LOG10EDependencies,
  LOG2EDependencies,
  PIDependencies,
  absDependencies,
  addDependencies,
  booleanDependencies,
  ceilDependencies,
  compareDependencies,
  compareTextDependencies,
  concatDependencies,
  create,
  cubeDependencies,
  divideDependencies,
  evaluateDependencies,
  expDependencies,
  floorDependencies,
  formatDependencies,
  log10Dependencies,
  log1pDependencies,
  log2Dependencies,
  logDependencies,
  meanDependencies,
  medianDependencies,
  multiplyDependencies,
  nthRootDependencies,
  nthRootsDependencies,
  numberDependencies,
  numericDependencies,
  piDependencies,
  powDependencies,
  printDependencies,
  printTransformDependencies,
  roundDependencies,
  sqrtDependencies,
  squareDependencies,
  stringDependencies,
  subtractDependencies,
  sumDependencies,
} from "mathjs";

// Add an uppercase() function, useful in report templates to make the patient last name be
// uppercase
function uppercase(value: unknown): string {
  if (typeof value !== "string") {
    return "";
  }

  return value.toUpperCase();
}

function getMathJsInstance(): MathJsInstance {
  const mathjs = create({
    absDependencies,
    addDependencies,
    booleanDependencies,
    ceilDependencies,
    concatDependencies,
    compareDependencies,
    compareTextDependencies,
    cubeDependencies,
    divideDependencies,
    evaluateDependencies,
    expDependencies,
    floorDependencies,
    formatDependencies,
    FractionDependencies,
    LN2Dependencies,
    LN10Dependencies,
    log1pDependencies,
    log2Dependencies,
    LOG2EDependencies,
    log10Dependencies,
    LOG10EDependencies,
    logDependencies,
    meanDependencies,
    medianDependencies,
    multiplyDependencies,
    nthRootDependencies,
    nthRootsDependencies,
    numberDependencies,
    numericDependencies,
    piDependencies,
    PIDependencies,
    powDependencies,
    printDependencies,
    printTransformDependencies,
    roundDependencies,
    sqrtDependencies,
    squareDependencies,
    stringDependencies,
    subtractDependencies,
    sumDependencies,
  });

  mathjs.import({ uppercase });

  return mathjs;
}

export const mathjsInstance = getMathJsInstance();

// Regex to match content enclosed in double curly braces, e.g. "Hello, {{ patient.name }}"
// Ideally this would use a positive look behind here to avoid having to remove the braces manually
// after the fact, but that's currently not supported in Safari.
export const CALCULATION_REGEX = /{{((.|\n)*?)}}/g;

export function extractCalculationsFromTemplate(template: string): string[] {
  return template.match(CALCULATION_REGEX)?.map((c) => c.slice(2, c.length - 2)) ?? [];
}
