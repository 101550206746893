import type { Ref } from "vue";
import { ref, shallowReactive } from "vue";

/**
 * Global settings for all CT viewers. This ensures the same windowing is used across all CTs.
 */
interface CTSettings {
  windowLevel: Ref<number>;
  windowWidth: Ref<number>;
}

function createCTSettings(): CTSettings {
  const windowLevel = ref(300);
  const windowWidth = ref(800);

  return shallowReactive({
    windowLevel,
    windowWidth,
  });
}

export const ctSettings = createCTSettings();
