import { isEqual } from "lodash";
import type { Ref } from "vue";
import { isPointInRegion } from "../../../../backend/src/measurements/measurement-helpers";
import { createRegionForCTSeries } from "../../../../backend/src/measurements/measurement-plane";
import { MeasurementToolName } from "../../../../backend/src/measurements/measurement-tool-names";
import { isPointWithinTolerance } from "../../../../backend/src/shared/math-utils";
import type { StudyClipRegionResponseDto } from "../../../../backend/src/studies/dto/study-get-one.dto";
import {
  activeMeasurement,
  restartMeasuring,
  startMeasuring,
} from "../../measurements/measurement-tool-state";
import { findRegionOfPoint } from "../../measurements/tools/measurement-tool-helpers";
import type { Study, StudyClip } from "../../utils/study-data";
import { convertSliceNumberToRASDimension } from "../ct/ct-renderer";
import { useStudyViewImageData } from "../study-view-image-data";
import type { ClipsGridItem } from "./clips-grid-item";
import { ClipsGridItemType } from "./clips-grid-item";

/**
 * A MeasurementsController is responsible for handling mouse events on the canvas and triggering
 * the appropriate effect on the active measurement tool.
 */
export interface MeasurementsController {
  handleCanvasMouseDown(event: MouseEvent): Promise<void>;
  handleCanvasMouseMove(event: MouseEvent): boolean;
  handleCanvasContextMenu(event: MouseEvent): Promise<void>;
  handleCanvasContainerMouseUp(): void;
}

export function createMeasurementsController(args: {
  study: Study;
  model: ClipsGridItem;
  canvas: HTMLCanvasElement;
  currentlyVisibleFrame: Ref<number | undefined>;
}): MeasurementsController {
  const { study, model, canvas, currentlyVisibleFrame } = args;

  let currentCanvasMousePosition: number[] = [];

  async function getRegion(clip: StudyClip): Promise<StudyClipRegionResponseDto | undefined> {
    if (model.type === ClipsGridItemType.CTClip) {
      const volume = await useStudyViewImageData().getThreeVolumeForSeries(study.id, clip.seriesId);

      const rasSliceNumber = convertSliceNumberToRASDimension(
        volume,
        model.sliceDirection.value,
        model.sliceNumber.value
      );

      return createRegionForCTSeries(volume, model.sliceDirection.value, rasSliceNumber);
    }

    return findRegionOfPoint(clip, currentCanvasMousePosition)?.region;
  }

  async function selectRegionAndMousedown(pt: number[]): Promise<void> {
    const clip = model.clip;
    if (clip === undefined) {
      return;
    }

    const region = await getRegion(clip);

    if (!activeMeasurement.value.isMeasurableOnStudyClip(clip, region)) {
      return;
    }

    if (!isEqual(region, activeMeasurement.value.region) && region !== undefined) {
      restartMeasuring({ studyClipId: undefined, region });

      if (activeMeasurement.value.shouldPassMousedownAfterRegionSelection) {
        activeMeasurement.value.onCanvasMouseDown(pt);
      }
    } else if (region !== undefined) {
      activeMeasurement.value.onCanvasMouseDown(pt);
    }
  }

  function updateCanvasCursor(): void {
    const interactivePoints = activeMeasurement.value.interactivePoints.value;

    for (let i = 0; i < interactivePoints.length; i += 2) {
      if (isPointWithinTolerance(currentCanvasMousePosition, interactivePoints.slice(i, i + 2))) {
        canvas.style.cursor = "pointer";
        return;
      }
    }

    canvas.style.cursor = "default";
  }

  async function handleCanvasMouseDown(event: MouseEvent): Promise<void> {
    // Update the clip being measured
    if (model.clip && activeMeasurement.value.studyClipId === "") {
      restartMeasuring({ studyClipId: model.clip.id, region: undefined });
      activeMeasurement.value.onFrameChange(currentlyVisibleFrame.value ?? 0);
    } else if (model.clip && activeMeasurement.value.studyClipId !== model.clip.id) {
      restartMeasuring({ studyClipId: model.clip.id, region: undefined });
      activeMeasurement.value.onFrameChange(currentlyVisibleFrame.value ?? 0);
    }

    // Tell the active measurement about this mousedown event
    const clientRect = canvas.getBoundingClientRect();
    const pt = [
      (event.clientX - clientRect.x) / clientRect.width,
      (event.clientY - clientRect.y) / clientRect.height,
    ];

    await selectRegionAndMousedown(pt);
  }

  function handleCanvasMouseMove(event: MouseEvent): boolean {
    const clientRect = canvas.getBoundingClientRect();
    currentCanvasMousePosition = [
      (event.clientX - clientRect.x) / clientRect.width,
      (event.clientY - clientRect.y) / clientRect.height,
    ];

    updateCanvasCursor();

    if (
      model.clip &&
      activeMeasurement.value.region &&
      activeMeasurement.value.studyClipId === model.clip.id &&
      isPointInRegion(model.clip, activeMeasurement.value.region, currentCanvasMousePosition)
    ) {
      return activeMeasurement.value.onCanvasMouseMove(currentCanvasMousePosition);
    }

    return false;
  }

  function handleCanvasContainerMouseUp() {
    if (
      model.clip &&
      activeMeasurement.value.studyClipId === model.clip.id &&
      (model.type === ClipsGridItemType.CTClip ||
        activeMeasurement.value.region ===
          findRegionOfPoint(model.clip, currentCanvasMousePosition)?.region)
    ) {
      activeMeasurement.value.onCanvasMouseUp(currentCanvasMousePosition);
    }
  }

  async function handleCanvasContextMenu(event: MouseEvent) {
    if (model.clip === undefined) {
      return;
    }

    const clientRect = canvas.getBoundingClientRect();
    const pt = [
      (event.clientX - clientRect.x) / clientRect.width,
      (event.clientY - clientRect.y) / clientRect.height,
    ];

    startMeasuring({
      tool: MeasurementToolName.Distance,
      study,
      clipId: model.clip.id,
      region: findRegionOfPoint(model.clip, pt)?.region,
      informOtherWindows: true,
    });

    await selectRegionAndMousedown(pt);
  }

  return {
    handleCanvasMouseDown,
    handleCanvasMouseMove,
    handleCanvasContextMenu,
    handleCanvasContainerMouseUp,
  };
}
