<template>
  <Modal title="Download Agent" @header-button-click="emits('close')">
    <div class="downloads-content">
      <div class="downloads-header">HeartLab Agent {{ agentDownloads.version }}</div>

      <div class="download-options">
        <a
          class="download"
          :href="agentDownloads.platforms['windows-x86_64'].url.replace('.nsis.zip', '.exe')"
          target="_blank"
        >
          <FontAwesomeIcon class="brand-icon" :icon="['fab', 'windows']" />
          <div class="label">Windows (64-bit)</div>
        </a>

        <a
          class="download"
          :href="agentDownloads.platforms['darwin-aarch64'].url.replace('.tar.gz', '.dmg')"
          target="_blank"
        >
          <FontAwesomeIcon class="brand-icon" :icon="['fab', 'apple']" />
          <div class="label">Apple Silicon</div>
        </a>
      </div>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import Modal from "@/components/Modal.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { AgentDownloadsGetResponseDto } from "../../../../../backend/src/integrations/agent/dto/agent-downloads-get.dto";

interface Props {
  agentDownloads: AgentDownloadsGetResponseDto;
}

interface Emits {
  (event: "close"): void;
}

defineProps<Props>();
const emits = defineEmits<Emits>();
</script>

<style scoped lang="scss">
.downloads-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.downloads-header {
  font-size: 16px;
  font-weight: bold;
}

.download-options {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  padding-bottom: 12px;

  .download {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    gap: 8px;

    .label {
      font-size: 16px;
    }
  }
}

.brand-icon {
  font-size: 48px;
}
</style>
