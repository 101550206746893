<template>
  <div ref="row" data-testid="study-list-row" class="grid-table-row" @click="onClick">
    <Tooltip
      v-if="props.study.processedClipsCount !== props.study.clipsCount"
      :content="`Study is being received and processed (${percentProcessed} complete)`"
    >
      <LoadingIndicator />
    </Tooltip>
    <StudyListReportStatus v-else :study="study" :class="{ hovered: isRowHovered }" />

    <div class="cell">
      <Tooltip
        v-if="hasErroredClips"
        :content="`There ${props.study.erroredClipsCount > 1 ? 'were' : 'was'} ${
          props.study.erroredClipsCount
        } error${props.study.erroredClipsCount > 1 ? 's' : ''} processing this study's clips`"
      >
        <FontAwesomeIcon icon="triangle-exclamation" style="color: red" />
      </Tooltip>

      <div class="text-content">
        {{ formatDicomName(study.patientName, currentTenant.patientNameFormat) }}
      </div>
    </div>

    <div v-if="isStudyListColumnVisible(StudyListColumn.PatientID)" class="cell">
      <div class="text-content">{{ study.patientId }}</div>
    </div>

    <div v-if="isStudyListColumnVisible(StudyListColumn.PatientBirthdate)" class="cell">
      <div class="text-content">{{ formatDateTime(study.patientBirthdate) }}</div>
    </div>

    <div class="cell">
      <div class="text-content">
        {{ formatDateTime(getStudyDateTime(study), { includeTime: true }) }}
      </div>
    </div>

    <div class="cell">
      <div class="text-content">{{ study.modality }}</div>
    </div>

    <div
      v-if="isStudyListColumnVisible(StudyListColumn.StudyType)"
      class="cell dropdown-selection"
      @click.stop
    >
      <StudyTypeDropdown
        :model-value="study.type"
        :disabled="!hasStudyUpdatePermission"
        :target-study="study"
        style="border: none"
        data-testid="study-type-dropdown"
      />
    </div>

    <div v-if="isStudyListColumnVisible(StudyListColumn.Institute)" class="cell">
      <div class="text-content">{{ study.institution }}</div>
    </div>

    <div v-if="isStudyListColumnVisible(StudyListColumn.PerformedBy)" class="cell">
      {{ formatDicomName(study.performingPhysician) }}
    </div>

    <div
      v-if="isStudyListColumnVisible(StudyListColumn.Trainee)"
      class="cell dropdown-selection"
      @click.stop
    >
      <StudyParticipantDropdown
        :study="study"
        field="traineeUserId"
        data-testid="study-trainee-dropdown"
        :required-role-id="currentTenant.traineeRoleId ?? undefined"
      />
    </div>

    <div
      v-if="isStudyListColumnVisible(StudyListColumn.Technician)"
      class="cell dropdown-selection"
      @click.stop
    >
      <StudyParticipantDropdown
        :study="study"
        field="technicianUserId"
        data-testid="study-technician-dropdown"
        :required-role-id="currentTenant.technicianRoleId ?? undefined"
      />
    </div>

    <div
      v-if="isStudyListColumnVisible(StudyListColumn.Physician)"
      class="cell dropdown-selection"
      @click.stop
    >
      <StudyParticipantDropdown
        :study="study"
        field="physicianUserId"
        data-testid="study-physician-dropdown"
        :required-role-id="currentTenant.physicianRoleId ?? undefined"
      />
    </div>

    <div class="cell dropdown-selection" @click.stop>
      <StudyParticipantDropdown
        :study="study"
        field="assignedUserId"
        data-testid="study-assignee-dropdown"
      />
    </div>

    <div class="cell" style="justify-content: flex-end" @click.stop>
      <Tooltip content="View report" :disabled="!isReported">
        <FontAwesomeIcon
          icon="file-lines"
          class="report-icon"
          :class="{ hidden: !isReported }"
          :data-testid="`view-report-button-${study.id}`"
          @click.stop="emits('view-report')"
        />
      </Tooltip>

      <StudyUrgentFlag :study="study" />

      <StudyDropdownPopper
        :study="study"
        :is-study-open="false"
        @study-deleted="emits('study-deleted')"
        @patient-identity-updated="emits('patient-identity-updated')"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { formatDicomName } from "@/../../backend/src/shared/dicom-helpers";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import Tooltip from "@/components/Tooltip.vue";
import {
  getStudyProcessedClipPercentage,
  isStudyListColumnVisible,
  type Study,
} from "@/utils/study-data";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useElementHover } from "@vueuse/core";
import { computed, ref } from "vue";
import { formatDateTime } from "../../../backend/src/shared/date-time-utils";
import { getStudyDateTime } from "../../../backend/src/studies/study-time";
import { StudyListColumn } from "../../../backend/src/tenants/study-list-column";
import { hasStudyUpdatePermission } from "../auth/authorization";
import { currentTenant } from "../auth/current-session";
import StudyDropdownPopper from "../components/StudyDropdownPopper.vue";
import StudyParticipantDropdown from "../components/StudyParticipantDropdown.vue";
import StudyTypeDropdown from "../components/StudyTypeDropdown.vue";
import StudyUrgentFlag from "../components/StudyUrgentFlag.vue";
import StudyListReportStatus from "./StudyListReportStatus.vue";

interface Props {
  study: Study;
}

interface Emits {
  (event: "view-report"): void;
  (event: "study-clicked"): void;
  (event: "study-deleted"): void;
  (event: "patient-identity-updated"): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const row = ref<HTMLDivElement | null>();
const isRowHovered = useElementHover(row);

const isReported = computed(() =>
  props.study.reports.some((report) => report.completedAt !== null)
);

const hasErroredClips = computed(() => props.study.erroredClipsCount > 0);

function onClick(): void {
  emits("study-clicked");
}

const percentProcessed = computed(() => getStudyProcessedClipPercentage(props.study));
</script>

<style scoped lang="scss">
.cell {
  display: flex;
  align-items: center;
  gap: 8px;

  // Text content wrapper that ensures ellipsis overflow works
  .text-content {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  padding-left: 8px;
  border-radius: 0;

  &:first-child {
    padding: 0;
  }
}

.dropdown-selection {
  display: flex;
  padding-right: 8px;

  :deep(.dropdown-widget) {
    height: 28px;
  }
}

.report-icon {
  color: var(--accent-color-1);
  transition: color 100ms ease;

  &:hover {
    color: var(--accent-color-2);
  }

  &.hidden {
    visibility: hidden;
  }
}
</style>
