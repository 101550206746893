<template>
  <Modal title="Share History" :activity-text="activityText" @header-button-click="emits('close')">
    <div class="study-share-history-modal">
      <div class="share-links-table">
        <div class="header-cell">Recipient</div>
        <div class="header-cell">Expires In</div>
        <div class="header-cell">Created By</div>
        <div class="header-cell">Last Opened</div>
        <div class="header-cell" />

        <div
          v-for="shareLink in shareLinks"
          :key="shareLink.id"
          class="grid-table-row share-links-table-row"
          :class="{ inactive: !shareLink.enabled || isExpired(shareLink) }"
        >
          <div>
            <div class="text-content">{{ shareLink.recipientName }}</div>
          </div>
          <div>
            <i v-if="isExpired(shareLink)"> Expired </i>
            <template v-else-if="shareLink.enabled">
              {{ formatRelativeTime(shareLink.expiresAt).substring(3) }}
            </template>
            <i v-else> Disabled </i>
          </div>
          <div>
            <div class="text-content">{{ shareLink.createdBy }}</div>
          </div>
          <div>
            <i v-if="shareLink.lastUsedAt === null"> Never </i>
            <template v-else>
              {{ formatRelativeTime(shareLink.lastUsedAt) }}
            </template>
          </div>
          <div>
            <Tooltip v-if="shareLink.enabled && !isExpired(shareLink)" content="Disable share link">
              <FontAwesomeIcon
                icon="trash"
                class="hover-icon"
                @click="disableShareLink(shareLink)"
              />
            </Tooltip>
          </div>
        </div>
      </div>

      <div v-if="shareLinks.length === 0 && activityText === ''" class="not-shared-message">
        This study has not been shared
      </div>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import Modal from "@/components/Modal.vue";
import { addNotification } from "@/utils/notifications";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import axios, { AxiosResponse } from "axios";
import { DateTime } from "luxon";
import { onMounted, ref } from "vue";
import { formatRelativeTime } from "../../../backend/src/shared/date-time-utils";
import type { StudyShareLinkGetManyResponseDto } from "../../../backend/src/studies/sharing/dto/study-share-link-get-many.dto";
import Tooltip from "./Tooltip.vue";
interface Props {
  studyId: string;
}

interface Emits {
  (event: "close"): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const shareLinks = ref<StudyShareLinkGetManyResponseDto>([]);

const activityText = ref("");

onMounted(() => {
  void getStudyShareLinkHistory();
});

async function getStudyShareLinkHistory(): Promise<void> {
  activityText.value = "Loading";

  let response: AxiosResponse<StudyShareLinkGetManyResponseDto> | undefined = undefined;
  try {
    response = await axios.get<StudyShareLinkGetManyResponseDto>(
      `/api/studies/${props.studyId}/share-links`
    );
  } catch (error) {
    addNotification({ type: "error", message: "Failed getting share link history" });
    return;
  } finally {
    activityText.value = "";
  }

  shareLinks.value = response.data;
}

async function disableShareLink(shareLink: {
  id: string;
  recipientName: string;
  enabled: boolean;
}): Promise<void> {
  if (!confirm("Are you sure you want to disable this share link?")) {
    return;
  }

  activityText.value = "Disabling share link";

  try {
    await axios.patch(`/api/studies/${props.studyId}/share-links/${shareLink.id}`, {
      enabled: false,
    });
  } catch (error) {
    addNotification({ type: "error", message: "Failed disabling share link" });
    return;
  } finally {
    activityText.value = "";
  }

  shareLink.enabled = false;

  addNotification({ type: "info", message: `Disabled share link` });
}

function isExpired(shareLink: { expiresAt: Date | string }): boolean {
  return (
    DateTime.fromISO(shareLink.expiresAt as string)
      .diffNow()
      .toMillis() < 0
  );
}
</script>

<style scoped lang="scss">
.study-share-history-modal {
  height: 50vh;
  max-width: 80vw;
  display: flex;
  flex-direction: column;
}

.loading-indicator {
  place-self: center;
  padding-top: 32px;
}

.not-shared-message {
  place-self: center;
  padding-top: 32px;
}

.header-cell {
  top: 0;
  position: sticky;
  height: 24px;
  background-color: var(--bg-color-2);
  border-bottom: 2px solid var(--bg-color-4);
  font-weight: bold;
  padding-right: 16px;

  &:first-child {
    padding-left: 8px;
  }
}

.share-links-table {
  display: grid;
  grid-template-columns: minmax(100px, 1fr) 120px minmax(100px, 1fr) 120px auto;
  align-items: center;
  row-gap: 2px;
  padding: 0 8px;
  margin-top: 8px;
  overflow-y: auto;
}

.text-content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.inactive {
  cursor: inherit;

  &:hover {
    color: var(--text-color-1);

    > * {
      background-color: var(--bg-color-2);
    }
  }

  > * {
    color: #777;
  }
}

.hover-icon {
  cursor: pointer;
  color: var(--accent-color-1);
  transition: color 100ms ease;

  &:hover {
    color: var(--accent-color-2);
  }
}

.passcode {
  color: rgba(0, 0, 0, 0);
  transition: color 100ms ease;
  cursor: text;
}

// Show the passcode when the mouse hovers the row
.share-links-table-row {
  &:not(.inactive) {
    &:hover {
      .passcode {
        color: var(--text-color-2);
      }
    }
  }
}
</style>
