<template>
  <div class="global-admin-initiated">
    <span class="message">Logged in via Global Admin Portal</span>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.global-admin-initiated {
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  justify-content: center;
  animation:
    fade-in-and-down 0.2s ease-out,
    shake 0.5s ease-out 0.2s;
  background-color: #f43948;
  border: 1px solid #f5c6cb;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

@keyframes fade-in-and-down {
  from {
    opacity: 0;
    transform: translateX(-50%) translateY(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
  }
}

@keyframes shake {
  0%,
  100% {
    transform: translateX(-50%);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-52%);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translateX(-48%);
  }
}

.message {
  color: white;
  font-size: 0.9em;
  font-weight: bold;
  padding: 8px 24px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
</style>
