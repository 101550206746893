<template>
  <Modal
    title="Update Patient Identity"
    :activity-text="isUpdating ? 'Updating' : ''"
    @header-button-click="emits('close')"
  >
    <div v-if="isStudyUpdatePermitted(study)" class="study-patient-update-modal">
      <div v-if="patientStudies.length > 1" class="other-studies-warning">
        <div data-testid="related-study-count-text">
          <b>Warning:</b> {{ studyCountText }} for this patient, but updating the details below will
          only affect <i>this</i> study.
        </div>

        <PatientStudiesPopper
          :patient-studies="patientStudies"
          :excluded-study-id="study.id"
          :is-secondary-window-open-allowed="isStudyOpen && getWindowType() === WindowType.Primary"
        >
          <div class="other-studies-details-button">Other Studies</div>
        </PatientStudiesPopper>
      </div>

      <div class="name-fields">
        <div class="field">
          <strong>First name</strong>
          <input
            ref="firstNameInputElement"
            v-model="patientFirstName"
            data-testid="update-patient-first-name-input"
          />
        </div>
        <div class="field">
          <strong>Middle name</strong>
          <input v-model="patientMiddleName" data-testid="update-patient-middle-name-input" />
        </div>
        <div class="field">
          <strong>Last name</strong>
          <input v-model="patientLastName" data-testid="update-patient-last-name-input" />
        </div>
        <div class="field">
          <strong>Prefix</strong>
          <input v-model="patientNamePrefix" data-testid="update-patient-prefix-input" />
        </div>
        <div class="field">
          <strong>Suffix</strong>
          <input v-model="patientNameSuffix" data-testid="update-patient-suffix-input" />
        </div>
      </div>

      <div />

      <div class="other-fields">
        <div class="field">
          <strong>{{ currentTenant.patientIdLabel }}</strong>
          <input v-model="patientId" data-testid="update-patient-id-input" />
        </div>

        <div class="field">
          <strong>Birthdate</strong>
          <input
            v-model="patientBirthDate"
            placeholder="YYYY-MM-DD"
            data-testid="update-patient-birth-date-input"
          />
        </div>

        <div class="field">
          <strong>Sex</strong>
          <DropdownWidget
            v-model="patientSex"
            :items="
              Object.values(PatientSex).map((sex) => ({
                value: sex,
                text: getPatientSexDisplayText(sex),
              }))
            "
            data-testid="update-patient-sex"
          />
        </div>
      </div>

      <div />

      <div class="field">
        <strong>Reason</strong>
        <input
          v-model="reason"
          placeholder="Enter the reason for updating the patient identity for this study"
          data-testid="reason-input"
        />
      </div>

      <Tooltip
        :content="buttonTooltip"
        style="width: max-content; align-self: center"
        placement="bottom"
      >
        <button
          class="accented"
          :disabled="!isFormSubmittable"
          data-testid="update-patient-identity-button"
          @click="updatePatientIdentity"
        >
          Update Patient Identity
        </button>
      </Tooltip>
    </div>

    <div v-else class="study-patient-update-modal">
      <div class="signed-report-message">
        It is not possible to update the patient's identity once the report is signed.
        <br />
        <br />
        To update the patient's identity: start a report amendment, then make the required updates,
        and then sign the amended report.
      </div>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import { useFocus } from "@vueuse/core";
import axios from "axios";
import { DateTime } from "luxon";
import { computed, ref, toRefs } from "vue";
import { evaluateCalculatedMeasurements } from "../../../backend/src/measurements/measurement-calculation-evaluation";
import { PatientSex } from "../../../backend/src/patients/patient-sex";
import { mathjsInstance } from "../../../backend/src/shared/mathjs";
import { isStudyUpdatePermitted } from "../auth/authorization";
import { currentTenant } from "../auth/current-session";
import PatientStudiesPopper from "../study-view/PatientStudiesPopper.vue";
import { WindowType, getWindowType } from "../study-view/multi-window/secondary-window";
import { addNotification } from "../utils/notifications";
import { getPatientSexDisplayText } from "../utils/patient-data";
import { usePatientStudies } from "../utils/patient-studies";
import { getRequestErrorMessage } from "../utils/request-helpers";
import { Study } from "../utils/study-data";
import DropdownWidget from "./DropdownWidget.vue";
import Modal from "./Modal.vue";
import Tooltip from "./Tooltip.vue";

interface Props {
  study: Study;
  isStudyOpen: boolean;
}

interface Emits {
  (event: "close"): void;
  (event: "patient-identity-updated"): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const patientLastName = ref(props.study.patientName[0] ?? "");
const patientFirstName = ref(props.study.patientName[1] ?? "");
const patientMiddleName = ref(props.study.patientName[2] ?? "");
const patientNamePrefix = ref(props.study.patientName[3] ?? "");
const patientNameSuffix = ref(props.study.patientName[4] ?? "");
const patientId = ref(props.study.patientId);
const patientBirthDate = ref(props.study.patientBirthdate ?? "");
const patientSex = ref(props.study.patientSex);

const reason = ref("");

const firstNameInputElement = ref<HTMLInputElement>();
useFocus(firstNameInputElement, { initialValue: true });

const isUpdating = ref(false);

// Check if patientBirthDate is of format YYYY-MM-DD
const isBirthDateValid = computed(() => {
  if (patientBirthDate.value.trim().length === 0) {
    return true;
  }

  return DateTime.fromFormat(patientBirthDate.value, "yyyy-LL-dd").isValid;
});

const isReasonValid = computed(() => reason.value.trim().length > 0);

// True when all required fields are filled out and birthdate is valid
const isFormSubmittable = computed(() => isBirthDateValid.value && isReasonValid.value);

const buttonTooltip = computed(() => {
  if (!isReasonValid.value) {
    return "Enter the reason for updating the patient identity for this study";
  }

  if (!isBirthDateValid.value) {
    return "Birth date must be in format YYYY-MM-DD";
  }

  return "";
});

async function updatePatientIdentity(): Promise<void> {
  isUpdating.value = true;

  let sanitizedPatientName = [
    patientLastName.value.trim(),
    patientFirstName.value.trim(),
    patientMiddleName.value.trim(),
    patientNamePrefix.value.trim(),
    patientNameSuffix.value.trim(),
  ].map(removeInvalidCharacters);

  const sanitizedPatientId = removeInvalidCharacters(patientId.value.trim());

  // Allow the birthdate to be nulled out by setting an empty string
  const sanitizedPatientBirthdate = patientBirthDate.value.trim() || null;

  // Remove trailing empty strings from the name
  while (sanitizedPatientName[sanitizedPatientName.length - 1] === "") {
    sanitizedPatientName.pop();
  }

  try {
    await axios.patch(`/api/studies/${props.study.id}/patient-identity`, {
      patientName: sanitizedPatientName,
      patientId: sanitizedPatientId,
      patientBirthdate: sanitizedPatientBirthdate,
      patientSex: patientSex.value,
      reason: reason.value.trim(),
    });
  } catch (error) {
    addNotification({
      type: "error",
      message: getRequestErrorMessage(error) ?? "Failed to update patient",
    });
    return;
  } finally {
    isUpdating.value = false;
  }

  const study = props.study;
  study.patientName = sanitizedPatientName;
  study.patientId = sanitizedPatientId;
  study.patientBirthdate = sanitizedPatientBirthdate;
  study.patientSex = patientSex.value;

  addNotification({ type: "info", message: "Updated patient identity" });

  evaluateCalculatedMeasurements(mathjsInstance, props.study);

  emits("patient-identity-updated");
  emits("close");
}

// Removes any control characters as well as caretes which are used as delimiters in DICOM names
function removeInvalidCharacters(value: string): string {
  // eslint-disable-next-line no-control-regex
  return value.replace(/[\x00-\x1F\\^]/g, "");
}

const patientStudies = usePatientStudies(toRefs(props).study);

const studyCountText = computed(() => {
  if (patientStudies.value.length < 2) {
    return "";
  }

  let text = "";

  if (patientStudies.value.length === 2) {
    text = "There is 1 other study";
  } else {
    text = `There are ${patientStudies.value.length - 1} other studies`;
  }

  return text;
});
</script>

<style scoped lang="scss">
.study-patient-update-modal {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.other-studies-warning {
  align-self: center;
  width: 420px;
  padding: 8px;
  display: flex;
  gap: 16px;
}

.other-studies-details-button {
  cursor: pointer;
  font-weight: bold;
  text-decoration: underline;
  white-space: nowrap;
  transition: color 100ms ease;

  &:hover {
    color: var(--text-color-2);
  }
}

.name-fields {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 8px 16px;
}

.other-fields {
  gap: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.field {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.signed-report-message {
  width: 420px;
  padding: 8px;
}
</style>
