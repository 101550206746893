<template>
  <Modal
    center-screen
    :button-text-left="isShowingSplashScreen || isLastStep ? '' : 'Back'"
    :activity-text="isSaving ? 'Saving' : ''"
    @header-button-click="onHeaderButtonClick"
  >
    <div class="onboarding" data-testid="onboarding-modal">
      <template v-if="isShowingSplashScreen">
        <div class="splash">
          <div class="logo">
            <Logo />
          </div>
          <div class="header">Welcome to HeartLab!</div>
          <div class="description">Let's start by setting up your account</div>
          <button
            class="accented continue-button"
            data-testid="onboarding-splash-continue-button"
            @click="isShowingSplashScreen = false"
          >
            Continue
          </button>
        </div>
      </template>

      <template v-else>
        <div class="onboarding-content">
          <div class="progress-bar">
            <div
              v-for="(step, index) of allSteps"
              :key="step.name"
              class="item"
              :class="{ active: index === currentStep }"
              data-testid="onboarding-progress-step"
            >
              <div v-if="index === currentStep" class="locator">
                <img src="../assets/progress-locator-heart.svg" />
              </div>
              <div class="bar" />
            </div>
          </div>
          <div class="onboarding-step">
            <div class="header">{{ allSteps[currentStep].header }}</div>
            <div v-if="allSteps[currentStep].description" class="description">
              {{ allSteps[currentStep].description }}
            </div>

            <OnboardingSetName v-if="allSteps[currentStep].name === 'name'" @continue="nextStep" />
            <SignaturePanel v-if="allSteps[currentStep].name === 'signature'" onboarding />
            <OnboardingSetSignatureText v-if="allSteps[currentStep].name === 'signature-text'" />
            <PINField
              v-if="allSteps[currentStep].name === 'pin'"
              v-model="pin"
              class="large-pin-field"
              auto-focus
              data-testid="onboarding-pin"
              @enter="nextStep"
            />
            <PINField
              v-if="allSteps[currentStep].name === 'pin-confirmation'"
              v-model="pinConfirmation"
              class="large-pin-field"
              auto-focus
              data-testid="onboarding-pin-confirmation"
              @enter="nextStep"
            />

            <button
              class="accented continue-button"
              data-testid="onboarding-continue-button"
              :disabled="!allSteps[currentStep].isComplete()"
              @click="nextStep"
            >
              <template v-if="isLastStep"> Start </template>
              <template v-else> Continue </template>
            </button>
          </div>
        </div>
      </template>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import {
  hasStudyReportAmendmentCompletePermission,
  hasStudyReportAmendmentCompleteWithUpdatedContentPermission,
  hasStudyReportPreliminaryFinalizePermission,
} from "@/auth/authorization";
import Modal, { HeaderButton } from "@/components/Modal.vue";
import PINField from "@/components/PINField.vue";
import SignaturePanel from "@/components/SignaturePanel.vue";
import { addNotification } from "@/utils/notifications";
import axios from "axios";
import confetti, { create as createConfettiCannon } from "canvas-confetti";
import { computed, ref } from "vue";
import { isPINValid, signOut } from "../auth/authentication";
import { currentUser, fetchCurrentTenantAndUser } from "../auth/current-session";
import Logo from "../components/Logo.vue";
import { loadUserList } from "../utils/users-list";
import OnboardingSetName from "./OnboardingSetName.vue";
import OnboardingSetSignatureText from "./OnboardingSetSignatureText.vue";

const isShowingSplashScreen = ref(true);
const currentStep = ref(0);
const isSaving = ref(false);

const pin = ref("");
const pinConfirmation = ref("");

interface OnboardingStep {
  name:
    | "done"
    | "name"
    | "pin-confirmation"
    | "pin"
    | "signature-text"
    | "signature"
    | "terms-and-conditions";

  header: string;
  description: string;
  isComplete: () => boolean;
}

const allSteps = computed(() => {
  const result: OnboardingStep[] = [];

  result.push({
    name: "name",
    header: "Let's find out who you are",
    description: "",
    isComplete: () => currentUser.name !== "",
  });

  if (
    hasStudyReportPreliminaryFinalizePermission.value ||
    hasStudyReportAmendmentCompleteWithUpdatedContentPermission.value
  ) {
    result.push({
      name: "signature",
      header: "Add your signature",
      description:
        "Your signature will be displayed on all your completed reports, and can be changed at any time",
      isComplete: () => currentUser.signatureDataUri !== "",
    });

    result.push({
      name: "signature-text",
      header: "Add your signature text",
      description: "This is the text that will display below your signature",
      isComplete: () => currentUser.signatureText !== "",
    });
  }

  if (
    hasStudyReportPreliminaryFinalizePermission.value ||
    hasStudyReportAmendmentCompletePermission.value
  ) {
    if (!currentUser.hasPin) {
      result.push({
        name: "pin",
        header: "Add a PIN",
        description: "This is how you'll securely complete reports",
        isComplete: () => isPINValid(pin.value),
      });

      result.push({
        name: "pin-confirmation",
        header: "Confirm your PIN",
        description: "Please re-enter your 4-digit PIN",
        isComplete: () => isPINValid(pinConfirmation.value) && pinConfirmation.value === pin.value,
      });
    }
  }

  result.push({
    name: "done",
    header: "Thanks, you're ready to go!",
    description: "",
    isComplete: () => true,
  });

  return result;
});

const isLastStep = computed(() => currentStep.value === allSteps.value.length - 1);

async function onHeaderButtonClick(button: HeaderButton): Promise<void> {
  if (button === "left") {
    if (currentStep.value === 0) {
      isShowingSplashScreen.value = true;
    } else {
      currentStep.value -= 1;
    }
  } else {
    await signOut();
  }
}

async function nextStep(): Promise<void> {
  if (!allSteps.value[currentStep.value].isComplete()) {
    return;
  }

  if (currentStep.value === allSteps.value.length - 2) {
    let params: Record<string, unknown> = {
      name: currentUser.name,
      onboarded: true,
    };

    if (hasStudyReportPreliminaryFinalizePermission.value) {
      params.signatureDataUri = currentUser.signatureDataUri;
      params.signatureText = currentUser.signatureText;
    }

    if (
      !currentUser.hasPin &&
      (hasStudyReportPreliminaryFinalizePermission.value ||
        hasStudyReportAmendmentCompletePermission.value ||
        hasStudyReportAmendmentCompleteWithUpdatedContentPermission.value)
    ) {
      params.newPin = pin.value;
    }

    isSaving.value = true;

    try {
      await axios.patch("/api/user/current", params);
    } catch (error) {
      addNotification({ type: "error", message: "Failed saving user data" });
      return;
    } finally {
      isSaving.value = false;
    }
  } else if (currentStep.value === allSteps.value.length - 1) {
    // Update the current user following completion of onboarding so that all details filled in
    // during onboarding are up to date. This is most relevant for the `hasPin` and `onboarded`
    // fields.
    await fetchCurrentTenantAndUser();
    await loadUserList();
  }

  currentStep.value += 1;

  if (currentStep.value === allSteps.value.length) {
    showConfetti();
  }
}

const confettiCannon = createConfettiCannon(null as unknown as HTMLCanvasElement, {
  useWorker: false,
  resize: true,
});

// Taken from https://www.kirilv.com/canvas-confetti/
function showConfetti(): void {
  const duration = 2.5 * 1000;
  const animationEnd = Date.now() + duration;
  const defaults: confetti.Options = { startVelocity: 30, spread: 360, ticks: 120, zIndex: 0 };

  function randomInRange(min: number, max: number): number {
    return Math.random() * (max - min) + min;
  }

  const interval = setInterval((): void => {
    const timeLeft = animationEnd - Date.now();

    if (timeLeft <= 0) {
      clearInterval(interval);
      return;
    }

    const particleCount = 300 * (timeLeft / duration);
    void confettiCannon({
      ...defaults,
      particleCount,
      origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
    });
    void confettiCannon({
      ...defaults,
      particleCount,
      origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
    });
  }, 500);
}
</script>

<style scoped lang="scss">
.onboarding {
  height: 600px;
  width: 800px;
  display: flex;
  justify-content: center;
}

button {
  font-size: 1.3em;
}

.splash {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
  align-self: center;

  .logo {
    width: 100px;
  }

  button {
    width: 320px;
  }
}

.onboarding-content {
  display: grid;
  grid-template-rows: auto 1fr;
  width: 100%;
  padding-top: 50px;
}

.onboarding-step {
  place-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.continue-button {
  margin-top: 16px;
  width: 320px;
  height: 42px;
}

.header {
  font-weight: bold;
  font-size: 3.5em;
  text-align: center;
  margin-bottom: 30px;
}

.description {
  font-size: 1.1em;
  margin-bottom: 35px;
  max-width: 400px;
  text-align: center;
}

.progress-bar {
  display: flex;
  column-gap: 15px;
  align-items: flex-end;
  place-self: center;

  .item {
    display: flex;
    flex-direction: column;
    align-items: center;

    .locator {
      font-size: 2em;
    }

    .bar {
      height: 5px;
      width: 100px;
      background-color: var(--accent-color-1);
      border-radius: var(--border-radius);
    }

    &.active {
      .bar {
        background-color: var(--accent-color-2);
      }
    }
  }
}

.large-pin-field {
  transform: scale(150%);
  margin-bottom: 20px;
}
</style>
