<template>
  <BackButton
    back-destination-route-name="settings-integrations-hl7-all"
    button-text="Back to HL7 Settings"
  />

  <div class="settings-title">
    HL7 Integration: {{ integration.name }}

    <button style="margin-left: auto; font-size: 13px" type="button" @click="deleteIntegration">
      Delete Integration
    </button>
  </div>

  <div class="field">
    <b>Name</b>
    <input v-model="integration.name" type="text" @update:model-value="updateIdentityDebounced" />
  </div>

  <div style="display: flex; gap: 16px">
    <div class="field">
      <b>Endpoint</b>
      <input
        v-model="integration.endpoint"
        type="text"
        @update:model-value="updateIdentityDebounced"
      />
    </div>

    <div class="field">
      <b>Receiving Application</b>
      <input
        v-model="integration.receivingApplication"
        type="text"
        @update:model-value="updateIdentityDebounced"
      />
    </div>
  </div>

  <div style="display: flex; gap: 16px">
    <div class="field">
      <b>Sending Facility</b>
      <input
        v-model="integration.sendingFacility"
        type="text"
        @update:model-value="updateIdentityDebounced"
      />
    </div>

    <div class="field">
      <b>Receiving Facility</b>
      <input
        v-model="integration.receivingFacility"
        type="text"
        @update:model-value="updateIdentityDebounced"
      />
    </div>
  </div>

  <div class="field">
    <b>Provider Details</b>
    <div class="name-input">
      <input
        v-model="integration.providerDetails[3]"
        style="width: 26px"
        type="text"
        placeholder="prefix"
        @update:model-value="updateIdentityDebounced"
      />
      <input
        v-model="integration.providerDetails[1]"
        type="text"
        placeholder="given name"
        @update:model-value="updateIdentityDebounced"
      />
      <input
        v-model="integration.providerDetails[0]"
        type="text"
        placeholder="family name"
        @update:model-value="updateIdentityDebounced"
      />
    </div>
  </div>

  <h3 style="margin-bottom: -10px">Recipients</h3>
  <div class="recipients-top-row">
    <FilterInput v-model="recipientSearchTerm" placeholder="Search" />
    <button class="accented" @click="isAddingRecipient = true">Create Recipient</button>
  </div>

  <div class="recipients-table">
    <div class="header">
      <div>Recipient Details</div>
      <div>Physician ID</div>
      <div>Default Recipient</div>
    </div>

    <div class="header-line" />

    <div v-for="recipient in filteredRecipients" :key="recipient.id" class="grid-table-row">
      <div @click="isEditingRecipient = recipient">{{ formatDicomName(recipient.name) }}</div>
      <div @click="isEditingRecipient = recipient">{{ recipient.physicianId }}</div>
      <div @click="isEditingRecipient = recipient">
        {{ recipient.default ? "Yes" : "No" }}
      </div>
    </div>
  </div>

  <HL7RecipientModal
    v-if="isAddingRecipient || isEditingRecipient"
    v-model:integration="integration"
    v-model:recipient="isEditingRecipient"
    :mode="isEditingRecipient?.id ? 'edit' : 'add'"
    @close="
      isAddingRecipient = false;
      isEditingRecipient = undefined;
    "
  />

  <ActivityOverlay v-if="activityText" :text="activityText" />
</template>

<script setup lang="ts">
import type { HL7IntegrationGetOneResponseDto } from "@/../../backend/src/integrations/hl7/dto/hl7-integration-get-one.dto";
import router from "@/router";
import { addNotification } from "@/utils/notifications";
import { useDebounceFn } from "@vueuse/core";
import axios, { type AxiosResponse } from "axios";
import { computed, onMounted, ref } from "vue";
import { formatDicomName } from "../../../../../backend/src/shared/dicom-helpers";
import ActivityOverlay from "../../../components/ActivityOverlay.vue";
import FilterInput from "../../../components/FilterInput.vue";
import BackButton from "../../components/BackButton.vue";
import HL7RecipientModal from "./HL7RecipientModal.vue";

interface Props {
  id: string;
}

const props = defineProps<Props>();

const activityText = ref("");
const recipientSearchTerm = ref<string>("");
const isAddingRecipient = ref(false);
const isEditingRecipient = ref<HL7IntegrationGetOneResponseDto["recipients"][0]>();
const integration = ref<HL7IntegrationGetOneResponseDto>({
  id: "",
  name: "",
  endpoint: "",
  receivingApplication: "",
  receivingFacility: "",
  sendingFacility: "",
  providerDetails: [],
  recipients: [],
});

onMounted(async () => {
  activityText.value = "Loading";

  let response: AxiosResponse<HL7IntegrationGetOneResponseDto> | undefined = undefined;
  try {
    response = await axios.get<HL7IntegrationGetOneResponseDto>(
      `/api/integrations/hl7/${props.id}`
    );
  } catch {
    addNotification({ type: "error", message: `Failed loading HL7 integration data` });
    return;
  } finally {
    activityText.value = "";
  }

  integration.value = response.data;
});

const filteredRecipients = computed(() => {
  const lowercaseSearchTerm = recipientSearchTerm.value.toLowerCase();

  return integration.value.recipients.filter(
    (recipient) =>
      recipient.physicianId.toLowerCase().includes(lowercaseSearchTerm) ||
      formatDicomName(recipient.name).toLowerCase().includes(lowercaseSearchTerm)
  );
});

const updateIdentityDebounced = useDebounceFn(() => {
  void updateIntegration();
}, 1000);

async function updateIntegration(): Promise<void> {
  try {
    await axios.patch(`/api/integrations/hl7/${integration.value.id}`, {
      name: integration.value.name,
      endpoint: integration.value.endpoint,
      receivingApplication: integration.value.receivingApplication,
      sendingFacility: integration.value.sendingFacility,
      receivingFacility: integration.value.receivingFacility,
      providerDetails: integration.value.providerDetails,
    });
  } catch {
    addNotification({ type: "error", message: "Error updating HL7 integration" });
    return;
  }

  addNotification({ type: "info", message: "Updated HL7 integration" });
}

async function deleteIntegration(): Promise<void> {
  if (!confirm(`Are you sure you want to delete the integration "${integration.value.name}"?`)) {
    return;
  }

  activityText.value = "Deleting HL7 integration";

  try {
    await axios.delete(`/api/integrations/hl7/${integration.value.id}`);
  } catch {
    addNotification({ type: "error", message: "Error deleting HL7 integration" });
    return;
  } finally {
    activityText.value = "";
  }

  await router.push({ name: "settings-integrations-hl7-all" });

  addNotification({ type: "info", message: "Deleted HL7 integration" });
}
</script>

<style scoped lang="scss">
.field {
  display: grid;
  gap: 8px;
}

.recipients-top-row {
  display: grid;
  grid-template-columns: 300px 1fr;
  align-items: center;
  margin: 10px 0;

  button {
    margin-left: auto;
  }
}

input {
  width: 300px;
}

.recipients-table {
  display: grid;
  grid-template-columns: 220px 220px auto;
  align-items: center;
  row-gap: 2px;

  .header {
    font-weight: bold;
    display: contents;
    > * {
      padding: 0 20px 4px 0;
    }
  }

  .header-line {
    grid-area: 2 / 1 / 2 / span 3;
    border-bottom: 2px solid var(--bg-color-4);
  }
}

.name-input {
  display: flex;
  gap: 10px;
  width: 400px;
}
</style>
