<template>
  <div class="field">
    <b>{{ templateName }} Report Name Format</b>

    <div class="report-name-config">
      <input :value="template" @input="onEditTemplate" />
      <div class="pdf-extension">.pdf</div>
    </div>

    <div style="display: flex; gap: 8px">
      <FontAwesomeIcon
        style="padding-left: 8px"
        :icon="templateErrors !== null ? 'xmark' : 'arrow-right'"
        :class="{ errored: templateErrors !== null }"
      />
      <div style="font-family: monospace">
        {{ templateErrors === null ? templateEvaluationExample : templateErrors }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { computed } from "vue";
import {
  DUMMY_REPORT_PDF_NAME_STUDY,
  DUMMY_STUDY_REPORT_PDF,
  evaluatePdfName,
  getPdfNameTemplateErrors,
} from "../../../backend/src/studies/study-report-pdf-name-evaluation";

interface Props {
  templateName: string;
  template: string;
}

interface Emits {
  (event: "update:template", newTemplate: string): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const templateEvaluationExample = computed(() =>
  evaluatePdfName(props.template, DUMMY_REPORT_PDF_NAME_STUDY, DUMMY_STUDY_REPORT_PDF)
);

const templateErrors = computed(() => {
  const errors = getPdfNameTemplateErrors(props.template);

  if (errors === null) {
    return null;
  }

  return `Error${errors.length > 1 ? "s" : ""} in template: ${errors.join(", ")}`;
});

function onEditTemplate(event: Event) {
  if (!(event.target instanceof HTMLInputElement)) {
    return;
  }

  emits("update:template", event.target.value);
}
</script>

<style scoped lang="scss">
.field {
  display: grid;
  gap: 8px;
}

.report-name-config {
  display: flex;
}

input {
  height: 16px;
  width: 800px;
  border-radius: var(--border-radius) 0 0 var(--border-radius);
  font-family: monospace;
}

.pdf-extension {
  display: flex;
  align-items: center;
  height: 24px;
  font-family: monospace;
  border: 1px solid var(--border-color-1);
  border-radius: 0 var(--border-radius) var(--border-radius) 0;
  background-color: var(--bg-color-3);
  padding: 4px 8px;
  border-left: none;
}

.errored {
  color: red;
}
</style>
