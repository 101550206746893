import { AuditAction } from "@/../../backend/src/audit/audit-actions";

const auditLogEventDescriptions: Record<AuditAction, string> = {
  [AuditAction.SessionCreated]: "Session created",
  [AuditAction.SessionCreateFailed]: "Session creation failed",
  [AuditAction.SessionDestroyed]: "Session destroyed",

  [AuditAction.UserIdentityCreated]: "User identity created",
  [AuditAction.UserIdentityUpdated]: "User identity updated",
  [AuditAction.UserIdentityPasswordChanged]: "User identity password changed",
  [AuditAction.UserIdentityPasswordChangeFailed]: "User identity password change failed",
  [AuditAction.UserIdentityPasswordResetRequested]: "User identity password reset requested",
  [AuditAction.UserIdentityMFAUpdated]: "User identity MFA updated",
  [AuditAction.UserIdentityLocked]: "User identity locked",
  [AuditAction.UserIdentityUnlocked]: "User identity unlocked",

  [AuditAction.StudyCreated]: "Study created",
  [AuditAction.StudyViewed]: "Study viewed",
  [AuditAction.StudyEdited]: "Study edited",
  [AuditAction.StudyReprocessed]: "Study reprocessed",
  [AuditAction.StudyAssigned]: "Study assigned",
  [AuditAction.StudyParticipantUpdated]: "Study participant updated",
  [AuditAction.StudyDeleted]: "Study deleted",

  [AuditAction.StudyBilled]: "Study billed",
  [AuditAction.StudyBillingError]: "Study billing error",

  [AuditAction.StudyClipDeleted]: "Study clip deleted",
  [AuditAction.StudyClipDownloaded]: "Study clip downloaded",

  [AuditAction.StudyReportCreated]: "Study report created",
  [AuditAction.StudyReportViewed]: "Study report viewed",
  [AuditAction.StudyReportEdited]: "Study report edited",
  [AuditAction.StudyReportCompleted]: "Study report completed",
  [AuditAction.StudyReportDeleted]: "Study report deleted",
  [AuditAction.StudyReportSigned]: "Study report signed",
  [AuditAction.StudyReportSignIncorrectPin]: "Study report sign - incorrect PIN",

  [AuditAction.StudyListQueried]: "Study list queried",

  [AuditAction.StudyFileUploaded]: "Study file uploaded",
  [AuditAction.StudyFileUploadError]: "Study file upload error",

  [AuditAction.StudyAttachedFileUploaded]: "Study attached file uploaded",
  [AuditAction.StudyAttachedFileViewed]: "Study attached file viewed",
  [AuditAction.StudyAttachedFileDeleted]: "Study attached file deleted",

  [AuditAction.StudyShareLinkCreated]: "Study share link created",
  [AuditAction.StudyShareLinkEmailSent]: "Study share link email sent",
  [AuditAction.StudyShareLinkUpdated]: "Study share link updated",
  [AuditAction.StudyShareLinkLocked]: "Study share link locked",

  [AuditAction.StudyClipProcessed]: "Study clip processed",
  [AuditAction.StudyClipProcessingError]: "Study clip processing error",
  [AuditAction.StudyClipUnrecognizedMeasurements]: "Study clip unrecognized measurements",

  [AuditAction.StudySeriesNRRDProcessed]: "Study series 3D volume file created",
  [AuditAction.StudySeriesNRRDProcessingError]: "Study series 3D volume file creation error",

  [AuditAction.StudyUpdatePatientIdentity]: "Study patient identity updated",

  [AuditAction.StudyWebhookActivated]: "Webhook activated for study",

  [AuditAction.SecondaryWindowOpened]: "Secondary window opened",
  [AuditAction.SecondaryWindowClosed]: "Secondary window closed",

  [AuditAction.StressModeOpened]: "Stress mode opened",
  [AuditAction.StressModeClosed]: "Stress mode closed",

  [AuditAction.MeasurementCreated]: "Measurement created",
  [AuditAction.MeasurementCreateFailed]: "Measurement create failed",
  [AuditAction.MeasurementEdited]: "Measurement edited",
  [AuditAction.MeasurementDeleted]: "Measurement deleted",
  [AuditAction.MeasurementAutomationRun]: "Measurement automation run",

  [AuditAction.MeasurementSequenceCreated]: "Measurement sequence created",
  [AuditAction.MeasurementSequenceUpdated]: "Measurement sequence updated",
  [AuditAction.MeasurementSequenceDeleted]: "Measurement sequence deleted",

  [AuditAction.MeasurementCalculationCreated]: "Measurement calculation created",
  [AuditAction.MeasurementCalculationUpdated]: "Measurement calculation updated",
  [AuditAction.MeasurementCalculationDeleted]: "Measurement calculation deleted",

  [AuditAction.ReportTemplateCreated]: "Report template created",
  [AuditAction.ReportTemplateUpdated]: "Report template updated",
  [AuditAction.ReportTemplateDeleted]: "Report template deleted",
  [AuditAction.ReportTemplateVersionCreated]: "Report template version created",
  [AuditAction.ReportTemplateVersionUpdated]: "Report template version updated",
  [AuditAction.ReportTemplateVersionDeleted]: "Report template version deleted",
  [AuditAction.ReportTemplateVersionPublished]: "Report template version published",

  [AuditAction.ReportSentenceGroupCreated]: "Report sentence group created",
  [AuditAction.ReportSentenceGroupUpdated]: "Report sentence group updated",
  [AuditAction.ReportSentenceGroupDeleted]: "Report sentence group deleted",

  [AuditAction.UserInvitationCreated]: "User invitation created",
  [AuditAction.UserInvitationAccepted]: "User invitation accepted",
  [AuditAction.UserInvitationAcceptFailed]: "User invitation accept failed",
  [AuditAction.UserInvitationCancelled]: "User invitation cancelled",

  [AuditAction.UserCreated]: "User created",
  [AuditAction.UserDeleted]: "User deleted",
  [AuditAction.UserDisabled]: "User disabled",
  [AuditAction.UserEnabled]: "User enabled",
  [AuditAction.UserPinUpdated]: "User PIN updated",
  [AuditAction.UserPinUpdateFailed]: "User PIN update failed",
  [AuditAction.UserPinTooManyAttempts]: "User PIN too many attempts",
  [AuditAction.UserSignatureUpdated]: "User signature updated",
  [AuditAction.UserOnboarded]: "User onboarded",
  [AuditAction.UserApiKeyCreated]: "User API key created",
  [AuditAction.UserApiKeyUpdated]: "User API key updated",
  [AuditAction.UserApiKeyDeleted]: "User API key deleted",
  [AuditAction.UserNameUpdated]: "User name updated",
  [AuditAction.UserDictationEnabledUpdated]: "User dictation enabled updated",
  [AuditAction.UserDefaultsDisplayOptionsUpdated]: "User defaults display options updated",

  [AuditAction.UserRoleCreated]: "User role created",
  [AuditAction.UserRoleUpdated]: "User role updated",
  [AuditAction.UserRoleDeleted]: "User role deleted",

  [AuditAction.AdminUserPermissionsUpdated]: "User permissions updated",
  [AuditAction.AdminUserEdited]: "User edited",

  [AuditAction.AdminMFARequiredUpdated]: "MFA required updated",
  [AuditAction.AdminStudySharingEnabledUpdated]: "Study sharing enabled updated",
  [AuditAction.AdminAPIKeyAccessEnabledUpdated]: "API keys access enabled updated",
  [AuditAction.AdminEmailSendingEnabledUpdated]: "Email sending enabled updated",
  [AuditAction.AdminPatientIDLabelUpdated]: "Patient ID label updated",
  [AuditAction.AdminStudyParticipantLabelUpdated]: "Study participant label updated",
  [AuditAction.AdminSessionTimeoutsUpdated]: "Session timeouts updated",
  [AuditAction.AdminPatientNameFormatUpdated]: "Patient Name format updated",
  [AuditAction.AdminStudyParticipantRoleUpdated]: "Study participant role updated",
  [AuditAction.AdminUnauthorizedTenantLogin]: "Unauthorized tenant login",

  [AuditAction.DicomAssociationAborted]: "DICOM association aborted",
  [AuditAction.DicomAssociationAccepted]: "DICOM association accepted",
  [AuditAction.DicomAssociationRejected]: "DICOM association rejected",
  [AuditAction.DicomAssociationReleased]: "DICOM association released",
  [AuditAction.DicomDuplicateIgnored]: "DICOM duplicate ignored",
  [AuditAction.DicomMessageReceived]: "DIMSE message received",
  [AuditAction.DicomMessageResponse]: "DIMSE message response",
  [AuditAction.DicomQueryUnsupported]: "DICOM query unsupported",
  [AuditAction.DicomRetrieved]: "DICOM retrieved",
  [AuditAction.DicomServerError]: "DICOM server error",
  [AuditAction.DicomUncompressedFormatDeprecated]: "DICOM file not compressed",

  [AuditAction.DicomClientIdentityCreated]: "DICOM client identity created",
  [AuditAction.DicomClientIdentityUpdated]: "DICOM client identity updated",
  [AuditAction.DicomClientIdentityDeleted]: "DICOM client identity deleted",

  [AuditAction.DicomEndpointCreated]: "DICOM endpoint created",
  [AuditAction.DicomEndpointUpdated]: "DICOM endpoint updated",
  [AuditAction.DicomEndpointDeleted]: "DICOM endpoint deleted",
  [AuditAction.DicomEndpointQueried]: "DICOM endpoint queried",
  [AuditAction.DicomEndpointEchoed]: "DICOM endpoint echoed",
  [AuditAction.DicomEndpointInstanceRetrieved]: "DICOM endpoint instance retrieved",
  [AuditAction.DicomEndpointInstanceRetrieveError]: "DICOM endpoint instance retrieve error",
  [AuditAction.DicomEndpointInstanceSent]: "DICOM endpoint instance sent",
  [AuditAction.DicomEndpointInstanceSendError]: "DICOM endpoint instance send error",
  [AuditAction.DicomEndpointInstanceSendQueued]: "DICOM endpoint instance send queued",
  [AuditAction.DicomEndpointStorageCommitmentResponseError]:
    "DICOM endpoint storage commitment response error",
  [AuditAction.DicomEndpointStorageCommitmentResponseQueued]:
    "DICOM endpoint storage commitment response queued",
  [AuditAction.DicomEndpointStorageCommitmentResponseSent]:
    "DICOM endpoint storage commitment response sent",

  [AuditAction.EmailSend]: "Email send",
  [AuditAction.EmailSendError]: "Email send error",

  [AuditAction.AgentIntegrationCreated]: "Agent integration created",
  [AuditAction.AgentIntegrationDeleted]: "Agent integration deleted",

  [AuditAction.HeartBoxIntegrationCreated]: "HeartBox integration created",
  [AuditAction.HeartBoxIntegrationUpdated]: "HeartBox integration updated",
  [AuditAction.HeartBoxIntegrationDeleted]: "HeartBox integration deleted",

  [AuditAction.HL7IntegrationCreated]: "HL7 integration created",
  [AuditAction.HL7IntegrationUpdated]: "HL7 integration updated",
  [AuditAction.HL7IntegrationDeleted]: "HL7 integration deleted",

  [AuditAction.HL7IntegrationRecipientCreated]: "HL7 integration recipient created",
  [AuditAction.HL7IntegrationRecipientUpdated]: "HL7 integration recipient updated",
  [AuditAction.HL7IntegrationRecipientDeleted]: "HL7 integration recipient deleted",

  [AuditAction.HL7MessageDownload]: "HL7 message downloaded",
  [AuditAction.HL7MessageSend]: "HL7 message send",
  [AuditAction.HL7MessageSendFailed]: "HL7 message send failed",

  [AuditAction.SftpIntegrationCreated]: "SFTP integration created",
  [AuditAction.SftpIntegrationUpdated]: "SFTP integration updated",
  [AuditAction.SftpIntegrationDeleted]: "SFTP integration deleted",
  [AuditAction.SftpIntegrationFileSend]: "SFTP integration file sent",
  [AuditAction.SftpIntegrationFileSendFailed]: "SFTP integration file send failed",

  [AuditAction.WebhookIntegrationCreated]: "Webhook integration created",
  [AuditAction.WebhookIntegrationUpdated]: "Webhook integration updated",
  [AuditAction.WebhookIntegrationDeleted]: "Webhook integration deleted",
  [AuditAction.WebhookIntegrationRequestSent]: "Webhook integration request sent",
  [AuditAction.WebhookIntegrationRequestFailed]: "Webhook integration request failed",

  [AuditAction.DicomScheduledProcedureStepCreated]: "DICOM procedure step created",
  [AuditAction.DicomScheduledProcedureStepDeleted]: "DICOM procedure step deleted",
  [AuditAction.DicomScheduledProcedureStepUpdated]: "DICOM procedure step updated",

  [AuditAction.SupportTicketCreated]: "Support ticket created",
  [AuditAction.SupportTicketRequestFailed]: "Support ticket request failed",

  [AuditAction.TenantCreated]: "Tenant created",
  [AuditAction.TenantUpdated]: "Tenant updated",
};

export function getAuditLogActionDescription(action: AuditAction): string {
  return auditLogEventDescriptions[action];
}
