import type { ShallowReactive } from "vue";
import { computed, nextTick, ref, shallowReactive, type ComputedRef, type Ref } from "vue";
import { getClips } from "../../../../backend/src/studies/study-helpers";
import { currentTenant } from "../../auth/current-session";
import { activeMeasurement, stopMeasuring } from "../../measurements/measurement-tool-state";
import type { Study, StudyClip, StudySeries } from "../../utils/study-data";
import { createClipModel, type ClipModel } from "../clip-model";
import { crosshairsStore, CrosshairsStoreState, exitCrosshairs } from "../ct/ct-crosshairs";
import { isCT3DSeries } from "../ct/ct-helpers";
import { createCTModel, type CTModel } from "../ct/ct-model";

/**
 * The types of content that can be present inside a position inside the study viewer clips grid.
 * - `RegularClip` refers to any 2D clip, such as an echo, angiogram, single 2D frame, etc.
 * - `CTClip` specifically refers to an item that is represented by a NRRD 3D mesh file.
 *
 * At present it's possible for a CT to be represented as a 2D clip where the .webp files are used
 * to show a video at a fixed window level moving vertically in the axial direction. In the future
 * once CT is expanded we're likely to remove the concept of "CT Mode" and use only the 3D viewer.
 */
export enum ClipsGridItemType {
  Null = "Null",
  RegularClip = "RegularClip",
  CTClip = "CTClip",
  DicomEncapsulatedPDF = "DicomEncapsulatedPDF",
}

/**
 * The set of styles that are applied to the scrubber handle and track progress bar in the viewer
 * in order to control the current percentage based position in the full scrollbar.
 */
interface ScrubberProgressBarStyles {
  progressBarLeft: string;
  progressBarWidth: string;
  handleLeft: string;
}

/**
 * The shared properties between all types of ClipsGridItems. This should be combined with a *Model
 * object in order to create a certain type of *ClipsGridItem. At present only regular clips and
 * CT (3D mesh) type clips items are supported, but it's set up in this way so we can easily add
 * new grid items such as embedding PDFs in the clips grid.
 */
export interface BaseClipsGridItem {
  /** The type of content that this clips grid item represents.  */
  type: ClipsGridItemType;

  /**
   * The clip that is linked to this clips grid item, if any. If this grid item is a CT clip, this
   * is the faked clip that is used for that series at present.
   *
   * TODO: The faked clip concept for series should be removed at some point in the future. This
   * won't be possible until we depreciate the 2D CT viewer and remove the CT mode tenant flag.
   */
  clip?: StudyClip;

  /** Whether the scrubber on this clips grid item is being moved. */
  isScrubbing: Ref<boolean>;

  /**
   * Whether the clip is currently being dragged over as part of a drag-and-drop from the clip list.
   */
  isDraggedOver: Ref<boolean>;

  /** Whether the clip is currently loading and a loading indicator should be shown. */
  isLoading: Ref<boolean> | ComputedRef<boolean>;

  /**
   * The styles that should be applied to the scrubber handle and progress bar in order to show the
   * current position throughout this grid item.
   */
  scrubberStyles: ComputedRef<ScrubberProgressBarStyles>;

  /**
   * The text that should be displayed while this grid item is loading, if any.
   */
  loadingText: ComputedRef<string | null>;

  /**
   * The action that should run when a single frame is stepped in the viewer, such as via the period
   * and comma keyboard shortcuts, if any.
   */
  onStepFrame: (delta: number) => void;

  /**
   * The actions that should be ran when this grid item is no longer in use, such as cancelling
   * in-progress data loading.
   */
  destroy?: () => void;
}

/**
 * A ClipsGridItem that represents no data in that item slot. Used to fill in empty spaces in.
 */
export type NullClipsGridItem = BaseClipsGridItem & { type: ClipsGridItemType.Null };

/**
 * A ClipsGridItem that represents a regular clip, such as an echo, angiogram, or single 2D frame.
 */
export type RegularClipsGridItem = BaseClipsGridItem &
  ClipModel & { type: ClipsGridItemType.RegularClip };

/**
 * A ClipsGridItem that represents a CT series, which is a 3D mesh file that is loaded in the viewer
 */
export type CTClipsGridItem = BaseClipsGridItem & CTModel & { type: ClipsGridItemType.CTClip };

/**
 * A ClipsGridItem that represents a DICOM encapsulated PDF file that is loaded in the viewer
 */
export type EncapsulatedPDFClipsGridItem = BaseClipsGridItem & {
  type: ClipsGridItemType.DicomEncapsulatedPDF;
};

export type ClipsGridItem =
  | NullClipsGridItem
  | RegularClipsGridItem
  | CTClipsGridItem
  | EncapsulatedPDFClipsGridItem;

/**
 * Creates an array of grid items for use with a ClipsArea component. The grid items array is a
 * ShallowReactive in order to have reactivity when creating new grid items and putting them in
 * this array, while avoiding unwanted deep reactivity as grid items handle reactivity themselves.
 */
export function createClipsGridItemsArray(count: number): ClipsGridItem[] {
  return shallowReactive(
    Array(count)
      .fill(null)
      .map((_) => createNullClipsGridItem())
  );
}

/** Creates a empty clips grid item used to represent a empty space in the clips grid. */
export function createNullClipsGridItem(): NullClipsGridItem {
  return {
    type: ClipsGridItemType.Null,
    isScrubbing: ref(false),
    isDraggedOver: ref(false),
    scrubberStyles: computed(() => ({ progressBarLeft: "", progressBarWidth: "", handleLeft: "" })),
    isLoading: computed(() => false),
    loadingText: computed(() => null),
    onStepFrame: () => null,
  };
}

/** Creates a regular clips grid item from a provided ClipModel. */
export function createRegularClipsGridItem(model: ClipModel): RegularClipsGridItem {
  const isLoading = computed(() => model.clip?.processedAt === null);
  const loadingText = computed(() =>
    model.clip?.processedAt === null ? "Image data is being processed" : null
  );

  const scrubberStyles = computed(() => {
    const clip = model.clip;
    if (clip === undefined || clip.frameCount === null) {
      return { progressBarLeft: "", progressBarWidth: "", handleLeft: "" };
    }

    const heartbeat = model.getSelectedHeartbeat();
    const frameOffset = heartbeat === undefined ? 0 : heartbeat.firstFrame;

    const progressBarLeft = `${100 * (frameOffset / clip.frameCount)}%`;
    const progressBarWidth = `${100 * (model.getCurrentTime() / model.clipDuration - frameOffset / clip.frameCount)}%`;

    return {
      progressBarLeft,
      progressBarWidth,
      handleLeft: `${(model.getCurrentTime() / model.clipDuration) * 100}%`,
    };
  });

  return {
    type: ClipsGridItemType.RegularClip,
    isLoading,
    isDraggedOver: ref(false),
    loadingText,
    scrubberStyles,
    ...model,
  };
}

/** Creates a CT clips grid item from a provided CTModel. */
export function createCTClipsGridItem(model: CTModel): CTClipsGridItem {
  const scrubberStyles = computed(() => {
    const percentage = model.sliceNumber.value / model.maxSliceNumber.value;

    return {
      progressBarLeft: "0%",
      progressBarWidth: `${percentage * 100}%`,
      handleLeft: `${percentage * 100}%`,
    };
  });

  return {
    type: ClipsGridItemType.CTClip,
    isScrubbing: ref(false),
    isDraggedOver: ref(false),
    scrubberStyles,
    ...model,
  };
}

/** Creates a PDF clips grid item from a clip (just wraps the clip as most fields aren't needed) */
export function createEncapsulatedPDFClipsGridItem(clip: StudyClip): EncapsulatedPDFClipsGridItem {
  return {
    type: ClipsGridItemType.DicomEncapsulatedPDF,
    isScrubbing: ref(false),
    isDraggedOver: ref(false),
    isLoading: computed(() => false),
    loadingText: computed(() => null),
    scrubberStyles: computed(() => ({
      progressBarLeft: "",
      progressBarWidth: "",
      handleLeft: "",
    })),
    clip,
    onStepFrame: () => null,
  };
}

/**
 * Updates an array of grid items to have items that match the set of desired clip IDs. Clip
 * models will be destroyed and created as required in order to achieve this state.
 */
// eslint-disable-next-line max-statements
export function updateClipsGridItemsArrayFromSelectedClips(
  clipsGridItems: ClipsGridItem[],
  study: Study,
  desiredClipIds: string[],
  isClipSyncEnabled: boolean,
  isMultiStudyGrid = false
): void {
  for (let i = 0; i < clipsGridItems.length; i++) {
    updateSingleClipGridItem(
      clipsGridItems,
      i,
      study,
      desiredClipIds,
      isClipSyncEnabled,
      isMultiStudyGrid
    );
  }

  handleMeasurementEditing(study, desiredClipIds);
  handleCrosshairsMode();
}

function updateSingleClipGridItem(
  clipsGridItems: ClipsGridItem[],
  index: number,
  study: Study,
  desiredClipIds: string[],
  isClipSyncEnabled: boolean,
  isMultiStudyGrid: boolean
): void {
  const desiredClipId = index < desiredClipIds.length ? desiredClipIds[index] : undefined;
  const currentClipId = clipsGridItems[index].clip?.id;

  const desiredClip = getClips(study).find((c) => c.id === desiredClipId);
  const desiredSeries = study.series.find((s) => s.id === desiredClip?.seriesId);

  // If the selected clip hasn't changed, and exists in the study, then there's nothing to do
  // OR if this is a multi-study grid and the clip isn't in the study, skip as it will get handled
  // in the other study's update pass, as otherwise this would destroy the other study's image
  if (shouldSkipUpdate(desiredClipId, currentClipId, desiredClip, isMultiStudyGrid)) {
    return;
  }

  clipsGridItems[index].destroy?.();

  if (desiredClip === undefined) {
    clipsGridItems[index] = createNullClipsGridItem();
    return;
  }

  if (desiredClip.hasEncapsulatedPDF) {
    clipsGridItems[index] = createEncapsulatedPDFClipsGridItem(desiredClip);
    return;
  }

  createAppropriateClipGridItem(
    clipsGridItems,
    index,
    study,
    desiredSeries,
    desiredClip,
    isClipSyncEnabled
  );
}

function shouldSkipUpdate(
  desiredClipId: string | undefined,
  currentClipId: string | undefined,
  desiredClip: StudyClip | undefined,
  isMultiStudyGrid: boolean
): boolean {
  return (
    (desiredClipId === currentClipId && desiredClip !== undefined) ||
    (isMultiStudyGrid && desiredClip === undefined)
  );
}

function createAppropriateClipGridItem(
  clipsGridItems: ClipsGridItem[],
  index: number,
  study: Study,
  desiredSeries: StudySeries | undefined,
  desiredClip: StudyClip,
  isClipSyncEnabled: boolean
): void {
  const previousItem = clipsGridItems[index];
  const isHeartbeatSelected =
    previousItem.type === ClipsGridItemType.RegularClip &&
    previousItem.getSelectedHeartbeatIndex() !== undefined;

  if (
    desiredSeries &&
    isCT3DSeries(desiredSeries, desiredClip) &&
    currentTenant.isCTModePermitted
  ) {
    clipsGridItems[index] = createCTClipsGridItem(createCTModel(study, desiredSeries));
  } else {
    clipsGridItems[index] = createRegularClipsGridItem(createClipModel(study, desiredClip));

    if (isHeartbeatSelected || isClipSyncEnabled) {
      (clipsGridItems[index] as RegularClipsGridItem).setSelectedHeartbeatIndex(0);
    }
  }
}

// Cancel measurement editing if the clip for the measurement value being edited is no longer
// visible
function handleMeasurementEditing(study: Study, desiredClipIds: string[]): void {
  if (activeMeasurement.value.editingMeasurementBatchId.value !== null) {
    study.measurements.forEach((measurement) => {
      const matchingValue = measurement.values.find(
        (value) =>
          value.measurementCreationBatchId ===
            activeMeasurement.value.editingMeasurementBatchId.value && value.studyClipId !== null
      );

      if (
        matchingValue &&
        matchingValue.studyClipId !== null &&
        !desiredClipIds.includes(matchingValue.studyClipId)
      ) {
        stopMeasuring();
      }
    });
  }
}

function handleCrosshairsMode(): void {
  if (crosshairsStore.value.state !== CrosshairsStoreState.Inactive) {
    exitCrosshairs();
  }
}

/** Extracts the regular clips grid items from a items array with the appropriate output typing. */
export function getRegularClipsGridItems(clipsGridItems: ClipsGridItem[]): RegularClipsGridItem[] {
  return clipsGridItems.filter(
    (item): item is RegularClipsGridItem => item.type === ClipsGridItemType.RegularClip
  );
}

/**
 * Sets up event handlers for updating selected clips when a grid item is dragged over or dropped
 * by a element from the clip list.
 */

export function useGridItemDragDropEventHandlers(
  gridItems: ShallowReactive<ClipsGridItem[]>,
  selectedClipIds: string[]
): {
  onDrop: (dragEvent: DragEvent, selectedClipIndex: number) => void;
  onDragOver: (dragEvent: DragEvent, clipIndex: number) => void;
  onDragLeave: (clipIndex: number) => void;
} {
  function onDrop(dragEvent: DragEvent, selectedClipIndex: number): void {
    const clipId = dragEvent.dataTransfer?.getData("clipId");
    if (clipId === undefined || clipId === "") {
      return;
    }

    selectedClipIds[selectedClipIndex] = clipId;

    dragEvent.preventDefault();

    void nextTick(() => {
      gridItems[selectedClipIndex].isDraggedOver.value = false;
    });
  }

  function onDragOver(dragEvent: DragEvent, clipIndex: number): void {
    const gridItem = gridItems[clipIndex];

    gridItem.isDraggedOver.value = dragEvent.dataTransfer?.types.includes("clipid") ?? false;

    if (gridItem.isDraggedOver.value) {
      dragEvent.preventDefault();
    }
  }

  function onDragLeave(clipIndex: number): void {
    const gridItem = gridItems[clipIndex];
    gridItem.isDraggedOver.value = false;
  }

  return { onDrop, onDragOver, onDragLeave };
}
