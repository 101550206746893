import { isEqual } from "lodash";
import type { ComputedRef, Ref } from "vue";
import { computed, ref } from "vue";
import { getDrawableAngleMeasurements } from "../../../../backend/src/measurements/drawable-measurements";
import { MeasurementToolName } from "../../../../backend/src/measurements/measurement-tool-names";
import {
  MeasurementUnit,
  getMeasurementDisplayUnit,
} from "../../../../backend/src/measurements/measurement-units";
import type { MeasurementPlane } from "../../../../backend/src/studies/study-clip-region";
import { findMainMeasurementAndValueForBatch } from "../../measurements/measurement-helpers";
import { activeMeasurement, isMeasuring } from "../../measurements/measurement-tool-state";
import {
  drawLinearMeasurement,
  getLinearMeasurementLabelPosition,
} from "../../measurements/tools/linear/measurement-base-linear";
import {
  drawContourEdges,
  drawContourInternalLines,
} from "../../measurements/tools/measurement-base-contour";
import { drawAngleArcAndVertices } from "../../measurements/tools/measurement-tool-angle";
import { getAreaMeasurementLabelPosition } from "../../measurements/tools/measurement-tool-area";
import { drawPlusMark } from "../../measurements/tools/measurement-tool-helpers";
import {
  drawVolumeMeasurementAxis,
  drawVolumeMeasurementPerpendicularScanlines,
} from "../../measurements/tools/measurement-tool-volume";
import type { Study, StudyMeasurement, StudyMeasurementValue } from "../../utils/study-data";
import type { ClipViewerMeasurementLabel } from "../clip-viewer-label";
import type { ClipsGridItem } from "./clips-grid-item";
import { ClipsGridItemType } from "./clips-grid-item";

type ClipViewer2DRegionLocation =
  | { type: "frame"; frame: number }
  | { type: "plane"; plane: MeasurementPlane };

/**
 * A MeasurementsRenderer is responsible for drawing both in-progress and saved measurements on the
 * provided canvas, and exposes the current set of measurement labels to display.
 */
export interface MeasurementsRenderer {
  measurementLabels: Ref<ClipViewerMeasurementLabel[]>;
  drawAllMeasurements: (viewingRegionLocation: ClipViewer2DRegionLocation) => void;
}

export function createMeasurementsRenderer(args: {
  study: Study;
  model: ClipsGridItem;
  canvas: HTMLCanvasElement;
  ctx: CanvasRenderingContext2D;
  showMeasurements: ComputedRef<boolean>;
}): MeasurementsRenderer {
  const { study, model, canvas, ctx, showMeasurements } = args;

  // The HTML measurement labels to show on top of the canvas. This array is repopulated on every
  // redraw.
  const measurementLabels = ref<ClipViewerMeasurementLabel[]>([]);

  // Fade out existing measurements when making a new one
  const measurementOpacity = computed(() => (isMeasuring.value ? 0.5 : 1));

  function shouldDrawMeasurementValueInBatch(measurementValue: StudyMeasurementValue) {
    if (model.clip === undefined || measurementValue.contour === null) {
      return false;
    }

    const measurementsInBatch = study.measurements
      .flatMap((m) => m.values)
      .filter((v) => v.measurementCreationBatchId === measurementValue.measurementCreationBatchId);

    // The only case where we don't draw all measurement values in a batch at present is with the
    // volume tool.
    if (measurementsInBatch.every((v) => v.measurementTool !== MeasurementToolName.Volume)) {
      return true;
    }

    // Only draw the main measurement value in a volume batch
    const mainVolumeMeasurement = findMainMeasurementAndValueForBatch(
      study,
      measurementValue.measurementCreationBatchId
    );

    return (
      mainVolumeMeasurement === undefined || mainVolumeMeasurement.value.id === measurementValue.id
    );
  }

  function isMeasurementValueBeingEdited(value: StudyMeasurementValue): boolean {
    return (
      activeMeasurement.value.editingMeasurementBatchId.value === value.measurementCreationBatchId
    );
  }

  function drawLinearMeasurementToCanvas(args: {
    measurement: StudyMeasurement;
    measurementValue: StudyMeasurementValue;
    canvas: HTMLCanvasElement;
    ctx: CanvasRenderingContext2D;
    drawEditHandles: boolean;
  }): void {
    const { measurement, measurementValue, canvas, ctx, drawEditHandles } = args;

    if (measurementValue.contour?.length !== 4 || isMeasurementValueBeingEdited(measurementValue)) {
      return;
    }

    const opacity = measurementOpacity.value;

    const from = [measurementValue.contour[0], measurementValue.contour[1]];
    const to = [measurementValue.contour[2], measurementValue.contour[3]];

    if (
      shouldDrawMeasurementValueInBatch(measurementValue) ||
      (model.type === ClipsGridItemType.RegularClip &&
        model.soloMeasurementValueId.value === measurementValue.id)
    ) {
      drawLinearMeasurement({ canvas, ctx, from, to, drawEditHandles, opacity });
    }

    const position = getLinearMeasurementLabelPosition({ canvas, from, to });
    measurementLabels.value.push({
      measurement,
      measurementValue,
      x: position.x,
      y: position.y,
      opacity,
    });
  }

  function drawContourToCanvas(args: {
    measurement: StudyMeasurement;
    measurementValue: StudyMeasurementValue;
    canvas: HTMLCanvasElement;
    ctx: CanvasRenderingContext2D;
  }): void {
    const { measurement, measurementValue, canvas, ctx } = args;
    if (
      measurementValue.contour === null ||
      measurementValue.contour.length < 4 ||
      isMeasurementValueBeingEdited(measurementValue)
    ) {
      return;
    }

    const opacity = measurementOpacity.value;
    const points = measurementValue.contour;

    if (
      shouldDrawMeasurementValueInBatch(measurementValue) ||
      (model.type === ClipsGridItemType.RegularClip &&
        model.soloMeasurementValueId.value === measurementValue.id)
    ) {
      drawContourEdges({
        canvas,
        ctx,
        points,
        drawMidpoints: false,
        pointOpacity: 0,
        opacity,
      });
      drawContourInternalLines({ canvas, ctx, points });
    }

    const position = getAreaMeasurementLabelPosition({ canvas, points });
    measurementLabels.value.push({
      measurement,
      measurementValue,
      x: position.x,
      y: position.y,
      opacity,
    });
  }

  function drawVolumeMeasurementToCanvas(obj: {
    measurement: StudyMeasurement;
    measurementValue: StudyMeasurementValue;
    canvas: HTMLCanvasElement;
    ctx: CanvasRenderingContext2D;
    hasAxisPoints: boolean;
  }): void {
    const { measurement, measurementValue, canvas, ctx, hasAxisPoints } = obj;
    if (
      measurementValue.contour === null ||
      measurementValue.contour.length < 10 ||
      isMeasurementValueBeingEdited(measurementValue)
    ) {
      return;
    }

    const opacity = measurementOpacity.value;

    let points: number[] = [];
    let axisPoints: number[] = [];

    if (hasAxisPoints) {
      points = measurementValue.contour.slice(0, -4);
      axisPoints = measurementValue.contour.slice(-4);
    } else {
      points = measurementValue.contour;
    }

    drawContourEdges({
      canvas,
      ctx,
      points,
      drawMidpoints: false,
      pointOpacity: 0,
      opacity,
    });
    drawVolumeMeasurementPerpendicularScanlines(canvas, ctx, points, axisPoints);
    drawVolumeMeasurementAxis(canvas, ctx, axisPoints, opacity);

    const position = getAreaMeasurementLabelPosition({ canvas, points });
    measurementLabels.value.push({
      measurement,
      measurementValue,
      x: position.x,
      y: position.y,
      opacity,
    });
  }

  function drawAngleMeasurementToCanvas(obj: {
    measurement: StudyMeasurement;
    measurementValue: StudyMeasurementValue;
    canvas: HTMLCanvasElement;
    ctx: CanvasRenderingContext2D;
  }): void {
    const { measurement, measurementValue, canvas, ctx } = obj;
    if (
      measurementValue.contour === null ||
      measurementValue.contour.length < 6 ||
      isMeasurementValueBeingEdited(measurementValue)
    ) {
      return;
    }

    const opacity = measurementOpacity.value;
    const points = measurementValue.contour;

    drawContourEdges({
      canvas,
      ctx,
      points,
      isInvalid: false,
      drawMidpoints: true,
      pointOpacity: 0,
      opacity,
    });

    drawAngleArcAndVertices({ canvas, ctx, points });

    const position = getAreaMeasurementLabelPosition({ canvas, points });
    measurementLabels.value.push({
      measurement,
      measurementValue,
      x: position.x,
      y: position.y,
      opacity,
    });
  }

  function drawPointMeasurementToCanvas({
    measurement,
    measurementValue,
    canvas,
    ctx,
  }: {
    canvas: HTMLCanvasElement;
    ctx: CanvasRenderingContext2D;
    measurement: StudyMeasurement;
    measurementValue: StudyMeasurementValue;
  }): void {
    if (
      measurementValue.contour === null ||
      measurementValue.contour.length < 2 ||
      isMeasurementValueBeingEdited(measurementValue)
    ) {
      return;
    }

    drawPlusMark(
      ctx,
      measurementValue.contour[0] * canvas.width,
      measurementValue.contour[1] * canvas.height,
      5,
      measurementOpacity.value
    );

    measurementLabels.value.push({
      measurement,
      measurementValue,
      x: measurementValue.contour[0] * canvas.width + 20,
      y: measurementValue.contour[1] * canvas.height,
      opacity: measurementOpacity.value,
    });
  }

  function drawAllMeasurements(viewingRegionLocation: ClipViewer2DRegionLocation): void {
    if (model.clip === undefined) {
      return;
    }

    measurementLabels.value = [];

    if (
      activeMeasurement.value.studyClipId === model.clip.id &&
      (viewingRegionLocation.type === "frame" ||
        isEqual(viewingRegionLocation.plane, activeMeasurement.value.region?.plane))
    ) {
      activeMeasurement.value.drawToCanvas(canvas, ctx);
    }

    if (!showMeasurements.value) {
      return;
    }

    function doesCurrentViewingRegionMatchMeasurement(measurementValue: StudyMeasurementValue) {
      if (viewingRegionLocation.type === "frame") {
        return measurementValue.frame === viewingRegionLocation.frame;
      } else {
        return isEqual(measurementValue.plane, viewingRegionLocation.plane);
      }
    }

    // Draw saved measurements for this frame
    for (const measurement of study.measurements) {
      for (const measurementValue of measurement.values) {
        if (
          measurementValue.contour === null ||
          measurementValue.studyClipId !== model.clip.id ||
          !doesCurrentViewingRegionMatchMeasurement(measurementValue)
        ) {
          continue;
        }

        // If this clip model has a soloed measurement value ID then only that value should be drawn
        if (
          model.type === ClipsGridItemType.RegularClip &&
          model.soloMeasurementValueId.value !== undefined &&
          model.soloMeasurementValueId.value !== measurementValue.id
        ) {
          continue;
        }

        if (measurementValue.contour.length === 2) {
          drawPointMeasurementToCanvas({ measurement, measurementValue, canvas, ctx });
        } else if (measurementValue.contour.length === 4) {
          drawLinearMeasurementToCanvas({
            measurement,
            measurementValue,
            canvas,
            ctx,
            drawEditHandles: false,
          });
        } else if (getMeasurementDisplayUnit(measurement.name) === MeasurementUnit.Milliliters) {
          drawVolumeMeasurementToCanvas({
            measurement,
            measurementValue,
            canvas,
            ctx,
            hasAxisPoints: true,
          });
        } else if (getDrawableAngleMeasurements().includes(measurement.name)) {
          drawAngleMeasurementToCanvas({
            measurement,
            measurementValue,
            canvas,
            ctx,
          });
        } else if (
          measurementValue.contour.length >= 6 &&
          measurementValue.contour.length % 2 === 0
        ) {
          drawContourToCanvas({ measurement, measurementValue, canvas, ctx });
        } else {
          console.warn(
            `Measurement value for ${measurement.name} has a contour that was NOT drawn`
          );
        }
      }
    }
  }

  return { measurementLabels, drawAllMeasurements };
}
