<template>
  <div class="back-btn" @click="router.push({ name: props.backDestinationRouteName })">
    <FontAwesomeIcon icon="arrow-left" />
    {{ props.buttonText }}
  </div>
</template>
<script setup lang="ts">
import router from "@/router";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

interface Props {
  backDestinationRouteName: string;
  buttonText: string;
}

const props = defineProps<Props>();
</script>
<style scoped>
.back-btn {
  cursor: pointer;
  opacity: 0.8;
  display: flex;
  gap: 8px;
  transition: opacity 100ms ease;

  &:hover {
    opacity: 1;
  }
}
</style>
