<template>
  <RoleEdit
    :id="id"
    :model-value="role"
    :is-editable="isEditable"
    :activity-text="activityText"
    @save-role="saveUserRole"
    @delete-role="deleteUserRole"
  >
    <PermissionGroups
      v-if="role"
      :permission-groups="PERMISSION_GROUPS"
      :role="{
        permissions: role.permissions,
        type: role.type,
      }"
      :is-editable="isEditable"
      @permission-group-toggle="onPermissionGroupToggle"
      @permission-toggle="onPermissionToggle"
    />
  </RoleEdit>
</template>

<script setup lang="ts">
import { currentTenant } from "@/auth/current-session";
import router from "@/router";
import { addNotification } from "@/utils/notifications";
import { useDebounceFn } from "@vueuse/core";
import axios from "axios";
import { computed, onBeforeUnmount, ref, watch } from "vue";
import { RoleType } from "../../../backend/src/user/roles/user-role-type";
import { hasUserRoleManagePermission } from "../auth/authorization";
import { useUserRoleList } from "../utils/user-roles-list";
import PermissionGroups from "./components/PermissionGroups.vue";
import {
  AdminPermissionGroup,
  AdminPermissionInfo,
  PERMISSION_GROUPS,
  PermissionGroup,
  PermissionInfo,
} from "./permission-toggle";
import RoleEdit from "./RoleEdit.vue";

interface Props {
  id: string;
}

const props = defineProps<Props>();

const userRoleList = useUserRoleList();
const role = computed(() => userRoleList.value.find((r) => r.id === props.id));

const isEditable = computed(
  () => hasUserRoleManagePermission.value && role.value?.type === RoleType.TenantControlled
);

const activityText = ref("");
const isSaved = ref(true);

let roleIdToSave = "";

watch(
  () => props.id,
  async () => {
    // Save any pending changes to the previously selected role
    await saveUserRoleImmediate();

    roleIdToSave = props.id;
  },
  { immediate: true }
);

async function saveUserRole(): Promise<void> {
  isSaved.value = false;
  await saveUserRoleDebounced();
}

const saveUserRoleDebounced = useDebounceFn(() => void saveUserRoleImmediate(), 1000);

async function saveUserRoleImmediate(): Promise<void> {
  if (isSaved.value) {
    return;
  }

  const roleToSave = userRoleList.value.find((r) => r.id === roleIdToSave);
  if (roleToSave === undefined) {
    return;
  }

  try {
    await axios.patch(`/api/user-roles/${roleToSave.id}`, {
      isEnabled: roleToSave.isEnabled,
      permissions: roleToSave.permissions,
      name: roleToSave.name.trim(),
    });
  } catch (error) {
    addNotification({ type: "error", message: "Failed saving roles" });
    return;
  }

  addNotification({ type: "info", message: "Updated role" });

  isSaved.value = true;
}

// Save any pending changes on navigating away
onBeforeUnmount(saveUserRoleImmediate);

function onPermissionGroupToggle(
  newValue: boolean,
  group: PermissionGroup | AdminPermissionGroup
): void {
  if (role.value === undefined || group.type === "admin") {
    return;
  }

  for (const permission of group.permissions) {
    if (newValue) {
      role.value.permissions.push(permission.value);

      // Add permissions that are required by this permission
      for (const requiredPermission of permission.requires ?? []) {
        if (!role.value.permissions.includes(requiredPermission)) {
          role.value.permissions.push(requiredPermission);
        }
      }
    } else {
      role.value.permissions = role.value.permissions.filter((p) => p !== permission.value);
    }
  }

  void saveUserRole();
}

function onPermissionToggle(
  newValue: boolean,
  permission: PermissionInfo | AdminPermissionInfo
): void {
  if (role.value === undefined || permission.type === "admin") {
    return;
  }

  if (newValue) {
    role.value.permissions.push(permission.value);

    // Add permissions that are required by this permission
    for (const requiredPermission of permission.requires ?? []) {
      if (!role.value.permissions.includes(requiredPermission)) {
        role.value.permissions.push(requiredPermission);
      }
    }
  } else {
    role.value.permissions = role.value.permissions.filter((p) => p !== permission.value);

    // Remove permissions that require this permission
    for (const group of PERMISSION_GROUPS) {
      for (const permissionInfo of group.permissions) {
        if ((permissionInfo.requires ?? []).includes(permission.value)) {
          role.value.permissions = role.value.permissions.filter((p) => p !== permissionInfo.value);
        }
      }
    }
  }

  void saveUserRole();
}

async function deleteUserRole(): Promise<void> {
  if (role.value === undefined) {
    return;
  }

  if (!confirm(`Are you sure you want to delete the role "${role.value.name}"?`)) {
    return;
  }

  activityText.value = "Deleting role";

  try {
    await axios.delete(`/api/user-roles/${role.value.id}`);
  } catch {
    addNotification({ type: "error", message: "Failed deleting role" });
    return;
  } finally {
    activityText.value = "";
  }

  // Remove role from the role list and the currentTenant.roles value
  currentTenant.roles.splice(
    currentTenant.roles.findIndex((r) => r.id === role.value?.id),
    1
  );
  userRoleList.value.splice(
    userRoleList.value.findIndex((r) => r.id === role.value?.id),
    1
  );

  addNotification({ type: "info", message: "Deleted role" });

  await router.push({ name: "settings-access-roles" });
}
</script>
