import type { Router } from "vue-router";
import About from "./About.vue";
import AdminUserRoleList from "./AdminUserRoleList.vue";
import Home from "./Home.vue";
import AuditLogs from "./audit-logs/AuditLogs.vue";
import AcceptInvitation from "./auth/AcceptInvitation.vue";
import PasscodePrompt from "./auth/PasscodePrompt.vue";
import PasswordReset from "./auth/PasswordReset.vue";
import SignIn from "./auth/SignIn.vue";
import { routeGuard } from "./auth/authentication";
import UnknownPath from "./components/UnknownPath.vue";
import ReportPDFNameSettings from "./reporting/ReportPDFNameSettings.vue";
import AccessSettings from "./settings/AccessSettings.vue";
import Activity from "./settings/Activity.vue";
import AdminUserRoleEdit from "./settings/AdminUserRoleEdit.vue";
import AdminUserView from "./settings/AdminUserView.vue";
import AdminUsersList from "./settings/AdminUsersList.vue";
import AdvancedSettings from "./settings/AdvancedSettings.vue";
import BillingSettings from "./settings/BillingSettings.vue";
import BillingTenantList from "./settings/BillingTenantList.vue";
import BillingTenantView from "./settings/BillingTenantView.vue";
import ReportCalculationDocs from "./settings/ReportCalculationDocs.vue";
import ReportSentenceGroupList from "./settings/ReportSentenceGroupList.vue";
import ReportSettings from "./settings/ReportSettings.vue";
import ReportTemplateEdit from "./settings/ReportTemplateEdit.vue";
import ReportTemplateList from "./settings/ReportTemplateList.vue";
import SessionsList from "./settings/SessionsList.vue";
import Settings from "./settings/Settings.vue";
import StudyListSettings from "./settings/StudyListSettings.vue";
import TenantList from "./settings/TenantList.vue";
import TenantSettings from "./settings/TenantSettings.vue";
import TenantView from "./settings/TenantView.vue";
import UserApiKeysList from "./settings/UserApiKeysList.vue";
import UserDefaultsDisplayOptionsSettings from "./settings/UserDefaultsDisplayOptionsSettings.vue";
import UserPINSettings from "./settings/UserPINSettings.vue";
import UserPersonalSettings from "./settings/UserPersonalSettings.vue";
import UserRoleEdit from "./settings/UserRoleEdit.vue";
import UserRoleList from "./settings/UserRoleList.vue";
import UserSecuritySettings from "./settings/UserSecuritySettings.vue";
import UserSettings from "./settings/UserSettings.vue";
import UserSignatureSettings from "./settings/UserSignatureSettings.vue";
import UserView from "./settings/UserView.vue";
import UsersList from "./settings/UsersList.vue";
import Worklists from "./settings/Worklists.vue";
import IntegrationSettings from "./settings/integrations/IntegrationSettings.vue";
import AgentList from "./settings/integrations/agent/AgentList.vue";
import AgentSettings from "./settings/integrations/agent/AgentSettings.vue";
import DicomEndpointList from "./settings/integrations/dicom/DicomEndpointList.vue";
import DicomEndpointSettings from "./settings/integrations/dicom/DicomEndpointSettings.vue";
import DicomEndpointView from "./settings/integrations/dicom/DicomEndpointView.vue";
import DicomIdentityList from "./settings/integrations/dicom/DicomIdentityList.vue";
import DicomIdentityView from "./settings/integrations/dicom/DicomIdentityView.vue";
import DicomSettings from "./settings/integrations/dicom/DicomSettings.vue";
import HeartBoxList from "./settings/integrations/heartbox/HeartBoxList.vue";
import HeartBoxSettings from "./settings/integrations/heartbox/HeartBoxSettings.vue";
import HeartBoxView from "./settings/integrations/heartbox/HeartBoxView.vue";
import ViewHL7Integration from "./settings/integrations/hl7/HL7IntegrationView.vue";
import HL7IntegrationsList from "./settings/integrations/hl7/HL7IntegrationsList.vue";
import HL7Settings from "./settings/integrations/hl7/HL7Settings.vue";
import ViewSftpIntegration from "./settings/integrations/sftp/SftpIntegrationView.vue";
import SftpIntegrationsList from "./settings/integrations/sftp/SftpIntegrationsList.vue";
import SftpSettings from "./settings/integrations/sftp/SftpSettings.vue";
import WebhookIntegrationList from "./settings/integrations/webhooks/WebhookIntegrationList.vue";
import WebhookIntegrationView from "./settings/integrations/webhooks/WebhookIntegrationView.vue";
import WebhookSettings from "./settings/integrations/webhooks/WebhookSettings.vue";
import MeasurementCalculationSettings from "./settings/measurements/MeasurementCalculationSettings.vue";
import MeasurementSequenceSettings from "./settings/measurements/MeasurementSequenceSettings.vue";
import MeasurementSettings from "./settings/measurements/MeasurementSettings.vue";
import MeasurementToolSettings from "./settings/measurements/MeasurementToolSettings.vue";
import StudyList from "./study-list/StudyList.vue";
import StudyView from "./study-view/StudyView.vue";
import ExtraClipsSecondaryWindowView from "./study-view/multi-window/ExtraClipsSecondaryWindowView.vue";
import SecondaryWindowView from "./study-view/multi-window/SecondaryWindowView.vue";
import SerialStudySecondaryWindowView from "./study-view/multi-window/SerialStudySecondaryWindowView.vue";

export function addRoutesToRouter(router: Router): void {
  router.addRoute({
    path: "/",
    redirect: "/study-list",
    name: "home",
    component: Home,
    beforeEnter: [routeGuard],

    // Tell the home component the ID of the currently open study, if any
    props: (to): Record<string, unknown> => {
      if (to.name === "study-view" && typeof to.params.id === "string") {
        return { studyId: to.params.id };
      }

      return {};
    },

    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: SignIn,
      },
      {
        path: "/accept-invitation",
        name: "accept-invitation",
        component: AcceptInvitation,
      },
      {
        path: "/reset-password",
        name: "reset-password",
        component: PasswordReset,
      },
      {
        path: "/study-list",
        name: "study-list",
        component: StudyList,
      },
      {
        path: "/study-view/:id",
        name: "study-view",
        component: StudyView,
        props: (to): Record<string, unknown> => ({ id: to.params.id }),
      },
      {
        path: "/about",
        name: "about",
        component: About,
      },
      {
        path: "/audit-logs",
        name: "audit-logs",
        component: AuditLogs,
      },
      {
        path: "/passcode-prompt",
        name: "passcode-prompt",
        component: PasscodePrompt,
      },
      {
        path: "/settings",
        name: "settings",
        component: Settings,
        redirect: "/settings/user",
        children: [
          {
            path: "/settings/user",
            name: "settings-user",
            component: UserSettings,
            redirect: "/settings/user/personal",
            children: [
              {
                path: "/settings/user/personal",
                name: "settings-user-personal",
                component: UserPersonalSettings,
              },
              {
                path: "/settings/user/security",
                name: "settings-user-security",
                component: UserSecuritySettings,
              },
              {
                path: "/settings/user/pin",
                name: "settings-user-pin",
                component: UserPINSettings,
              },
              {
                path: "/settings/user/signature",
                name: "settings-user-signature",
                component: UserSignatureSettings,
              },
              {
                path: "/settings/user/api-keys",
                name: "settings-user-api-keys",
                component: UserApiKeysList,
              },
              {
                path: "/settings/user/sessions",
                name: "settings-user-sessions",
                component: SessionsList,
              },
              {
                path: "/settings/user/defaults",
                name: "settings-user-defaults",
                component: UserDefaultsDisplayOptionsSettings,
              },
            ],
          },
          {
            path: "/settings/access",
            name: "settings-access",
            component: AccessSettings,
            redirect: "/settings/access/users/all",
            children: [
              {
                path: "/settings/access/users/all",
                name: "settings-access-users-all",
                component: UsersList,
              },
              {
                path: "/settings/access/users/:id",
                name: "settings-access-users-view",
                component: UserView,
                props: (to): Record<string, unknown> => ({ id: to.params.id }),
              },
              {
                path: "/settings/access/roles",
                name: "settings-access-roles",
                component: UserRoleList,
                children: [
                  {
                    path: "/settings/access/roles/:id",
                    name: "settings-access-roles-edit",
                    component: UserRoleEdit,
                    props: (to): Record<string, unknown> => ({ id: to.params.id }),
                  },
                ],
              },
            ],
          },
          {
            path: "/settings/worklists",
            name: "settings-worklists",
            component: Worklists,
          },
          {
            path: "/settings/reporting",
            name: "settings-reporting",
            component: ReportSettings,
            redirect: "/settings/reporting/templates",
            children: [
              {
                path: "/settings/reporting/sentence-library",
                name: "settings-reporting-sentence-library",
                component: ReportSentenceGroupList,
              },
              {
                path: "/settings/reporting/templates",
                name: "settings-reporting-templates-all",
                component: ReportTemplateList,
              },
              {
                path: "/settings/reporting/templates/:id",
                name: "settings-reporting-templates-edit",
                component: ReportTemplateEdit,
                props: (to): Record<string, unknown> => ({ id: to.params.id }),
              },
              {
                path: "/settings/reporting/calculations",
                name: "settings-reporting-calculations",
                component: ReportCalculationDocs,
              },
              {
                path: "/settings/reporting/pdf",
                name: "settings-reporting-pdf",
                component: ReportPDFNameSettings,
              },
            ],
          },
          {
            path: "/settings/integrations",
            name: "settings-integrations",
            component: IntegrationSettings,
            redirect: "/settings/integrations/dicom",
            children: [
              {
                path: "/settings/integrations/agent",
                name: "settings-agent",
                component: AgentSettings,
                redirect: "/settings/integrations/agent/all",
                children: [
                  {
                    path: "/settings/integrations/agent/all",
                    name: "settings-agent-all",
                    component: AgentList,
                  },
                ],
              },
              {
                path: "/settings/integrations/dicom",
                name: "settings-integrations-dicom",
                component: DicomSettings,
                redirect: "/settings/integrations/dicom/all",
                children: [
                  {
                    path: "/settings/integrations/dicom/all",
                    name: "settings-integrations-dicom-all",
                    component: DicomIdentityList,
                  },
                  {
                    path: "/settings/integrations/dicom/:id",
                    name: "settings-integrations-dicom-view",
                    component: DicomIdentityView,
                    props: (route): Record<string, unknown> => ({ id: route.params.id }),
                  },
                ],
              },
              {
                path: "/settings/integrations/heartbox",
                name: "settings-integrations-heartbox",
                component: HeartBoxSettings,
                redirect: "/settings/integrations/heartbox/all",
                children: [
                  {
                    path: "/settings/integrations/heartbox/all",
                    name: "settings-integrations-heartbox-all",
                    component: HeartBoxList,
                  },
                  {
                    path: "/settings/integrations/heartbox/:id",
                    name: "settings-integrations-heartbox-view",
                    component: HeartBoxView,
                    props: (to): Record<string, unknown> => ({ id: to.params.id }),
                  },
                ],
              },
              {
                path: "/settings/integrations/hl7",
                name: "settings-integrations-hl7",
                component: HL7Settings,
                redirect: "/settings/integrations/hl7/all",
                children: [
                  {
                    path: "/settings/integrations/hl7/all",
                    name: "settings-integrations-hl7-all",
                    component: HL7IntegrationsList,
                  },
                  {
                    path: "/settings/integrations/hl7/:id",
                    name: "settings-integrations-hl7-view",
                    component: ViewHL7Integration,
                    props: (to): Record<string, unknown> => ({ id: to.params.id }),
                  },
                ],
              },
              {
                path: "/settings/integrations/sftp",
                name: "settings-integrations-sftp",
                component: SftpSettings,
                redirect: "/settings/integrations/sftp/all",
                children: [
                  {
                    path: "/settings/integrations/sftp/all",
                    name: "settings-integrations-sftp-all",
                    component: SftpIntegrationsList,
                  },
                  {
                    path: "/settings/integrations/sftp/:id",
                    name: "settings-integrations-sftp-view",
                    component: ViewSftpIntegration,
                    props: (to): Record<string, unknown> => ({ id: to.params.id }),
                  },
                ],
              },
              {
                path: "/settings/integrations/webhook",
                name: "settings-integrations-webhook",
                component: WebhookSettings,
                redirect: "/settings/integrations/webhook/all",
                children: [
                  {
                    path: "/settings/integrations/webhook/all",
                    name: "settings-integrations-webhook-all",
                    component: WebhookIntegrationList,
                  },
                  {
                    path: "/settings/integrations/webhook/:id",
                    name: "settings-integrations-webhook-view",
                    component: WebhookIntegrationView,
                    props: (to): Record<string, unknown> => ({ id: to.params.id }),
                  },
                ],
              },
            ],
          },
          {
            path: "/settings/measurements",
            name: "settings-measurements",
            component: MeasurementSettings,
            redirect: "/settings/measurements/tools",
            children: [
              {
                path: "/settings/measurements/tools",
                name: "settings-measurements-tools",
                component: MeasurementToolSettings,
              },
              {
                path: "/settings/measurements/sequences",
                name: "settings-measurements-sequences",
                component: MeasurementSequenceSettings,
              },
              {
                path: "/settings/measurements/calculations",
                name: "settings-measurements-calculations",
                component: MeasurementCalculationSettings,
              },
            ],
          },
          {
            path: "/settings/advanced",
            name: "settings-advanced",
            component: AdvancedSettings,
          },
          {
            path: "/settings/study-list",
            name: "settings-study-list",
            component: StudyListSettings,
          },
          {
            path: "/settings/activity",
            name: "settings-actiivity",
            component: Activity,
          },
          {
            path: "/settings/global-admin/tenants",
            name: "settings-tenants",
            component: TenantSettings,
            redirect: "/settings/global-admin/tenants/all",
            children: [
              {
                path: "/settings/global-admin/tenants/all",
                name: "settings-tenants-all",
                component: TenantList,
              },
              {
                path: "/settings/global-admin/tenants/:id",
                name: "settings-tenants-view",
                component: TenantView,
                props: (to): Record<string, unknown> => ({ id: to.params.id }),
              },
            ],
          },
          {
            path: "/settings/global-admin/billing",
            name: "settings-billing",
            component: BillingSettings,
            redirect: "/settings/global-admin/billing/all",
            children: [
              {
                path: "/settings/global-admin/billing/all",
                name: "settings-billing-all",
                component: BillingTenantList,
              },
              {
                path: "/settings/global-admin/billing/:id",
                name: "settings-billing-tenant",
                component: BillingTenantView,
                props: (to): Record<string, unknown> => ({ id: to.params.id }),
              },
            ],
          },
          {
            path: "/settings/global-admin/dicom-endpoints",
            name: "settings-dicom-endpoints",
            component: DicomEndpointSettings,
            redirect: "/settings/global-admin/dicom-endpoints/all",
            children: [
              {
                path: "/settings/global-admin/dicom-endpoints/all",
                name: "settings-dicom-endpoints-all",
                component: DicomEndpointList,
              },
              {
                path: "/settings/global-admin/dicom-endpoints/:id",
                name: "settings-dicom-endpoints-view",
                component: DicomEndpointView,
                props: (to): Record<string, unknown> => ({ id: to.params.id }),
              },
            ],
          },
          {
            path: "/settings/global-admin",
            name: "settings-global-admin",
            redirect: "/settings/global-admin/users/all",
            children: [
              {
                path: "/settings/global-admin/users/all",
                name: "settings-global-admin-users-all",
                component: AdminUsersList,
              },
              {
                path: "/settings/global-admin/users/:id",
                name: "settings-global-admin-users-view",
                component: AdminUserView,
                props: (to): Record<string, unknown> => ({ id: to.params.id }),
              },
              {
                path: "/settings/global-admin/roles",
                name: "settings-global-admin-roles",
                component: AdminUserRoleList,
                children: [
                  {
                    path: "/settings/global-admin/roles/:id",
                    name: "settings-global-admin-roles-edit",
                    component: AdminUserRoleEdit,
                    props: (to): Record<string, unknown> => ({ id: to.params.id }),
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  });

  // Put the secondary window routes outside the above routes to prevent the standard header from
  // being visible on secondary windows. This helps to prevent the user from navigating away from
  // the secondary window route through the UI.
  router.addRoute({
    path: "/secondary-window/:primaryWindowId",
    name: "secondary-window",
    component: SecondaryWindowView,
    beforeEnter: [routeGuard],
    props: (to): Record<string, unknown> => ({ primaryWindowId: to.params.primaryWindowId }),
    children: [
      {
        path: "extra-clips",
        name: "secondary-window-extra-clips",
        component: ExtraClipsSecondaryWindowView,
      },
      {
        path: "serial-study/:studyId",
        name: "secondary-window-serial-study",
        component: SerialStudySecondaryWindowView,
        props: (to): Record<string, unknown> => ({ studyId: to.params.studyId }),
      },
    ],
  });

  // Redirect any unknown routes to "/"
  router.addRoute({
    name: "unknown-path",
    path: "/:pathMatch(.*)*",
    component: UnknownPath,
  });
}
