<script setup lang="ts">
import FilterInput from "@/components/FilterInput.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import { addNotification } from "@/utils/notifications";
import { useQuery } from "@tanstack/vue-query";
import { useDebounceFn } from "@vueuse/core";
import axios from "axios";
import { computed, ref, watch } from "vue";
import type { BillingTenantGetManyResponseDto } from "../../../backend/src/billing/dto/billing-tenant-get-many.dto";
import { formatDateTime } from "../../../backend/src/shared/date-time-utils";
import router from "../router";

const searchTerm = ref("");

const { isLoading, isError, data } = useQuery({
  queryKey: ["tenant-billing-details"],
  queryFn: async () => {
    const response = await axios.get<BillingTenantGetManyResponseDto>("/api/billings");
    return response.data;
  },
});

const addErrorNotification = useDebounceFn(() => {
  return addNotification({
    type: "error",
    message: "Failed to load tenants",
  });
}, 4000);

watch(isError, (newValue) => {
  if (newValue) {
    void addErrorNotification();
  }
});

const tenants = computed(
  () =>
    data.value?.filter((tenant) =>
      tenant.name.toLowerCase().includes(searchTerm.value.toLowerCase())
    ) ?? []
);

async function onViewTenant(tenantId: string): Promise<void> {
  await router.push({ name: "settings-billing-tenant", params: { id: tenantId } });
}
</script>

<template>
  <div class="settings-title">Billing Management</div>

  <div class="top-row">
    <FilterInput v-model="searchTerm" placeholder="Search" />
  </div>

  <div v-if="!isLoading" class="tenants-table">
    <div class="header">
      <div class="header-item">
        <div class="title">Name</div>
      </div>
      <div class="header-item">
        <div class="title">Current Billing Period</div>
      </div>
    </div>

    <div class="header-line" />

    <div
      v-for="tenant in tenants"
      :key="tenant.id"
      class="grid-table-row"
      @click="onViewTenant(tenant.id)"
    >
      <div>{{ tenant.name }}</div>
      <div>
        {{ formatDateTime(tenant.currentBillingPeriod?.start) }} -
        {{ formatDateTime(tenant.currentBillingPeriod?.end) }}
      </div>
    </div>
  </div>

  <LoadingIndicator v-else style="align-self: center" size="2x" />
</template>

<style scoped lang="scss">
.top-row {
  display: grid;
  grid-template-columns: 300px 1fr;
  align-items: center;
}

.tenants-table {
  display: grid;
  grid-template-columns: 300px 1fr;
  row-gap: 8px;

  .header {
    display: contents;

    .header-item {
      display: flex;
      gap: 12px;
      cursor: pointer;
      transition: color 100ms ease;

      .title {
        font-weight: bold;
      }

      .sort-icon {
        color: var(--accent-color-1);
        transition: color 100ms ease;
        visibility: hidden;

        &.visible {
          visibility: visible;
        }
      }

      &:hover {
        color: var(--text-color-2);

        .sort-icon {
          color: var(--accent-color-2);
        }
      }
    }
  }

  .header-line {
    grid-area: 2 / 1 / 2 / span 2;
    border-bottom: 2px solid var(--bg-color-3);
  }
}
</style>
