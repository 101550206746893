<template>
  <div class="settings-title">
    Agent Settings

    <div class="agent-download" @click="isAgentDownloadModalVisible = true">Download Agent</div>

    <button
      class="accented"
      style="font-size: 13px"
      data-testid="open-add-agent-integration-modal"
      @click="showAddAgentIntegrationModal"
    >
      Create Agent
    </button>
  </div>

  <div class="agents-table">
    <div class="header">
      <div>Name</div>
      <div>Token</div>
      <div>Last Used</div>
      <div>Enabled</div>
      <div />
    </div>

    <div class="header-line" />

    <div v-for="agent in agents" :key="agent.id" class="grid-table-row">
      <div>{{ agent.name }}</div>
      <template v-if="agent.apiKey">
        <div>
          <code>{{ agent.apiKey.tokenInitialCharacters }}</code> ...
        </div>
        <div>{{ agent.apiKey.lastUsedAt }}</div>
        <div style="display: grid; padding: 0; place-content: center">
          <ToggleSwitch
            v-model="agent.apiKey.enabled"
            :data-testid="`agent-${agent.name}-toggle`"
            @update:model-value="onUpdateAgentEnabled(agent.id, agent.apiKey.enabled)"
          />
        </div>
      </template>
      <div v-else style="grid-column: 2 / span 3">
        The API key for this agent has been deleted.
        <span
          class="regenerate-api-key"
          data-testid="recreate-api-key"
          @click="regenerateAgentAPIKey(agent)"
        >
          Recreate?
        </span>
      </div>

      <div style="align-self: stretch; display: grid; place-content: center">
        <Tooltip content="Delete agent" @click="deleteAgentIntegration(agent.id)">
          <FontAwesomeIcon
            icon="trash"
            class="delete-icon"
            :data-testid="`agent-${agent.name}-delete`"
          />
        </Tooltip>
      </div>
    </div>

    <ActivityOverlay v-if="isLoading" text="Loading" />
  </div>

  <Modal
    v-if="isAgentIntegrationCreateModalVisible"
    :title="newAgentIntegration.id === '' ? 'Create Agent' : 'Created Agent'"
    :activity-text="isCreatingAgentIntegration ? 'Creating Agent' : ''"
    @header-button-click="isAgentIntegrationCreateModalVisible = false"
  >
    <div class="modal-container">
      <div class="field">
        <b>Agent Name</b>
        <input
          ref="nameInputElement"
          v-model="newAgentIntegration.name"
          type="text"
          data-testid="agent-name-input"
          :disabled="newAgentIntegration.token !== ''"
        />
      </div>

      <button
        v-if="newAgentIntegration.id === ''"
        class="accented"
        :disabled="newAgentIntegration.name === ''"
        data-testid="create-agent-integration"
        @click="createAgentIntegration"
      >
        Create Agent
      </button>

      <SingleViewToken
        v-else
        header="Agent API Key"
        token-type="API Key"
        :token="newAgentIntegration.token"
      >
        Use this API key when configuring the HeartLab Agent.
      </SingleViewToken>
    </div>
  </Modal>

  <AgentDownloadModal
    v-if="isAgentDownloadModalVisible && agentDownloadDetails"
    :agent-downloads="agentDownloadDetails"
    @close="isAgentDownloadModalVisible = false"
  />
</template>

<script setup lang="ts">
import ToggleSwitch from "@/components/ToggleSwitch.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useFocus } from "@vueuse/core";
import axios from "axios";
import { onMounted, reactive, ref } from "vue";
import { AgentDownloadsGetResponseDto } from "../../../../../backend/src/integrations/agent/dto/agent-downloads-get.dto";
import { AgentIntegrationCreateResponseDto } from "../../../../../backend/src/integrations/agent/dto/agent-integration-create.dto";
import { AgentIntegrationGetOneResponseDto } from "../../../../../backend/src/integrations/agent/dto/agent-integration-get-one.dto";
import ActivityOverlay from "../../../components/ActivityOverlay.vue";
import Modal from "../../../components/Modal.vue";
import Tooltip from "../../../components/Tooltip.vue";
import { addNotification } from "../../../utils/notifications";
import { getRequestErrorMessage } from "../../../utils/request-helpers";
import SingleViewToken from "../../SingleViewToken.vue";
import AgentDownloadModal from "./AgentDownloadModal.vue";

const isLoading = ref(false);

const agents = ref<AgentIntegrationGetOneResponseDto[]>([]);

async function loadAgents(): Promise<void> {
  try {
    agents.value = (
      await axios.get<AgentIntegrationGetOneResponseDto[]>(`/api/integrations/agent`)
    ).data;
  } catch {
    addNotification({ type: "error", message: "Failed loading list of agents" });
  }
}

async function fetchAgentDownloads(): Promise<void> {
  const latestJson = await axios.get<AgentDownloadsGetResponseDto>(
    "/api/integrations/agent/downloads"
  );
  agentDownloadDetails.value = latestJson.data;
}

onMounted(async () => {
  await loadAgents();
  await fetchAgentDownloads();
});

const isAgentDownloadModalVisible = ref(false);
const agentDownloadDetails = ref<AgentDownloadsGetResponseDto | null>(null);

const isAgentIntegrationCreateModalVisible = ref(false);
const newAgentIntegration = reactive({
  id: "",
  name: "",
  token: "",
});

const isCreatingAgentIntegration = ref(false);

function showAddAgentIntegrationModal(): void {
  isAgentIntegrationCreateModalVisible.value = true;
  newAgentIntegration.id = "";
  newAgentIntegration.name = "";
  newAgentIntegration.token = "";
}

const nameInputElement = ref<HTMLInputElement | null>(null);
useFocus(nameInputElement, { initialValue: true });

async function createAgentIntegration(): Promise<void> {
  isCreatingAgentIntegration.value = true;

  newAgentIntegration.name = newAgentIntegration.name.trim();

  let response: AgentIntegrationCreateResponseDto | undefined = undefined;

  try {
    response = (
      await axios.post<AgentIntegrationCreateResponseDto>(`/api/integrations/agent`, {
        name: newAgentIntegration.name,
      })
    ).data;
  } catch (error) {
    addNotification({
      type: "error",
      message: getRequestErrorMessage(error) ?? "Failed creating Agent integration",
    });
    return;
  } finally {
    isCreatingAgentIntegration.value = false;
  }

  addNotification({ type: "info", message: "Created Agent integration" });
  newAgentIntegration.id = response.id;
  newAgentIntegration.token = response.token;

  agents.value.push(response);
}

async function deleteAgentIntegration(id: string): Promise<void> {
  if (!confirm("Are you sure you want to delete this agent?")) {
    return;
  }

  try {
    await axios.delete(`/api/integrations/agent/${id}`);

    addNotification({ type: "info", message: "Deleted Agent integration" });

    agents.value.splice(
      agents.value.findIndex((agent) => agent.id === id),
      1
    );
  } catch (error) {
    addNotification({
      type: "error",
      message: getRequestErrorMessage(error) ?? "Failed deleting Agent integration",
    });
    return;
  }
}

async function onUpdateAgentEnabled(id: string, enabled: boolean): Promise<void> {
  try {
    await axios.patch(`/api/integrations/agent/${id}`, { enabled });
  } catch {
    addNotification({ type: "error", message: "Failed updating agent" });
    return;
  }

  addNotification({ type: "info", message: "Updated Agent integration" });
}

async function regenerateAgentAPIKey(agent: AgentIntegrationGetOneResponseDto): Promise<void> {
  let response: AgentIntegrationCreateResponseDto | undefined = undefined;

  try {
    response = (
      await axios.post<AgentIntegrationCreateResponseDto>(
        `/api/integrations/agent/${agent.id}/regenerate-key`
      )
    ).data;
  } catch (error) {
    addNotification({
      type: "error",
      message: getRequestErrorMessage(error) ?? "Failed recreating API key",
    });
    return;
  }

  isAgentIntegrationCreateModalVisible.value = true;
  newAgentIntegration.id = response.id;
  newAgentIntegration.name = response.name;
  newAgentIntegration.token = response.token;

  await loadAgents();
}
</script>

<style scoped lang="scss">
.top-row {
  display: grid;
  grid-template-columns: 300px 1fr;
  align-items: center;
}

.agent-download {
  margin-left: auto;
  align-self: center;
  font-size: 13px;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: var(--accent-color-2);
  }
}

.agents-table {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr min-content min-content;
  align-items: center;
  row-gap: 2px;

  .header {
    font-weight: bold;
    display: contents;

    > * {
      padding-bottom: 8px;
    }
  }

  .header-line {
    grid-area: 2 / 1 / 2 / span 5;
    border-bottom: 2px solid var(--bg-color-3);
  }
}

.delete-icon {
  opacity: 0;
  cursor: pointer;
  color: var(--accent-color-1);
  transition:
    color 100ms ease,
    opacity 100ms ease;

  &:hover {
    color: var(--accent-color-2);
  }
}

.grid-table-row:hover .delete-icon {
  opacity: 1;
}

.modal-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 400px;

  .field {
    display: flex;
    flex-direction: column;
    gap: 8px;

    span {
      font-size: 0.85em;
    }
  }

  button {
    margin: 0 auto;
  }
}

.regenerate-api-key {
  font-weight: bold;
  text-decoration: underline;
}

input[type="text"]:disabled {
  opacity: 1;
}
</style>
