<template>
  <Transition name="fade">
    <div v-if="!hasLoadedPdf && !isStudyLoadingIndicatorVisible" class="progress-container">
      <LoadingIndicator size="2x" />
    </div>
  </Transition>

  <PdfViewer
    :doc-path="`/api/studies/${study.id}/clips/${gridItem.clip?.id}/pdf`"
    @on-load="hasLoadedPdf = true"
  />
</template>

<script setup lang="ts">
import { inject, ref } from "vue";
import LoadingIndicator from "../../components/LoadingIndicator.vue";
import PdfViewer from "../../components/PdfViewer.vue";
import { Study } from "../../utils/study-data";
import { EncapsulatedPDFClipsGridItem } from "./clips-grid-item";

interface Props {
  study: Study;
  gridItem: EncapsulatedPDFClipsGridItem;
}

const hasLoadedPdf = ref(false);
const isStudyLoadingIndicatorVisible = inject("study-loading-indicator-visible");

defineProps<Props>();
</script>

<style scoped lang="scss">
.progress-container {
  position: absolute;
  z-index: 15;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  font-weight: bold;
}
</style>
