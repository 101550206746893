import type { UserGetManyResponseDto } from "../../../backend/src/tenants/dto/user-get-many.dto";
import type { GlobalAdminUserGetManyResponseDto } from "../../../backend/src/tenants/global-admin/dto/global-admin-user-get-many.dto";

export interface DisplayedUser {
  id: string;
  name: string;
  email: string;
  mfaEnabled: boolean;
  inviteExpiresAt: string | Date | null;
  disabledAt: string | Date | null;
  lastSeenAt: string | Date | null;
  roleIds: string[];
}

export function convertGlobalAdminUsersToDisplayUsers(
  users: GlobalAdminUserGetManyResponseDto
): DisplayedUser[] {
  return users.map((u) => ({
    id: u.id,
    name: u.name,
    email: u.email,
    mfaEnabled: u.mfaEnabled ?? false,
    inviteExpiresAt: u.inviteExpiresAt,
    disabledAt: u.disabledAt,
    lastSeenAt: u.lastSeenAt,
    roleIds: u.globalAdminRoleIds,
  }));
}

export function convertUsersToDisplayUsers(users: UserGetManyResponseDto): DisplayedUser[] {
  return users.map((u) => ({
    id: u.id,
    name: u.name,
    email: u.email,
    mfaEnabled: u.mfaEnabled ?? false,
    inviteExpiresAt: u.inviteExpiresAt,
    disabledAt: u.disabledAt,
    lastSeenAt: u.lastSeenAt,
    roleIds: u.roleIds,
  }));
}
