<template>
  <div class="clip-list">
    <OverlayScrollbar :scroll-target="scrollTarget" @scroll="onScroll">
      <div class="groups">
        <div v-if="erroredClips.length !== 0">
          <div
            class="clip-errors-button"
            data-testid="clip-errors-button"
            @click="isErroredClipsGroupOpen = !isErroredClipsGroupOpen"
          >
            Errored Clips
            <FontAwesomeIcon
              class="red"
              :icon="isErroredClipsGroupOpen ? 'chevron-up' : 'chevron-down'"
            />
          </div>

          <div v-if="isErroredClipsGroupOpen" class="clip-errors">
            <div
              v-for="clip in erroredClips"
              :key="clip.id"
              class="clip-error selectable-text"
              data-testid="clip-errors-content"
            >
              <b>{{ clip.sopInstanceUid }}</b>
              <br />
              <code>{{ clip.errorDetails }}</code>
            </div>
          </div>
        </div>

        <div v-if="validClips.length > 0" class="clip-thumbnails">
          <template
            v-for="(seriesRow, seriesIndex) in validClipsGroupedBySeries"
            :key="seriesIndex"
          >
            <div
              v-if="
                seriesRow.series.seriesDescription !== '' || validClipsGroupedBySeries.length !== 1
              "
              class="series-info"
              :class="{ 'first-series': seriesIndex === 0 }"
            >
              {{ seriesRow.series.seriesDescription }}

              <div
                v-if="
                  isCT3DSeries(seriesRow.series, seriesRow.series.clips[0]) &&
                  currentTenant.isCTModePermitted
                "
                class="mpr-button"
                @click="onMPRButtonClick(seriesRow.series)"
              >
                MPR
              </div>
            </div>

            <div v-for="(clip, clipIndex) in seriesRow.clips" :key="clip.id">
              <ClipThumbnail
                v-if="clip"
                :key="clip.id"
                :study="study"
                :clip="clip"
                :clip-number="validClips.indexOf(clip) + 1"
                :clip-count="validClips.length"
                :data-testid="`clip-thumbnail-${clipIndex}`"
                :is-selected="selectedClipIds.includes(clip.id)"
                :is-selected-on-secondary-window="isClipSelectedInSecondaryWindow(clip.id)"
                :context-menu-clip-id="
                  (studyClipContextMenu?.isContextMenuVisible
                    ? studyClipContextMenu?.studyClip?.id
                    : null) ?? null
                "
                @click="emits('clip-thumbnail-click', clip)"
                @measurement-icon-click="
                  (measurementValue) => emits('measurement-icon-click', measurementValue)
                "
                @contextmenu.prevent="(e: MouseEvent) => onClipThumbnailRightClick(e, clip)"
              />
            </div>
          </template>

          <!--
              Invisible elements that ensure the thumbnails are the correct size when groups
              have only one or two items in them. There needs to be one less grid-spacer element
              than the maximum number of columns.
            -->
          <div class="grid-spacer" />
          <div class="grid-spacer" />
          <div class="grid-spacer" />
          <div class="grid-spacer" />
          <div class="grid-spacer" />
        </div>
        <div v-else-if="study.id" class="empty-group-text">No images found</div>
      </div>
    </OverlayScrollbar>

    <StudyClipContextMenu
      ref="studyClipContextMenu"
      :study="study"
      @clip-deleted="(deletedClip) => emits('clip-deleted', deletedClip)"
    />
  </div>
</template>

<script setup lang="ts">
import { currentTenant } from "@/auth/current-session";
import OverlayScrollbar from "@/components/OverlayScrollbar.vue";
import { Study, StudyMeasurementValue, StudySeries, type StudyClip } from "@/utils/study-data";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { computed, ref, type Ref } from "vue";
import { isClipSelectedInSecondaryWindow } from "../study-view/multi-window/secondary-window";
import {
  getErroredClips,
  getValidClips,
  getValidClipsGroupedBySeries,
} from "../study-view/study-clip-helpers";
import StudyClipContextMenu from "./../study-view/StudyClipContextMenu.vue";
import ClipThumbnail from "./ClipThumbnail.vue";
import { beginCrosshairsLoading } from "./ct/ct-crosshairs";
import { isCT3DSeries } from "./ct/ct-helpers";

interface Props {
  study: Study;
  selectedClipIds: string[];
  scrollTarget: HTMLElement | null;
}

interface Emits {
  (event: "clip-thumbnail-click", clip: StudyClip): void;
  (event: "measurement-icon-click", measurementValue: StudyMeasurementValue): void;
  (event: "clip-deleted", deletedClip: StudyClip): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const isErroredClipsGroupOpen = ref(false);

const validClips = computed(() => getValidClips(props.study));
const validClipsGroupedBySeries = computed(() => getValidClipsGroupedBySeries(props.study));
const erroredClips = computed(() => getErroredClips(props.study));

const studyClipContextMenu = ref<{
  showContextMenu: (event: MouseEvent, clip: StudyClip) => Promise<void>;
  hideContextMenu: () => void;
  studyClip: StudyClip | null;
  isContextMenuVisible: Ref<boolean>;
} | null>(null);

function onClipThumbnailRightClick(e: MouseEvent, clip: StudyClip): void {
  void studyClipContextMenu.value?.showContextMenu(e, clip);
}

function onScroll(): void {
  studyClipContextMenu.value?.hideContextMenu();
}

function onMPRButtonClick(series: StudySeries): void {
  beginCrosshairsLoading(series.id);
}
</script>

<style scoped lang="scss">
.clip-list {
  display: flex;
  flex-direction: column;
  min-height: 0;
  height: 100%;
  width: 100%;
  position: relative;
}

.series-info {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 4px 8px;
  min-height: 24px;
  grid-column: 1 / -1;
  background-color: var(--bg-color-2);
  align-items: center;
  padding: 4px 8px;
  font-weight: bold;

  &:not(&.first-series) {
    border-top: 1px solid var(--border-color-1);
  }
}

@container (max-width: 160px) {
  .series-info {
    grid-template-columns: 1fr;
  }
}

.groups {
  display: flex;
  flex-direction: column;
  padding: 1px;
  container-type: inline-size;
}

.clip-errors-button {
  background-color: #74153b;
  padding: 8px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  transition: background-color 100ms ease;
  margin-bottom: 1px;

  &:hover {
    background-color: #a20e28;
  }
}

.clip-errors {
  background-color: #ff000026;
  margin-bottom: 1px;

  .clip-error {
    padding: 8px;
    word-break: break-word;
  }
}

.clip-thumbnails {
  display: grid;
  place-items: stretch;
  gap: 0.5px 1px;
  grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
}

.grid-spacer {
  height: 0px;
  width: 100px;
}

.empty-group-text {
  padding-top: 16px;
  text-align: center;
  font-weight: bold;
}

.mpr-button {
  cursor: pointer;
  border: 1px solid var(--border-color-1);
  border-radius: var(--border-radius);
  padding: 2px 4px;
  margin-left: auto;

  width: min-content;

  &:hover {
    filter: brightness(200%);
  }
}
</style>
