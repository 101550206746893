<template>
  <TabsRoot v-bind="forwarded" :class="['tabs-root', $attrs.class]">
    <slot />
  </TabsRoot>
</template>

<script setup lang="ts">
import type { TabsRootEmits, TabsRootProps } from "radix-vue";
import { TabsRoot, useForwardPropsEmits } from "radix-vue";

const props = defineProps<TabsRootProps>();
const emits = defineEmits<TabsRootEmits>();

const forwarded = useForwardPropsEmits(props, emits);
</script>

<style scoped lang="scss">
.tabs-root {
  display: flex;
  flex-direction: column;
}
</style>
