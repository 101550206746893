import { useRafFn } from "@vueuse/core";
import type { WebGLRenderer } from "three";
import { OrthographicCamera, Scene } from "three";
import type { VolumeSlice } from "three/examples/jsm/Addons.js";
import type { Volume } from "three/examples/jsm/misc/Volume.js";
import type { ShallowRef } from "vue";
import { computed, ref, shallowRef, watch } from "vue";
import { clamp } from "../../../../backend/src/shared/math-utils";
import type { Study } from "../../utils/study-data";
import type { CTClipsGridItem } from "../clip-viewer/clips-grid-item";
import { createMeasurementsController } from "../clip-viewer/measurements-controller";
import type { MeasurementsRenderer } from "../clip-viewer/measurements-renderer";
import { createMeasurementsRenderer } from "../clip-viewer/measurements-renderer";
import { crosshairsStore, CrosshairsStoreState } from "./ct-crosshairs";
import type { CTCrosshairsRenderer } from "./ct-crosshairs-renderer";
import { createCTCrosshairsRenderer } from "./ct-crosshairs-renderer";
import type { CTModel } from "./ct-model";
import { ctSettings } from "./ct-settings";
import { CTSliceDirection } from "./ct-slice-direction";

export const CT_16_BIT_ZERO_WINDOW_LEVEL = 32768;

/**
 * A CTRenderer is responsible for rendering a CT volume in a Three.js scene. The renderer holds
 * the scene & camera details and manages all aspects of rendering the individual slices to the
 * provided Three WebGLRenderer and hence canvas.
 */
export interface CTRenderer {
  measurementsRenderer: MeasurementsRenderer;

  /**
   * Loads a 3D volume created from a NRRD file into the renderer. This will be the 3D object that
   * we take slices from to display.
   */
  loadVolume: (ctVolume: Volume) => void;

  /**
   * Updates the camera position and orientation in response to a change in slice direction.
   */
  updateCameraPositionAndOrientation: () => void;

  /**
   * Extracts the current slice from the volume, removes any currently visible slice and displays
   * it in the scene.
   */
  displayCurrentSlice: () => void;

  /** Actions to be triggered when a canvas mousedown event is received. */
  onCanvasMouseDown: (event: MouseEvent) => Promise<void>;

  /** Actions to be triggered when a canvas mousemove event is received. */
  onCanvasMouseMove: (event: MouseEvent) => void;

  /** Actions to be triggered when a canvas mouseup event is received. */
  onCanvasMouseUp: () => void;
}

export function createCTRenderer(args: {
  study: Study;
  model: Omit<CTModel, "provideRenderer" | "destroy">;
  gridItem: CTClipsGridItem;
  webglRenderer: WebGLRenderer;
  canvasRect: { top: number; left: number; width: number; height: number };
  measurementCanvas: HTMLCanvasElement;
  measurementCtx: CanvasRenderingContext2D;
  crosshairsCanvas: HTMLCanvasElement;
  crosshairsCtx: CanvasRenderingContext2D;
}): CTRenderer {
  const {
    study,
    model,
    gridItem,
    webglRenderer,
    canvasRect,
    measurementCanvas,
    measurementCtx,
    crosshairsCanvas,
    crosshairsCtx,
  } = args;

  const camera = new OrthographicCamera();
  const scene = new Scene();

  const volume: ShallowRef<Volume | null> = shallowRef(null);
  let slice: VolumeSlice | null = null;

  const measurementsController = createMeasurementsController({
    study,
    model: gridItem,
    canvas: measurementCanvas,
    currentlyVisibleFrame: ref(0),
  });

  const measurementsRenderer = createMeasurementsRenderer({
    study,
    model: gridItem,
    canvas: measurementCanvas,
    ctx: measurementCtx,
    showMeasurements: computed(() => true),
  });

  let crosshairsRenderer: CTCrosshairsRenderer | null = null;

  let canvasMouseDownPosition = [-1, -1];
  let canvasMouseDownInitialWindowLevel = -1;
  let canvasMouseDownInitialWindowWidth = -1;

  function setupRenderer() {
    scene.add(camera);

    webglRenderer.setPixelRatio(window.devicePixelRatio);
    webglRenderer.setSize(canvasRect.width, canvasRect.height);
    webglRenderer.setAnimationLoop(() => {
      webglRenderer.render(scene, camera);
    });
  }

  const sliceDirectionVectorLetter = computed(() => {
    return {
      [CTSliceDirection.Sagittal]: "x",
      [CTSliceDirection.Coronal]: "y",
      [CTSliceDirection.Axial]: "z",
    }[model.sliceDirection.value];
  });

  function setCameraFrustrum(width: number, height: number) {
    camera.left = -width / 2;
    camera.right = width / 2;
    camera.top = height / 2;
    camera.bottom = -height / 2;
    camera.far = 10000;

    camera.updateProjectionMatrix();
  }

  function updateCameraPositionAndOrientation() {
    const sliceDirection = model.sliceDirection.value;

    if (volume.value !== null) {
      const widthDimension =
        volume.value.RASDimensions[
          model.sliceDirection.value === CTSliceDirection.Sagittal ? 1 : 0
        ];
      const heightDimension =
        volume.value.RASDimensions[model.sliceDirection.value === CTSliceDirection.Axial ? 1 : 2];

      // Set the camera frustrum to be the size of the volume in the direction of the slice.
      setCameraFrustrum(widthDimension, heightDimension);
    }

    if (volume.value !== null) {
      const widthDimension =
        volume.value.RASDimensions[
          model.sliceDirection.value === CTSliceDirection.Sagittal ? 1 : 0
        ];
      const heightDimension =
        volume.value.RASDimensions[model.sliceDirection.value === CTSliceDirection.Axial ? 1 : 2];

      // Set the camera frustrum to be the size of the volume in the direction of the slice.
      setCameraFrustrum(widthDimension, heightDimension);
    }

    // Put the camera behind the volume for axial & sagittal slices. This is so we view the
    // current slice from the correct side - if we were trying to view the volume axially from the
    // top the slices would be flipped left to right, so view them from below instead.
    // The coronal position is from the front as per the RAS coordinate system so doesn't need to
    // to be flipped.
    camera.position.set(
      sliceDirection === CTSliceDirection.Sagittal ? -5000 : 0,
      sliceDirection === CTSliceDirection.Coronal ? 5000 : 0,
      sliceDirection === CTSliceDirection.Axial ? -5000 : 0
    );

    // Rotate the camera back towards the volume so we can see the slice and that it's rotated in
    // the correct direction for this type of slice.
    if (sliceDirection === CTSliceDirection.Sagittal) {
      camera.rotation.set(0.5 * Math.PI, 1.5 * Math.PI, 0);
    } else if (sliceDirection === CTSliceDirection.Coronal) {
      camera.rotation.set(0.5 * Math.PI, Math.PI, 0);
    } else {
      camera.rotation.set(0, Math.PI, 0);
    }

    camera.updateProjectionMatrix();
  }

  function displayCurrentSlice() {
    if (volume.value === null) {
      return;
    }

    if (slice !== null) {
      scene.remove(slice.mesh);
    }

    volume.value.windowLow =
      ctSettings.windowLevel.value - ctSettings.windowWidth.value / 2 + CT_16_BIT_ZERO_WINDOW_LEVEL;
    volume.value.windowHigh =
      ctSettings.windowLevel.value + ctSettings.windowWidth.value / 2 + CT_16_BIT_ZERO_WINDOW_LEVEL;

    slice = volume.value.extractSlice(sliceDirectionVectorLetter.value, model.rasSliceNumber.value);
    scene.add(slice.mesh);
  }

  function loadVolume(ctVolume: Volume) {
    volume.value = ctVolume;

    setCameraFrustrum(volume.value.RASDimensions[0], volume.value.RASDimensions[1]);
    updateCameraPositionAndOrientation();
    displayCurrentSlice();
  }

  setupRenderer();

  /**
   * Mouse event handling
   */
  async function onCanvasMouseDown(event: MouseEvent): Promise<void> {
    await measurementsController.handleCanvasMouseDown(event);

    const didSwallowCrosshairsEvent = crosshairsRenderer?.onCanvasMouseDown(event);

    if (didSwallowCrosshairsEvent === true) {
      return;
    }

    canvasMouseDownPosition = [event.clientX, event.clientY];
    canvasMouseDownInitialWindowLevel = ctSettings.windowLevel.value;
    canvasMouseDownInitialWindowWidth = ctSettings.windowWidth.value;
  }

  function onCanvasMouseMove(event: MouseEvent): void {
    const didSwallowMeasurementEvent = measurementsController.handleCanvasMouseMove(event);

    if (didSwallowMeasurementEvent) {
      return;
    }

    const didSwallowEvent = crosshairsRenderer?.onCanvasMouseMove(event);

    if (
      didSwallowEvent !== true &&
      event.buttons === 1 &&
      canvasMouseDownInitialWindowLevel !== -1
    ) {
      const newWindowLevel =
        canvasMouseDownInitialWindowLevel + (canvasMouseDownPosition[1] - event.clientY);
      ctSettings.windowLevel.value = clamp(newWindowLevel, -1024, 4096);

      const newWindowWidth =
        canvasMouseDownInitialWindowWidth - (canvasMouseDownPosition[0] - event.clientX);
      ctSettings.windowWidth.value = clamp(newWindowWidth, 0, 2048);
    }
  }

  function onCanvasMouseUp(): void {
    measurementsController.handleCanvasContainerMouseUp();
    crosshairsRenderer?.onCanvasMouseUp();

    canvasMouseDownInitialWindowLevel = -1;
  }

  /**
   * Crosshairs
   */

  // When the overall store state changes, either spin up or tear down the crosshairs renderer
  watch(crosshairsStore, () => {
    if (
      crosshairsStore.value.state === CrosshairsStoreState.Active &&
      crosshairsStore.value.series.id === model.series.id
    ) {
      crosshairsRenderer = createCTCrosshairsRenderer({
        model,
        canvas: crosshairsCanvas,
        ctx: crosshairsCtx,
      });
    } else {
      crosshairsRenderer = null;
    }
  });

  useRafFn(() => {
    measurementCtx.clearRect(0, 0, measurementCanvas.width, measurementCanvas.height);
    measurementsRenderer.drawAllMeasurements({ type: "plane", plane: model.currentPlane.value });

    if (crosshairsRenderer !== null) {
      crosshairsRenderer.render();
    }
  });

  return {
    measurementsRenderer,

    loadVolume,
    updateCameraPositionAndOrientation,
    displayCurrentSlice,

    onCanvasMouseDown,
    onCanvasMouseMove,
    onCanvasMouseUp,
  };
}

// We need to convert the slice number in the LPS coordinate system (i.e. individual slice number)
// to the RAS coordinate system (which is affected by pixel spacing) as volume.extractSlice
// expects the slice direction in the RAS coordinate system.
//
// See https://github.com/mrdoob/three.js/issues/24920
export function convertSliceNumberToRASDimension(
  volume: Volume | null,
  sliceDirection: CTSliceDirection,
  sliceNumber: number
): number {
  if (volume === null) {
    return 0;
  }

  const sliceDirectionVectorLetter = {
    [CTSliceDirection.Sagittal]: "x",
    [CTSliceDirection.Coronal]: "y",
    [CTSliceDirection.Axial]: "z",
  }[sliceDirection];

  const dimension = ["x", "y", "z"].indexOf(sliceDirectionVectorLetter);

  const maxDimension = volume.RASDimensions[dimension];
  const spacingAdjustedSlice = sliceNumber * volume.spacing[dimension];

  // Because we're looking at the volume from the back, we need to get the slice with that RAS
  // index against the -ve slice axis instead of +ve, so we need to subtract the slice number
  // from the max dimension.
  return maxDimension - spacingAdjustedSlice;
}

export function convertRASDimensionToSliceNumber(
  volume: Volume | null,
  sliceDirection: CTSliceDirection,
  sliceNumber: number
): number {
  if (volume === null) {
    return 0;
  }

  const sliceDirectionVectorLetter = {
    [CTSliceDirection.Sagittal]: "x",
    [CTSliceDirection.Coronal]: "y",
    [CTSliceDirection.Axial]: "z",
  }[sliceDirection];

  const dimension = ["x", "y", "z"].indexOf(sliceDirectionVectorLetter);

  const maxDimension = volume.RASDimensions[dimension];
  const spacingAdjustedSlice = (maxDimension - sliceNumber) / volume.spacing[dimension];

  return spacingAdjustedSlice;
}
