<template>
  <ReportStructureDraggableItem
    ref="rootComponent"
    v-model="fieldName"
    placeholder="Name"
    :editable="isEditingReportStructure"
    @enter-pressed="emits('name-input-enter-pressed')"
  >
    <Transition name="fade">
      <div
        v-if="isFieldPopperVisible"
        ref="fieldPopperElement"
        class="field-popper"
        :style="{ top: `${optionsPopperTop}em` }"
      >
        <div class="field-popper-header">
          <div class="title">Field Type:</div>

          <fieldset class="field-type-selector" :class="{ disabled: !isEditingReportStructure }">
            <div
              v-for="fieldTypeDefinition in [
                { type: 'text', label: 'Text' },
                { type: 'dropdown', label: 'Dropdown' },
                { type: 'measurement', label: 'Measurement' },
              ]"
              :key="fieldTypeDefinition.type"
              style="display: flex; align-items: center"
              :data-testid="`select-field-type-${field.name}-${fieldTypeDefinition.type}`"
            >
              <input
                :id="`field-type-${fieldTypeDefinition.type}`"
                v-model="fieldType"
                type="radio"
                name="field-type"
                :value="fieldTypeDefinition.type"
              />
              <label :for="`field-type-${fieldTypeDefinition.type}`">
                {{ fieldTypeDefinition.label }}
              </label>
            </div>
          </fieldset>

          <template
            v-if="isEditingReportStructure && !(field.type === 'dropdown' && field.isSentence)"
          >
            <Tooltip content="Bold text">
              <FontAwesomeIcon
                icon="bold"
                class="header-icon rpt-text-normal"
                :class="{ selected: field.styling.bold, 'not-selected': !field.styling.bold }"
                data-testid="bold-toggle"
                @click="
                  emits('mutate-structure', createFieldBoldToggleMutation(sectionId, field.id))
                "
              />
            </Tooltip>

            <Tooltip content="Italicize text">
              <FontAwesomeIcon
                icon="italic"
                class="header-icon rpt-text-normal"
                :class="{ selected: field.styling.italic, 'not-selected': !field.styling.italic }"
                data-testid="italic-toggle"
                @click="
                  emits('mutate-structure', createFieldItalicToggleMutation(sectionId, field.id))
                "
              />
            </Tooltip>

            <Popper class="color-picker-popper">
              <FontAwesomeIcon
                icon="palette"
                class="header-icon rpt-text-normal"
                :style="{ color: field.styling.color }"
              />

              <template #content>
                <CompactColorPicker
                  :model-value="field.styling.color"
                  @update:model-value="
                    (newColor) =>
                      emits(
                        'mutate-structure',
                        createFieldColorMutation(sectionId, field.id, newColor.hex)
                      )
                  "
                />
              </template>
            </Popper>
          </template>

          <template
            v-if="
              section.type === 'table' &&
              isEditingReportStructure &&
              field.cellAlignment !== undefined
            "
          >
            <Tooltip content="Horizontal alignment" :visible="!isHorizontalAlignmentPopperOpen">
              <Popper
                placement="bottom-end"
                class="alignment-popper"
                data-testid="alignment-popper-horizontal"
                @open="isHorizontalAlignmentPopperOpen = true"
                @close="isHorizontalAlignmentPopperOpen = false"
              >
                <FontAwesomeIcon
                  :icon="`align-${field.cellAlignment.horizontal}`"
                  class="header-icon rpt-text-normal"
                />

                <template #content>
                  <div class="alignment-group">
                    <FontAwesomeIcon
                      v-for="alignment in horizontalAlignments"
                      :key="alignment"
                      :icon="`align-${alignment}`"
                      class="header-icon rpt-text-normal"
                      :class="{ 'not-selected': field.cellAlignment.horizontal !== alignment }"
                      :data-testid="`alignment-select-${alignment}`"
                      @click="
                        emits(
                          'mutate-structure',
                          createTableHorizontalAlignmentMutation(sectionId, field.id, alignment)
                        )
                      "
                    />
                  </div>
                </template>
              </Popper>
            </Tooltip>

            <Tooltip content="Vertical alignment" :visible="!isVerticalAlignmentPopperOpen">
              <Popper
                placement="bottom-end"
                class="alignment-popper"
                data-testid="alignment-popper-horizontal"
                @open="isVerticalAlignmentPopperOpen = true"
                @close="isVerticalAlignmentPopperOpen = false"
              >
                <FontAwesomeIcon
                  :icon="getIconForVerticalAlignment(field.cellAlignment.vertical)"
                  class="header-icon rpt-text-normal"
                  fixed-width
                />

                <template #content>
                  <div class="alignment-group">
                    <FontAwesomeIcon
                      v-for="alignment in verticalAlignments"
                      :key="alignment"
                      :icon="getIconForVerticalAlignment(alignment)"
                      class="header-icon rpt-text-normal"
                      :class="{ 'not-selected': field.cellAlignment.vertical !== alignment }"
                      :data-testid="`alignment-select-${alignment}`"
                      @click="
                        emits(
                          'mutate-structure',
                          createTableVerticalAlignmentMutation(sectionId, field.id, alignment)
                        )
                      "
                    />
                  </div>
                </template>
              </Popper>
            </Tooltip>
          </template>

          <Tooltip
            v-if="isEditingReportStructure"
            content="Delete this field"
            @click="onDeleteField"
          >
            <FontAwesomeIcon icon="trash" class="header-icon rpt-text-normal" />
          </Tooltip>
          <div v-else />

          <Tooltip
            v-if="isEditingReportStructure"
            content="Duplicate this field"
            @click="onDuplicateField"
          >
            <FontAwesomeIcon icon="copy" class="header-icon rpt-text-normal" />
          </Tooltip>
          <div v-else />

          <FontAwesomeIcon
            icon="times"
            class="header-icon close-button"
            style="font-size: 1.5em"
            data-testid="close-field-popper"
            @click="onHideFieldPopper()"
          />
        </div>

        <div v-if="field.type === 'dropdown'">
          <div ref="fieldOptionsElement" style="display: contents">
            <OverlayScrollbar style="max-height: 400px">
              <DraggableList :enabled="isEditingReportStructure" @reorder="onReorderFieldOptions">
                <ReportStructureDraggableItem
                  v-for="(option, optionIndex) of visibleOptions"
                  :id="`option-${option.id}`"
                  :key="option.id"
                  v-model="option.name"
                  placeholder="Option title"
                  :name-field-width="field.isSentence ? '15em' : 'auto'"
                  show-delete-button
                  delete-button-tooltip="Delete this option"
                  :editable="isEditingReportStructure"
                  @update:model-value="
                    emits(
                      'mutate-structure',
                      createOptionNameMutation(sectionId, field.id, option.id)
                    )
                  "
                  @delete="onDeleteFieldOption(option.id)"
                >
                  <div v-if="field.isSentence" class="field-input" style="border-left-width: 0.1em">
                    <input
                      v-model="option.sentence"
                      placeholder="Sentence to appear on the report"
                      :disabled="!isEditingReportStructure"
                      class="rpt-text-normal"
                      @update:model-value="
                        emits(
                          'mutate-structure',
                          createOptionSentenceMutation(sectionId, field.id, option.id)
                        )
                      "
                      @keydown.enter="onOptionSentenceEnterPressed(optionIndex + 1)"
                    />
                  </div>
                </ReportStructureDraggableItem>
              </DraggableList>
            </OverlayScrollbar>
          </div>

          <AddReportStructureButton
            v-if="isEditingReportStructure"
            :options="[{ key: '', text: 'Add option' }]"
            @click="onAddOption"
          />

          <div class="custom-option" :class="{ disabled: !isCustomOptionEnabled }">
            <div class="toggle-switch-container">
              <Tooltip
                :content="
                  isEditingReportStructure
                    ? 'Toggle whether custom responses are allowed for this field'
                    : ''
                "
              >
                <div style="display: flex; align-items: center; gap: 0.4em">
                  <ToggleSwitch
                    v-model="isCustomOptionEnabled"
                    :enabled="isEditingReportStructure"
                  />
                  <span class="rpt-text-normal">Custom</span>
                </div>
              </Tooltip>
            </div>

            <div v-if="customOption" class="field-input">
              <input
                v-model="customOption.sentence"
                placeholder="Optional initial text for a custom response"
                :disabled="!isCustomOptionEnabled || !isEditingReportStructure"
                class="rpt-text-normal"
                @update:model-value="
                  emits(
                    'mutate-structure',
                    createOptionSentenceMutation(sectionId, field.id, 'custom')
                  )
                "
              />
            </div>
            <div v-else class="custom-responses-disabled-message">
              <span class="rpt-text-normal">Custom responses are disabled for this field</span>
            </div>
          </div>

          <div style="display: flex; gap: 0.8em; align-items: center; margin-top: 0.4em">
            <ReportCheckbox
              :model-value="field.isSentence"
              @update:model-value="
                emits('mutate-structure', createDropdownSentenceToggleMutation(sectionId, field.id))
              "
            >
              Map options to sentences
            </ReportCheckbox>

            <ReportCheckbox
              :model-value="field.isMultiSelect"
              @update:model-value="
                emits(
                  'mutate-structure',
                  createDropdownMultiSelectToggleMutation(sectionId, field.id)
                )
              "
            >
              Allow multiple selections
            </ReportCheckbox>
          </div>
        </div>

        <div
          v-else-if="field.type === 'measurement'"
          style="display: flex; flex-direction: column; gap: 0.4em"
        >
          <div v-if="isEditingReportStructure" class="field-input show-border">
            <input
              ref="measurementFilterInput"
              v-model="measurementFilter"
              placeholder="Search measurements"
              :disabled="!isEditingReportStructure"
              data-testid="search-measurements-field"
            />
          </div>

          <OverlayScrollbar>
            <div class="measurement-selection-table">
              <div
                v-for="(mmt, index) in filteredMeasurements"
                :key="index"
                class="measurement-item"
                :class="{
                  selected: field.measurementName === mmt.name && field.isIndexed === mmt.isIndexed,
                  disabled: !isEditingReportStructure,
                }"
                :data-testid="`measurement-item-${mmt.name}`"
                @click="onMeasurementSelection(mmt.name, mmt.isIndexed)"
              >
                <span class="rpt-text-normal">
                  {{ getMeasurementDisplayName(mmt.name, mmt.isIndexed ? "indexed" : "unindexed") }}
                </span>
              </div>
            </div>
          </OverlayScrollbar>
        </div>

        <template v-if="field.type === 'text'">
          <div class="field-input show-border">
            <input
              v-model="text"
              placeholder="Text"
              :disabled="!isEditingReportStructure"
              :style="getFieldStylingCSS(field)"
            />

            <RouterLink
              v-if="isCalculation(field.text)"
              :to="{ name: 'settings-reporting-calculations' }"
              target="_blank"
            >
              <Tooltip
                :content="`${
                  reportCalculationError ?? 'The calculation is valid'
                }. Click to view documentation.`"
                max-width="400px"
              >
                <FontAwesomeIcon
                  :icon="reportCalculationError === undefined ? 'check' : 'times'"
                  class="calculation-status"
                  :class="reportCalculationError ? 'invalid' : 'valid'"
                />
              </Tooltip>
            </RouterLink>
          </div>

          <div class="rpt-text-normal">
            <p>Leave the box above empty to create a text entry field on the report.</p>
            <p>
              If text is entered above then it will be displayed on the report. This text can
              contain calculations as described
              <RouterLink
                :to="{ name: 'settings-reporting-calculations' }"
                target="_blank"
                style="color: blue; text-decoration: underline"
              >
                here.
              </RouterLink>
            </p>
          </div>
        </template>

        <ReportCheckbox
          v-if="field.type === 'text' || field.type === 'measurement'"
          :model-value="field.isEditable"
          @update:model-value="
            emits('mutate-structure', createEditableToggleMutation(sectionId, field.id))
          "
        >
          Allow text to be edited on the report
        </ReportCheckbox>
      </div>
    </Transition>

    <div
      class="edit-options-button"
      :data-testid="`edit-options-btn-${fieldName}`"
      :class="{ 'popper-visible': isFieldPopperVisible }"
      @click="onShowOptionsPopper"
    >
      <FontAwesomeIcon icon="chevron-down" class="dropdown-icon rpt-text-normal" />
    </div>
  </ReportStructureDraggableItem>
</template>

<script setup lang="ts">
import {
  ReportSectionStructuredFieldType,
  type ReportSectionMeasurementFieldStructure,
  type ReportSectionStructuredField,
  type ReportStructure,
} from "@/../../backend/src/reporting/report-structure";
import DraggableList from "@/components/DraggableList.vue";
import Popper from "@/components/Popper.vue";
import Tooltip from "@/components/Tooltip.vue";
import { Compact as CompactColorPicker } from "@ckpack/vue-color";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { onClickOutside, onKeyStroke, useFocus } from "@vueuse/core";
import type { SortableEvent } from "sortablejs";
import { computed, inject, nextTick, reactive, ref } from "vue";
import { getMeasurementDisplayName } from "../../../backend/src/measurements/measurement-display";
import { MeasurementName } from "../../../backend/src/measurements/measurement-names";
import OverlayScrollbar from "../components/OverlayScrollbar.vue";
import ToggleSwitch from "../components/ToggleSwitch.vue";
import {
  filterMeasurementNames,
  isMeasurementIndexable,
} from "../measurements/measurement-helpers";
import AddReportStructureButton from "./AddReportStructureButton.vue";
import ReportCheckbox from "./ReportCheckbox.vue";
import ReportStructureDraggableItem from "./ReportStructureDraggableItem.vue";
import { getTextFieldCalculationErrors, isCalculation } from "./report-calculation";
import { ReportContentMode, getFieldStylingCSS } from "./report-content";
import {
  ReportStructureMutation,
  createDropdownMultiSelectToggleMutation,
  createDropdownSentenceToggleMutation,
  createEditableToggleMutation,
  createFieldBoldToggleMutation,
  createFieldColorMutation,
  createFieldItalicToggleMutation,
  createFieldNameMutation,
  createFieldRemoveMutation,
  createFieldTypeMutation,
  createMeasurementMutation,
  createOptionAddMutation,
  createOptionMoveMutation,
  createOptionNameMutation,
  createOptionRemoveMutation,
  createOptionSentenceMutation,
  createTableHorizontalAlignmentMutation,
  createTableVerticalAlignmentMutation,
  createTextMutation,
} from "./report-structure-mutations";

interface Props {
  structure: ReportStructure;
  sectionId: string;
  field: ReportSectionStructuredField;
  mode: ReportContentMode;
  scaleFactor: number;
}

interface Emits {
  (event: "duplicate"): void;
  (event: "delete"): void;
  (event: "name-input-enter-pressed"): void;
  (event: "mutate-structure", mutation: ReportStructureMutation): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const rootComponent = ref<{ rootElement: HTMLDivElement | null }>();

const measurementFilterInput = ref<HTMLInputElement | null>(null);
const measurementFilter = ref("");

const setOpenPopperHeight: (height: number) => void = inject("set-open-popper-height")!;

const section = computed(() => {
  const result = props.structure.sections.find((s) => s.id === props.sectionId);

  if (result === undefined) {
    throw Error(`Section with id ${props.sectionId} is not present in the report template`);
  }

  return result;
});

useFocus(measurementFilterInput, { initialValue: true });

const filteredMeasurements = computed((): ReportSectionMeasurementFieldStructure[] =>
  filterMeasurementNames(Object.values(MeasurementName), measurementFilter.value)
    .map((name) => [
      { name, isIndexed: false },
      ...(isMeasurementIndexable(name) ? [{ name, isIndexed: true }] : []),
    ])
    .flat()
);

const isEditingReportStructure = computed(
  () => props.mode === ReportContentMode.EditReportStructure
);

const fieldName = computed({
  get(): string {
    return props.field.name;
  },
  set(newValue: string): void {
    // eslint-disable-next-line vue/no-mutating-props
    props.field.name = newValue;
    emits("mutate-structure", createFieldNameMutation(props.sectionId, props.field.id));
  },
});

const fieldType = computed({
  get(): ReportSectionStructuredFieldType {
    return props.field.type;
  },
  set(newValue: ReportSectionStructuredFieldType): void {
    let newField: ReportSectionStructuredField | undefined = undefined;

    if (newValue === "text") {
      newField = {
        id: props.field.id,
        name: props.field.name,
        type: "text",
        cellAlignment: props.field.cellAlignment,
        styling: props.field.styling,
        ...textFieldDetails,
      };
    } else if (newValue === "dropdown") {
      newField = {
        id: props.field.id,
        name: props.field.name,
        type: newValue,
        cellAlignment: props.field.cellAlignment,
        styling: props.field.styling,
        ...dropdownFieldDetails,
      };
    } else {
      newField = {
        id: props.field.id,
        name: props.field.name,
        type: newValue,
        cellAlignment: props.field.cellAlignment,
        styling: props.field.styling,
        ...measurementFieldDetails,
      };
    }

    emits("mutate-structure", createFieldTypeMutation(props.sectionId, props.field.id, newField));
  },
});

const textFieldDetails = reactive({
  text: props.field.type === "text" ? props.field.text : "",
  isEditable: props.field.type === "text" ? props.field.isEditable : true,
});

const dropdownFieldDetails = reactive({
  options: props.field.type === "dropdown" ? props.field.options : [],
  isMultiSelect: props.field.type === "dropdown" ? props.field.isMultiSelect : false,
  isSentence: props.field.type === "dropdown" ? props.field.isSentence : false,
});

const measurementFieldDetails = reactive({
  measurementName: props.field.type === "measurement" ? props.field.measurementName : null,
  isIndexed: props.field.type === "measurement" ? props.field.isIndexed : false,
  isEditable: props.field.type === "measurement" ? props.field.isEditable : true,
});

const fieldPopperElement = ref<HTMLElement>();
const optionsPopperTop = ref(0);
const isFieldPopperVisible = ref(false);

const fieldOptionsElement = ref<HTMLElement>();

function onReorderFieldOptions(event: SortableEvent): void {
  if (event.oldIndex === undefined || event.newIndex === undefined) {
    return;
  }

  // If this is a single select dropdown then the first option will be set to "", i.e.
  // indicating no option selected, but that entry isn't explicitly shown in the list, so we need to
  // adjust the index values from Sortable.js to account for this
  let indexOffset = 0;
  if (props.field.type === "dropdown" && !props.field.isMultiSelect) {
    indexOffset = 1;
  }

  emits(
    "mutate-structure",
    createOptionMoveMutation(
      props.sectionId,
      props.field.id,
      event.oldIndex + indexOffset,
      event.newIndex + indexOffset
    )
  );
}

onClickOutside(fieldPopperElement, onHideFieldPopper);
onKeyStroke("Escape", () => onHideFieldPopper());

function onShowOptionsPopper(): void {
  const root = rootComponent.value?.rootElement;
  if (!root) {
    return;
  }

  isFieldPopperVisible.value = !isFieldPopperVisible.value;
  optionsPopperTop.value = (root.offsetTop + root.offsetHeight + 2) / (props.scaleFactor * 10);

  // Adjust the position of the popper if the popper renders offscreen. This needs to be done on the
  // next tick as the popper element hasn't rendered in yet so we can't get it's height. It might
  // be possible to avoid doing this if we refactor the popper to use floating-ui but that's
  // non-trivial due to handling the scale factors involved with reporting.
  void nextTick(() => {
    if (fieldPopperElement.value) {
      const rect = fieldPopperElement.value.getBoundingClientRect();
      setOpenPopperHeight(rect.height);
    }
  });
}

function onHideFieldPopper(event?: PointerEvent): void {
  let target = event?.target as HTMLElement | null | undefined;
  if (target !== undefined) {
    // If the target element is inside this component then don't hide the options popper
    if (rootComponent.value?.rootElement?.contains(target) === true) {
      return;
    }

    // Don't hide the options popper when clicking on the undo/redo buttons
    while (target) {
      if (target.id === "report-template-undo-redo-buttons") {
        return;
      }

      target = target.parentElement;
    }
  }

  isFieldPopperVisible.value = false;
  setOpenPopperHeight(0);
}

const visibleOptions = computed(() =>
  props.field.type === "dropdown"
    ? props.field.options.filter((option) => option.id !== "" && option.id !== "custom")
    : []
);

function onDeleteFieldOption(optionId: string): void {
  emits("mutate-structure", createOptionRemoveMutation(props.sectionId, props.field.id, optionId));
}

function onDuplicateField(): void {
  isFieldPopperVisible.value = false;
  emits("duplicate");
}

function onDeleteField(): void {
  isFieldPopperVisible.value = false;
  emits("mutate-structure", createFieldRemoveMutation(props.sectionId, props.field.id));
}

function onAddOption(): void {
  if (props.field.type !== "dropdown") {
    return;
  }

  let index = props.field.options.length;
  if (isCustomOptionEnabled.value) {
    index -= 1;
  }

  emits(
    "mutate-structure",
    createOptionAddMutation(props.sectionId, props.field.id, index, (newOption) => {
      // Set focus to the new option's title input
      void nextTick(() =>
        fieldOptionsElement.value
          ?.querySelector<HTMLInputElement>(`#option-${newOption.id} input`)
          ?.focus()
      );
    })
  );
}

// Pressing enter in the sentence box for the last option in the list automatically adds a new
// option
function onOptionSentenceEnterPressed(optionIndex: number): void {
  if (props.field.type !== "dropdown") {
    return;
  }

  const options = props.field.options;

  if (options[optionIndex].sentence.trim().length === 0) {
    return;
  }

  if (
    optionIndex === options.length - 1 ||
    (optionIndex === options.length - 2 && isCustomOptionEnabled.value)
  ) {
    onAddOption();
  }
}

const customOption = computed(() =>
  props.field.type === "dropdown"
    ? props.field.options.find((option) => option.id === "custom")
    : undefined
);

const isCustomOptionEnabled = computed({
  get() {
    return props.field.type === "dropdown"
      ? props.field.options.some((option) => option.id === "custom")
      : false;
  },
  set(newValue: boolean) {
    if (newValue && props.field.type === "dropdown") {
      emits(
        "mutate-structure",
        createOptionAddMutation(
          props.sectionId,
          props.field.id,
          props.field.options.length,
          (newOption) => {
            newOption.id = "custom";
            newOption.name = "Custom";
          }
        )
      );
    } else {
      emits(
        "mutate-structure",
        createOptionRemoveMutation(props.sectionId, props.field.id, "custom")
      );
    }
  },
});

const text = computed({
  get(): string {
    return props.field.type === "text" ? props.field.text : "";
  },
  set(newValue: string) {
    if (props.field.type === "text") {
      textFieldDetails.text = newValue;
      emits("mutate-structure", createTextMutation(props.sectionId, props.field.id, newValue));
    }
  },
});

const reportCalculationError = computed(() =>
  getTextFieldCalculationErrors(text.value, props.structure)
);

function onMeasurementSelection(name: MeasurementName, isIndexed: boolean): void {
  measurementFieldDetails.measurementName = name;
  measurementFieldDetails.isIndexed = isIndexed;

  emits(
    "mutate-structure",
    createMeasurementMutation(props.sectionId, props.field.id, name, isIndexed)
  );
}

const isVerticalAlignmentPopperOpen = ref(false);
const isHorizontalAlignmentPopperOpen = ref(false);

type VerticalAlignment = "bottom" | "center" | "top";
type HorizontalAlignment = "center" | "left" | "right";

const verticalAlignments: VerticalAlignment[] = ["top", "center", "bottom"];
const horizontalAlignments: HorizontalAlignment[] = ["left", "center", "right"];

function getIconForVerticalAlignment(alignment: VerticalAlignment): string {
  return { top: "arrows-up-to-line", bottom: "arrows-down-to-line", center: "arrows-up-down" }[
    alignment
  ];
}
</script>

<style scoped lang="scss">
.edit-options-button {
  padding: 0 0.2em;
  display: flex;
  align-items: center;
  justify-content: space-between;

  color: var(--report-text-color-1);

  cursor: pointer;

  .dropdown-icon {
    color: var(--report-accent-color-2);
    transition: color 100ms ease;
  }

  &:hover,
  &.popper-visible {
    color: var(--report-text-color-2);

    .dropdown-icon {
      color: var(--report-text-color-2);
    }
  }
}

.field-popper {
  position: absolute;
  left: -0.3em;
  right: -0.3em;
  background-color: var(--report-section-bg-color);
  border-radius: 0.2em;
  padding: 0.8em;
  box-shadow: rgba(0, 0, 0, 0.35) 0 0.5em 1.5em;
  display: flex;
  flex-direction: column;
  gap: 0.8em;
  z-index: 1;
}

.field-popper-header {
  color: var(--report-text-color-1);
  display: flex;
  align-items: center;
  gap: 1.2em;

  .title {
    font-weight: bold;
    font-size: 1.1em;
    white-space: nowrap;
  }

  .header-icon {
    color: var(--report-text-color-1);
    cursor: pointer;
    transition: color 100ms ease;
    padding: 0.4em;
    border-radius: 0.4em;
    margin: -0.4em;

    &:hover {
      color: var(--report-text-color-2);
    }

    &.close-button {
      margin-left: 0.4em;
    }

    &.not-selected {
      color: var(--report-placeholder-text-color);

      &:hover {
        color: var(--report-text-color-2);
      }
    }

    &.selected {
      background-color: var(--report-placeholder-text-color);
      color: var(--report-text-color-2);
    }
  }
}

.field-options {
  display: flex;
  flex-direction: column;
  gap: 0.2em;
}

.trash-icon {
  color: var(--report-accent-color-1);
  cursor: pointer;
  transition: color 100ms ease;
  margin-left: 0.4em;

  &:hover {
    color: var(--report-accent-color-2);
  }
}

.custom-option {
  height: 2.2em;

  display: flex;
  align-items: stretch;

  .toggle-switch-container {
    width: 17.5em;
    background-color: var(--report-widget-bg-color);
    border: 0.1em solid var(--report-widget-border-color);
    border-radius: 0.2em 0 0 0.2em;
    border-right: 0;
    padding-left: 0.2em;
    display: flex;
    align-items: center;

    :deep(.toggle-switch) {
      transform: v-bind("`scale(${scaleFactor * 0.75})`");
      transform-origin: center;
    }
  }

  .field-input {
    border-radius: 0 0.2em 0.2em 0;
    border-right-width: 0.1em;
    border-left-width: 0.1em;
  }
}

.custom-responses-disabled-message {
  flex: 1;
  padding-left: 0.5em;
  font-style: italic;
  color: var(--report-placeholder-text-color);
  border: 0.1em solid var(--report-widget-border-color);
  border-radius: 0 0.2em 0.2em 0;
  display: flex;
  align-items: center;
}

.field-type-selector {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 1.2em;
  border: none;
  font-size: 1.2em;
  padding: 0;
  margin-right: auto;

  label {
    margin: 0;
    padding-left: 0.4em;
    white-space: nowrap;
  }

  input {
    width: 1em;
    height: 1em;
  }

  input,
  label {
    cursor: pointer;
  }

  &.disabled {
    pointer-events: none;
  }
}

.calculation-status {
  margin-left: 0.4em;
  font-size: 1.4em;

  &.valid {
    color: green;
  }

  &.invalid {
    color: red;
  }
}

.measurement-selection-table {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: min-content;
  gap: 0.4em;
  max-height: 20em;
}

.measurement-item {
  padding: 0.2em 0.4em;
  border-radius: 0.2em;
  background-color: var(--report-widget-bg-color);
  transition: background-color 100ms ease;
  cursor: pointer;

  &:hover,
  &.selected {
    background-color: var(--report-widget-bg-color-hover);
  }

  &.selected {
    outline: 0.1em solid var(--report-widget-border-color);
  }

  &.disabled {
    pointer-events: none;
  }
}

.alignment-group {
  display: flex;
  gap: 0.8em;
}

:deep(.alignment-popper) {
  background-color: var(--report-widget-bg-color);
  padding: 0.6em;
}

.color-selection {
  font-size: 1.2em;
  font-weight: bold;
}

:deep(.color-picker-popper) {
  padding: 0;
}
</style>
