<template>
  <div
    class="settings-title"
    style="display: flex; justify-content: space-between; align-items: flex-end"
  >
    DICOM Settings

    <Popper placement="left-start">
      <a class="view-dicom-endpoints">View DICOM Endpoints</a>

      <template #content>
        <div v-if="dicomPublicEndpoints" class="view-dicom-endpoints-popper">
          <div class="dicom-info selectable-text">
            <h3>DICOM via VPN</h3>
            <p><b>AE Title:</b> {{ dicomPublicEndpoints.aeTitle }}</p>
            <p><b>Address:</b> {{ dicomPublicEndpoints.dicom.address }}</p>
            <p><b>Port:</b> {{ formatPortList(dicomPublicEndpoints.dicom.ports) }}</p>
          </div>

          <div class="dicom-info selectable-text">
            <h3>DICOM via TLS</h3>
            <p><b>AE Title:</b> {{ dicomPublicEndpoints.aeTitle }}</p>
            <p><b>Address:</b> {{ dicomPublicEndpoints.dicomTls.address }}</p>
            <p><b>Port:</b> {{ formatPortList(dicomPublicEndpoints.dicomTls.ports) }}</p>
            <div class="server-ca-download-divider" />
            <b> Download Server CA Certificate </b>
            <p>
              <a
                class="download-certificate"
                :download="`HeartLabCA.pfx`"
                :href="`data:application/x-pkcs12;base64,${dicomPublicEndpoints.dicomTls.caCert.pkcs12DerBase64}`"
              >
                PKCS #12 / PFX Format
              </a>
            </p>
            <p>
              <a
                class="download-certificate"
                :download="`HeartLabCA.pem`"
                :href="`data:application/x-pem-file;base64,${caCertificatePemBase64}`"
              >
                PEM Format
              </a>
            </p>
          </div>
        </div>
      </template>
    </Popper>
  </div>

  <div class="top-row">
    <FilterInput v-model="searchTerm" placeholder="Search" />

    <button class="accented" style="justify-self: end" @click="showAddIdentityModal">
      Create DICOM Identity
    </button>
  </div>

  <div class="identities-table">
    <div class="header">
      <div>Name</div>
      <div>AE Title</div>
      <div>Last Used</div>
      <div>Enabled</div>
      <div>Allowed DIMSE Requests</div>
    </div>

    <div class="header-line" />

    <div v-for="identity in filteredIdentities" :key="identity.id" class="grid-table-row">
      <div @click="editDicomClientIdentity(identity.id)">
        {{ identity.name }}
      </div>
      <div @click="editDicomClientIdentity(identity.id)">{{ identity.aeTitle }}</div>

      <div @click="editDicomClientIdentity(identity.id)">
        {{ formatRelativeTime(identity.lastUsedAt) }}
      </div>
      <div @click="editDicomClientIdentity(identity.id)">{{ identity.enabled ? "Yes" : "No" }}</div>
      <div @click="editDicomClientIdentity(identity.id)">
        {{
          identity.allowedDimseRequests
            .sort()
            .join(", ")
            .replace(DimseMessage.NActionRequest, "Storage Commitment")
        }}
      </div>
    </div>
  </div>

  <Modal
    v-if="isAddIdentityModalVisible"
    title="Create DICOM Identity"
    :activity-text="isCreatingDicomClientIdentity ? 'Creating identity' : ''"
    @header-button-click="isAddIdentityModalVisible = false"
    @enter-key-press="createDicomClientIdentity"
  >
    <div class="modal-container">
      <div class="field">
        <b>Name</b>
        <input ref="nameInputElement" v-model="newIdentity.name" type="text" />
      </div>

      <div class="field">
        <b>AE Title</b>
        <input v-model="newIdentity.aeTitle" type="text" />
      </div>

      <button
        class="create-identity-button accented"
        :disabled="!isNewIdentityValid"
        @click="createDicomClientIdentity"
      >
        Create Identity
      </button>
    </div>
  </Modal>

  <ActivityOverlay v-if="isLoading" text="Loading" />
</template>

<script setup lang="ts">
import type { DicomClientIdentityGetManyResponseDto } from "@/../../backend/src/dicom/dto/dicom-client-identity-get-many.dto";
import { DicomPublicEndpointsGetResponseDto } from "@/../../backend/src/dicom/dto/dicom-public-endpoints-get.dto";
import ActivityOverlay from "@/components/ActivityOverlay.vue";
import Popper from "@/components/Popper.vue";
import { addNotification } from "@/utils/notifications";
import { useFocus } from "@vueuse/core";
import axios from "axios";
import { computed, reactive, ref } from "vue";
import { DimseMessage } from "../../../../../backend/src/dicom/dimse-message";
import { formatRelativeTime } from "../../../../../backend/src/shared/date-time-utils";
import FilterInput from "../../../components/FilterInput.vue";
import Modal from "../../../components/Modal.vue";
import router from "../../../router";
import { getRequestErrorMessage } from "../../../utils/request-helpers";

const isAddIdentityModalVisible = ref(false);
const isCreatingDicomClientIdentity = ref(false);

const dicomClientIdentities = ref<DicomClientIdentityGetManyResponseDto>([]);

const searchTerm = ref("");

const nameInputElement = ref<HTMLInputElement>();
useFocus(nameInputElement, { initialValue: true });

const filteredIdentities = computed(() => {
  const lowercaseSearchTerm = searchTerm.value.toLowerCase();

  return dicomClientIdentities.value.filter(
    (identity) =>
      identity.name.toLowerCase().includes(lowercaseSearchTerm) ||
      identity.aeTitle.toLowerCase().includes(lowercaseSearchTerm)
  );
});

const dicomPublicEndpoints = ref<DicomPublicEndpointsGetResponseDto | undefined>();
async function fetchDicomPublicEndpoints(): Promise<void> {
  dicomPublicEndpoints.value = (
    await axios.get<DicomPublicEndpointsGetResponseDto>(
      `/api/dicom-client-identities/public-endpoints`
    )
  ).data;
}

const isLoading = ref(false);

async function fetchDicomClientIdentities(): Promise<void> {
  isLoading.value = true;

  try {
    dicomClientIdentities.value = (
      await axios.get<DicomClientIdentityGetManyResponseDto>(`/api/dicom-client-identities`)
    ).data;
  } catch {
    addNotification({ type: "error", message: "Error loading DICOM identities" });
  } finally {
    isLoading.value = false;
  }
}

const newIdentity = reactive({
  name: "",
  aeTitle: "",
});

function showAddIdentityModal(): void {
  newIdentity.name = "";
  newIdentity.aeTitle = "";
  isAddIdentityModalVisible.value = true;
}

const isNewIdentityValid = computed(
  () => newIdentity.name.trim().length !== 0 && newIdentity.aeTitle.trim().length !== 0
);

async function createDicomClientIdentity(): Promise<void> {
  if (!isNewIdentityValid.value) {
    return;
  }

  isCreatingDicomClientIdentity.value = true;

  try {
    await axios.post(`/api/dicom-client-identities`, newIdentity);
  } catch (error) {
    addNotification({
      type: "error",
      message: getRequestErrorMessage(error) ?? "Error creating DICOM identity",
    });
    return;
  } finally {
    isCreatingDicomClientIdentity.value = false;
  }

  addNotification({ type: "info", message: "Created DICOM identity" });
  await fetchDicomClientIdentities();

  isAddIdentityModalVisible.value = false;
}

async function editDicomClientIdentity(identityId: string): Promise<void> {
  await router.push({ name: "settings-integrations-dicom-view", params: { id: identityId } });
}

function formatPortList(ports: number[]): string {
  if (ports.length === 1) {
    return ports[0].toString();
  }

  return `${ports.slice(0, ports.length - 1).join(", ")} or ${ports[ports.length - 1]}`;
}

void fetchDicomPublicEndpoints();
void fetchDicomClientIdentities();

const caCertificatePemBase64 = computed(() =>
  btoa(dicomPublicEndpoints.value?.dicomTls.caCert.pem ?? "")
);
</script>

<style scoped lang="scss">
.top-row {
  display: grid;
  grid-template-columns: 300px 1fr;
  align-items: center;
}

.identities-table {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  align-items: center;
  row-gap: 2px;

  .header {
    font-weight: bold;
    display: contents;
    > * {
      padding: 0 20px 4px 0;
    }
  }

  .header-line {
    grid-area: 2 / 1 / 2 / span 5;
    border-bottom: 2px solid var(--bg-color-2);
  }
}

.server-ca-download-divider {
  height: 1px;
  background-color: var(--border-color-1);
  width: 100%;
  margin-bottom: 10px;
}

.download-certificate {
  text-decoration: underline;
}

.view-dicom-endpoints {
  font-size: 13px;
  cursor: pointer;
}

.view-dicom-endpoints-popper {
  font-size: 13px;
  font-weight: normal;
  display: flex;
  gap: 32px;

  .dicom-info {
    p {
      margin: 8px 0;
    }
    h3 {
      margin-top: 0;
    }
  }
}

.modal-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 400px;

  .field {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .create-identity-button {
    width: max-content;
    margin: 0 auto;
  }
}
</style>
