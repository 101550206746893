<template>
  <BackButton
    back-destination-route-name="settings-integrations-webhook"
    button-text="Back to Webhook Settings"
  />

  <div class="settings-title">
    Webhook: {{ webhook.name }}

    <div style="font-size: 13px; font-weight: normal; display: contents">
      <button
        style="margin-left: auto"
        type="button"
        data-testid="webhook-integration-delete-btn"
        @click="deleteWebhook"
      >
        Delete Webhook
      </button>
    </div>
  </div>

  <div class="field">
    <b>Name</b>
    <input
      v-model="webhook.name"
      type="text"
      data-testid="webhook-name-input"
      @update:model-value="updateWebhookDebounced"
    />
  </div>

  <div class="field">
    <b>URL</b>
    <p>
      The URL that this webhook will send its requests to. This can be either HTTP or HTTPS and can
      include query parameters.
    </p>
    <input
      v-model="webhook.url"
      type="text"
      data-testid="webhook-url-input"
      @update:model-value="updateWebhookDebounced"
    />
  </div>

  <div class="field">
    <b>Study Send Mode</b>
    <p>
      The study send mode for this webhook. New studies can be sent to the webhook automatically, or
      studies can be sent to the webhook only on request, or study sending to the webhook can be
      disabled completely.
    </p>
    <DropdownWidget
      v-model="webhook.studySendMode"
      data-testid="webhook-integration-mode-dropdown"
      style="width: 200px"
      :items="
        Object.values(WebhookStudySendMode).map((mode) => ({
          value: mode,
          text: getWebhookStudySendModeDisplayText(mode),
        }))
      "
      @update:model-value="updateWebhookDebounced"
    />
  </div>

  <div style="height: 1px; width: 520px; background-color: var(--bg-color-4); margin: 16px 0" />

  <p>
    See the
    <a href="/api/docs/#tag/Webhooks" target="blank" style="text-decoration: underline">
      API Documentation
    </a>
    for a description of webhook events and their request payloads.
  </p>

  <p>
    Press the button below to send a dummy <code>POST</code> request to this webhook's URL. The
    payload will have its event set to <code>"test"</code>.
  </p>

  <button class="accented" style="width: min-content" @click="testWebhook">Test Webhook</button>

  <ActivityOverlay v-if="activityText" :text="activityText" />
</template>

<script setup lang="ts">
import router from "@/router";
import { useDebounceFn } from "@vueuse/shared";
import axios, { AxiosResponse } from "axios";
import { onMounted, ref } from "vue";
import { WebhookIntegrationGetOneResponseDto } from "../../../../../backend/src/integrations/webhook/dto/webhook-integration-get-one.dto";
import {
  WebhookStudySendMode,
  getWebhookStudySendModeDisplayText,
} from "../../../../../backend/src/integrations/webhook/webhook-study-send-mode";
import ActivityOverlay from "../../../components/ActivityOverlay.vue";
import DropdownWidget from "../../../components/DropdownWidget.vue";
import { addNotification } from "../../../utils/notifications";
import { isValidHttpUrl } from "../../../utils/validation";
import BackButton from "../../components/BackButton.vue";

interface Props {
  id: string;
}

const props = defineProps<Props>();

const activityText = ref("");

const webhook = ref<WebhookIntegrationGetOneResponseDto>({
  id: "",
  name: "",
  url: "",
  lastUsedAt: null,
  studySendMode: WebhookStudySendMode.SendAutomatically,
});

onMounted(async () => {
  activityText.value = "Loading";

  let response: AxiosResponse<WebhookIntegrationGetOneResponseDto> | undefined = undefined;
  try {
    response = await axios.get<WebhookIntegrationGetOneResponseDto>(
      `/api/integrations/webhook/${props.id}`
    );
  } catch {
    addNotification({ type: "error", message: `Failed loading webhook` });
    return;
  } finally {
    activityText.value = "";
  }

  webhook.value = response.data;
});

async function updateWebhook(): Promise<void> {
  if (!isValidHttpUrl(webhook.value.url)) {
    addNotification({
      type: "error",
      message: "Webhook URL must be a valid http:// or https:// URL",
    });
    return;
  }

  try {
    await axios.patch(`/api/integrations/webhook/${webhook.value.id}`, {
      name: webhook.value.name,
      url: webhook.value.url,
      lastUsedAt: webhook.value.lastUsedAt,
      studySendMode: webhook.value.studySendMode,
    });
  } catch {
    addNotification({ type: "error", message: "Error updating webhook" });
    return;
  }

  addNotification({ type: "info", message: "Updated webhook" });
}

const updateWebhookDebounced = useDebounceFn(() => {
  void updateWebhook();
}, 1000);

async function deleteWebhook(): Promise<void> {
  if (!confirm(`Are you sure you want to delete the webhook "${webhook.value.name}"?`)) {
    return;
  }

  activityText.value = "Deleting webhook";

  try {
    await axios.delete(`/api/integrations/webhook/${webhook.value.id}`);
  } catch {
    addNotification({ type: "error", message: "Error deleting webhook" });
    return;
  } finally {
    activityText.value = "";
  }

  await router.push({ name: "settings-integrations-webhook" });

  addNotification({ type: "info", message: "Deleted webhook" });
}

async function testWebhook(): Promise<void> {
  activityText.value = "Testing webhook";

  try {
    const response = await axios.post<boolean>(
      `/api/integrations/webhook/${webhook.value.id}/test`
    );

    if (!response.data) {
      throw Error("Webhook request failed");
    }

    addNotification({ type: "info", message: "Webhook request succeeded" });
  } catch {
    addNotification({
      type: "error",
      message: "Webhook request failed, see the audit logs for details",
    });
  } finally {
    activityText.value = "";
  }
}
</script>

<style scoped lang="scss">
.field {
  display: grid;
  gap: 8px;
}

input {
  width: 500px;
}
</style>
