<template>
  <div class="settings-title">
    HeartBoxes

    <button
      class="accented"
      style="margin-left: auto; font-size: 13px"
      data-testid="open-add-heartbox-integration-modal"
      @click="showAddHeartBoxIntegrationModal"
    >
      Create HeartBox
    </button>
  </div>

  <div class="heartboxes-table" data-testid="heartboxes-table">
    <div class="header">
      <div>Display Name</div>
      <div>Device Name</div>
      <div>Last Seen</div>
    </div>

    <div class="header-line" />

    <div
      v-for="heartbox in heartboxes"
      :key="heartbox.id"
      class="grid-table-row"
      @click="openHeartBoxIntegration(heartbox.id)"
    >
      <div>{{ heartbox.displayName }}</div>
      <div>{{ heartbox.deviceName }}</div>
      <div>{{ formatRelativeTime(heartbox.lastSeenAt) }}</div>
    </div>

    <ActivityOverlay v-if="isLoading" text="Loading" />
  </div>

  <Modal
    v-if="isHeartBoxIntegrationCreateModalVisible"
    :title="newHeartBoxIntegration.id === '' ? 'Create HeartBox' : 'Created HeartBox'"
    :activity-text="isCreatingHeartBoxIntegration ? 'Creating HeartBox' : ''"
    @header-button-click="isHeartBoxIntegrationCreateModalVisible = false"
    @enter-key-press="createHeartBoxIntegration"
  >
    <div class="modal-container">
      <div class="field">
        <b>Display Name</b>
        <input
          ref="displayNameInputElement"
          v-model="newHeartBoxIntegration.displayName"
          type="text"
          :readonly="newHeartBoxIntegration.id !== ''"
          data-testid="display-name-input"
        />
      </div>

      <div class="field">
        <b>Device Name</b>
        <template v-if="newHeartBoxIntegration.id === ''">
          <div style="display: flex; align-items: center; gap: 8px">
            <code>box-</code>
            <input
              v-model="newHeartBoxIntegration.deviceName"
              type="text"
              style="flex: 1"
              data-testid="device-name-input"
            />
          </div>
          <span>The device name can't be changed after creation.</span>
        </template>
        <input
          v-else
          v-model="newHeartBoxIntegration.deviceName"
          type="text"
          data-testid="device-name-input"
          readonly
        />
      </div>

      <button
        v-if="newHeartBoxIntegration.id === ''"
        class="accented"
        :disabled="!isNewHeartBoxIntegrationValid"
        data-testid="create-heartbox-integration"
        @click="createHeartBoxIntegration"
      >
        Create HeartBox
      </button>

      <template v-else>
        <SingleViewToken
          header="Device Token"
          token-type="device token"
          :token="newHeartBoxIntegration.deviceToken"
        >
          Use this device token when provisioning the new HeartBox device.
        </SingleViewToken>

        <button
          class="accented"
          data-testid="view-heartbox-integration-button"
          @click="
            router.push({
              name: 'settings-integrations-heartbox-view',
              params: { id: newHeartBoxIntegration.id },
            })
          "
        >
          View HeartBox
        </button>
      </template>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import { useFocus } from "@vueuse/core";
import axios, { AxiosResponse } from "axios";
import { computed, onMounted, reactive, ref } from "vue";
import { HeartBoxIntegrationCreateResponseDto } from "../../../../../backend/src/integrations/heartbox/dto/heartbox-integration-create.dto";
import type { HeartBoxIntegrationGetManyResponseDto } from "../../../../../backend/src/integrations/heartbox/dto/heartbox-integration-get-many.dto";
import { formatRelativeTime } from "../../../../../backend/src/shared/date-time-utils";
import ActivityOverlay from "../../../components/ActivityOverlay.vue";
import Modal from "../../../components/Modal.vue";
import router from "../../../router";
import { addNotification } from "../../../utils/notifications";
import { getRequestErrorMessage } from "../../../utils/request-helpers";
import SingleViewToken from "../../SingleViewToken.vue";

const isLoading = ref(false);

const heartboxes = ref<HeartBoxIntegrationGetManyResponseDto>([]);

onMounted(async () => {
  let response: AxiosResponse<HeartBoxIntegrationGetManyResponseDto> | undefined = undefined;

  isLoading.value = true;

  try {
    response = await axios.get<HeartBoxIntegrationGetManyResponseDto>(`/api/integrations/heartbox`);
  } catch {
    addNotification({ type: "error", message: "Failed loading HeartBox list" });
    return;
  } finally {
    isLoading.value = false;
  }

  heartboxes.value = response.data;
});

async function openHeartBoxIntegration(heartboxIntegrationId: string): Promise<void> {
  await router.push({
    name: "settings-integrations-heartbox-view",
    params: { id: heartboxIntegrationId },
  });
}

const isHeartBoxIntegrationCreateModalVisible = ref(false);
const newHeartBoxIntegration = reactive({
  id: "",
  displayName: "",
  deviceName: "",
  deviceToken: "",
});

const isCreatingHeartBoxIntegration = ref(false);

function showAddHeartBoxIntegrationModal(): void {
  isHeartBoxIntegrationCreateModalVisible.value = true;
  newHeartBoxIntegration.id = "";
  newHeartBoxIntegration.displayName = "";
  newHeartBoxIntegration.deviceName = "";
  newHeartBoxIntegration.deviceToken = "";
}

const isNewHeartBoxIntegrationValid = computed(
  () =>
    newHeartBoxIntegration.displayName.trim() !== "" &&
    newHeartBoxIntegration.deviceName.trim() !== ""
);

const displayNameInputElement = ref<HTMLInputElement | null>(null);
useFocus(displayNameInputElement, { initialValue: true });

async function createHeartBoxIntegration(): Promise<void> {
  isCreatingHeartBoxIntegration.value = true;

  newHeartBoxIntegration.displayName = newHeartBoxIntegration.displayName.trim();
  newHeartBoxIntegration.deviceName = newHeartBoxIntegration.deviceName.trim().toLowerCase();

  let response: HeartBoxIntegrationCreateResponseDto | undefined = undefined;

  try {
    response = (
      await axios.post<HeartBoxIntegrationCreateResponseDto>(`/api/integrations/heartbox`, {
        displayName: newHeartBoxIntegration.displayName,
        deviceName: newHeartBoxIntegration.deviceName,
      })
    ).data;
  } catch (error) {
    addNotification({
      type: "error",
      message: getRequestErrorMessage(error) ?? "Failed creating HeartBox integration",
    });
    return;
  } finally {
    isCreatingHeartBoxIntegration.value = false;
  }

  addNotification({ type: "info", message: "Created HeartBox integration" });

  newHeartBoxIntegration.id = response.id;
  newHeartBoxIntegration.deviceName = response.deviceName;
  newHeartBoxIntegration.deviceToken = response.deviceToken;
}
</script>

<style scoped lang="scss">
.heartboxes-table {
  display: grid;
  grid-template-columns: auto auto auto;
  row-gap: 2px;

  .header {
    font-weight: bold;
    display: contents;

    > * {
      padding: 0 20px 4px 0;
    }
  }

  .header-line {
    grid-area: 2 / 1 / 2 / span 3;
    border-bottom: 2px solid var(--bg-color-3);
  }
}

.modal-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 400px;

  .field {
    display: flex;
    flex-direction: column;
    gap: 8px;

    span {
      font-size: 0.85em;
    }
  }

  button {
    margin: 0 auto;
  }
}
</style>
