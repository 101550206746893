<template>
  <div class="pdf-viewer">
    <div ref="pdfContainer" />

    <div v-if="!pdfSupported" class="error">
      <p>
        This browser does not support inline PDFs. Please download the PDF to view it:
        <a :href="props.docPath" download><u>Download PDF</u></a>
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
/**
 * This is a simple PDF viewer component that uses the PDFObject library to embed a PDF document in an iframe, with a fallback link for browsers that do not support inline PDFs.
 */
import PDFObject from "pdfobject";
import { onMounted, onUpdated, ref } from "vue";

interface Props {
  /**
   * The path to the PDF document to display.
   */
  docPath: string;
}

interface Emits {
  (event: "onLoad"): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const pdfContainer = ref<HTMLElement | null>(null);
const pdfSupported = ref<boolean>(true);

function initPdf() {
  if (pdfContainer.value) {
    const embedded: HTMLElement | false = PDFObject.embed(props.docPath, pdfContainer.value, {
      PDFJS_URL: "/pdfjs/web/viewer.html",
      fallbackLink: false,
    });

    pdfSupported.value = embedded instanceof HTMLElement;

    emits("onLoad");
  }
}

onMounted(() => {
  initPdf();
});

onUpdated(() => {
  initPdf();
});
</script>

<style scoped lang="scss">
.pdf-viewer {
  display: grid;
  grid-template-areas: "content";
  width: 100%;
  height: 100%;
}

.error {
  grid-area: content;
  place-self: center;
  text-align: center;
}

iframe {
  grid-area: content;
  place-self: stretch;
  width: 100%;
  height: 100%;
  transition: opacity 1000ms ease;
  border-radius: var(--border-radius);
  border: none;
}
</style>
