<template>
  <ReportStateBanner
    v-model:selected-report-id="selectedReportId"
    :study="study"
    :display-mode="displayMode"
    :report="latestReportWithTemplateAndContent"
  />
  <div
    class="report-pane"
    :data-test-latest-report-signed="latestReportWithTemplateAndContent?.isSigned"
    :data-test-latest-report-type="latestReportWithTemplateAndContent?.type"
    :data-test-latest-report-completed-by-id="latestReportWithTemplateAndContent?.completedById"
  >
    <!-- Show a PDF viewer if the report is completed or we're showing a previous report -->
    <ReportPDFViewer
      v-if="showReportPDFViewer"
      :study-id="study.id"
      :study-report-id="selectedReportId"
    />

    <!-- If we're showing a generated PDF based on the active report's content then display that -->
    <template v-else-if="isShowingGeneratedReportPdf">
      <PdfViewer
        :doc-path="reportPdfBlobUri"
        :data-test-loading="isShowingGeneratedReportPdf && reportPdfBlobUri === ''"
        data-testid="generated-report-pdf"
      />

      <!-- Hidden copy of the report preview that emits the HTML for the final PDF -->
      <Report
        v-if="latestReportWithTemplateAndContent !== null"
        :study="study"
        :report="latestReportWithTemplateAndContent"
        :signature-data="signatureData"
        :display-mode="displayMode"
        :mode="ReportContentMode.GenerateHTML"
        @html-updated="onReportHtmlUpdated"
      />
    </template>

    <!-- Otherwise, show the report editing UI for the active report -->
    <div
      v-else-if="latestReportWithTemplateAndContent !== null"
      ref="previewContainer"
      class="report-container"
      data-testid="report-pane-edit"
    >
      <OverlayScrollbar id="report-pane-scroll-bar">
        <Report
          :study="study"
          :report="latestReportWithTemplateAndContent"
          :container-width="reportContainerWidth"
          :signature-data="signatureData"
          :mode="ReportContentMode.EditReportContent"
          :display-mode="displayMode"
          @update-report-content="debouncedReportContentUpdate"
          @update-patient-info="emits('update-patient-info')"
          @section-comment-field-action="onSectionCommentFieldAction"
        />
      </OverlayScrollbar>
    </div>
  </div>
  <div class="toolbar-container">
    <progress
      v-if="showProgress"
      :max="progressMax"
      :value="progressValue"
      data-testid="report-progress"
    ></progress>
    <div
      v-if="
        !showReportPDFViewer &&
        latestReport.type === StudyReportType.Amendment &&
        hasStudyReportAmendmentCompletePermission &&
        !isReviewing
      "
      class="amendment-reason"
    >
      <div class="amendment-reason-heading-container">
        <strong>
          Amendment Reason
          <span v-if="currentTenant.isAmendmentReasonRequired" style="color: red">*</span>
        </strong>
        <Checkbox
          :model-value="latestReportWithTemplateAndContent?.content.showAmendmentReason ?? false"
          data-testid="amendment-reason-display-checkbox"
          @update:model-value="
            (newValue: boolean) => {
              if (latestReportWithTemplateAndContent !== null) {
                latestReportWithTemplateAndContent.content.showAmendmentReason = newValue;
                debouncedReportContentUpdate();
              }
            }
          "
        >
          Display on report
        </Checkbox>
      </div>
      <input
        v-if="latestReportWithTemplateAndContent !== null"
        v-model="latestReportWithTemplateAndContent.content.amendmentReason"
        :placeholder="`Enter the reason for amending this report. ${currentTenant.isAmendmentReasonRequired ? 'Required' : 'Optional'}`"
        style="margin: 0 8px"
        data-testid="amendment-reason-toolbar-textfield"
        @update:model-value="debouncedReportContentUpdate"
      />
    </div>
    <div class="toolbar" data-testid="report-pane-toolbar">
      <template v-if="isReviewing">
        <button @click="showLatestReport">
          <FontAwesomeIcon icon="chevron-left" class="accent-icon" />
          Back
        </button>
        <button
          v-if="
            displayMode === StudyReportType.Preliminary &&
            hasStudyReportPreliminaryCompletePermission
          "
          :disabled="reportPdfBlobUri === ''"
          style="margin-left: auto"
          class="accented"
          data-testid="approve-preliminary-report-button"
          @click="completeReport(false)"
        >
          Approve Preliminary Report
        </button>

        <template
          v-else-if="
            (latestReport.type === StudyReportType.Preliminary &&
              displayMode === StudyReportType.Final &&
              hasStudyReportPreliminaryFinalizePermission) ||
            (latestReport.type === StudyReportType.Amendment &&
              hasStudyReportAmendmentCompletePermission)
          "
        >
          <template v-if="isUserReportingConfigComplete">
            <b style="padding-left: 16px"> Enter your PIN to complete this report </b>

            <PINField
              v-model="userPin"
              style="margin-left: auto; margin-right: 20px"
              data-testid="sign-report-pin"
              @enter="isPINValid(userPin) && completeReport(true)"
            />

            <Tooltip :content="isPINValid(userPin) ? '' : 'Enter your PIN to sign this report'">
              <button
                class="success"
                data-testid="sign-report-button"
                :disabled="!isPINValid(userPin) || reportPdfBlobUri === ''"
                @click="completeReport(true)"
              >
                <FontAwesomeIcon icon="check" />
                {{
                  latestReport.type === StudyReportType.Amendment
                    ? "Sign Amendment"
                    : "Finalize Report"
                }}
              </button>
            </Tooltip>
          </template>

          <div v-else class="set-signature-prompt">
            <template v-if="currentUser.hasPin">
              <template v-if="latestReport.type === StudyReportType.Preliminary">
                <RouterLink to="/settings/user/signature" class="user-settings-link">
                  Set your signature
                </RouterLink>
                to sign this report.
              </template>
              <template v-else>
                The original signer of this report has removed their signature.
              </template>
            </template>
            <template v-else>
              <RouterLink to="/settings/user/pin" class="user-settings-link">
                Set your PIN
              </RouterLink>
              to sign this report.
            </template>
          </div>
        </template>
      </template>

      <template v-else>
        <Tooltip
          v-if="
            selectedReportId === latestReport.id &&
            ((latestReport.isSigned && hasStudyReportDeleteSignedPermission) ||
              (!latestReport.isSigned && hasStudyReportDeleteUnsignedPermission))
          "
          :content="
            latestReport.type === StudyReportType.Amendment ? 'Delete Amendment' : 'Delete Report'
          "
          placement="right"
        >
          <button
            style="margin-right: auto"
            :disabled="isDeletingReport || loadLatestReportTemplateAndContent.isRunning"
            data-testid="delete-report-btn"
            @click="onDeleteReport"
          >
            <FontAwesomeIcon icon="trash" />
          </button>
        </Tooltip>

        <button
          v-else-if="showReportPDFViewer && selectedReportId !== latestReport.id"
          style="margin-right: auto"
          @click="showLatestReport"
        >
          <FontAwesomeIcon icon="chevron-left" class="accent-icon" />
          Latest Version
        </button>

        <div class="toolbar-dropdown-container" style="margin-left: auto">
          <b>Assignee</b>
          <StudyParticipantDropdown
            :study="study"
            field="assignedUserId"
            data-testid="study-assignee-dropdown"
          />
        </div>

        <template v-if="latestReport.completedAt === null && !showReportPDFViewer">
          <!--
          Button for reviewing the preliminary or final report, and for choosing which of the two
          should be created if they have permissions to create both.
        -->
          <div
            v-if="
              (hasStudyReportPreliminaryCompletePermission ||
                hasStudyReportPreliminaryFinalizePermission) &&
              latestReport.type !== StudyReportType.Amendment
            "
            style="display: flex"
          >
            <Tooltip
              v-if="hasCompleteAndFinalizeReportPermissions"
              :content="`Change to ${
                displayMode === StudyReportType.Preliminary
                  ? StudyReportType.Final
                  : StudyReportType.Preliminary
              } report`"
              placement="top-end"
              white-space="nowrap"
            >
              <button
                data-testid="change-report-editing-mode"
                style="margin: 0 8px"
                @click="
                  displayMode =
                    displayMode === StudyReportType.Preliminary
                      ? StudyReportType.Final
                      : StudyReportType.Preliminary
                "
              >
                <FontAwesomeIcon icon="repeat" />
              </button>
            </Tooltip>

            <Tooltip
              :content="
                isReviewPreliminaryReportButtonEnabled
                  ? ''
                  : 'To approve the preliminary report, first assign this study to a user who can sign it off'
              "
            >
              <button
                class="accented"
                :disabled="!isReviewButtonEnabled"
                :data-testid="`review-${displayMode}-report-button`"
                @click="onReviewReport"
              >
                Review
                {{ displayMode[0].toUpperCase() + displayMode.slice(1) }}
                Report
                <FontAwesomeIcon icon="chevron-right" />
              </button>
            </Tooltip>

            <!-- Allow user to toggle between completing preliminary or finalizing report, if they can -->
          </div>

          <!-- Review amendment -->

          <Tooltip
            v-if="
              latestReport.type === StudyReportType.Amendment &&
              hasStudyReportAmendmentCompletePermission
            "
            :content="
              isReportAmendmentReasonMissing ? 'Enter a reason for amending this report' : ''
            "
          >
            <button
              class="accented"
              :disabled="isReportAmendmentReasonMissing"
              data-testid="review-amendment-report-button"
              :style="[hasCompleteAndFinalizeReportPermissions ? { marginLeft: '8px' } : {}]"
              @click="onReviewReport"
            >
              {{ latestReport.isSigned ? "Amend Report" : "Review Amendment" }}
            </button>
          </Tooltip>
        </template>

        <Popper
          v-else-if="latestReport.id === selectedReportId"
          class="report-pane-select-report-template-popper"
          placement="top-end"
          :offset-distance="2"
          :interactive="false"
          :disabled="latestReport.reportTemplateVersionId !== null"
          @open="isReportTemplateSelectorDropdownOpen = true"
          @close="isReportTemplateSelectorDropdownOpen = false"
        >
          <button
            :disabled="
              (latestReport.isSigned && !hasStudyReportAmendmentCreatePermission) ||
              (!latestReport.isSigned && !hasStudyReportPreliminaryCreatePermission)
            "
            class="accented"
            :class="{ active: isReportTemplateSelectorDropdownOpen }"
            :data-testid="`${latestReport.isSigned ? 'amend-report-button' : 'edit-report-button'}`"
            style="margin-left: 8px"
            @click="
              latestReport.reportTemplateVersionId !== null &&
                emits('report-create', latestReport.reportTemplateVersionId)
            "
          >
            {{ latestReport.isSigned ? "Amend Report" : "Edit Report" }}
          </button>

          <template #content>
            <ReportTemplateSelector
              v-if="
                (latestReport.isSigned && hasStudyReportAmendmentCreatePermission) ||
                (!latestReport.isSigned && hasStudyReportPreliminaryCreatePermission)
              "
              @select-template="
                (reportTemplateVersionId) => emits('report-create', reportTemplateVersionId)
              "
            />
          </template>
        </Popper>
      </template>
    </div>
  </div>

  <div
    v-if="
      focusedSection !== null &&
      focusedSectionSentenceGroups.length !== 0 &&
      latestReportWithTemplateAndContent !== null
    "
    style="grid-area: 2 / 1"
  >
    <Popper show placement="left-start" class="report-sentence-library-popper">
      <div />

      <template #content>
        <ReportSentenceLibrary
          :study="study"
          :report="latestReportWithTemplateAndContent"
          :focused-section="focusedSection"
          :sentence-groups="focusedSectionSentenceGroups"
          analytics-enabled
          @close="onCloseSentenceLibrary"
        />
      </template>
    </Popper>
  </div>

  <ActivityOverlay
    v-if="
      (isShowingGeneratedReportPdf && reportPdfBlobUri === '') ||
      isCompletingReport ||
      isDeletingReport
    "
  />
</template>

<script setup lang="ts">
import {
  hasStudyReportAmendmentCompletePermission,
  hasStudyReportAmendmentCompleteWithUpdatedContentPermission,
  hasStudyReportAmendmentCreatePermission,
  hasStudyReportDeleteSignedPermission,
  hasStudyReportDeleteUnsignedPermission,
  hasStudyReportPreliminaryCompletePermission,
  hasStudyReportPreliminaryCreatePermission,
  hasStudyReportPreliminaryFinalizePermission,
  isStudyUpdatePermitted,
} from "@/auth/authorization";
import Checkbox from "@/components/Checkbox.vue";
import PdfViewer from "@/components/PdfViewer.vue";
import Popper from "@/components/Popper.vue";
import Tooltip from "@/components/Tooltip.vue";
import ReportStateBanner from "@/reporting/ReportStateBanner.vue";
import {
  getEmptyStudyReportDescription,
  getSortedReports,
  ReportTemplateVersion,
  SignatureData,
  Study,
  StudyReport,
  StudyReportDescription,
} from "@/utils/study-data";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useDebounceFn, useElementSize } from "@vueuse/core";
import axios, { AxiosResponse, isAxiosError } from "axios";
import { computed, reactive, ref, watch } from "vue";
import { useTask, type YieldReturn } from "vue-concurrency";
import { RouterLink } from "vue-router";
import { Permission } from "../../../backend/src/auth/permissions/permissions";
import { ReportSentenceGroupCreateResponseDto } from "../../../backend/src/reporting/dto/report-sentence-group-create.dto";
import type { ReportTemplateVersionGetResponseDto } from "../../../backend/src/reporting/dto/report-template-version-get.dto";
import { ReportContent } from "../../../backend/src/reporting/report-content";
import { ReportComponent } from "../../../backend/src/reporting/report-structure";
import { StudyReportType } from "../../../backend/src/studies/study-report-type";
import { isPINValid } from "../auth/authentication";
import { currentTenant, currentUser } from "../auth/current-session";
import ActivityOverlay from "../components/ActivityOverlay.vue";
import OverlayScrollbar from "../components/OverlayScrollbar.vue";
import PINField from "../components/PINField.vue";
import StudyParticipantDropdown from "../components/StudyParticipantDropdown.vue";
import { addNotification } from "../utils/notifications";
import { getRequestErrorMessage } from "../utils/request-helpers";
import { useUserList } from "../utils/users-list";
import Report from "./Report.vue";
import ReportPDFViewer from "./ReportPDFViewer.vue";
import ReportSentenceLibrary from "./ReportSentenceLibrary.vue";
import ReportTemplateSelector from "./ReportTemplateSelector.vue";
import { useGeneratedReportPdf } from "./generate-report-pdf";
import { ReportContentMode } from "./report-content";
import { getStudyReportStateDetails, StudyReportStatus } from "./report-status";
import { useReportFocusedSection } from "./use-report-focused-section";

interface Props {
  study: Study;
}

interface Emits {
  (event: "update-patient-info"): void;

  (event: "report-create", reportTemplateVersionId: string): void;

  (event: "report-latest-deleted"): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const latestReport = computed(
  (): StudyReportDescription =>
    getSortedReports(props.study.reports)[0] ?? getEmptyStudyReportDescription()
);

// The latest report for the study is the only one that's potentially editable. Editable reports
// are those created in Pulse that have a report template and report content specified. In order to
// edit such reports, make sure their content and report template are loaded. Reports imported from
// external systems that only have a PDF and no report content or template are not editable.
const reportTemplateVersion = ref<ReportTemplateVersion | null>(null);
const reportContent = ref<ReportContent | null>(null);
const latestReportWithTemplateAndContent = ref<StudyReport | null>(null);
const tenantSentenceGroups = reactive({}) as Record<string, { name: string; sentences: string[] }>;

const loadLatestReportTemplateAndContent = useTask(function* (signal) {
  if (latestReport.value.id === "") {
    return;
  }

  reportContent.value = null;

  // Request a different report template version if the required one isn't present
  if (latestReport.value.reportTemplateVersionId === null) {
    reportTemplateVersion.value = null;
  } else if (latestReport.value.reportTemplateVersionId !== reportTemplateVersion.value?.id) {
    reportTemplateVersion.value = (yield loadReportTemplateVersion(
      latestReport.value.reportTemplateVersionId
    )) as YieldReturn<typeof loadReportTemplateVersion>;
  }

  reportContent.value = (yield loadReportContent(signal)) as YieldReturn<typeof loadReportContent>;

  if (reportContent.value !== null) {
    reportContent.value.showAmendmentReason ??= true;
  }

  // If everything is there then set the full variant of the latest report that has the content and
  // report template set
  if (reportContent.value !== null && reportTemplateVersion.value !== null) {
    latestReportWithTemplateAndContent.value = {
      ...latestReport.value,
      content: reportContent.value,
      reportTemplateVersion: reportTemplateVersion.value,
    };
  }

  // If there are any sentence groups in the report template structure, fetch the tenant's sentence
  // groups and add them to the structure
  const sentenceGroups =
    latestReportWithTemplateAndContent.value?.reportTemplateVersion.structure.sentenceGroups;

  if (Object.keys(sentenceGroups ?? {}).length > 0) {
    void fetchTenantSentenceGroups();
  }

  displayMode.value = getLatestReportDefaultDisplayMode();
}).restartable();

const signatureData = reactive({
  signatureLogoDataUri: "",
  signatureDataUri: "",
  signatureText: "",
});

// If the report isn't an amendment and the user has permission to finalize a preliminary report
// then the current user's signature is put on the report. Otherwise, the signature of the user who
// signed the final report for this study will be re-used for all subsequent amendments.
async function updateSignatureData(): Promise<void> {
  if (latestReport.value.type !== StudyReportType.Amendment) {
    if (hasStudyReportPreliminaryFinalizePermission.value) {
      signatureData.signatureDataUri = currentUser.signatureDataUri;
      signatureData.signatureLogoDataUri = currentUser.signatureLogoDataUri;
      signatureData.signatureText = currentUser.signatureText;
    }

    return;
  }

  let response: AxiosResponse<SignatureData> | undefined = undefined;
  try {
    response = await axios.get<SignatureData>(
      `/api/studies/${props.study.id}/reports/final-signature`
    );
  } catch (error) {
    addNotification({ type: "error", message: "Failed loading signature for report" });
    return;
  }

  signatureData.signatureLogoDataUri = response.data.signatureLogoDataUri;
  signatureData.signatureDataUri = response.data.signatureDataUri;
  signatureData.signatureText = response.data.signatureText;
}

watch(
  latestReport,
  () => {
    void loadLatestReportTemplateAndContent.perform();
    void updateSignatureData();
  },
  { immediate: true }
);

async function loadReportTemplateVersion(
  reportTemplateVersionId: string
): Promise<ReportTemplateVersion | null> {
  let response: AxiosResponse<ReportTemplateVersionGetResponseDto> | undefined = undefined;
  try {
    response = await axios.get<ReportTemplateVersionGetResponseDto>(
      `/api/reporting/template-version/${reportTemplateVersionId}`
    );
  } catch (error) {
    addNotification({
      type: "error",
      message: getRequestErrorMessage(error) ?? "Failed loading report template",
    });
    return null;
  }

  return response.data;
}

async function fetchTenantSentenceGroups(): Promise<void> {
  let response: AxiosResponse<ReportSentenceGroupCreateResponseDto[]> | undefined = undefined;

  try {
    response = await axios.get<ReportSentenceGroupCreateResponseDto[]>(
      "/api/report-sentence-groups"
    );
  } catch (error) {
    addNotification({ type: "error", message: "Error loading sentence groups" });
    return;
  }

  for (const group of response.data) {
    tenantSentenceGroups[group.id] = {
      name: group.name,
      sentences: group.sentences,
    };
  }
}

async function loadReportContent(signal: AbortSignal): Promise<ReportContent | null> {
  let response: AxiosResponse<ReportContent> | undefined = undefined;
  try {
    response = await axios.get<ReportContent>(
      `/api/studies/${props.study.id}/reports/${latestReport.value.id}/content`,
      { signal }
    );
  } catch (error) {
    if (isAxiosError(error) && !signal.aborted) {
      addNotification({ type: "error", message: "Failed loading report content" });
    }

    return null;
  }

  return response.data;
}

const { userList } = useUserList();

const selectedReportId = ref("");
watch(latestReport, () => (selectedReportId.value = latestReport.value.id), { immediate: true });

const userPin = ref("");

const isReviewing = ref(false);

const displayMode = ref(getLatestReportDefaultDisplayMode());

const progressMax = computed(() => {
  return displayMode.value === StudyReportType.Final ||
    displayMode.value === StudyReportType.Amendment
    ? 3
    : 2;
});

const reportProgress = ref(1);
const progressValue = computed(() => reportProgress.value + (isPINValid(userPin.value) ? 1 : 0));

const studyReportState = computed(() =>
  getStudyReportStateDetails(props.study.reports, userList.value)
);

const showProgress = computed(() => {
  if (studyReportState.value?.status === StudyReportStatus.ReportFinalized) {
    return false;
  }

  if (latestReport.value.id !== selectedReportId.value) {
    return false;
  }

  // Check if the user has permission to finalize the preliminary report
  if (
    latestReport.value.type === StudyReportType.Preliminary &&
    !(
      hasStudyReportPreliminaryFinalizePermission.value ||
      hasStudyReportPreliminaryCompletePermission.value
    )
  ) {
    return false;
  }

  // Check if the user has permission to complete the amendment
  if (
    latestReport.value.type === StudyReportType.Amendment &&
    !(
      hasStudyReportAmendmentCompletePermission.value ||
      hasStudyReportAmendmentCompleteWithUpdatedContentPermission.value
    )
  ) {
    return false;
  }

  return !(
    studyReportState.value?.status === StudyReportStatus.PreliminaryReportApproved &&
    latestReport.value.completedAt !== null
  );
});

watch(showProgress, (show) => {
  if (!show) {
    reportProgress.value = 1;
  }
});

function getLatestReportDefaultDisplayMode() {
  // Update the display mode for the new latest report
  if (latestReport.value.type === StudyReportType.Amendment) {
    return StudyReportType.Amendment;
  } else if (hasStudyReportPreliminaryFinalizePermission.value) {
    return StudyReportType.Final;
  } else {
    return StudyReportType.Preliminary;
  }
}

const hasCompleteAndFinalizeReportPermissions = computed(
  () =>
    hasStudyReportPreliminaryCompletePermission.value &&
    hasStudyReportPreliminaryFinalizePermission.value
);

const isReviewButtonEnabled = computed(() =>
  displayMode.value === StudyReportType.Preliminary
    ? isReviewPreliminaryReportButtonEnabled.value
    : hasStudyReportPreliminaryFinalizePermission.value
);

const isUserReportingConfigComplete = computed(() => {
  // The user must have a PIN set to finalize a report or complete an amendment
  if (!currentUser.hasPin) {
    return false;
  }

  const reportStructure = latestReportWithTemplateAndContent.value?.reportTemplateVersion.structure;
  if (reportStructure === undefined) {
    return false;
  }

  // If the report template doesn't show a signature then there's nothing further to check
  const isSignaturePresentOnReport = reportStructure.layout.components.some(
    (c) => c.name === ReportComponent.Signature && c.isVisible
  );
  if (!isSignaturePresentOnReport) {
    return true;
  }

  // The report template has the signature component visible, so require valid signature data
  const isSignatureSet =
    signatureData.signatureDataUri.length > 0 && signatureData.signatureText.length > 0;

  return isSignatureSet;
});

const isPINInvalid = computed(() => !isPINValid(userPin.value));

const previewContainer = ref<HTMLDivElement>();
const { width: reportContainerWidth } = useElementSize(previewContainer);

const showReportPDFViewer = computed(
  () => selectedReportId.value !== latestReport.value.id || latestReport.value.completedAt !== null
);

const isShowingGeneratedReportPdf = computed(
  () => !showReportPDFViewer.value && (isReviewing.value || !isStudyUpdatePermitted(props.study))
);

// The preliminary report can only be completed when the study is assigned to a user who has
// permission to finalize it, or if the current user can complete and finalize a report.
const isReviewPreliminaryReportButtonEnabled = computed(
  () =>
    hasStudyReportPreliminaryFinalizePermission.value ||
    userList.value
      .find((user) => user.id === props.study.assignedUserId)
      ?.roleIds.some((roleId) => {
        const role = currentTenant.roles.find((r) => r.id === roleId);
        if (role === undefined) {
          return false;
        }

        return role.permissions.includes(Permission.StudyReportPreliminaryFinalize);
      })
);

const isReportAmendmentReasonMissing = computed(
  () =>
    currentTenant.isAmendmentReasonRequired &&
    latestReport.value.type === StudyReportType.Amendment &&
    reportContent.value !== null &&
    reportContent.value.amendmentReason.trim().length === 0
);

const isReportTemplateSelectorDropdownOpen = ref(false);

const { onReportHtmlUpdated, reportPdfBlob, reportPdfBlobUri } = useGeneratedReportPdf(
  isShowingGeneratedReportPdf
);

function showLatestReport(): void {
  isReviewing.value = false;
  userPin.value = "";
  selectedReportId.value = latestReport.value.id;
  reportProgress.value = 1;
}

const isCompletingReport = ref(false);

async function completeReport(signing: boolean): Promise<void> {
  if ((signing && isPINInvalid.value) || !reportPdfBlob.value) {
    return;
  }

  isCompletingReport.value = true;

  const pdf = await blobToBase64(reportPdfBlob.value);

  try {
    await axios.post(`/api/studies/${props.study.id}/reports/${latestReport.value.id}/complete`, {
      pdf,
      pin: signing ? userPin.value : undefined,
    });
  } catch (error) {
    const message = signing ? "Failed signing report" : "Failed approving preliminary report";
    addNotification({ type: "error", message: getRequestErrorMessage(error) ?? message });

    isCompletingReport.value = false;

    return;
  }

  latestReport.value.completedAt = new Date().toISOString();
  latestReport.value.completedById = currentUser.id;

  if (signing) {
    latestReport.value.isSigned = true;
  }

  addNotification({
    type: "info",
    message: signing ? "Signed report" : "Approved preliminary report",
  });

  isCompletingReport.value = false;
  isReviewing.value = false;

  if (signing) {
    userPin.value = "";

    // If the setting for clearing the assignee on signing a final report is turned on then
    // immediately clear the study's assignee to match what the backend just did
    if (
      currentTenant.isStudyAssigneeClearedWhenFinalReportCompleted &&
      latestReport.value.type !== StudyReportType.Amendment
    ) {
      // eslint-disable-next-line vue/no-mutating-props
      props.study.assignedUserId = null;
    }

    // After signing the preliminary report becomes a final report. Amendments stay as-is.
    if (latestReport.value.type === StudyReportType.Preliminary) {
      latestReport.value.type = StudyReportType.Final;
    }
  }
}

async function onSaveReportContent(): Promise<void> {
  try {
    await axios.patch(`/api/studies/${props.study.id}/reports/${latestReport.value.id}`, {
      content: reportContent.value,
    });
  } catch (error) {
    addNotification({ type: "error", message: "Failed saving report" });
    return;
  }

  addNotification({ type: "info", message: "Saved report" });
}

const debouncedReportContentUpdate = useDebounceFn(() => {
  void onSaveReportContent();
}, 1000);

async function blobToBase64(blob: Blob): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (): void => resolve((reader.result as string).split(",")[1]);
    reader.onerror = (): void => reject(Error("Failed converting blob to Base64"));
    reader.onabort = (): void => reject(new Error("FileReader aborted"));
    reader.readAsDataURL(blob);
  });
}

const isDeletingReport = ref(false);

function onReviewReport(): void {
  isReviewing.value = true;
  reportProgress.value++;
}

async function onDeleteReport(): Promise<void> {
  if (
    !confirm(
      `Are you sure you want to delete this ${
        latestReport.value.type === StudyReportType.Amendment ? "amendment" : "report"
      }?`
    )
  ) {
    return;
  }

  isDeletingReport.value = true;

  try {
    await axios.delete(`/api/studies/${props.study.id}/reports/${latestReport.value.id}`);
  } catch (error) {
    addNotification({ type: "error", message: "Failed deleting report" });
    return;
  } finally {
    isDeletingReport.value = false;
  }

  addNotification({ type: "info", message: "Deleted report" });

  emits("report-latest-deleted");
}

const {
  focusedSection,
  focusedSectionSentenceGroups,
  onSectionCommentFieldAction,
  onCloseSentenceLibrary,
} = useReportFocusedSection(() => {
  const structure = latestReportWithTemplateAndContent.value?.reportTemplateVersion.structure;

  if (!structure) {
    return undefined;
  }

  return {
    ...structure,
    sentenceGroups: {
      ...structure.sentenceGroups,
      ...tenantSentenceGroups, // Tenant's sentence groups should be added after in case of existing IDs
    },
  };
});
</script>

<style scoped lang="scss">
.report-pane {
  display: grid;
  place-items: stretch;
  grid-template-areas: "main";
  grid-area: 2 / 1;
  background-color: var(--bg-color-2);
  overflow-y: hidden;
}

.report-container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

iframe {
  border: none;
}

.toolbar-container {
  display: flex;
  flex-direction: column;
  border-top: 1px solid var(--border-color-1);
}

.toolbar {
  min-height: 48px;
  display: flex;
  align-items: center;
  padding: 0 8px;
}

.amendment-reason {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.amendment-reason-heading-container {
  display: flex;
  justify-content: space-between;
  padding: 8px;
}

.set-signature-prompt {
  flex: 1;
  text-align: right;
}

.user-settings-link {
  text-decoration: underline;
  font-weight: bold;
}

.toolbar-dropdown-container {
  flex: 0 1 200px;
  display: flex;
  align-items: center;
  gap: 8px;

  line-height: 1em;
}

.accent-icon {
  color: var(--accent-color-2);
}

:deep(.report-pane-select-report-template-popper) {
  background-color: var(--bg-color-4);
  padding: 0;
  border-radius: 0 0 var(--border-radius) var(--border-radius);
}

:deep(.report-sentence-library-popper) {
  background-color: var(--bg-color-3);
  padding: 0;

  position: absolute;
  width: 300px;
  left: 16px;
  top: 16px;
}

progress {
  height: 6px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
}

progress::-webkit-progress-value {
  background-color: var(--confirm-color-2);
  transition: width 0.5s ease;
}

progress::-webkit-progress-bar {
  background-color: #434343;
}

progress[value]::-moz-progress-bar {
  background-color: var(--confirm-color-2);
  transition: width 0.5s ease;
}
</style>
