<template>
  <DropdownWidget
    :model-value="modelValue"
    :background="getBackgroundFromVariant(variant)"
    :items="
      sortedReports.map((report, index) => ({
        value: report.id,
        text: getReportDropdownDisplayText(report, index),
      }))
    "
    data-testid="study-reports-dropdown"
    @update:model-value="(value) => emits('update:modelValue', value)"
  />
</template>

<script setup lang="ts">
import { computed } from "vue";
import { formatDateTime } from "../../../backend/src/shared/date-time-utils";
import { StudyReportType } from "../../../backend/src/studies/study-report-type";
import DropdownWidget, { DropdownBackground } from "../components/DropdownWidget.vue";
import { getSortedReports, StudyReportDescription } from "../utils/study-data";
import { StudyReportStatus } from "./report-status";

interface Props {
  modelValue: string;
  reports: StudyReportDescription[];
  variant?: StudyReportStatus;
}

interface Emits {
  (event: "close"): void;
  (event: "update:modelValue", reportId: string): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const sortedReports = computed(() => getSortedReports(props.reports));

function getBackgroundFromVariant(variant: StudyReportStatus | undefined): DropdownBackground {
  if (variant === undefined) {
    return "";
  }

  switch (variant) {
    case StudyReportStatus.AmendmentInProgress:
      return "purple";
    case StudyReportStatus.ReportFinalized:
      return "green";
    case StudyReportStatus.PreliminaryReportApproved:
    case StudyReportStatus.ReportInProgress:
      return "blue";
  }
}

function getReportDropdownDisplayText(report: StudyReportDescription, index: number): string {
  if (report.completedAt === null) {
    return "Current";
  }

  const timestamp = formatDateTime(report.completedAt.toString());

  const reportType = {
    [StudyReportType.Preliminary]: "Preliminary Report",
    [StudyReportType.Final]: "Final Report",
    [StudyReportType.Amendment]: "Amendment",
  }[report.type];

  return `#${props.reports.length - index}: ${reportType} on ${timestamp}`;
}
</script>
