<template>
  <BackButton
    back-destination-route-name="settings-integrations-sftp"
    button-text="Back to SFTP Settings"
  />

  <div class="settings-title">
    SFTP Integration: {{ integration.name }}

    <div style="font-size: 13px; font-weight: normal; display: contents">
      <ToggleSwitch v-model="integration.enabled" @update:model-value="updateIntegrationDebounced">
        <strong>Enabled</strong>
      </ToggleSwitch>
      <button
        style="margin-left: auto; font-size: 13px"
        type="button"
        data-testid="sftp-integration-delete-btn"
        @click="deleteIntegration"
      >
        Delete Integration
      </button>
    </div>
  </div>

  <div class="field">
    <b>Integration Name</b>
    <input
      v-model="integration.name"
      type="text"
      data-testid="sftp-name-input"
      @update:model-value="updateIntegrationDebounced"
    />
  </div>

  <div class="field">
    <b>Endpoint</b>
    <input
      v-model="integration.endpoint"
      type="text"
      data-testid="sftp-endpoint-input"
      @update:model-value="updateIntegrationDebounced"
    />
  </div>

  <div class="field">
    <b>Port</b>
    <input
      v-model="integration.port"
      type="number"
      data-testid="sftp-port-input"
      @update:model-value="updateIntegrationDebounced"
    />
  </div>

  <div class="field">
    <b>Username</b>
    <input
      v-model="integration.user"
      type="text"
      data-testid="sftp-user-input"
      @update:model-value="updateIntegrationDebounced"
    />
  </div>

  <div class="field">
    <b>Remote Target Prefix</b>
    <input
      v-model="integration.remoteTargetPrefix"
      type="text"
      data-testid="sftp-prefix-input"
      @update:model-value="updateIntegrationDebounced"
    />
  </div>

  <div class="field">
    <b>Private Key</b>
    <i>Redacted</i>
  </div>

  <div class="field">
    <b>Test this SFTP Integration</b>
    <p>
      Press the button below to send a test file using this SFTP integration. The file that is sent
      will have the name "<code>pulse-sftp-test-<i>TIMESTAMP</i>.txt</code>" and its content will be
      the word "<code>success</code>".
    </p>
    <p>
      Note: Full details of SFTP activity can be viewed in the
      <RouterLink to="/audit-logs" style="text-decoration: underline">audit logs</RouterLink>.
    </p>
    <button class="accented" style="width: min-content" @click="onTestSftpIntegration">
      Send Test File
    </button>
  </div>

  <ActivityOverlay v-if="activityText" :text="activityText" />
</template>

<script setup lang="ts">
import ToggleSwitch from "@/components/ToggleSwitch.vue";
import router from "@/router";
import { addNotification } from "@/utils/notifications";
import { useDebounceFn } from "@vueuse/core";
import axios, { type AxiosResponse } from "axios";
import { onMounted, ref } from "vue";
import type { SftpIntegrationGetOneResponseDto } from "../../../../../backend/src/integrations/sftp/dto/sftp-integration-get-one.dto";
import ActivityOverlay from "../../../components/ActivityOverlay.vue";
import BackButton from "../../components/BackButton.vue";

interface Props {
  id: string;
}

const props = defineProps<Props>();

const activityText = ref("");

const integration = ref<SftpIntegrationGetOneResponseDto>({
  id: "",
  name: "",
  endpoint: "",
  port: 0,
  user: "",
  enabled: false,
  tenantId: "",
  remoteTargetPrefix: "",
});

onMounted(async () => {
  activityText.value = "Loading";

  let response: AxiosResponse<SftpIntegrationGetOneResponseDto> | undefined = undefined;
  try {
    response = await axios.get<SftpIntegrationGetOneResponseDto>(
      `/api/integrations/sftp/${props.id}`
    );
  } catch {
    addNotification({ type: "error", message: `Failed loading SFTP integration data` });
    return;
  } finally {
    activityText.value = "";
  }

  integration.value = response.data;
});

const updateIntegrationDebounced = useDebounceFn(() => {
  void updateIntegration();
}, 1000);

async function updateIntegration(): Promise<void> {
  try {
    let params: Record<string, unknown> = {
      name: integration.value.name,
      endpoint: integration.value.endpoint,
      port: Number(integration.value.port),
      user: integration.value.user,
      enabled: integration.value.enabled,
      remoteTargetPrefix: integration.value.remoteTargetPrefix,
    };

    await axios.patch(`/api/integrations/sftp/${integration.value.id}`, params);
  } catch {
    addNotification({ type: "error", message: "Error updating SFTP integration" });
    return;
  }

  addNotification({ type: "info", message: "Updated SFTP integration" });
}

async function deleteIntegration(): Promise<void> {
  if (!confirm(`Are you sure you want to delete the integration "${integration.value.name}"?`)) {
    return;
  }

  activityText.value = "Deleting SFTP integration";

  try {
    await axios.delete(`/api/integrations/sftp/${integration.value.id}`);
  } catch {
    addNotification({ type: "error", message: "Error deleting SFTP integration" });
    return;
  } finally {
    activityText.value = "";
  }

  await router.push({ name: "settings-integrations-sftp" });

  addNotification({ type: "info", message: "Deleted SFTP integration" });
}

async function onTestSftpIntegration(): Promise<void> {
  activityText.value = "Sending test file";

  try {
    await axios.post(`/api/integrations/sftp/${integration.value.id}/test`);
  } catch {
    addNotification({ type: "error", message: "Failed sending test file over SFTP" });
    return;
  } finally {
    activityText.value = "";
  }

  addNotification({ type: "info", message: "Succeeded sending test file over SFTP" });
}
</script>

<style scoped lang="scss">
.field {
  display: grid;
  gap: 8px;
}

input {
  width: 300px;
}

p {
  margin: 0;
  max-width: 500px;
}
</style>
