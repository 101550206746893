<template>
  <div class="settings-title">Reporting</div>

  <div class="top-row">
    <FilterInput v-model="searchTerm" placeholder="Search" />

    <button
      data-testid="create-report-template"
      style="justify-self: end"
      class="accented"
      @click="showCreateReportTemplateModal"
    >
      Create Template
    </button>
  </div>

  <div v-if="!isLoading" class="report-templates-table">
    <div class="header">
      <div>Template Name</div>
      <div>Last Published</div>
      <div>Reports</div>
      <div>Enabled</div>
    </div>

    <div class="header-line" />

    <div
      v-for="reportTemplate in reportTemplates"
      :key="reportTemplate.id"
      class="grid-table-row"
      @click="onClickTemplate(reportTemplate.id)"
    >
      <div :data-testid="`report-template-${reportTemplate.name}`">{{ reportTemplate.name }}</div>
      <div>{{ formatRelativeTime(reportTemplate.lastPublishedAt) }}</div>
      <div>{{ reportTemplate.studyCount }}</div>
      <div style="display: grid; padding: 0; place-content: center" @click.stop>
        <ToggleSwitch
          v-model="reportTemplate.enabled"
          @update:model-value="onUpdateReportTemplate(reportTemplate.id, reportTemplate.enabled)"
        />
      </div>
    </div>
  </div>

  <LoadingIndicator v-else style="align-self: center" size="2x" />

  <Modal
    v-if="isCreateReportTemplateModalVisible"
    title="Create Report Template"
    :activity-text="isCreatingReportTemplate ? 'Creating template' : ''"
    @header-button-click="isCreateReportTemplateModalVisible = false"
    @enter-key-press="onCreateReportTemplate(newReportTemplateName)"
  >
    <div class="create-report-template-modal">
      <div class="field">
        <b>Template Name</b>
        <input
          ref="newReportTemplateNameInputElement"
          v-model="newReportTemplateName"
          data-testid="new-report-template-name"
        />
      </div>

      <button
        data-testid="create-report-template-confirm"
        :disabled="!isNewTemplateNameValid"
        class="accented"
        @click="onCreateReportTemplate(newReportTemplateName)"
      >
        Create Template
      </button>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import { addNotification } from "@/utils/notifications";
import { useFocus } from "@vueuse/core";
import axios, { type AxiosResponse } from "axios";
import { computed, onMounted, ref } from "vue";
import type { ReportTemplateCreateResponseDto } from "../../../backend/src/reporting/dto/report-template-create.dto";
import type { ReportTemplateGetManyResponseDto } from "../../../backend/src/reporting/dto/report-template-get-many.dto";
import { formatRelativeTime } from "../../../backend/src/shared/date-time-utils";
import FilterInput from "../components/FilterInput.vue";
import Modal from "../components/Modal.vue";
import ToggleSwitch from "../components/ToggleSwitch.vue";
import router from "../router";

const allReportTemplates = ref<ReportTemplateGetManyResponseDto>([]);

const isLoading = ref(false);

async function loadReportTemplates(): Promise<void> {
  let response: AxiosResponse<ReportTemplateGetManyResponseDto> | undefined = undefined;
  isLoading.value = true;

  try {
    response = await axios.get<ReportTemplateGetManyResponseDto>("/api/reporting/templates");
  } catch {
    addNotification({ type: "error", message: "Failed loading report templates" });
    return;
  } finally {
    isLoading.value = false;
  }

  allReportTemplates.value = response.data;
}

onMounted(loadReportTemplates);

const searchTerm = ref("");

const reportTemplates = computed(() =>
  allReportTemplates.value
    .filter((reportTemplate) =>
      reportTemplate.name.toLowerCase().includes(searchTerm.value.toLowerCase())
    )
    .sort((a, b) => a.name.localeCompare(b.name))
);

function showCreateReportTemplateModal(): void {
  isCreateReportTemplateModalVisible.value = true;
  newReportTemplateName.value = "";
}

const isCreateReportTemplateModalVisible = ref(false);
const newReportTemplateName = ref("");
const isCreatingReportTemplate = ref(false);

const isNewTemplateNameValid = computed(() => newReportTemplateName.value.trim().length !== 0);

const newReportTemplateNameInputElement = ref<HTMLInputElement>();
useFocus(newReportTemplateNameInputElement, { initialValue: true });

async function onCreateReportTemplate(name: string): Promise<void> {
  if (!isNewTemplateNameValid.value) {
    return;
  }

  isCreatingReportTemplate.value = true;

  try {
    // Create new template
    const newTemplate = await axios.post<ReportTemplateCreateResponseDto>(
      `/api/reporting/templates`,
      { name }
    );

    // Create an initial template version
    await axios.post(`/api/reporting/templates/${newTemplate.data.id}/versions`);

    await onClickTemplate(newTemplate.data.id);
  } catch (error) {
    addNotification({ type: "error", message: "Failed creating report template" });
    return;
  } finally {
    isCreatingReportTemplate.value = false;
  }

  isCreateReportTemplateModalVisible.value = false;

  addNotification({ type: "info", message: "Created new report template" });

  await loadReportTemplates();
}

async function onClickTemplate(reportTemplateId: string): Promise<void> {
  await router.push({
    name: "settings-reporting-templates-edit",
    params: { id: reportTemplateId },
  });
}

async function onUpdateReportTemplate(reportTemplateId: string, enabled: boolean): Promise<void> {
  try {
    await axios.patch(`/api/reporting/templates/${reportTemplateId}`, { enabled });
  } catch {
    addNotification({ type: "error", message: "Failed updating report template" });
    return;
  }

  addNotification({ type: "info", message: "Updated report template" });
}
</script>

<style scoped lang="scss">
.top-row {
  display: grid;
  grid-template-columns: 300px 1fr;
  align-items: center;
}

.report-templates-table {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 8px;

  .header {
    font-weight: bold;
    display: contents;
  }

  .header-line {
    grid-area: 2 / 1 / 2 / span 4;
    border-bottom: 2px solid var(--bg-color-3);
  }
}

.create-report-template-modal {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 400px;

  button {
    width: max-content;
    margin: 0 auto;
  }
}

.field {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
</style>
