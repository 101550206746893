<template>
  <Popper placement="bottom-start" :offset-distance="5" class="filter-dropdown-container">
    <FontAwesomeIcon
      :icon="['fas', 'filter-list']"
      class="filter-icon"
      :class="{ active: isActive }"
      size="lg"
    />

    <template #content>
      <div style="min-width: 180px">
        <div
          v-for="filter in filters"
          :key="filter.filterText"
          class="filter-option"
          style="margin-bottom: 0.5rem"
          @click="handleFilterClick(filter)"
        >
          <Checkbox :model-value="filter.value" variant="blue">
            {{ filter.filterText }}
          </Checkbox>
        </div>
        <div class="divider" />
        <div class="deselect" @click="handleDeselectAll">Deselect all</div>
      </div>
    </template>
  </Popper>
</template>
<script setup lang="ts">
import { StudyListStatusFilter } from "@/reporting/report-status";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { computed } from "vue";
import Checkbox from "../components/Checkbox.vue";
import Popper from "../components/Popper.vue";

interface Props {
  filters: StudyListStatusFilter[];
}

interface Emits {
  (event: "update:filters", newValue: StudyListStatusFilter[]): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const isActive = computed(() =>
  props.filters.some((filter: StudyListStatusFilter) => filter.value)
);

function handleDeselectAll(): void {
  emits(
    "update:filters",
    props.filters.map((f): StudyListStatusFilter => ({ ...f, value: false }))
  );
}

function handleFilterClick(filter: StudyListStatusFilter): void {
  emits(
    "update:filters",
    props.filters.map(
      (f: StudyListStatusFilter): StudyListStatusFilter =>
        f === filter ? { ...f, value: !f.value } : f
    )
  );
}
</script>

<style scoped lang="scss">
.filter-icon {
  color: #6d6d6d;
  cursor: pointer;
  padding: 0.3rem 0.3rem;
  border-radius: 2px;
  margin: 0.1rem;
  transition:
    color 0.2s,
    background-color 0.2s;

  &:hover {
    color: #aaaaaa;
    background-color: #2d2d2d;
  }

  &.active {
    color: #f1f2f2;
    background-color: var(--accent-color-blue);

    &:hover {
      color: white;
      background-color: #4779ff;
    }
  }
}

:deep(.filter-dropdown-container) {
  min-width: 200px;
}

.deselect {
  cursor: pointer;
  transition: color 0.2s;
  &:hover {
    color: #aaaaaa;
  }
}

.divider {
  margin: 0.5rem 0;
  border-top: 1px solid #6d6d6d;
}
</style>
