<template>
  <template v-if="gridItem.type === ClipsGridItemType.RegularClip">
    <template
      v-for="indicator in activeMeasurement.scrubberIndicators?.value"
      :key="indicator.label"
    >
      <div
        class="measurement-locator"
        :style="{
          left: `${
            100 * (((indicator.frame + 0.5) * gridItem.frameDuration) / gridItem.clipDuration)
          }%`,
          'background-color': `${indicator.color}`,
        }"
      />
      <div
        class="handle"
        :style="{
          left: `${
            100 * (((indicator.frame + 0.5) * gridItem.frameDuration) / gridItem.clipDuration)
          }%`,
          color: `${indicator.color}`,
        }"
      >
        {{ indicator.label }}
      </div>
    </template>
  </template>

  <div
    v-for="[left, measurementDetails] in framesWithMeasurements"
    :key="left"
    class="measurement-locator"
    :style="{ left: `${left}%` }"
  >
    <Tooltip placement="top">
      <div style="width: 12px; height: 12px" />

      <template #content>
        <div class="measurement-tooltip">
          <template v-for="{ id, name, value } of measurementDetails" :key="id">
            <strong>{{ name }}</strong>
            <div>{{ value }}</div>
          </template>
        </div>
      </template>
    </Tooltip>
  </div>
</template>

<script setup lang="ts">
import { isEqual } from "lodash";
import { computed } from "vue";
import {
  getMeasurementDisplayName,
  getStudyMeasurementDisplayValue,
} from "../../../../backend/src/measurements/measurement-display";
import Tooltip from "../../components/Tooltip.vue";
import { activeMeasurement } from "../../measurements/measurement-tool-state";
import { Study, StudyMeasurementValue } from "../../utils/study-data";
import {
  ClipsGridItem,
  ClipsGridItemType,
  CTClipsGridItem,
  RegularClipsGridItem,
} from "./clips-grid-item";

interface Props {
  study: Study;
  gridItem: ClipsGridItem;
}

const props = defineProps<Props>();

function calculateLeftPosition(measurementValue: StudyMeasurementValue): number | undefined {
  if (props.gridItem.type === ClipsGridItemType.RegularClip) {
    return calculateRegularClipPosition(measurementValue, props.gridItem);
  } else if (props.gridItem.type === ClipsGridItemType.CTClip) {
    return calculateCTClipPosition(measurementValue, props.gridItem);
  }

  return undefined;
}

function calculateRegularClipPosition(
  measurementValue: StudyMeasurementValue,
  gridItem: RegularClipsGridItem
): number | undefined {
  if (measurementValue.frame === null) {
    return undefined;
  }

  const framePosition = measurementValue.frame + 0.5;
  const frameDuration = gridItem.frameDuration;
  const clipDuration = gridItem.clipDuration;

  return 100 * ((framePosition * frameDuration) / clipDuration);
}

function calculateCTClipPosition(
  measurementValue: StudyMeasurementValue,
  gridItem: CTClipsGridItem
): number | undefined {
  if (!isEqual(gridItem.currentPlane.value.normal, measurementValue.plane?.normal)) {
    return undefined;
  }

  const measurementSliceNumber = measurementValue.plane?.point.find((p) => p !== 0);
  const sliceIndex = measurementValue.plane?.point.findIndex((p) => p !== 0);

  if (measurementSliceNumber === undefined || sliceIndex === undefined) {
    return undefined;
  }

  const maxSliceNumberInRAS = gridItem.threeVolume.value?.RASDimensions[sliceIndex];

  if (maxSliceNumberInRAS === undefined) {
    return undefined;
  }

  return 100 * ((maxSliceNumberInRAS - measurementSliceNumber + 0.5) / maxSliceNumberInRAS);
}

const framesWithMeasurements = computed(() => {
  const result = new Map<number, { id: string; name: string; value: string }[]>();

  for (const measurement of props.study.measurements) {
    for (const measurementValue of measurement.values) {
      if (measurementValue.studyClipId !== props.gridItem.clip?.id) {
        continue;
      }

      const left = calculateLeftPosition(measurementValue);
      if (left === undefined) {
        continue;
      }

      if (!result.has(left)) {
        result.set(left, []);
      }

      result.get(left)!.push({
        id: measurementValue.id,
        name: getMeasurementDisplayName(measurement, "unindexed"),
        value:
          getStudyMeasurementDisplayValue(
            { ...measurement, values: [measurementValue] },
            "unindexed"
          )?.fullText ?? "",
      });
    }
  }

  // Sort measurements by name
  for (const value of result.values()) {
    value.sort((a, b) => a.name.localeCompare(b.name));
  }

  return result;
});
</script>

<style scoped lang="scss">
.handle {
  top: 100%;
  position: absolute;
  transform: translateX(-50%);
  font-weight: bold;
  cursor: default;
}

.measurement-locator {
  z-index: 2;
  top: 0;
  bottom: 0;
  width: 12px;
  border-radius: var(--border-radius);
  transform: translateX(-6px);
  position: absolute;
  background-color: #0f0;
}

.measurement-tooltip {
  display: grid;
  gap: 2px 12px;
  grid-template-columns: max-content auto;
}
</style>
