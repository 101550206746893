import type { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { config, library } from "@fortawesome/fontawesome-svg-core";
import { faApple, faGoogle, faMicrosoft, faWindows } from "@fortawesome/free-brands-svg-icons";
import {
  faCalendar,
  faCircleCheck as faCircleCheckRegular,
} from "@fortawesome/free-regular-svg-icons";
import {
  faAlignCenter,
  faAlignLeft,
  faAlignRight,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowTurnUp,
  faArrowUp,
  faArrowsDownToLine,
  faArrowsUpDown,
  faArrowsUpToLine,
  faBars,
  faBold,
  faBolt,
  faBook,
  faBookMedical,
  faBullhorn,
  faCalculator,
  faCalendarDays,
  faCaretDown,
  faCaretUp,
  faChartLine,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleCheck,
  faCircleInfo,
  faCircleQuestion,
  faClock,
  faClockRotateLeft,
  faCloudArrowDown,
  faCompress,
  faCopy,
  faDownload,
  faEllipsis,
  faEnvelope,
  faEnvelopeOpenText,
  faExclamation,
  faExclamationCircle,
  faExpand,
  faEye,
  faEyeSlash,
  faFileExport,
  faFileLines,
  faFilePdf,
  faFilePen,
  faFilm,
  faFlag,
  faGear,
  faGripLinesVertical,
  faHandPointer,
  faHeartbeat,
  faHospitalUser,
  faHouse,
  faImage,
  faInfo,
  faItalic,
  faLink,
  faLinkSlash,
  faList,
  faListUl,
  faLock,
  faMicrophone,
  faNetworkWired,
  faPalette,
  faPaperclip,
  faPause,
  faPen,
  faPenFancy,
  faPlay,
  faPlug,
  faPlus,
  faProjectDiagram,
  faQuestion,
  faRedoAlt,
  faRepeat,
  faRightToBracket,
  faRotateLeft,
  faRuler,
  faSave,
  faScrewdriverWrench,
  faSearch,
  faShare,
  faSignOutAlt,
  faSort,
  faSpinner,
  faStepBackward,
  faStepForward,
  faSync,
  faTableList,
  faTimes,
  faTimesCircle,
  faTrash,
  faTriangleExclamation,
  faUndo,
  faUnlock,
  faUpRightFromSquare,
  faUpload,
  faUser,
  faUserLock,
  faUserSlash,
  faUsersGear,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import { faCircleHalf as fadCircleHalf } from "@fortawesome/pro-duotone-svg-icons";
import { faChevronsUp, faFilterList } from "@fortawesome/pro-solid-svg-icons";

export function setupIcons(): void {
  config.autoAddCss = false;

  library.add(
    faAlignCenter,
    faAlignLeft,
    faAlignRight,
    faArrowDown,
    faArrowLeft,
    faArrowRight,
    faGoogle as IconDefinition,
    faMicrosoft as IconDefinition,
    faApple as IconDefinition,
    faWindows as IconDefinition,
    faArrowsDownToLine,
    faArrowsUpDown,
    faArrowsUpToLine,
    faArrowTurnUp,
    faArrowUp,
    faBars,
    faBold,
    faBolt,
    faBook,
    faBookMedical,
    faBullhorn,
    faCaretDown,
    faCaretUp,
    faCalculator,
    faCalendar,
    faCalendarDays,
    faChartLine,
    faCheck,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faCircle,
    faCircleCheck,
    faCircleCheckRegular,
    fadCircleHalf as IconDefinition,
    faCircleInfo,
    faCircleQuestion,
    faClock,
    faClockRotateLeft,
    faCloudArrowDown,
    faCompress,
    faCopy,
    faDownload,
    faEllipsis,
    faEnvelope,
    faEnvelopeOpenText,
    faExclamation,
    faExclamationCircle,
    faExpand,
    faEye,
    faEyeSlash,
    faFileLines,
    faFileExport,
    faFilePdf,
    faFilePen,
    faFilm,
    faFilterList as IconDefinition,
    faFlag,
    faGear,
    faGripLinesVertical,
    faGripLinesVertical,
    faHandPointer,
    faHeartbeat,
    faHospitalUser,
    faHouse,
    faImage,
    faInfo,
    faItalic,
    faLink,
    faLinkSlash,
    faList,
    faListUl,
    faLock,
    faMicrophone,
    faNetworkWired,
    faPaperclip,
    faPalette,
    faPause,
    faPen,
    faPenFancy,
    faPlay,
    faPlug,
    faPlus,
    faProjectDiagram,
    faQuestion,
    faRedoAlt,
    faRepeat,
    faRotateLeft,
    faRightToBracket,
    faRuler,
    faSave,
    faScrewdriverWrench,
    faSearch,
    faShare,
    faSignOutAlt,
    faSort,
    faSpinner,
    faStepBackward,
    faStepForward,
    faSync,
    faTableList,
    faTimes,
    faTimesCircle,
    faTrash,
    faTriangleExclamation,
    faUndo,
    faUnlock,
    faUpload,
    faUpRightFromSquare,
    faUser,
    faUserLock,
    faUsersGear,
    faUserSlash,
    faX,
    faChevronsUp as IconDefinition,
    faFilterList as IconDefinition
  );
}
