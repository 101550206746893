<template>
  <AccordionRoot
    class="accordion-root"
    v-bind="forwardedProps"
    @update:model-value="emitUpdatedModelValue"
  >
    <slot />
  </AccordionRoot>
</template>

<script setup lang="ts">
import { AccordionRoot, AccordionRootProps, useForwardProps } from "radix-vue";

interface Emits {
  (event: "update:model-value", value: string[]): void;
}

const props = defineProps<AccordionRootProps>();
const emits = defineEmits<Emits>();

const forwardedProps = useForwardProps(props);

// Easier to just emit an array of strings in all cases
function emitUpdatedModelValue(value: string | string[] | undefined) {
  emits("update:model-value", value !== undefined ? [value].flat() : []);
}
</script>

<style scoped lang="scss">
.accordion-root {
  border-radius: 6px;
  width: 300px;
  background-color: var(--bg-color-2);
}
</style>
