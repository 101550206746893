<template>
  <a @click="inputElement?.click()">
    <FontAwesomeIcon icon="download" />
    <b>Import</b>

    <input ref="inputElement" hidden @click="showImportStudiesModal" />

    <Modal
      v-if="isImportStudiesModalVisible"
      title="Import Studies from DICOM Modality"
      :activity-text="activityText"
      @header-button-click="hideImportStudiesModal"
    >
      <div class="import-studies-modal">
        <div class="query-options">
          <div class="field">
            <b>Modality</b>
            <DropdownWidget
              v-model="selectedDicomEndpoint"
              :items="
                dicomEndpoints.map((endpoint) => ({ value: endpoint.id, text: endpoint.name }))
              "
            />
          </div>

          <div class="field">
            <b>Last Name</b>
            <input
              v-model="queryParams.patientLastName"
              placeholder="Last name"
              @keydown.enter="findStudies"
            />
          </div>

          <div class="field">
            <b>First Name</b>
            <input
              v-model="queryParams.patientFirstName"
              placeholder="First name"
              :disabled="queryParams.patientLastName.trim().length === 0"
              @keydown.enter="findStudies"
            />
          </div>

          <div class="field">
            <b>Patient ID</b>
            <input
              v-model="queryParams.patientId"
              style="flex: 1"
              placeholder="Patient ID"
              @keydown.enter="findStudies"
            />
          </div>

          <div class="field">
            <b>Study Date</b>
            <DatePicker
              v-model="queryParams.studyDateRange"
              is-range
              placeholder="Select date range"
            />
          </div>
        </div>

        <button
          class="find-studies-button accented"
          :disabled="!isFindStudiesButtonEnabled"
          @click="findStudies"
        >
          Find Studies
        </button>

        <div v-if="queryResults.length > 0" class="query-results-table">
          <div class="header">
            <span>Patient Name</span>
            <span>Patient ID</span>
            <span>Study Date</span>
            <span>Study Description</span>
            <span>Modality</span>
            <span>DICOMs</span>
            <div />
          </div>

          <div class="header-line" />

          <template v-for="result in queryResults" :key="result.id">
            <div class="grid-table-row" @click="loadExistingStudy(result)">
              <div>{{ formatDicomName(result.patientName, currentTenant.patientNameFormat) }}</div>
              <div>{{ result.patientId }}</div>
              <div>{{ formatDateTime(getStudyDateTime(result), { includeTime: true }) }}</div>
              <div>{{ result.studyDescription }}</div>
              <div>{{ result.modalities?.filter((m) => m !== "SR").join(", ") }}</div>
              <div>{{ result.relatedInstanceCount }}</div>
              <div
                class="import-button"
                :class="{
                  disabled: !isImportStudyButtonEnabled[result.studyInstanceUid],
                  highlight: !isQueryResultImported(result),
                }"
                @click.stop="importStudy(result)"
              >
                <Tooltip
                  :content="
                    isQueryResultImported(result)
                      ? 'This study is already in HeartLab'
                      : isImportStudyButtonEnabled[result.studyInstanceUid]
                        ? 'Import this study into HeartLab'
                        : 'Import of this study into HeartLab is underway'
                  "
                >
                  <FontAwesomeIcon
                    v-if="isQueryResultImported(result)"
                    icon="check"
                    class="imported-icon"
                  />
                  <FontAwesomeIcon v-else icon="download" />
                </Tooltip>
              </div>
            </div>
          </template>
        </div>
        <div v-if="isNoResultsMessageVisible" class="no-results">Search returned no results</div>
      </div>
    </Modal>
  </a>
</template>

<script setup lang="ts">
import DatePicker from "@/components/DatePicker.vue";
import DropdownWidget from "@/components/DropdownWidget.vue";
import router from "@/router";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import axios, { AxiosResponse } from "axios";
import { computed, ref } from "vue";
import { DicomEndpointQueryResponseDto } from "../../../backend/src/dicom/dto/dicom-endpoint-query.dto";
import { formatDateTime } from "../../../backend/src/shared/date-time-utils";
import { formatDicomName } from "../../../backend/src/shared/dicom-helpers";
import { getStudyDateTime } from "../../../backend/src/studies/study-time";
import { currentTenant } from "../auth/current-session";
import { useDicomEndpoints } from "../utils/dicom-endpoints";
import { addNotification } from "../utils/notifications";
import { getRequestErrorMessage } from "../utils/request-helpers";
import Modal from "./Modal.vue";
import Tooltip from "./Tooltip.vue";

const activityText = ref("");

// Display
const inputElement = ref<HTMLInputElement | null>(null);
const isImportStudiesModalVisible = ref(false);

const selectedDicomEndpoint = ref("");
const dicomEndpoints = useDicomEndpoints();
const isFindStudiesButtonEnabled = computed(() => selectedDicomEndpoint.value !== "");

// Query
interface QueryParams {
  patientFirstName: string;
  patientLastName: string;
  patientId: string;
  studyDateRange: {
    start: Date | null;
    end: Date | null;
  } | null;
}

const queryParams = ref<QueryParams>({
  patientFirstName: "",
  patientLastName: "",
  patientId: "",
  studyDateRange: null,
});
const queryResults = ref<DicomEndpointQueryResponseDto[]>([]);
const isImportStudyButtonEnabled = ref<Record<string, boolean>>({});

async function findStudies(): Promise<void> {
  activityText.value = "Searching";

  const { patientId, patientFirstName, patientLastName, studyDateRange } = queryParams.value;

  let response: AxiosResponse<DicomEndpointQueryResponseDto[]> | undefined = undefined;
  try {
    response = await axios.post<DicomEndpointQueryResponseDto[]>(
      `/api/dicom-endpoints/${selectedDicomEndpoint.value}/query`,
      {
        patientId,
        patientFirstName,
        patientLastName,
        studyDateFrom: studyDateRange?.start,
        studyDateTo: studyDateRange?.end,
      }
    );
  } catch (error) {
    isNoResultsMessageVisible.value = false;
    addNotification({ type: "error", message: "Failed searching for studies" });

    return;
  } finally {
    activityText.value = "";
  }

  queryResults.value = response.data;

  // Enable the import button for all studies, it is then disabled if the import button is clicked
  isImportStudyButtonEnabled.value = {};
  for (const data of response.data) {
    isImportStudyButtonEnabled.value[data.studyInstanceUid] = true;
  }

  isNoResultsMessageVisible.value = response.data.length === 0;
}

const isNoResultsMessageVisible = ref(false);

function isQueryResultImported(queryResult: DicomEndpointQueryResponseDto): boolean {
  return queryResult.existingStudyClipCount >= queryResult.relatedInstanceCount;
}

async function importStudy(queryResult: DicomEndpointQueryResponseDto): Promise<void> {
  if (
    isQueryResultImported(queryResult) ||
    !isImportStudyButtonEnabled.value[queryResult.studyInstanceUid]
  ) {
    return;
  }

  activityText.value = "Initiating import";

  const studyInstanceUid = queryResult.studyInstanceUid;
  isImportStudyButtonEnabled.value[studyInstanceUid] = false;

  try {
    await axios.post(`/api/dicom-endpoints/${selectedDicomEndpoint.value}/import-study`, {
      studyInstanceUid,
    });
  } catch (error) {
    isImportStudyButtonEnabled.value[studyInstanceUid] = true;

    addNotification({
      type: "error",
      message: getRequestErrorMessage(error) ?? "Failed initiating study import",
    });

    return;
  } finally {
    activityText.value = "";
  }

  addNotification({ type: "info", message: "Study import initiated" });
}

function showImportStudiesModal(): void {
  queryResults.value = [];
  isNoResultsMessageVisible.value = false;

  isImportStudiesModalVisible.value = true;
}

function hideImportStudiesModal(): void {
  isImportStudiesModalVisible.value = false;
}

async function loadExistingStudy(queryResult: DicomEndpointQueryResponseDto): Promise<void> {
  if (queryResult.existingStudyId === null) {
    return;
  }

  await router.push({ name: "study-view", params: { id: queryResult.existingStudyId } });

  hideImportStudiesModal();
}
</script>

<style scoped lang="scss">
.import-studies-modal {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: 60vh;
}

.query-options {
  display: grid;
  grid-template-columns: 160px 1fr 1fr 1fr 250px;
  gap: 16px;
  align-items: start;

  input {
    height: 16px;
  }
}

.field {
  display: grid;
  gap: 8px;
}

.find-studies-button {
  width: max-content;
  align-self: center;
}

.query-results-table {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr auto auto;
  overflow-y: auto;
  margin-top: 8px;

  .header {
    font-weight: bold;
    display: contents;
    border-bottom: 1px solid var(--accent-color-1);

    > * {
      padding-right: 16px;
      position: sticky;
      top: 0;
      height: 24px;
      background-color: var(--bg-color-2);
    }
  }

  .header-line {
    grid-area: 2 / 1 / 2 / span 7;
    background: var(--bg-color-4);
    height: 2px;
    position: sticky;
    top: 24px;
  }
}

.import-button {
  width: 40px;
  display: grid;
  place-content: center;
  padding: 0;

  &.highlight:not(.disabled) {
    cursor: pointer;

    &:hover {
      background-color: var(--bg-color-4);
    }
  }

  &.disabled {
    svg {
      opacity: 0.3;
    }
  }
}

.imported-icon {
  color: var(--confirm-color-2);
}

.no-results {
  font-style: italic;
  align-self: center;
  margin: 8px 0;
}
</style>
