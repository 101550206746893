// Common utils shared by primative UI components

import { omit } from "lodash";
import type { HTMLAttributes } from "vue";
import { computed } from "vue";

/**
 * The purpose of this function is to create a reactive property that contains all the
 * props passed to a component, except for the class prop.
 * This is useful in scenarios where you want to pass most props down to a child component or
 * element, but handle the class prop separately
 * (which is a common pattern in Vue components, especially when dealing with styling).
 *
 * @param props - The props object to delegate.
 * @returns A computed property that delegates props by removing the `class` property.
 */
export function usePropsExcludingClass<T extends { class?: HTMLAttributes["class"] }>(props: T) {
  return computed(() => omit(props, "class"));
}
