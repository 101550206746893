<template>
  <div ref="rootElement" :data-test-is-popper-open="isPopperOpen" @keydown.escape="onEscapePressed">
    <div
      ref="dropdown"
      class="report-dropdown"
      :class="{ open: isPopperOpen }"
      tabindex="0"
      data-testid="report-dropdown"
      @click="onToggleDropdown"
      @keypress.enter.prevent="onEnterKeyPressed"
      @keydown.arrow-down.stop.prevent="onArrowKeyPressed"
      @keydown.arrow-up.stop.prevent="onArrowKeyPressed"
      @keydown.tab="closePopper"
    >
      <span class="content rpt-text-normal" :style="getFieldStylingCSS(field)">
        {{
          modelValue
            .map((optionId) => items.find((i) => i.value === optionId))
            .map((option) => {
              if (option === undefined) {
                return; // This should never happen
              }

              return option.value === "custom" ? customText : option.text;
            })
            .filter(Boolean)
            .join(", ")
        }}
      </span>

      <FontAwesomeIcon :icon="isPopperOpen ? 'chevron-up' : 'chevron-down'" />
    </div>

    <Transition name="fade">
      <div v-if="isPopperOpen" ref="popper" class="report-dropdown-popper">
        <OverlayScrollbar style="max-height: 400px; width: 100%" :late-destroy="true">
          <div class="report-dropdown-popper-content">
            <div
              v-for="(item, index) in items"
              :key="item.value"
              class="popper-item"
              :class="{ highlighted: index === highlightedItemIndex }"
              @click.stop="onItemClick(item)"
            >
              <ReportCheckbox
                :data-testid="`rpt-dropdown-item-${item.text}`"
                :class="{ selected: modelValue.includes(item.value) }"
                :model-value="modelValue.includes(item.value)"
              >
                <span
                  class="text rpt-text-normal"
                  :class="{ selected: modelValue.includes(item.value) }"
                >
                  {{ item.text === "" ? "—" : item.text }}
                </span>
              </ReportCheckbox>
            </div>

            <div
              v-if="modelValue.includes('custom')"
              class="rpt-fancy-input"
              style="background: white"
            >
              <ResizingTextbox
                ref="customTextResizingTextbox"
                v-model="customText"
                class="rpt-text-normal"
                :scale-factor="scaleFactor"
                @keypress.enter.prevent="closeAndFocus"
                @keydown.arrow-up.prevent="onCustomTextboxUpArrowKeyPressed"
              />
            </div>
          </div>
        </OverlayScrollbar>
      </div>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import ResizingTextbox from "@/components/ResizingTextbox.vue";
import { autoUpdate, computePosition } from "@floating-ui/dom";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { onClickOutside, useElementSize, useFocus } from "@vueuse/core";
import { computed, nextTick, onUnmounted, ref } from "vue";
import type {
  ReportSectionCommonFieldStructure,
  ReportSectionDropdownFieldStructure,
} from "../../../backend/src/reporting/report-structure";
import { clamp } from "../../../backend/src/shared/math-utils";
import OverlayScrollbar from "../components/OverlayScrollbar.vue";
import ReportCheckbox from "./ReportCheckbox.vue";
import { getFieldStylingCSS } from "./report-content";

interface Props {
  modelValue: string[];
  customText: string;
  field: ReportSectionCommonFieldStructure & ReportSectionDropdownFieldStructure;
  enabled: boolean;
  scaleFactor: number;
  initialFocus?: boolean;
}

interface Emits {
  (event: "update:modelValue", newValue: string[]): void;
  (event: "update:customText", newValue: string): void;
  (event: "enter"): void;
}

const props = withDefaults(defineProps<Props>(), {
  initialFocus: false,
});
const emits = defineEmits<Emits>();

const isPopperOpen = ref(false);

const rootElement = ref<HTMLElement | null>(null);
const dropdown = ref<HTMLElement | null>(null);
const popper = ref<HTMLElement | null>(null);

const customTextResizingTextbox = ref<{ isFocused(): boolean; focus: () => void } | null>(null);

const { width: dropdownWidth } = useElementSize(dropdown);

const { focused: dropdownFocused } = useFocus(dropdown, { initialValue: props.initialFocus });

const items = computed(() =>
  props.field.options.map((option) => ({ value: option.id, text: option.name }))
);

const customText = computed({
  get() {
    return props.customText;
  },
  set(newValue: string) {
    emits("update:customText", newValue);
  },
});

let cleanupFloatingUI: (() => void) | undefined = undefined;
onUnmounted(() => cleanupFloatingUI?.());

function onToggleDropdown(): void {
  if (isPopperOpen.value) {
    closePopper();
    return;
  }

  const firstSelectedItem = items.value.findIndex((item) => props.modelValue.includes(item.value));
  highlightedItemIndex.value = firstSelectedItem === -1 ? 0 : firstSelectedItem;

  // If custom text is being specified then set focus to that textbox
  if (props.modelValue.includes("custom")) {
    void nextTick(() => void nextTick(() => customTextResizingTextbox.value?.focus()));
  }

  isPopperOpen.value = true;

  void nextTick(createPopper);
}

const highlightedItemIndex = ref(0);

function onEnterKeyPressed(): void {
  if (!isPopperOpen.value) {
    onToggleDropdown();
    return;
  }

  const item = items.value[highlightedItemIndex.value];
  if (props.modelValue.includes(item.value) && !props.field.isMultiSelect) {
    closePopper();
    return;
  }

  onItemClick(item);
  emits("enter");
}

function closeAndFocus(): void {
  // Close the dropdown and focus on the dropdown
  dropdownFocused.value = true;
  closePopper();
}

function onCustomTextboxUpArrowKeyPressed(): void {
  dropdownFocused.value = true;
  highlightedItemIndex.value = Math.max(highlightedItemIndex.value - 1, 0);
}

function onArrowKeyPressed(event: KeyboardEvent): void {
  if (!isPopperOpen.value) {
    onToggleDropdown();
    return;
  }

  if (event.key === "ArrowDown") {
    highlightedItemIndex.value++;
  } else if (event.key === "ArrowUp") {
    highlightedItemIndex.value--;
  }

  highlightedItemIndex.value = clamp(highlightedItemIndex.value, 0, items.value.length - 1);
}

function onItemClick(item: { value: string; text: string }): void {
  if (item.value === "custom") {
    void nextTick(() => void nextTick(() => customTextResizingTextbox.value?.focus()));
  }

  if (props.field.isMultiSelect) {
    if (props.modelValue.includes(item.value)) {
      emits(
        "update:modelValue",
        props.modelValue.filter((i) => i !== item.value)
      );
    } else {
      emits(
        "update:modelValue",
        [...props.modelValue, item.value].sort(
          (a, b) =>
            items.value.findIndex((i) => i.value === a) -
            items.value.findIndex((j) => j.value === b)
        )
      );
    }
  } else {
    emits("update:modelValue", props.modelValue[0] !== item.value ? [item.value] : [""]);
    isPopperOpen.value = item.value === "custom";
  }

  highlightedItemIndex.value = items.value.findIndex((i) => i.value === item.value);
}

function createPopper(): void {
  if (dropdown.value === null || popper.value === null) {
    return;
  }

  const cleanup = autoUpdate(dropdown.value, popper.value, () => {
    if (dropdown.value === null || popper.value === null) {
      return;
    }

    void computePosition(dropdown.value, popper.value, {
      placement: "bottom-end",
    }).then(({ x, y }) => {
      if (popper.value === null) {
        return;
      }

      popper.value.style.left = `${x}px`;
      popper.value.style.top = `${y}px`;
    });
  });

  cleanupFloatingUI = (): void => {
    cleanup();
    cleanupFloatingUI = undefined;
  };
}

function onEscapePressed(event: KeyboardEvent): void {
  if (isPopperOpen.value || customTextResizingTextbox.value?.isFocused() === true) {
    event.stopPropagation();
    closePopper();
  }

  if (customTextResizingTextbox.value?.isFocused() === true) {
    dropdownFocused.value = true;
  }
}

function closePopper(): void {
  isPopperOpen.value = false;
  cleanupFloatingUI?.();
}

onClickOutside(rootElement, closePopper);
</script>

<style scoped lang="scss">
.report-dropdown {
  display: flex;
  align-items: center;
  gap: 0.8em;
  min-height: 2em;
  color: var(--report-text-color-1);
  background-color: var(--report-widget-bg-color);
  padding: 0.2em 0.4em;
  cursor: pointer;
  border-radius: 0.2em;
  transition: background-color 0.1s ease;

  .content {
    flex: 1;
    overflow: hidden;
    word-wrap: break-word;
  }

  &:hover,
  &.open {
    color: var(--report-text-color-2);
    background-color: var(--report-widget-bg-color-hover);
  }

  &.open {
    border-radius: 0.2em 0.2em 0 0;
  }

  &:focus {
    outline: 0.1em solid var(--report-widget-outline-color-focused);
  }
}

.report-dropdown-popper {
  position: absolute;
  z-index: 1;
  background-color: var(--report-widget-bg-color);
  border-radius: 0 0 0.2em 0.2em;
  padding: 0.4em;
  box-shadow: rgba(0, 0, 0, 0.35) 0.2em 0.2em 0.2em;
  min-width: v-bind("`${dropdownWidth}px`");
  display: flex;
  width: max-content;
  max-width: 28em;
}

.report-dropdown-popper-content {
  display: flex;
  flex-direction: column;
  gap: 0.4em;
}

.popper-item {
  cursor: pointer;
  color: var(--report-text-color-1);
  border-radius: 0.2em;

  &:hover,
  &.selected {
    color: var(--report-text-color-2);
  }

  &.highlighted {
    background: whitesmoke;
  }

  .text {
    transition: color 100ms ease;

    &.selected {
      color: var(--report-text-color-2);
    }
  }
}
</style>
