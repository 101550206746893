import axios from "axios";
import type { WebGLRenderer } from "three";
import type { Volume } from "three/examples/jsm/Addons.js";
import type { ComputedRef, Ref, ShallowRef } from "vue";
import { computed, nextTick, ref, shallowRef, watch } from "vue";
import {
  createPlaneFromSliceDirectionAndNumber,
  createRegionForCTSeries,
  getDirectionForPlane,
} from "../../../../backend/src/measurements/measurement-plane";
import { NRRDProcessState } from "../../../../backend/src/studies/study-clip-processed-files";
import type { MeasurementPlane } from "../../../../backend/src/studies/study-clip-region";
import { activeMeasurement } from "../../measurements/measurement-tool-state";
import type { Study, StudyClip, StudySeries } from "../../utils/study-data";
import type { ClipViewerMeasurementLabel } from "../clip-viewer-label";
import type { CanvasContainerRect } from "../clip-viewer/clip-renderer-2d";
import type { CTClipsGridItem } from "../clip-viewer/clips-grid-item";
import { useStudyViewImageData } from "../study-view-image-data";
import { isCT3DSeries } from "./ct-helpers";
import {
  convertRASDimensionToSliceNumber,
  convertSliceNumberToRASDimension,
  createCTRenderer,
  type CTRenderer,
} from "./ct-renderer";
import { ctSettings } from "./ct-settings";
import { CTSliceDirection } from "./ct-slice-direction";

/**
 * A CTModel is responsible for managing the state of a CT viewer. On creation it requests a volume
 * to be loaded via the study view image data store as well as a CTRenderer which will be assigned
 * to the WebGL canvas to display the slices on. A CTModel holds all information about the current
 * state of the slice being displayed such as windowing & slice number/direction and requests a
 * redraw from the CTRenderer when these change.
 */
export interface CTModel {
  /** The series of the CT that is being displayed */
  readonly series: StudySeries;

  /** The faked clip representing the series that is being displayed. */
  readonly clip: StudyClip;

  /** The Three.js volume object representing this CT series. Will be null if not yet loaded. */
  threeVolume: ShallowRef<Volume | null>;

  /** The slice plane that is being displayed from this CT. */
  sliceDirection: Ref<CTSliceDirection>;

  /**
   * The current slice number being displayed in the LPS coordinate system.
   *
   * NOTE: it may potentially be preferable to take slice numbers in the RAS coordinate system.
   * This would mean that each slice in every direction would be 1mm apart rather than the actual
   * number of slices in each direction, which can vary significantly more and provides less idea
   * of the actual physical size of the CT scan. Needs investigation of standard CT products.
   */
  sliceNumber: Ref<number>;

  /**
   * The slice number in the RAS coordinate system. This is the number of millimeters from the
   * origin in the direction of the slice.
   */
  rasSliceNumber: ComputedRef<number>;

  /**
   * The maximum slice number that can be obtained from this CT & slice direction, i.e. the value
   * of the dimension that is being sliced (e.g. xy slice: size in the z-direction).
   */
  maxSliceNumber: ComputedRef<number>;

  /** The current plane that is being displayed in the CT series. Used for measurements. */
  currentPlane: ComputedRef<MeasurementPlane>;

  /** The aspect ratio of the current view in the CT series, based on the slice direction */
  aspectRatio: ComputedRef<number>;

  /** Whether the CT series is loaded and ready to view. */
  isLoading: Ref<boolean>;

  /** The text to display while the CT NRRD loads in */
  loadingText: ComputedRef<string>;

  /** The measurement labels to display on this CT */
  measurementLabels: ComputedRef<ClipViewerMeasurementLabel[]>;

  /** Steps the slice number by the given number of frames/slices to step. */
  onStepFrame(delta: number): void;

  /** Sets the current state of the CT viewer to the provided plane */
  onJumpToPlane(plane: MeasurementPlane): Promise<void>;

  /**
   * Provides a Three WebGLRenderer to this model. This is so the renderer can be given after the
   * model is created, which instantiates the CTViewer and creates the canvas that the renderer
   * is linked to.
   */
  provideRenderer(args: {
    gridItem: CTClipsGridItem;
    webglRenderer: WebGLRenderer;
    canvasRect: CanvasContainerRect;
    measurementCanvas: HTMLCanvasElement;
    measurementCtx: CanvasRenderingContext2D;
    crosshairsCanvas: HTMLCanvasElement;
    crosshairsCtx: CanvasRenderingContext2D;
  }): void;

  /** Actions to perform when the CT model receives a mousedown event */
  onCanvasMouseDown(event: MouseEvent): Promise<void>;

  /** Actions to perform when the CT model receives a mousemove event */
  onCanvasMouseMove(event: MouseEvent): void;

  /** Actions to perform when the CT model receives a mouseup event */
  onCanvasMouseUp(): void;

  /** Destroys the model, cleaning up any watchers or other resources. */
  destroy(): void;
}

// eslint-disable-next-line max-statements
export function createCTModel(study: Study, series: StudySeries): CTModel {
  const studyViewImageData = useStudyViewImageData();

  const maxSliceNumber = computed(() => calculateMaxSliceNumber(series, sliceDirection.value));

  const sliceDirection = ref<CTSliceDirection>(CTSliceDirection.Axial);
  const sliceNumber = ref<number>(Math.floor(maxSliceNumber.value / 2));

  const rasSliceNumber = computed(() => {
    const rasNumber = convertSliceNumberToRASDimension(
      threeVolume.value,
      model.sliceDirection.value,
      model.sliceNumber.value
    );

    return rasNumber;
  });

  const currentPlane = computed(() => {
    const plane = createPlaneFromSliceDirectionAndNumber(
      model.sliceDirection.value,
      rasSliceNumber.value
    );

    return plane;
  });

  const isLoading = ref(!studyViewImageData.isThreeVolumeForSeriesLoaded(study.id, series.id));
  const loadProgress = ref(0);

  const loadingText = computed(() => {
    if (series.nrrdProcessState === NRRDProcessState.Processing) {
      return "CT series is being processed...";
    }

    return isLoading.value
      ? `Loading ${series.seriesDescription}... ${Math.floor(loadProgress.value * 100)}%`
      : "";
  });

  const clip = series.clips[0];

  const threeVolume: ShallowRef<Volume | null> = shallowRef(null);
  let renderer: CTRenderer | null = null;
  let onProvideRenderer: (() => void) | null = null;

  const onVolumeLoadActions: (() => void)[] = [];

  function onStepFrame(delta: number): void {
    sliceNumber.value = Math.max(0, Math.min(sliceNumber.value + delta, maxSliceNumber.value - 1));
  }

  async function onJumpToPlane(plane: MeasurementPlane): Promise<void> {
    async function performJump() {
      sliceDirection.value = getDirectionForPlane(plane);

      // Wait for the slice direction to update before updating the slice number so the scrubber gets
      // placed in the correct position
      await nextTick();
      sliceNumber.value = convertRASDimensionToSliceNumber(
        threeVolume.value,
        sliceDirection.value,
        plane.point.find((p) => p !== 0) ?? 0
      );
    }

    if (threeVolume.value !== null) {
      await performJump();
    } else {
      onVolumeLoadActions.push(() => void performJump());
    }
  }

  async function onCanvasMouseDown(event: MouseEvent): Promise<void> {
    await renderer?.onCanvasMouseDown(event);
  }

  function onCanvasMouseMove(event: MouseEvent): void {
    renderer?.onCanvasMouseMove(event);
  }

  function onCanvasMouseUp(): void {
    renderer?.onCanvasMouseUp();
  }

  const aspectRatio = computed(() => {
    if (threeVolume.value === null) {
      return 1;
    }

    const xDimension =
      threeVolume.value.RASDimensions[sliceDirection.value === CTSliceDirection.Sagittal ? 0 : 1];
    const yDimension =
      threeVolume.value.RASDimensions[sliceDirection.value === CTSliceDirection.Axial ? 1 : 2];

    return xDimension / yDimension;
  });

  const measurementLabels = computed(
    () => renderer?.measurementsRenderer.measurementLabels.value ?? []
  );

  const model = {
    clip,
    series,
    threeVolume,
    sliceDirection,
    sliceNumber,
    rasSliceNumber,
    maxSliceNumber,
    currentPlane,
    aspectRatio,
    isLoading,
    loadingText,
    measurementLabels,

    onCanvasMouseDown,
    onCanvasMouseMove,
    onCanvasMouseUp,
    onStepFrame,
    onJumpToPlane,
  };

  function provideRenderer(args: {
    gridItem: CTClipsGridItem;
    webglRenderer: WebGLRenderer;
    canvasRect: CanvasContainerRect;
    measurementCanvas: HTMLCanvasElement;
    measurementCtx: CanvasRenderingContext2D;
    crosshairsCanvas: HTMLCanvasElement;
    crosshairsCtx: CanvasRenderingContext2D;
  }) {
    renderer = createCTRenderer({
      ...args,
      study,
      model,
    });

    onProvideRenderer?.();
  }

  function onNRRDLoadProgress(progress: ProgressEvent): void {
    loadProgress.value = progress.loaded / progress.total;
  }

  if (series.nrrdProcessState === NRRDProcessState.Completed) {
    void studyViewImageData
      .getThreeVolumeForSeries(study.id, series.id, onNRRDLoadProgress)
      .then((volume) => {
        threeVolume.value = volume;

        onProvideRenderer = () => {
          renderer?.loadVolume(volume);
          isLoading.value = false;
          onProvideRenderer = null;
        };

        if (renderer) {
          onProvideRenderer();
        }
      });
  } else if (series.nrrdProcessState === NRRDProcessState.NotStarted) {
    // Process all NRRD files for the study when they open a CT if they aren't already processed
    for (const seriesToProcess of study.series) {
      if (
        seriesToProcess.nrrdProcessState === NRRDProcessState.NotStarted &&
        isCT3DSeries(seriesToProcess, seriesToProcess.clips[0])
      ) {
        seriesToProcess.nrrdProcessState = NRRDProcessState.Processing;
      }
    }

    void axios.post(`/api/studies/${study.id}/process-nrrd`);
  }

  watch(threeVolume, () => {
    onVolumeLoadActions.forEach((action) => action());
  });

  const unwatchSlicingAndWindowing = watch(
    [sliceNumber, ctSettings.windowLevel, ctSettings.windowWidth],
    () => {
      // Update the faked region if the slice number changes
      if (threeVolume.value !== null) {
        const rasSliceNumber = convertSliceNumberToRASDimension(
          threeVolume.value,
          sliceDirection.value,
          sliceNumber.value
        );

        activeMeasurement.value.updateRegion?.(
          createRegionForCTSeries(threeVolume.value, sliceDirection.value, rasSliceNumber)
        );
      }

      renderer?.displayCurrentSlice();
    }
  );

  const unwatchSliceDirection = watch(sliceDirection, (newDirection, oldDirection) => {
    sliceNumber.value =
      (sliceNumber.value / calculateMaxSliceNumber(series, oldDirection)) *
      calculateMaxSliceNumber(series, newDirection);

    renderer?.updateCameraPositionAndOrientation();
    renderer?.displayCurrentSlice();
  });

  function destroy() {
    unwatchSlicingAndWindowing();
    unwatchSliceDirection();
  }

  return {
    ...model,
    provideRenderer,
    onCanvasMouseDown,
    onCanvasMouseMove,
    destroy,
  };
}

function calculateMaxSliceNumber(series: StudySeries, sliceDirection: CTSliceDirection): number {
  if (sliceDirection === CTSliceDirection.Axial) {
    return series.clips[0].frameCount ?? 0;
  }

  return sliceDirection === CTSliceDirection.Coronal
    ? series.clips[0].height ?? 0
    : series.clips[0].width ?? 0;
}
