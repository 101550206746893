import Flatten from "@flatten-js/core";
import {
  dopplerSlopeAssociatedMeasurements,
  efAssociatedMeasurements,
  velocityAssociatedMeasurements,
  volumeAssociatedMeasurements,
  vtiAssociatedMeasurements,
} from "../../../backend/src/measurements/associated-measurements";
import { getMeasurementDisplayName } from "../../../backend/src/measurements/measurement-display";
import {
  isCustomMeasurement,
  MeasurementName,
  measurementNames,
} from "../../../backend/src/measurements/measurement-names";
import { formatDateTime } from "../../../backend/src/shared/date-time-utils";
import type { Study, StudyMeasurement, StudyMeasurementValue } from "../utils/study-data";
import type { UserListEntry } from "../utils/users-list";
import type { MeasurementToolRecreationDetails } from "./tools/measurement-tool";

/**
 * Returns whether the given measurement is able to be indexed.
 */
export function isMeasurementIndexable(measurementName: MeasurementName): boolean {
  return measurementNames[measurementName].indexedName !== undefined;
}

/** The distance away from measurements to place their labels, in pixels */
export const MEASUREMENT_LABEL_OFFSET = 15;

/**
 * Returns the subset of the passed measurement names that match the given filter string. This tries
 * a couple of different methods for matching the measurement names to the filter string.
 */
export function filterMeasurementNames(
  names: MeasurementName[],
  filter: string
): MeasurementName[] {
  const tokens = filter
    .split(" ")
    .filter((token) => token.length !== 0)
    .map((token) => token.toLowerCase());

  if (tokens.length === 0) {
    return names.filter((m) => !isCustomMeasurement(m));
  }

  return names.filter((name) => {
    // Custom measurements are ignored
    if (isCustomMeasurement(name)) {
      return false;
    }

    for (const token of tokens) {
      // Try and match against the enum value directly as this has a lot of useful words in it
      // written out in full
      if (name.toLowerCase().includes(token)) {
        continue;
      }

      // Try and match against the display name of the measurement, which is typically an
      // abbreviated name more commonly used in clinical practice than the measurement enum name
      if (getMeasurementDisplayName(name, "unindexed").toLowerCase().includes(token)) {
        continue;
      }

      return false;
    }

    return true;
  });
}

/**
 * Given a measurement creation batch ID, returns the measurement value in that batch that was the
 * main measurement value, i.e. it was not an associated measurement.
 */
export function findMainMeasurementAndValueForBatch(
  study: Study,
  creationBatchId: string
): { measurement: StudyMeasurement; value: StudyMeasurementValue } | undefined {
  const valuesInBatch = study.measurements.flatMap((m) =>
    m.values
      .filter((v) => v.measurementCreationBatchId === creationBatchId)
      .map((v) => ({ ...v, name: m.name, measurementId: m.id }))
  );

  // If there's only one measurement in the batch, it is the main measurement.
  if (valuesInBatch.length === 1) {
    const measurement = study.measurements.find((m) => m.id === valuesInBatch[0].measurementId);
    if (measurement === undefined) {
      return undefined;
    }

    return { measurement, value: valuesInBatch[0] };
  }

  const associatedMeasurementSets = [
    efAssociatedMeasurements,
    volumeAssociatedMeasurements,
    vtiAssociatedMeasurements,
    dopplerSlopeAssociatedMeasurements,
    velocityAssociatedMeasurements,
  ] as Partial<Record<MeasurementName, Record<string, MeasurementName>>>[];

  const associatedMeasurementKeys = associatedMeasurementSets.map((s) => Object.keys(s));

  for (const keys of associatedMeasurementKeys) {
    const mainMeasurementValue = valuesInBatch.find((v) => keys.includes(v.name));

    const mainMeasurement = study.measurements.find(
      (m) => m.id === mainMeasurementValue?.measurementId
    );

    if (mainMeasurement && mainMeasurementValue) {
      return { measurement: mainMeasurement, value: mainMeasurementValue };
    }
  }

  // If there's multiple measurements in the batch and we can't find one in the batch that has a
  // measurement name that can create associated measurements, that means this measurement is an
  // orphaned associated measurement and can't be edited. These are possible in historical data, but
  // aren't possible moving forward.

  return undefined;
}

export function getValuesInBatch(study: Study, batchId: string): StudyMeasurementValue[] {
  return study.measurements
    .flatMap((m) => m.values)
    .filter((v) => v.measurementCreationBatchId === batchId);
}

/**
 * Find the saved associated measurements for a given study and main measurement value. This
 * looks in the main measurement's creation batch and matches these against the provided set of
 * potentially creatable associated measurements.
 */
export function findSavedAssociatedMeasurements(
  study: Study,
  mainMeasurementValue: MeasurementToolRecreationDetails,
  associatedMeasurementsToNameMap: Partial<Record<MeasurementName, Record<string, MeasurementName>>>
): (MeasurementToolRecreationDetails & { measurementId: string })[] {
  const associatedMeasurementsForThisName = Object.values(
    associatedMeasurementsToNameMap[mainMeasurementValue.measurementName] ?? {}
  );

  // Get all measurement values in the batch
  const measurementValueCandidates = study.measurements.flatMap((m) =>
    m.values
      .filter(
        (v) => v.measurementCreationBatchId === mainMeasurementValue.measurementCreationBatchId
      )
      .map((v) => ({ ...v, measurementName: m.name }))
  );

  return measurementValueCandidates.filter((v) =>
    Object.values(associatedMeasurementsForThisName).includes(v.measurementName)
  );
}

/**
 * Editing an EF measurement is only possible when both of its two associated volume measurements
 * are present.
 */
export function isEjectionFractionMeasurementWithoutAssociatedVolumes(
  study: Study,
  measurement: StudyMeasurement,
  value: StudyMeasurementValue
): boolean {
  const associatedMeasurements = efAssociatedMeasurements[measurement.name];
  if (associatedMeasurements === undefined) {
    return false;
  }

  const measurementsInBatch = getValuesInBatch(study, value.measurementCreationBatchId).map((v) =>
    study.measurements.find((m) => m.id === v.measurementId)
  );

  return !(
    measurementsInBatch.some((m) => m?.name === associatedMeasurements.endDiastolicVolume) &&
    measurementsInBatch.some((m) => m?.name === associatedMeasurements.endSystolicVolume)
  );
}

export function getLastUpdateTooltipTextForMeasurementValue(
  userList: UserListEntry[],
  value: StudyMeasurementValue
): string {
  const date = value.lastUpdatedAt ?? value.createdAt;
  const userId = value.lastUpdatedById ?? value.createdById;
  const startText = value.lastUpdatedById !== null ? "Last updated" : "Taken";

  return `${startText} ${formatDateTime(date, { includeTime: true })} by ${
    userList.find((c) => c.id === userId)?.name ?? "Unknown"
  }`;
}

/** Creates a Flatten.js polygon from the given set of points. */
export function createFlattenPolygon(points: number[]): Flatten.Polygon {
  const flattenPoints = [];
  for (let i = 0; i < points.length; i += 2) {
    flattenPoints.push(Flatten.point(points[i], points[i + 1]));
  }

  return new Flatten.Polygon(flattenPoints);
}

export enum MeasurementFunctions {
  StrainMeasurements = "Strain Measurements",
  FlowMeasurements = "Flow Measurements",
  VascularRatios = "Vascular Ratios",
  VascularVelocities = "Vascular Velocities",
  LeftVentricularSystolicFunction = "LV Systolic Function",
  RightVentricularSystolicFunction = "RV Systolic Function",
  LeftVentricularDiastolicFunction = "LV Diastolic Function",
  VascularResistance = "Vascular Resistance",
  LeftVentricleDimensions = "LV Dimensions",
  RightVentricleDimensions = "RV Dimensions",
  AtriaDimensions = "Atria Dimensions",
  AorticValve = "Aortic Valve",
  AorticValveInsufficiency = "Aortic Valve Insufficiency",
  MitralValve = "Mitral Valve",
  MitralValveRegurgitation = "Mitral Valve Regurgitation",
  TricuspidValve = "Tricuspid Valve",
  TricuspidValveRegurgitation = "Tricuspid Valve Regurgitation",
  PulmonicValve = "Pulmonic Valve",
  PulmonicValveInsufficiency = "PV Insufficiency",
  GreatVessels = "Great Vessels",
  CustomMeasurements = "Custom",
  Other = "Other",
}

export enum MeasurementStructures {
  LeftVentricle = "Left Ventricle",
  RightVentricle = "Right Ventricle",
  LeftAtrium = "Left Atrium",
  RightAtrium = "Right Atrium",
  AorticValve = "Aortic Valve",
  MitralValve = "Mitral Valve",
  TricuspidValve = "Tricuspid Valve",
  Aorta = "Aorta",
  PulmonaryArtery = "Pulmonary Artery",
  InferiorVenaCava = "Inferior Vena Cava",
  LeftCarotidArtery = "Left Carotid Artery",
  RightCarotidArtery = "Right Carotid Artery",
  LeftVentricularOutflowTract = "LVOT",
  RightVentricularOutflowTract = "RVOT",
  PulmonicValve = "Pulmonic Valve",
  PulmonaryVeins = "Pulmonary Veins",
  CustomMeasurements = "Custom",
  Other = "Other",
}

type MeasurementGrouping = Record<
  MeasurementName,
  {
    functions: MeasurementFunctions[];
    structures: MeasurementStructures[];
  }
>;

export const measurementGroups: MeasurementGrouping = {
  // Systolic Function
  [MeasurementName.LeftVentricleEjectionFraction4DEF]: {
    functions: [MeasurementFunctions.LeftVentricularSystolicFunction],
    structures: [MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.LeftVentricleEjectionFractionAutoEF]: {
    functions: [MeasurementFunctions.LeftVentricularSystolicFunction],
    structures: [MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.LeftVentricleEjectionFractionBiplane]: {
    functions: [MeasurementFunctions.LeftVentricularSystolicFunction],
    structures: [MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.LeftVentricleEjectionFractionCube]: {
    functions: [MeasurementFunctions.LeftVentricularSystolicFunction],
    structures: [MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.LeftVentricleEjectionFractionTeichholz]: {
    functions: [MeasurementFunctions.LeftVentricularSystolicFunction],
    structures: [MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.LeftVentricleEjectionFractionUniplaneA2C]: {
    functions: [MeasurementFunctions.LeftVentricularSystolicFunction],
    structures: [MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.LeftVentricleEjectionFractionUniplaneA4C]: {
    functions: [MeasurementFunctions.LeftVentricularSystolicFunction],
    structures: [MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.LeftVentricleFractionalShortening]: {
    functions: [MeasurementFunctions.LeftVentricularSystolicFunction],
    structures: [MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.LeftVentricleFractionalShorteningCubeMethod]: {
    functions: [MeasurementFunctions.LeftVentricularSystolicFunction],
    structures: [MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.LeftVentricleFractionalShorteningTeichholz]: {
    functions: [MeasurementFunctions.LeftVentricularSystolicFunction],
    structures: [MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.StrokeVolume]: {
    functions: [MeasurementFunctions.LeftVentricularSystolicFunction],
    structures: [MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.CardiacOutput]: {
    functions: [MeasurementFunctions.LeftVentricularSystolicFunction],
    structures: [MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.GlobalPeakLongitudinalStrainAverage]: {
    functions: [
      MeasurementFunctions.LeftVentricularSystolicFunction,
      MeasurementFunctions.StrainMeasurements,
    ],
    structures: [MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.GlobalPeakLongitudinalStrainA2C]: {
    functions: [
      MeasurementFunctions.LeftVentricularSystolicFunction,
      MeasurementFunctions.StrainMeasurements,
    ],
    structures: [MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.GlobalPeakLongitudinalStrainA3C]: {
    functions: [
      MeasurementFunctions.LeftVentricularSystolicFunction,
      MeasurementFunctions.StrainMeasurements,
    ],
    structures: [MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.GlobalPeakLongitudinalStrainA4C]: {
    functions: [
      MeasurementFunctions.LeftVentricularSystolicFunction,
      MeasurementFunctions.StrainMeasurements,
    ],
    structures: [MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.RightVentricleFractionalAreaChange]: {
    functions: [MeasurementFunctions.RightVentricularSystolicFunction],
    structures: [MeasurementStructures.RightVentricle],
  },
  [MeasurementName.TricuspidAnnularPlaneSystolicExcursion]: {
    functions: [
      MeasurementFunctions.RightVentricularSystolicFunction,
      MeasurementFunctions.TricuspidValve,
    ],
    structures: [MeasurementStructures.TricuspidValve, MeasurementStructures.RightVentricle],
  },
  [MeasurementName.MitralAnnularPlaneSystolicExcursion]: {
    functions: [MeasurementFunctions.LeftVentricularSystolicFunction],
    structures: [MeasurementStructures.MitralValve, MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.LeftVentricleFractionalAreaChange]: {
    functions: [MeasurementFunctions.LeftVentricularSystolicFunction],
    structures: [MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.RightVentricleEjectionFractionUniplaneA4C]: {
    functions: [MeasurementFunctions.RightVentricularSystolicFunction],
    structures: [MeasurementStructures.RightVentricle],
  },
  [MeasurementName.LeftVentricleChangeInTime]: {
    functions: [MeasurementFunctions.LeftVentricularSystolicFunction],
    structures: [MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.RightVentricleChangeInTime]: {
    functions: [MeasurementFunctions.RightVentricularSystolicFunction],
    structures: [MeasurementStructures.RightVentricle],
  },
  [MeasurementName.LeftVentricleContractilityFromMitralRegurgitationVelocity]: {
    functions: [MeasurementFunctions.LeftVentricularSystolicFunction],
    structures: [MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.RightVentricleContractilityFromTricuspidRegurgitationVelocity]: {
    functions: [MeasurementFunctions.RightVentricularSystolicFunction],
    structures: [MeasurementStructures.RightVentricle],
  },
  [MeasurementName.LeftVentriclePeakStrainDispersion]: {
    functions: [
      MeasurementFunctions.LeftVentricularSystolicFunction,
      MeasurementFunctions.StrainMeasurements,
    ],
    structures: [MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.RightVentricleFreeWallStrain]: {
    functions: [
      MeasurementFunctions.RightVentricularSystolicFunction,
      MeasurementFunctions.StrainMeasurements,
    ],
    structures: [MeasurementStructures.RightVentricle],
  },
  [MeasurementName.RightVentricleFreeWallSPrime]: {
    functions: [MeasurementFunctions.RightVentricularSystolicFunction],
    structures: [MeasurementStructures.RightVentricle],
  },

  // Diastolic Function
  [MeasurementName.MitralValveEWavePeakVelocity]: {
    functions: [
      MeasurementFunctions.LeftVentricularDiastolicFunction,
      MeasurementFunctions.MitralValve,
    ],
    structures: [MeasurementStructures.MitralValve],
  },
  [MeasurementName.MitralValveAWavePeakVelocity]: {
    functions: [
      MeasurementFunctions.LeftVentricularDiastolicFunction,
      MeasurementFunctions.MitralValve,
    ],
    structures: [MeasurementStructures.MitralValve],
  },
  [MeasurementName.MitralValveEToARatio]: {
    functions: [
      MeasurementFunctions.LeftVentricularDiastolicFunction,
      MeasurementFunctions.MitralValve,
    ],
    structures: [MeasurementStructures.MitralValve],
  },
  [MeasurementName.MitralValveDecelerationTime]: {
    functions: [
      MeasurementFunctions.LeftVentricularDiastolicFunction,
      MeasurementFunctions.MitralValve,
    ],
    structures: [MeasurementStructures.MitralValve],
  },
  [MeasurementName.PulmonaryVeinSystolicPeakVelocity]: {
    functions: [MeasurementFunctions.LeftVentricularDiastolicFunction],
    structures: [MeasurementStructures.PulmonaryVeins],
  },
  [MeasurementName.PulmonaryVeinDiastolicPeakVelocity]: {
    functions: [MeasurementFunctions.LeftVentricularDiastolicFunction],
    structures: [MeasurementStructures.PulmonaryVeins],
  },
  [MeasurementName.MitralValveAnnulusLateralEarlyDiastolicTissuePeakVelocity]: {
    functions: [MeasurementFunctions.LeftVentricularDiastolicFunction],
    structures: [MeasurementStructures.MitralValve, MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.MitralValveAnnulusMedialEarlyDiastolicTissuePeakVelocity]: {
    functions: [MeasurementFunctions.LeftVentricularDiastolicFunction],
    structures: [MeasurementStructures.MitralValve, MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.MitralValvePeakVelocityToLeftVentriclePeakTissueVelocityAverageEWaveRatio]: {
    functions: [MeasurementFunctions.LeftVentricularDiastolicFunction],
    structures: [MeasurementStructures.MitralValve, MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.MitralValvePeakVelocityToLeftVentriclePeakTissueVelocityLateralEWaveRatio]: {
    functions: [MeasurementFunctions.LeftVentricularDiastolicFunction],
    structures: [MeasurementStructures.MitralValve, MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.MitralValvePeakVelocityToLeftVentriclePeakTissueVelocityMedialEWaveRatio]: {
    functions: [MeasurementFunctions.LeftVentricularDiastolicFunction],
    structures: [MeasurementStructures.MitralValve, MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.LeftAtriumEndSystolicVolumeBiplane]: {
    functions: [
      MeasurementFunctions.AtriaDimensions,
      MeasurementFunctions.LeftVentricularDiastolicFunction,
    ],
    structures: [MeasurementStructures.LeftAtrium],
  },
  [MeasurementName.LeftVentricleInflowVelocityPropagationSlope]: {
    functions: [MeasurementFunctions.LeftVentricularDiastolicFunction],
    structures: [MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.MitralValveEWaveDecelerationTime]: {
    functions: [
      MeasurementFunctions.LeftVentricularDiastolicFunction,
      MeasurementFunctions.MitralValve,
    ],
    structures: [MeasurementStructures.MitralValve],
  },
  [MeasurementName.MitralValveAWaveDuration]: {
    functions: [MeasurementFunctions.MitralValve],
    structures: [MeasurementStructures.MitralValve],
  },
  [MeasurementName.PulmonaryVeinAWaveDuration]: {
    functions: [
      MeasurementFunctions.LeftVentricularDiastolicFunction,
      MeasurementFunctions.GreatVessels,
    ],
    structures: [MeasurementStructures.PulmonaryVeins],
  },
  [MeasurementName.PulmonaryVeinAtrialContractionReversalPeakVelocity]: {
    functions: [
      MeasurementFunctions.LeftVentricularDiastolicFunction,
      MeasurementFunctions.GreatVessels,
    ],
    structures: [MeasurementStructures.PulmonaryVeins],
  },
  [MeasurementName.PulmonaryVeinSystolicToDiastolicRatio]: {
    functions: [
      MeasurementFunctions.LeftVentricularDiastolicFunction,
      MeasurementFunctions.GreatVessels,
    ],
    structures: [MeasurementStructures.PulmonaryVeins],
  },
  [MeasurementName.LeftVentricleEToATissueVelocityRatioLateralMitralAnnulus]: {
    functions: [MeasurementFunctions.LeftVentricularDiastolicFunction],
    structures: [MeasurementStructures.LeftVentricle, MeasurementStructures.MitralValve],
  },
  [MeasurementName.LeftVentricleEToATissueVelocityRatioMedialMitralAnnulus]: {
    functions: [MeasurementFunctions.LeftVentricularDiastolicFunction],
    structures: [MeasurementStructures.LeftVentricle, MeasurementStructures.MitralValve],
  },
  [MeasurementName.TricuspidValveEWavePeakVelocity]: {
    functions: [MeasurementFunctions.TricuspidValve],
    structures: [MeasurementStructures.TricuspidValve],
  },
  [MeasurementName.TricuspidValveAWavePeakVelocity]: {
    functions: [MeasurementFunctions.TricuspidValve],
    structures: [MeasurementStructures.TricuspidValve],
  },

  // Valvular Function
  [MeasurementName.AorticValvePeakVelocity]: {
    functions: [MeasurementFunctions.AorticValve],
    structures: [MeasurementStructures.AorticValve],
  },
  [MeasurementName.AorticValvePeakGradient]: {
    functions: [MeasurementFunctions.AorticValve],
    structures: [MeasurementStructures.AorticValve],
  },
  [MeasurementName.AorticValveMeanGradient]: {
    functions: [MeasurementFunctions.AorticValve],
    structures: [MeasurementStructures.AorticValve],
  },
  [MeasurementName.AorticValveArea]: {
    functions: [MeasurementFunctions.AorticValve],
    structures: [MeasurementStructures.AorticValve],
  },
  [MeasurementName.AorticValveAreaByVelocityTimeIntegral]: {
    functions: [MeasurementFunctions.AorticValve],
    structures: [MeasurementStructures.AorticValve],
  },
  [MeasurementName.AorticValveAreaPlanimetry]: {
    functions: [MeasurementFunctions.AorticValve],
    structures: [MeasurementStructures.AorticValve],
  },
  [MeasurementName.AorticRegurgitationPeakVelocity]: {
    functions: [MeasurementFunctions.AorticValveInsufficiency],
    structures: [MeasurementStructures.AorticValve],
  },
  [MeasurementName.AorticRegurgitationPeakGradient]: {
    functions: [MeasurementFunctions.AorticValveInsufficiency],
    structures: [MeasurementStructures.AorticValve],
  },
  [MeasurementName.AorticRegurgitationMeanGradient]: {
    functions: [MeasurementFunctions.AorticValveInsufficiency],
    structures: [MeasurementStructures.AorticValve],
  },
  [MeasurementName.AorticRegurgitationEffectiveRegurgitantOrificeArea]: {
    functions: [MeasurementFunctions.AorticValveInsufficiency],
    structures: [MeasurementStructures.AorticValve],
  },
  [MeasurementName.AorticRegurgitationVolume]: {
    functions: [MeasurementFunctions.AorticValveInsufficiency],
    structures: [MeasurementStructures.AorticValve],
  },
  [MeasurementName.AorticRegurgitationVenaContractaWidth]: {
    functions: [MeasurementFunctions.AorticValveInsufficiency],
    structures: [MeasurementStructures.AorticValve],
  },
  [MeasurementName.AorticValveVelocityTimeIntegral]: {
    functions: [MeasurementFunctions.AorticValve],
    structures: [MeasurementStructures.AorticValve],
  },
  [MeasurementName.AorticValveAccelerationTime]: {
    functions: [MeasurementFunctions.AorticValve],
    structures: [MeasurementStructures.AorticValve],
  },
  [MeasurementName.AorticValveDecelerationTime]: {
    functions: [MeasurementFunctions.AorticValve],
    structures: [MeasurementStructures.AorticValve],
  },
  [MeasurementName.AorticValveEjectionTime]: {
    functions: [MeasurementFunctions.AorticValve],
    structures: [MeasurementStructures.AorticValve],
  },
  [MeasurementName.AorticValvePressureHalfTime]: {
    functions: [MeasurementFunctions.AorticValve],
    structures: [MeasurementStructures.AorticValve],
  },
  [MeasurementName.AorticRegurgitationPressureHalfTime]: {
    functions: [MeasurementFunctions.AorticValveInsufficiency],
    structures: [MeasurementStructures.AorticValve],
  },
  [MeasurementName.AorticValveDimensionlessPerformanceIndex]: {
    functions: [MeasurementFunctions.AorticValve],
    structures: [MeasurementStructures.AorticValve],
  },
  [MeasurementName.MitralValvePeakVelocity]: {
    functions: [MeasurementFunctions.MitralValve],
    structures: [MeasurementStructures.MitralValve],
  },
  [MeasurementName.MitralValvePeakGradient]: {
    functions: [MeasurementFunctions.MitralValve],
    structures: [MeasurementStructures.MitralValve],
  },
  [MeasurementName.MitralValveMeanGradient]: {
    functions: [MeasurementFunctions.MitralValve],
    structures: [MeasurementStructures.MitralValve],
  },
  [MeasurementName.MitralValveAreaByPressureHalfTime]: {
    functions: [MeasurementFunctions.MitralValve],
    structures: [MeasurementStructures.MitralValve],
  },
  [MeasurementName.MitralValveAreaByVelocityTimeIntegral]: {
    functions: [MeasurementFunctions.MitralValve],
    structures: [MeasurementStructures.MitralValve],
  },
  [MeasurementName.MitralValveAreaByPlanimetry]: {
    functions: [MeasurementFunctions.MitralValve],
    structures: [MeasurementStructures.MitralValve],
  },
  [MeasurementName.MitralRegurgitationPeakVelocity]: {
    functions: [MeasurementFunctions.MitralValveRegurgitation],
    structures: [MeasurementStructures.MitralValve],
  },
  [MeasurementName.MitralRegurgitationPeakGradient]: {
    functions: [MeasurementFunctions.MitralValveRegurgitation],
    structures: [MeasurementStructures.MitralValve],
  },
  [MeasurementName.MitralRegurgitationMeanGradient]: {
    functions: [MeasurementFunctions.MitralValveRegurgitation],
    structures: [MeasurementStructures.MitralValve],
  },
  [MeasurementName.MitralRegurgitationEffectiveRegurgitantOrificeArea]: {
    functions: [MeasurementFunctions.MitralValveRegurgitation],
    structures: [MeasurementStructures.MitralValve],
  },
  [MeasurementName.MitralRegurgitationVolume]: {
    functions: [MeasurementFunctions.MitralValveRegurgitation],
    structures: [MeasurementStructures.MitralValve],
  },
  [MeasurementName.MitralValveVelocityTimeIntegral]: {
    functions: [MeasurementFunctions.MitralValve],
    structures: [MeasurementStructures.MitralValve],
  },
  [MeasurementName.MitralValveAccelerationTime]: {
    functions: [MeasurementFunctions.MitralValve],
    structures: [MeasurementStructures.MitralValve],
  },
  [MeasurementName.MitralValvePressureHalfTime]: {
    functions: [MeasurementFunctions.MitralValve],
    structures: [MeasurementStructures.MitralValve],
  },
  [MeasurementName.MitralValveEPointSeptalSeparation]: {
    functions: [MeasurementFunctions.MitralValve],
    structures: [MeasurementStructures.MitralValve],
  },

  // Chamber Dimensions
  [MeasurementName.LeftVentricleInternalEndDiastolicDimension]: {
    functions: [MeasurementFunctions.LeftVentricleDimensions],
    structures: [MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.LeftVentricleInternalSystolicDimension]: {
    functions: [MeasurementFunctions.LeftVentricleDimensions],
    structures: [MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.RightVentricleBaseMinorAxisDiastoleA4C]: {
    functions: [MeasurementFunctions.RightVentricleDimensions],
    structures: [MeasurementStructures.RightVentricle],
  },
  [MeasurementName.LeftAtriumDiameter]: {
    functions: [MeasurementFunctions.AtriaDimensions],
    structures: [MeasurementStructures.LeftAtrium],
  },
  [MeasurementName.RightAtriumDiameterSystole]: {
    functions: [MeasurementFunctions.AtriaDimensions],
    structures: [MeasurementStructures.RightAtrium],
  },

  // Vascular Measurements
  [MeasurementName.AorticRootDiameter]: {
    functions: [MeasurementFunctions.GreatVessels],
    structures: [MeasurementStructures.Aorta],
  },
  [MeasurementName.AscendingAortaDiameter]: {
    functions: [MeasurementFunctions.GreatVessels],
    structures: [MeasurementStructures.Aorta],
  },
  [MeasurementName.MainPulmonaryArteryDiameter]: {
    functions: [MeasurementFunctions.GreatVessels],
    structures: [MeasurementStructures.PulmonaryArtery],
  },
  [MeasurementName.InferiorVenaCavaDiameter]: {
    functions: [MeasurementFunctions.GreatVessels],
    structures: [MeasurementStructures.InferiorVenaCava],
  },

  // Other Measurements
  [MeasurementName.HeartRate]: {
    functions: [MeasurementFunctions.Other],
    structures: [MeasurementStructures.Other],
  },
  [MeasurementName.SystolicBloodPressure]: {
    functions: [MeasurementFunctions.Other],
    structures: [MeasurementStructures.Other],
  },
  [MeasurementName.LeftVentricleEarlyDiastolicTissuePeakVelocity]: {
    functions: [MeasurementFunctions.LeftVentricularDiastolicFunction],
    structures: [MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.LeftVentricleOutflowTractPeakVelocity]: {
    functions: [
      MeasurementFunctions.AorticValve,
      MeasurementFunctions.LeftVentricularSystolicFunction,
    ],
    structures: [MeasurementStructures.LeftVentricularOutflowTract],
  },
  [MeasurementName.LeftCarotidArteryDistalPeakSystolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.LeftCarotidArtery],
  },
  [MeasurementName.RightCarotidArteryDistalPeakSystolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.RightCarotidArtery],
  },
  [MeasurementName.AtrioseptalDefectDiameterMajorAxis]: {
    functions: [MeasurementFunctions.Other],
    structures: [MeasurementStructures.Other],
  },
  [MeasurementName.CustomValue]: {
    functions: [MeasurementFunctions.CustomMeasurements],
    structures: [MeasurementStructures.CustomMeasurements],
  },
  [MeasurementName.CustomAngle]: {
    functions: [MeasurementFunctions.CustomMeasurements],
    structures: [MeasurementStructures.CustomMeasurements],
  },
  [MeasurementName.CustomArea]: {
    functions: [MeasurementFunctions.CustomMeasurements],
    structures: [MeasurementStructures.CustomMeasurements],
  },
  [MeasurementName.CustomDopplerSlope]: {
    functions: [MeasurementFunctions.CustomMeasurements],
    structures: [MeasurementStructures.CustomMeasurements],
  },
  [MeasurementName.CustomMModeSlope]: {
    functions: [MeasurementFunctions.CustomMeasurements],
    structures: [MeasurementStructures.CustomMeasurements],
  },
  [MeasurementName.CustomEjectionFraction]: {
    functions: [
      MeasurementFunctions.CustomMeasurements,
      MeasurementFunctions.LeftVentricularSystolicFunction,
    ],
    structures: [MeasurementStructures.CustomMeasurements],
  },
  [MeasurementName.CustomDistance]: {
    functions: [MeasurementFunctions.CustomMeasurements],
    structures: [MeasurementStructures.CustomMeasurements],
  },
  [MeasurementName.CustomTime]: {
    functions: [MeasurementFunctions.CustomMeasurements],
    structures: [MeasurementStructures.CustomMeasurements],
  },
  [MeasurementName.CustomVelocity]: {
    functions: [MeasurementFunctions.CustomMeasurements],
    structures: [MeasurementStructures.CustomMeasurements],
  },
  [MeasurementName.CustomVelocityTimeIntegral]: {
    functions: [MeasurementFunctions.CustomMeasurements],
    structures: [MeasurementStructures.CustomMeasurements],
  },
  [MeasurementName.CustomVolume]: {
    functions: [MeasurementFunctions.CustomMeasurements],
    structures: [MeasurementStructures.CustomMeasurements],
  },
  [MeasurementName.CustomDisplacement]: {
    functions: [MeasurementFunctions.CustomMeasurements],
    structures: [MeasurementStructures.CustomMeasurements],
  },

  // Additional measurements
  [MeasurementName.AbdominalAortaDiameter]: {
    functions: [MeasurementFunctions.GreatVessels],
    structures: [MeasurementStructures.Aorta],
  },
  [MeasurementName.AorticAnnulusDiameter]: {
    functions: [MeasurementFunctions.GreatVessels],
    structures: [MeasurementStructures.Aorta],
  },
  [MeasurementName.AorticArchDiameter]: {
    functions: [MeasurementFunctions.GreatVessels],
    structures: [MeasurementStructures.Aorta],
  },
  [MeasurementName.AorticRegurgitationAccelerationTime]: {
    functions: [MeasurementFunctions.AorticValveInsufficiency],
    structures: [MeasurementStructures.AorticValve],
  },
  [MeasurementName.AorticRegurgitationAliasingVelocity]: {
    functions: [MeasurementFunctions.AorticValveInsufficiency],
    structures: [MeasurementStructures.AorticValve],
  },
  [MeasurementName.AorticRegurgitationDecelerationSlope]: {
    functions: [MeasurementFunctions.AorticValveInsufficiency],
    structures: [MeasurementStructures.AorticValve],
  },
  [MeasurementName.AorticRegurgitationDecelerationTime]: {
    functions: [MeasurementFunctions.AorticValveInsufficiency],
    structures: [MeasurementStructures.AorticValve],
  },
  [MeasurementName.AorticRegurgitationMeanVelocity]: {
    functions: [MeasurementFunctions.AorticValveInsufficiency],
    structures: [MeasurementStructures.AorticValve],
  },
  [MeasurementName.AorticRegurgitationProximalIsovelocitySurfaceArea]: {
    functions: [MeasurementFunctions.AorticValveInsufficiency],
    structures: [MeasurementStructures.AorticValve],
  },
  [MeasurementName.AorticRegurgitationProximalIsovelocitySurfaceAreaRadius]: {
    functions: [MeasurementFunctions.AorticValveInsufficiency],
    structures: [MeasurementStructures.AorticValve],
  },
  [MeasurementName.AorticRegurgitationVelocityTimeIntegral]: {
    functions: [MeasurementFunctions.AorticValveInsufficiency],
    structures: [MeasurementStructures.AorticValve],
  },
  [MeasurementName.AorticRootDiameterMMode]: {
    functions: [MeasurementFunctions.GreatVessels],
    structures: [MeasurementStructures.Aorta],
  },
  [MeasurementName.AorticSinotubularJunctionDiameter]: {
    functions: [MeasurementFunctions.GreatVessels],
    structures: [MeasurementStructures.Aorta],
  },
  [MeasurementName.AorticValveAccelerationSlope]: {
    functions: [MeasurementFunctions.AorticValve],
    structures: [MeasurementStructures.AorticValve],
  },
  [MeasurementName.AorticValveClosure]: {
    functions: [MeasurementFunctions.AorticValve],
    structures: [MeasurementStructures.AorticValve],
  },
  [MeasurementName.AorticValveCuspSeparationMMode]: {
    functions: [MeasurementFunctions.AorticValve],
    structures: [MeasurementStructures.AorticValve],
  },
  [MeasurementName.AorticValveDecelerationSlope]: {
    functions: [MeasurementFunctions.AorticValve],
    structures: [MeasurementStructures.AorticValve],
  },
  [MeasurementName.AorticValveMeanVelocity]: {
    functions: [MeasurementFunctions.AorticValve],
    structures: [MeasurementStructures.AorticValve],
  },
  [MeasurementName.AorticValveVelocityRatio]: {
    functions: [MeasurementFunctions.AorticValve],
    structures: [MeasurementStructures.AorticValve],
  },
  [MeasurementName.AscendingAortaMeanGradient]: {
    functions: [MeasurementFunctions.GreatVessels],
    structures: [MeasurementStructures.Aorta],
  },
  [MeasurementName.AscendingAortaMeanVelocity]: {
    functions: [MeasurementFunctions.GreatVessels],
    structures: [MeasurementStructures.Aorta],
  },
  [MeasurementName.AscendingAortaPeakGradient]: {
    functions: [MeasurementFunctions.GreatVessels],
    structures: [MeasurementStructures.Aorta],
  },
  [MeasurementName.AscendingAortaPeakVelocity]: {
    functions: [MeasurementFunctions.GreatVessels],
    structures: [MeasurementStructures.Aorta],
  },
  [MeasurementName.AscendingAortaVelocityTimeIntegral]: {
    functions: [MeasurementFunctions.GreatVessels],
    structures: [MeasurementStructures.Aorta],
  },
  [MeasurementName.AtrioseptalDefectDiameterMinorAxis]: {
    functions: [MeasurementFunctions.Other, MeasurementFunctions.AtriaDimensions],
    structures: [MeasurementStructures.Other],
  },
  [MeasurementName.DescendingAortaDiameter]: {
    functions: [MeasurementFunctions.GreatVessels],
    structures: [MeasurementStructures.Aorta],
  },
  [MeasurementName.DiastolicBloodPressure]: {
    functions: [MeasurementFunctions.Other],
    structures: [MeasurementStructures.Other],
  },
  [MeasurementName.DoseLengthProduct]: {
    functions: [MeasurementFunctions.Other],
    structures: [MeasurementStructures.Other],
  },
  [MeasurementName.EVelocityToAnnulusEVelocityRatio]: {
    functions: [
      MeasurementFunctions.LeftVentricularDiastolicFunction,
      MeasurementFunctions.MitralValve,
    ],
    structures: [MeasurementStructures.MitralValve, MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.InferiorVenaCavaDiameterExpiration]: {
    functions: [MeasurementFunctions.GreatVessels],
    structures: [MeasurementStructures.InferiorVenaCava],
  },
  [MeasurementName.InferiorVenaCavaDiameterInspiration]: {
    functions: [MeasurementFunctions.GreatVessels],
    structures: [MeasurementStructures.InferiorVenaCava],
  },
  [MeasurementName.InterventricularSeptumDiastolicThickness]: {
    functions: [MeasurementFunctions.LeftVentricleDimensions],
    structures: [MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.InterventricularSeptumSystolicThickness]: {
    functions: [MeasurementFunctions.LeftVentricleDimensions],
    structures: [MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.InterventricularSeptumToPosteriorWallThicknessRatio]: {
    functions: [MeasurementFunctions.LeftVentricleDimensions],
    structures: [MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.LeftAtrialAppendageExitVelocity]: {
    functions: [MeasurementFunctions.Other],
    structures: [MeasurementStructures.LeftAtrium],
  },
  [MeasurementName.LeftAtrialAppendageOcclusionDiscDepth]: {
    functions: [MeasurementFunctions.Other],
    structures: [MeasurementStructures.Other],
  },
  [MeasurementName.LeftAtriumAorticRootRatio]: {
    functions: [MeasurementFunctions.AtriaDimensions, MeasurementFunctions.GreatVessels],
    structures: [MeasurementStructures.LeftAtrium, MeasurementStructures.Aorta],
  },
  [MeasurementName.LeftAtriumAreaDiastolicA2C]: {
    functions: [MeasurementFunctions.AtriaDimensions],
    structures: [MeasurementStructures.LeftAtrium],
  },
  [MeasurementName.LeftAtriumAreaDiastolicA4C]: {
    functions: [MeasurementFunctions.AtriaDimensions],
    structures: [MeasurementStructures.LeftAtrium],
  },
  [MeasurementName.LeftAtriumAreaSystolicA2C]: {
    functions: [MeasurementFunctions.AtriaDimensions],
    structures: [MeasurementStructures.LeftAtrium],
  },
  [MeasurementName.LeftAtriumAreaSystolicA4C]: {
    functions: [MeasurementFunctions.AtriaDimensions],
    structures: [MeasurementStructures.LeftAtrium],
  },
  [MeasurementName.LeftAtriumDiameterA2C]: {
    functions: [MeasurementFunctions.AtriaDimensions],
    structures: [MeasurementStructures.LeftAtrium],
  },
  [MeasurementName.LeftAtriumDiameterA4C]: {
    functions: [MeasurementFunctions.AtriaDimensions],
    structures: [MeasurementStructures.LeftAtrium],
  },
  [MeasurementName.LeftAtriumDiastolicMajorAxisA2C]: {
    functions: [MeasurementFunctions.AtriaDimensions],
    structures: [MeasurementStructures.LeftAtrium],
  },
  [MeasurementName.LeftAtriumDiastolicMajorAxisA4C]: {
    functions: [MeasurementFunctions.AtriaDimensions],
    structures: [MeasurementStructures.LeftAtrium],
  },
  [MeasurementName.LeftAtriumEndDiastolicVolumeBiplaneAreaLength]: {
    functions: [
      MeasurementFunctions.AtriaDimensions,
      MeasurementFunctions.LeftVentricularDiastolicFunction,
    ],
    structures: [MeasurementStructures.LeftAtrium],
  },
  [MeasurementName.LeftAtriumEndDiastolicVolumeBiplane]: {
    functions: [
      MeasurementFunctions.AtriaDimensions,
      MeasurementFunctions.LeftVentricularDiastolicFunction,
    ],
    structures: [MeasurementStructures.LeftAtrium],
  },
  [MeasurementName.LeftAtriumEndDiastolicVolumeUniplaneA2C]: {
    functions: [
      MeasurementFunctions.AtriaDimensions,
      MeasurementFunctions.LeftVentricularDiastolicFunction,
    ],
    structures: [MeasurementStructures.LeftAtrium],
  },
  [MeasurementName.LeftAtriumEndDiastolicVolumeUniplaneA4C]: {
    functions: [
      MeasurementFunctions.AtriaDimensions,
      MeasurementFunctions.LeftVentricularDiastolicFunction,
    ],
    structures: [MeasurementStructures.LeftAtrium],
  },
  [MeasurementName.LeftAtriumEndDiastolicVolumeUniplaneAreaLengthA2C]: {
    functions: [
      MeasurementFunctions.AtriaDimensions,
      MeasurementFunctions.LeftVentricularDiastolicFunction,
    ],
    structures: [MeasurementStructures.LeftAtrium],
  },
  [MeasurementName.LeftAtriumEndDiastolicVolumeUniplaneAreaLengthA4C]: {
    functions: [
      MeasurementFunctions.AtriaDimensions,
      MeasurementFunctions.LeftVentricularDiastolicFunction,
    ],
    structures: [MeasurementStructures.LeftAtrium],
  },
  [MeasurementName.LeftAtriumEndSystolicVolumeBiplaneAreaLength]: {
    functions: [
      MeasurementFunctions.AtriaDimensions,
      MeasurementFunctions.LeftVentricularDiastolicFunction,
    ],
    structures: [MeasurementStructures.LeftAtrium],
  },
  [MeasurementName.LeftAtriumEndSystolicVolumeUniplaneA2C]: {
    functions: [
      MeasurementFunctions.AtriaDimensions,
      MeasurementFunctions.LeftVentricularDiastolicFunction,
    ],
    structures: [MeasurementStructures.LeftAtrium],
  },
  [MeasurementName.LeftAtriumEndSystolicVolumeUniplaneA4C]: {
    functions: [
      MeasurementFunctions.AtriaDimensions,
      MeasurementFunctions.LeftVentricularDiastolicFunction,
    ],
    structures: [MeasurementStructures.LeftAtrium],
  },
  [MeasurementName.LeftAtriumEndSystolicVolumeUniplaneAreaLengthA2C]: {
    functions: [
      MeasurementFunctions.AtriaDimensions,
      MeasurementFunctions.LeftVentricularDiastolicFunction,
    ],
    structures: [MeasurementStructures.LeftAtrium],
  },
  [MeasurementName.LeftAtriumEndSystolicVolumeUniplaneAreaLengthA4C]: {
    functions: [
      MeasurementFunctions.AtriaDimensions,
      MeasurementFunctions.LeftVentricularDiastolicFunction,
    ],
    structures: [MeasurementStructures.LeftAtrium],
  },
  [MeasurementName.LeftAtriumSystolicMajorAxis]: {
    functions: [MeasurementFunctions.AtriaDimensions],
    structures: [MeasurementStructures.LeftAtrium],
  },
  [MeasurementName.LeftAtriumSystolicMajorAxisA2C]: {
    functions: [MeasurementFunctions.AtriaDimensions],
    structures: [MeasurementStructures.LeftAtrium],
  },
  [MeasurementName.LeftAtriumSystolicMajorAxisA4C]: {
    functions: [MeasurementFunctions.AtriaDimensions],
    structures: [MeasurementStructures.LeftAtrium],
  },
  [MeasurementName.LeftAtriumSystolicVolumeUniplaneAreaLength]: {
    functions: [
      MeasurementFunctions.AtriaDimensions,
      MeasurementFunctions.LeftVentricularDiastolicFunction,
    ],
    structures: [MeasurementStructures.LeftAtrium],
  },
  [MeasurementName.LeftAtriumSystolicVolumeUniplaneAreaLengthA4C]: {
    functions: [
      MeasurementFunctions.AtriaDimensions,
      MeasurementFunctions.LeftVentricularDiastolicFunction,
    ],
    structures: [MeasurementStructures.LeftAtrium],
  },
  [MeasurementName.LeftCarotidArteryDistalEndDiastolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.LeftCarotidArtery],
  },
  [MeasurementName.LeftCarotidArteryDistalMinimumDiastolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.LeftCarotidArtery],
  },
  [MeasurementName.LeftCarotidArteryMidLongitudinalEndDiastolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.LeftCarotidArtery],
  },
  [MeasurementName.LeftCarotidArteryMidLongitudinalMinimumDiastolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.LeftCarotidArtery],
  },
  [MeasurementName.LeftCarotidArteryMidLongitudinalPeakSystolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.LeftCarotidArtery],
  },
  [MeasurementName.LeftCarotidArteryProximalEndDiastolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.LeftCarotidArtery],
  },
  [MeasurementName.LeftCarotidArteryProximalMinimumDiastolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.LeftCarotidArtery],
  },
  [MeasurementName.LeftCarotidArteryProximalPeakSystolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.LeftCarotidArtery],
  },
  [MeasurementName.LeftCommonCarotidArteryDistalEndDiastolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.LeftCarotidArtery],
  },
  [MeasurementName.LeftCommonCarotidArteryDistalMinimumDiastolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.LeftCarotidArtery],
  },
  [MeasurementName.LeftCommonCarotidArteryDistalPeakSystolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.LeftCarotidArtery],
  },
  [MeasurementName.LeftCommonCarotidArteryMidLongitudinalEndDiastolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.LeftCarotidArtery],
  },
  [MeasurementName.LeftCommonCarotidArteryMidLongitudinalMinimumDiastolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.LeftCarotidArtery],
  },
  [MeasurementName.LeftCommonCarotidArteryMidLongitudinalPeakSystolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.LeftCarotidArtery],
  },
  [MeasurementName.LeftCommonCarotidArteryProximalEndDiastolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.LeftCarotidArtery],
  },
  [MeasurementName.LeftCommonCarotidArteryProximalMinimumDiastolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.LeftCarotidArtery],
  },
  [MeasurementName.LeftCommonCarotidArteryProximalPeakSystolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.LeftCarotidArtery],
  },
  [MeasurementName.LeftExternalCarotidArteryDistalEndDiastolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.LeftCarotidArtery],
  },
  [MeasurementName.LeftExternalCarotidArteryDistalMinimumDiastolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.LeftCarotidArtery],
  },
  [MeasurementName.LeftExternalCarotidArteryDistalPeakSystolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.LeftCarotidArtery],
  },
  [MeasurementName.LeftExternalCarotidArteryMidLongitudinalEndDiastolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.LeftCarotidArtery],
  },
  [MeasurementName.LeftExternalCarotidArteryMidLongitudinalMinimumDiastolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.LeftCarotidArtery],
  },
  [MeasurementName.LeftExternalCarotidArteryMidLongitudinalPeakSystolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.LeftCarotidArtery],
  },
  [MeasurementName.LeftExternalCarotidArteryProximalEndDiastolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.LeftCarotidArtery],
  },
  [MeasurementName.LeftExternalCarotidArteryProximalMinimumDiastolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.LeftCarotidArtery],
  },
  [MeasurementName.LeftExternalCarotidArteryProximalPeakSystolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.LeftCarotidArtery],
  },
  [MeasurementName.LeftInternalCarotidArteryDistalEndDiastolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.LeftCarotidArtery],
  },
  [MeasurementName.LeftInternalCarotidArteryDistalMinimumDiastolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.LeftCarotidArtery],
  },
  [MeasurementName.LeftInternalCarotidArteryDistalPeakSystolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.LeftCarotidArtery],
  },
  [MeasurementName.LeftInternalCarotidArteryMidLongitudinalEndDiastolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.LeftCarotidArtery],
  },
  [MeasurementName.LeftInternalCarotidArteryMidLongitudinalMinimumDiastolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.LeftCarotidArtery],
  },
  [MeasurementName.LeftInternalCarotidArteryMidLongitudinalPeakSystolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.LeftCarotidArtery],
  },
  [MeasurementName.LeftInternalCarotidArteryProximalEndDiastolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.LeftCarotidArtery],
  },
  [MeasurementName.LeftInternalCarotidArteryProximalMinimumDiastolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.LeftCarotidArtery],
  },
  [MeasurementName.LeftInternalCarotidArteryProximalPeakSystolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.LeftCarotidArtery],
  },
  [MeasurementName.LeftInternalCarotidArteryToCommonCarotidArteryPeakSystolicVelocityRatio]: {
    functions: [MeasurementFunctions.VascularRatios],
    structures: [MeasurementStructures.LeftCarotidArtery],
  },
  [MeasurementName.LeftVertebralArteryEndDiastolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.LeftCarotidArtery],
  },
  [MeasurementName.LeftVertebralArteryMinimumDiastolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.LeftCarotidArtery],
  },
  [MeasurementName.LeftVertebralArteryPeakSystolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.LeftCarotidArtery],
  },
  [MeasurementName.LeftPulmonaryArteryDiameter]: {
    functions: [MeasurementFunctions.GreatVessels],
    structures: [MeasurementStructures.PulmonaryArtery],
  },
  [MeasurementName.LeftPulmonaryArteryPeakGradient]: {
    functions: [MeasurementFunctions.GreatVessels],
    structures: [MeasurementStructures.PulmonaryArtery],
  },
  [MeasurementName.LeftPulmonaryArteryPeakVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.PulmonaryArtery],
  },
  [MeasurementName.LeftVentricleDiameterMethodOfDisks]: {
    functions: [MeasurementFunctions.LeftVentricleDimensions],
    structures: [MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.LeftVentricleDiastolicAreaA2C]: {
    functions: [MeasurementFunctions.LeftVentricleDimensions],
    structures: [MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.LeftVentricleDiastolicAreaA4C]: {
    functions: [MeasurementFunctions.LeftVentricleDimensions],
    structures: [MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.LeftVentricleEndDiastolicVolume4DEF]: {
    functions: [MeasurementFunctions.LeftVentricleDimensions],
    structures: [MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.LeftVentricleEndDiastolicVolumeAutoEF]: {
    functions: [MeasurementFunctions.LeftVentricleDimensions],
    structures: [MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.LeftVentricleEndDiastolicVolumeBiplane]: {
    functions: [MeasurementFunctions.LeftVentricleDimensions],
    structures: [MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.LeftVentricleEndDiastolicVolumeCubeMethod]: {
    functions: [MeasurementFunctions.LeftVentricleDimensions],
    structures: [MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.LeftVentricleEndDiastolicVolumeTeichholz]: {
    functions: [MeasurementFunctions.LeftVentricleDimensions],
    structures: [MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.LeftVentricleEndDiastolicVolumeUniplaneA2C]: {
    functions: [MeasurementFunctions.LeftVentricleDimensions],
    structures: [MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.LeftVentricleEndDiastolicVolumeUniplaneA3C]: {
    functions: [MeasurementFunctions.LeftVentricleDimensions],
    structures: [MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.LeftVentricleEndDiastolicVolumeUniplaneA4C]: {
    functions: [MeasurementFunctions.LeftVentricleDimensions],
    structures: [MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.LeftVentricleEndSystolicVolume4DEF]: {
    functions: [MeasurementFunctions.LeftVentricleDimensions],
    structures: [MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.LeftVentricleEndSystolicVolumeAutoEF]: {
    functions: [MeasurementFunctions.LeftVentricleDimensions],
    structures: [MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.LeftVentricleEndSystolicVolumeBiplane]: {
    functions: [MeasurementFunctions.LeftVentricleDimensions],
    structures: [MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.LeftVentricleEndSystolicVolumeCubeMethod]: {
    functions: [MeasurementFunctions.LeftVentricleDimensions],
    structures: [MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.LeftVentricleEndSystolicVolumeTeichholz]: {
    functions: [MeasurementFunctions.LeftVentricleDimensions],
    structures: [MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.LeftVentricleEndSystolicVolumeUniplaneA2C]: {
    functions: [MeasurementFunctions.LeftVentricleDimensions],
    structures: [MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.LeftVentricleEndSystolicVolumeUniplaneA3C]: {
    functions: [MeasurementFunctions.LeftVentricleDimensions],
    structures: [MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.LeftVentricleEndSystolicVolumeUniplaneA4C]: {
    functions: [MeasurementFunctions.LeftVentricleDimensions],
    structures: [MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.LeftVentricleMajorAxisDiastolicDimensionA2C]: {
    functions: [MeasurementFunctions.LeftVentricleDimensions],
    structures: [MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.LeftVentricleMajorAxisDiastolicDimensionA4C]: {
    functions: [MeasurementFunctions.LeftVentricleDimensions],
    structures: [MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.LeftVentricleMajorAxisSystolicDimensionA2C]: {
    functions: [MeasurementFunctions.LeftVentricleDimensions],
    structures: [MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.LeftVentricleMajorAxisSystolicDimensionA4C]: {
    functions: [MeasurementFunctions.LeftVentricleDimensions],
    structures: [MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.LeftVentricleMass]: {
    functions: [MeasurementFunctions.LeftVentricleDimensions],
    structures: [MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.LeftVentricleOutflowTractAccelerationTime]: {
    functions: [
      MeasurementFunctions.AorticValve,
      MeasurementFunctions.LeftVentricularSystolicFunction,
    ],
    structures: [MeasurementStructures.LeftVentricularOutflowTract],
  },
  [MeasurementName.LeftVentricleOutflowTractArea]: {
    functions: [MeasurementFunctions.LeftVentricleDimensions, MeasurementFunctions.AorticValve],
    structures: [MeasurementStructures.LeftVentricularOutflowTract],
  },
  [MeasurementName.LeftVentricleOutflowTractDiameter]: {
    functions: [MeasurementFunctions.LeftVentricleDimensions, MeasurementFunctions.AorticValve],
    structures: [MeasurementStructures.LeftVentricularOutflowTract],
  },
  [MeasurementName.LeftVentricleOutflowTractHeight]: {
    functions: [MeasurementFunctions.LeftVentricleDimensions],
    structures: [MeasurementStructures.LeftVentricularOutflowTract],
  },
  [MeasurementName.LeftVentricleOutflowTractMeanGradient]: {
    functions: [
      MeasurementFunctions.LeftVentricularSystolicFunction,
      MeasurementFunctions.AorticValve,
    ],
    structures: [MeasurementStructures.LeftVentricularOutflowTract],
  },
  [MeasurementName.LeftVentricleOutflowTractMeanVelocity]: {
    functions: [
      MeasurementFunctions.LeftVentricularSystolicFunction,
      MeasurementFunctions.AorticValve,
    ],
    structures: [MeasurementStructures.LeftVentricularOutflowTract],
  },
  [MeasurementName.LeftVentricleOutflowTractPeakGradient]: {
    functions: [MeasurementFunctions.FlowMeasurements],
    structures: [MeasurementStructures.LeftVentricularOutflowTract],
  },
  [MeasurementName.LeftVentricleOutflowTractVelocityTimeIntegral]: {
    functions: [
      MeasurementFunctions.LeftVentricularSystolicFunction,
      MeasurementFunctions.AorticValve,
    ],
    structures: [MeasurementStructures.LeftVentricularOutflowTract],
  },
  [MeasurementName.LeftVentriclePosteriorWallDiastolicThickness]: {
    functions: [MeasurementFunctions.LeftVentricleDimensions],
    structures: [MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.LeftVentriclePosteriorWallSystolicThickness]: {
    functions: [MeasurementFunctions.LeftVentricleDimensions],
    structures: [MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.LeftVentricleSystolicAreaA2C]: {
    functions: [MeasurementFunctions.LeftVentricleDimensions],
    structures: [MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.LeftVentricleSystolicAreaA4C]: {
    functions: [MeasurementFunctions.LeftVentricleDimensions],
    structures: [MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.LeftVentricleTotalVolume]: {
    functions: [MeasurementFunctions.LeftVentricleDimensions],
    structures: [MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.LeftVentricularCavityVolume]: {
    functions: [MeasurementFunctions.LeftVentricleDimensions],
    structures: [MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.LeftVentricularIsovolumetricContractionTime]: {
    functions: [MeasurementFunctions.LeftVentricularSystolicFunction],
    structures: [MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.LeftVentricularIsovolumetricRelaxationTime]: {
    functions: [MeasurementFunctions.LeftVentricularDiastolicFunction],
    structures: [MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.MitralRegurgitationAccelerationTime]: {
    functions: [MeasurementFunctions.MitralValveRegurgitation],
    structures: [MeasurementStructures.MitralValve],
  },
  [MeasurementName.MitralRegurgitationAliasingVelocity]: {
    functions: [MeasurementFunctions.MitralValveRegurgitation],
    structures: [MeasurementStructures.MitralValve],
  },
  [MeasurementName.MitralRegurgitationMeanVelocity]: {
    functions: [MeasurementFunctions.MitralValveRegurgitation],
    structures: [MeasurementStructures.MitralValve],
  },
  [MeasurementName.MitralRegurgitationProximalIsovelocitySurfaceArea]: {
    functions: [MeasurementFunctions.MitralValveRegurgitation],
    structures: [MeasurementStructures.MitralValve],
  },
  [MeasurementName.MitralRegurgitationProximalIsovelocitySurfaceAreaRadius]: {
    functions: [MeasurementFunctions.MitralValveRegurgitation],
    structures: [MeasurementStructures.MitralValve],
  },
  [MeasurementName.MitralRegurgitationVelocityTimeIntegral]: {
    functions: [MeasurementFunctions.MitralValveRegurgitation],
    structures: [MeasurementStructures.MitralValve],
  },
  [MeasurementName.MitralValveAccelerationTimeToDecelerationTimeRatio]: {
    functions: [MeasurementFunctions.MitralValve],
    structures: [MeasurementStructures.MitralValve],
  },
  [MeasurementName.MitralValveAnnulusDiameter]: {
    functions: [MeasurementFunctions.MitralValve, MeasurementFunctions.MitralValveRegurgitation],
    structures: [MeasurementStructures.MitralValve],
  },
  [MeasurementName.MitralValveAnnulusEarlyDiastolicMeanVelocity]: {
    functions: [
      MeasurementFunctions.MitralValve,
      MeasurementFunctions.LeftVentricularDiastolicFunction,
    ],
    structures: [MeasurementStructures.MitralValve],
  },
  [MeasurementName.MitralValveAPrimeVelocityLateral]: {
    functions: [
      MeasurementFunctions.MitralValve,
      MeasurementFunctions.LeftVentricularDiastolicFunction,
    ],
    structures: [MeasurementStructures.MitralValve],
  },
  [MeasurementName.MitralValveAPrimeVelocitySeptal]: {
    functions: [
      MeasurementFunctions.MitralValve,
      MeasurementFunctions.LeftVentricularDiastolicFunction,
    ],
    structures: [MeasurementStructures.MitralValve],
  },
  [MeasurementName.MitralValveAToERatio]: {
    functions: [MeasurementFunctions.MitralValve],
    structures: [MeasurementStructures.MitralValve],
  },
  [MeasurementName.MitralValveAWavePressureGradient]: {
    functions: [MeasurementFunctions.MitralValve],
    structures: [MeasurementStructures.MitralValve],
  },
  [MeasurementName.MitralValveDecelerationSlope]: {
    functions: [
      MeasurementFunctions.MitralValve,
      MeasurementFunctions.LeftVentricularDiastolicFunction,
    ],
    structures: [MeasurementStructures.MitralValve],
  },
  [MeasurementName.MitralValveDEExcursionMMode]: {
    functions: [MeasurementFunctions.MitralValve],
    structures: [MeasurementStructures.MitralValve],
  },
  [MeasurementName.MitralValveDESlopeMMode]: {
    functions: [MeasurementFunctions.MitralValve],
    structures: [MeasurementStructures.MitralValve],
  },
  [MeasurementName.MitralValveEWavePressureGradient]: {
    functions: [MeasurementFunctions.MitralValve],
    structures: [MeasurementStructures.MitralValve],
  },
  [MeasurementName.MitralValveMeanVelocity]: {
    functions: [MeasurementFunctions.MitralValve],
    structures: [MeasurementStructures.MitralValve],
  },
  [MeasurementName.MitralValvePressureHalfTimePeakVelocity]: {
    functions: [MeasurementFunctions.MitralValve],
    structures: [MeasurementStructures.MitralValve],
  },
  [MeasurementName.MitralValveRegurgitantFraction]: {
    functions: [MeasurementFunctions.MitralValveRegurgitation],
    structures: [MeasurementStructures.MitralValve],
  },
  [MeasurementName.MyocardialPerformanceIndex]: {
    functions: [
      MeasurementFunctions.LeftVentricularSystolicFunction,
      MeasurementFunctions.RightVentricularSystolicFunction,
    ],
    structures: [MeasurementStructures.LeftVentricle, MeasurementStructures.RightVentricle],
  },
  [MeasurementName.PatentDuctusArteriosusDiameter]: {
    functions: [MeasurementFunctions.Other],
    structures: [MeasurementStructures.Other],
  },
  [MeasurementName.ProximalInterventricularSeptumThickness]: {
    functions: [MeasurementFunctions.LeftVentricleDimensions],
    structures: [MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.PulmonaryArterySystolicPressureCorrectedForPulmonaryValve]: {
    functions: [MeasurementFunctions.GreatVessels],
    structures: [MeasurementStructures.PulmonaryArtery],
  },
  [MeasurementName.PulmonaryRegurgitationAccelerationSlope]: {
    functions: [MeasurementFunctions.PulmonicValveInsufficiency],
    structures: [MeasurementStructures.PulmonicValve],
  },
  [MeasurementName.PulmonaryRegurgitationAccelerationTime]: {
    functions: [MeasurementFunctions.PulmonicValveInsufficiency],
    structures: [MeasurementStructures.PulmonicValve],
  },
  [MeasurementName.PulmonaryRegurgitationAliasingVelocity]: {
    functions: [MeasurementFunctions.PulmonicValveInsufficiency],
    structures: [MeasurementStructures.PulmonicValve],
  },
  [MeasurementName.PulmonaryRegurgitationDecelerationTime]: {
    functions: [MeasurementFunctions.PulmonicValveInsufficiency],
    structures: [MeasurementStructures.PulmonicValve],
  },
  [MeasurementName.PulmonaryRegurgitationDecelerationSlope]: {
    functions: [MeasurementFunctions.PulmonicValveInsufficiency],
    structures: [MeasurementStructures.PulmonicValve],
  },
  [MeasurementName.PulmonaryRegurgitationEffectiveRegurgitantOrificeArea]: {
    functions: [MeasurementFunctions.PulmonicValveInsufficiency],
    structures: [MeasurementStructures.PulmonicValve],
  },
  [MeasurementName.PulmonaryRegurgitationMeanGradient]: {
    functions: [MeasurementFunctions.PulmonicValveInsufficiency],
    structures: [MeasurementStructures.PulmonicValve],
  },
  [MeasurementName.PulmonaryRegurgitationMeanVelocity]: {
    functions: [MeasurementFunctions.PulmonicValveInsufficiency],
    structures: [MeasurementStructures.PulmonicValve],
  },
  [MeasurementName.PulmonaryRegurgitationPeakGradient]: {
    functions: [MeasurementFunctions.PulmonicValveInsufficiency],
    structures: [MeasurementStructures.PulmonicValve],
  },
  [MeasurementName.PulmonaryRegurgitationPeakVelocity]: {
    functions: [MeasurementFunctions.PulmonicValveInsufficiency],
    structures: [MeasurementStructures.PulmonicValve],
  },
  [MeasurementName.PulmonaryRegurgitationPressureHalfTime]: {
    functions: [MeasurementFunctions.PulmonicValveInsufficiency],
    structures: [MeasurementStructures.PulmonicValve],
  },
  [MeasurementName.PulmonaryRegurgitationProximalIsovelocitySurfaceArea]: {
    functions: [MeasurementFunctions.PulmonicValveInsufficiency],
    structures: [MeasurementStructures.PulmonicValve],
  },
  [MeasurementName.PulmonaryRegurgitationProximalIsovelocitySurfaceAreaRadius]: {
    functions: [MeasurementFunctions.PulmonicValveInsufficiency],
    structures: [MeasurementStructures.PulmonicValve],
  },
  [MeasurementName.PulmonaryRegurgitationVelocityTimeIntegral]: {
    functions: [MeasurementFunctions.PulmonicValveInsufficiency],
    structures: [MeasurementStructures.PulmonicValve],
  },
  [MeasurementName.PulmonaryRegurgitationVolume]: {
    functions: [MeasurementFunctions.PulmonicValveInsufficiency],
    structures: [MeasurementStructures.PulmonicValve],
  },
  [MeasurementName.PulmonaryRootDiameter]: {
    functions: [MeasurementFunctions.GreatVessels],
    structures: [MeasurementStructures.PulmonaryArtery],
  },
  [MeasurementName.PulmonaryValveAreaByVelocityTimeIntegral]: {
    functions: [MeasurementFunctions.PulmonicValve],
    structures: [MeasurementStructures.PulmonicValve],
  },
  [MeasurementName.PulmonaryValveAccelerationSlope]: {
    functions: [MeasurementFunctions.PulmonicValve],
    structures: [MeasurementStructures.PulmonicValve],
  },
  [MeasurementName.PulmonaryValveAccelerationTime]: {
    functions: [MeasurementFunctions.PulmonicValve],
    structures: [MeasurementStructures.PulmonicValve],
  },
  [MeasurementName.PulmonaryValveDecelerationTime]: {
    functions: [MeasurementFunctions.PulmonicValve],
    structures: [MeasurementStructures.PulmonicValve],
  },
  [MeasurementName.PulmonaryValveDecelerationSlope]: {
    functions: [MeasurementFunctions.PulmonicValve],
    structures: [MeasurementStructures.PulmonicValve],
  },
  [MeasurementName.PulmonaryValveMeanGradient]: {
    functions: [MeasurementFunctions.PulmonicValve],
    structures: [MeasurementStructures.PulmonicValve],
  },
  [MeasurementName.PulmonaryValveMeanVelocity]: {
    functions: [MeasurementFunctions.PulmonicValve],
    structures: [MeasurementStructures.PulmonicValve],
  },
  [MeasurementName.PulmonaryValvePeakGradient]: {
    functions: [MeasurementFunctions.PulmonicValve],
    structures: [MeasurementStructures.PulmonicValve],
  },
  [MeasurementName.PulmonaryValvePeakVelocity]: {
    functions: [MeasurementFunctions.PulmonicValve],
    structures: [MeasurementStructures.PulmonicValve],
  },
  [MeasurementName.PulmonaryValvePressureHalfTime]: {
    functions: [MeasurementFunctions.PulmonicValve],
    structures: [MeasurementStructures.PulmonicValve],
  },
  [MeasurementName.PulmonaryValveVelocityTimeIntegral]: {
    functions: [MeasurementFunctions.PulmonicValve],
    structures: [MeasurementStructures.PulmonicValve],
  },
  [MeasurementName.PulmonaryVascularResistance]: {
    functions: [MeasurementFunctions.Other],
    structures: [MeasurementStructures.Other],
  },
  [MeasurementName.QpToQsRatio]: {
    functions: [MeasurementFunctions.Other],
    structures: [MeasurementStructures.Other],
  },
  [MeasurementName.RelativeWallThickness]: {
    functions: [MeasurementFunctions.LeftVentricleDimensions],
    structures: [MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.RightAtriumDiameterDiastole]: {
    functions: [MeasurementFunctions.AtriaDimensions],
    structures: [MeasurementStructures.RightAtrium],
  },
  [MeasurementName.RightAtriumDiastolicArea]: {
    functions: [MeasurementFunctions.AtriaDimensions],
    structures: [MeasurementStructures.RightAtrium],
  },
  [MeasurementName.RightAtriumEndDiastolicVolumeBiplane]: {
    functions: [MeasurementFunctions.AtriaDimensions],
    structures: [MeasurementStructures.RightAtrium],
  },
  [MeasurementName.RightAtriumEndDiastolicVolumeUniplaneA2C]: {
    functions: [MeasurementFunctions.AtriaDimensions],
    structures: [MeasurementStructures.RightAtrium],
  },
  [MeasurementName.RightAtriumEndDiastolicVolumeUniplaneA4C]: {
    functions: [MeasurementFunctions.AtriaDimensions],
    structures: [MeasurementStructures.RightAtrium],
  },
  [MeasurementName.RightAtriumEndSystolicVolumeBiplane]: {
    functions: [MeasurementFunctions.AtriaDimensions],
    structures: [MeasurementStructures.RightAtrium],
  },
  [MeasurementName.RightAtriumEndSystolicVolumeBiplaneAreaLength]: {
    functions: [MeasurementFunctions.AtriaDimensions],
    structures: [MeasurementStructures.RightAtrium],
  },
  [MeasurementName.RightAtriumEndSystolicVolumeUniplaneA2C]: {
    functions: [MeasurementFunctions.AtriaDimensions],
    structures: [MeasurementStructures.RightAtrium],
  },
  [MeasurementName.RightAtriumEndSystolicVolumeUniplaneA4C]: {
    functions: [MeasurementFunctions.AtriaDimensions],
    structures: [MeasurementStructures.RightAtrium],
  },
  [MeasurementName.RightAtriumEndSystolicVolumeUniplaneAreaLengthA4C]: {
    functions: [MeasurementFunctions.AtriaDimensions],
    structures: [MeasurementStructures.RightAtrium],
  },
  [MeasurementName.RightAtriumSystolicArea]: {
    functions: [MeasurementFunctions.AtriaDimensions],
    structures: [MeasurementStructures.RightAtrium],
  },
  [MeasurementName.RightAtriumSystolicPressure]: {
    functions: [MeasurementFunctions.Other],
    structures: [MeasurementStructures.RightAtrium, MeasurementStructures.RightVentricle],
  },
  [MeasurementName.RightAtriumSystolicMajorAxisA4C]: {
    functions: [MeasurementFunctions.AtriaDimensions],
    structures: [MeasurementStructures.RightAtrium],
  },
  [MeasurementName.RightPulmonaryArteryDiameter]: {
    functions: [MeasurementFunctions.GreatVessels],
    structures: [MeasurementStructures.PulmonaryArtery],
  },
  [MeasurementName.RightPulmonaryArteryPeakGradient]: {
    functions: [MeasurementFunctions.GreatVessels],
    structures: [MeasurementStructures.PulmonaryArtery],
  },
  [MeasurementName.RightPulmonaryArteryPeakVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.PulmonaryArtery],
  },
  [MeasurementName.RightVentricleMidMinorAxisDiastoleA4C]: {
    functions: [MeasurementFunctions.RightVentricleDimensions],
    structures: [MeasurementStructures.RightVentricle],
  },
  [MeasurementName.RightVentricleEndDiastolicAreaA4C]: {
    functions: [MeasurementFunctions.RightVentricleDimensions],
    structures: [MeasurementStructures.RightVentricle],
  },
  [MeasurementName.RightVentricleEndDiastolicVolumeUniplaneA4C]: {
    functions: [MeasurementFunctions.RightVentricleDimensions],
    structures: [MeasurementStructures.RightVentricle],
  },
  [MeasurementName.RightVentricleEndSystolicAreaA4C]: {
    functions: [MeasurementFunctions.RightVentricleDimensions],
    structures: [MeasurementStructures.RightVentricle],
  },
  [MeasurementName.RightVentricleEndSystolicVolumeUniplaneA4C]: {
    functions: [MeasurementFunctions.RightVentricleDimensions],
    structures: [MeasurementStructures.RightVentricle],
  },
  [MeasurementName.RightVentricleInternalDiastolicDimension]: {
    functions: [MeasurementFunctions.RightVentricleDimensions],
    structures: [MeasurementStructures.RightVentricle],
  },
  [MeasurementName.RightVentricleInternalSystolicDimension]: {
    functions: [MeasurementFunctions.RightVentricleDimensions],
    structures: [MeasurementStructures.RightVentricle],
  },
  [MeasurementName.RightVentricleMajorAxis]: {
    functions: [MeasurementFunctions.RightVentricleDimensions],
    structures: [MeasurementStructures.RightVentricle],
  },
  [MeasurementName.RightVentricleOutflowTractAccelerationTime]: {
    functions: [
      MeasurementFunctions.RightVentricularSystolicFunction,
      MeasurementFunctions.PulmonicValve,
    ],
    structures: [MeasurementStructures.RightVentricularOutflowTract],
  },
  [MeasurementName.RightVentricleOutflowTractArea]: {
    functions: [MeasurementFunctions.RightVentricleDimensions, MeasurementFunctions.PulmonicValve],
    structures: [MeasurementStructures.RightVentricularOutflowTract],
  },
  [MeasurementName.RightVentricleOutflowTractDiameter]: {
    functions: [MeasurementFunctions.RightVentricleDimensions, MeasurementFunctions.PulmonicValve],
    structures: [MeasurementStructures.RightVentricularOutflowTract],
  },
  [MeasurementName.RightVentricleOutflowTractMeanGradient]: {
    functions: [
      MeasurementFunctions.RightVentricularSystolicFunction,
      MeasurementFunctions.PulmonicValve,
    ],
    structures: [MeasurementStructures.RightVentricularOutflowTract],
  },
  [MeasurementName.RightVentricleOutflowTractMeanVelocity]: {
    functions: [
      MeasurementFunctions.RightVentricularSystolicFunction,
      MeasurementFunctions.PulmonicValve,
    ],
    structures: [MeasurementStructures.RightVentricularOutflowTract],
  },
  [MeasurementName.RightVentricleOutflowTractPeakGradient]: {
    functions: [
      MeasurementFunctions.RightVentricularSystolicFunction,
      MeasurementFunctions.PulmonicValve,
    ],
    structures: [MeasurementStructures.RightVentricularOutflowTract],
  },
  [MeasurementName.RightVentricleOutflowTractPeakVelocity]: {
    functions: [
      MeasurementFunctions.RightVentricularSystolicFunction,
      MeasurementFunctions.PulmonicValve,
    ],
    structures: [MeasurementStructures.RightVentricularOutflowTract],
  },
  [MeasurementName.RightVentricleOutflowTractVelocityTimeIntegral]: {
    functions: [
      MeasurementFunctions.RightVentricularSystolicFunction,
      MeasurementFunctions.PulmonicValve,
    ],
    structures: [MeasurementStructures.RightVentricularOutflowTract],
  },
  [MeasurementName.RightVentriclePeakSystolicPressure]: {
    functions: [MeasurementFunctions.RightVentricularSystolicFunction],
    structures: [MeasurementStructures.RightVentricle],
  },
  [MeasurementName.RightVentricleStrokeVolume]: {
    functions: [MeasurementFunctions.RightVentricularSystolicFunction],
    structures: [MeasurementStructures.RightVentricle],
  },
  [MeasurementName.SeptalPosteriorWallMotionDelay]: {
    functions: [MeasurementFunctions.LeftVentricularSystolicFunction],
    structures: [MeasurementStructures.LeftVentricle],
  },
  [MeasurementName.TricuspidAnnulusDiameter]: {
    functions: [
      MeasurementFunctions.TricuspidValve,
      MeasurementFunctions.TricuspidValveRegurgitation,
    ],
    structures: [MeasurementStructures.TricuspidValve],
  },
  [MeasurementName.TricuspidRegurgitationAccelerationTime]: {
    functions: [MeasurementFunctions.TricuspidValveRegurgitation],
    structures: [MeasurementStructures.TricuspidValve],
  },
  [MeasurementName.TricuspidRegurgitationAliasingVelocity]: {
    functions: [MeasurementFunctions.TricuspidValveRegurgitation],
    structures: [MeasurementStructures.TricuspidValve],
  },
  [MeasurementName.TricuspidRegurgitationEffectiveRegurgitantOrificeArea]: {
    functions: [MeasurementFunctions.TricuspidValveRegurgitation],
    structures: [MeasurementStructures.TricuspidValve],
  },
  [MeasurementName.TricuspidRegurgitationMeanGradient]: {
    functions: [MeasurementFunctions.TricuspidValveRegurgitation],
    structures: [MeasurementStructures.TricuspidValve],
  },
  [MeasurementName.TricuspidRegurgitationMeanVelocity]: {
    functions: [MeasurementFunctions.TricuspidValveRegurgitation],
    structures: [MeasurementStructures.TricuspidValve],
  },
  [MeasurementName.TricuspidRegurgitationPeakGradient]: {
    functions: [
      MeasurementFunctions.TricuspidValveRegurgitation,
      MeasurementFunctions.RightVentricularSystolicFunction,
    ],
    structures: [MeasurementStructures.TricuspidValve],
  },
  [MeasurementName.TricuspidRegurgitationPeakVelocity]: {
    functions: [
      MeasurementFunctions.TricuspidValveRegurgitation,
      MeasurementFunctions.RightVentricularSystolicFunction,
    ],
    structures: [MeasurementStructures.TricuspidValve],
  },
  [MeasurementName.TricuspidRegurgitationProximalIsovelocitySurfaceArea]: {
    functions: [MeasurementFunctions.TricuspidValveRegurgitation],
    structures: [MeasurementStructures.TricuspidValve],
  },
  [MeasurementName.TricuspidRegurgitationProximalIsovelocitySurfaceAreaRadius]: {
    functions: [MeasurementFunctions.TricuspidValveRegurgitation],
    structures: [MeasurementStructures.TricuspidValve],
  },
  [MeasurementName.TricuspidRegurgitationVelocityTimeIntegral]: {
    functions: [MeasurementFunctions.TricuspidValveRegurgitation],
    structures: [MeasurementStructures.TricuspidValve],
  },
  [MeasurementName.TricuspidRegurgitationVolume]: {
    functions: [MeasurementFunctions.TricuspidValveRegurgitation],
    structures: [MeasurementStructures.TricuspidValve],
  },
  [MeasurementName.TricuspidValveAccelerationTime]: {
    functions: [MeasurementFunctions.TricuspidValve],
    structures: [MeasurementStructures.TricuspidValve],
  },
  [MeasurementName.TricuspidValveAreaByVelocityTimeIntegral]: {
    functions: [MeasurementFunctions.TricuspidValve],
    structures: [MeasurementStructures.TricuspidValve],
  },
  [MeasurementName.TricuspidValveMeanGradient]: {
    functions: [MeasurementFunctions.TricuspidValve],
    structures: [MeasurementStructures.TricuspidValve],
  },
  [MeasurementName.TricuspidValveMeanVelocity]: {
    functions: [MeasurementFunctions.TricuspidValve],
    structures: [MeasurementStructures.TricuspidValve],
  },
  [MeasurementName.TricuspidValvePeakGradient]: {
    functions: [MeasurementFunctions.TricuspidValve],
    structures: [MeasurementStructures.TricuspidValve],
  },
  [MeasurementName.TricuspidValvePeakVelocity]: {
    functions: [MeasurementFunctions.TricuspidValve],
    structures: [MeasurementStructures.TricuspidValve],
  },
  [MeasurementName.TricuspidValvePressureHalfTime]: {
    functions: [MeasurementFunctions.TricuspidValve],
    structures: [MeasurementStructures.TricuspidValve],
  },
  [MeasurementName.TricuspidValveVelocityTimeIntegral]: {
    functions: [MeasurementFunctions.TricuspidValve],
    structures: [MeasurementStructures.TricuspidValve],
  },
  [MeasurementName.VentricularSeptalDefectDiameter]: {
    functions: [MeasurementFunctions.Other],
    structures: [MeasurementStructures.Other],
  },
  [MeasurementName.RightCarotidArteryDistalEndDiastolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.RightCarotidArtery],
  },
  [MeasurementName.RightCarotidArteryDistalMinimumDiastolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.RightCarotidArtery],
  },
  [MeasurementName.RightCarotidArteryMidLongitudinalEndDiastolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.RightCarotidArtery],
  },
  [MeasurementName.RightCarotidArteryMidLongitudinalMinimumDiastolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.RightCarotidArtery],
  },
  [MeasurementName.RightCarotidArteryMidLongitudinalPeakSystolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.RightCarotidArtery],
  },
  [MeasurementName.RightCarotidArteryProximalEndDiastolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.RightCarotidArtery],
  },
  [MeasurementName.RightCarotidArteryProximalMinimumDiastolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.RightCarotidArtery],
  },
  [MeasurementName.RightCarotidArteryProximalPeakSystolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.RightCarotidArtery],
  },
  [MeasurementName.RightCommonCarotidArteryDistalEndDiastolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.RightCarotidArtery],
  },
  [MeasurementName.RightCommonCarotidArteryDistalMinimumDiastolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.RightCarotidArtery],
  },
  [MeasurementName.RightCommonCarotidArteryDistalPeakSystolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.RightCarotidArtery],
  },
  [MeasurementName.RightCommonCarotidArteryMidLongitudinalEndDiastolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.RightCarotidArtery],
  },
  [MeasurementName.RightCommonCarotidArteryMidLongitudinalMinimumDiastolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.RightCarotidArtery],
  },
  [MeasurementName.RightCommonCarotidArteryMidLongitudinalPeakSystolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.RightCarotidArtery],
  },
  [MeasurementName.RightCommonCarotidArteryProximalEndDiastolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.RightCarotidArtery],
  },
  [MeasurementName.RightCommonCarotidArteryProximalMinimumDiastolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.RightCarotidArtery],
  },
  [MeasurementName.RightCommonCarotidArteryProximalPeakSystolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.RightCarotidArtery],
  },
  [MeasurementName.RightExternalCarotidArteryDistalEndDiastolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.RightCarotidArtery],
  },
  [MeasurementName.RightExternalCarotidArteryDistalMinimumDiastolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.RightCarotidArtery],
  },
  [MeasurementName.RightExternalCarotidArteryDistalPeakSystolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.RightCarotidArtery],
  },
  [MeasurementName.RightExternalCarotidArteryMidLongitudinalEndDiastolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.RightCarotidArtery],
  },
  [MeasurementName.RightExternalCarotidArteryMidLongitudinalMinimumDiastolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.RightCarotidArtery],
  },
  [MeasurementName.RightExternalCarotidArteryMidLongitudinalPeakSystolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.RightCarotidArtery],
  },
  [MeasurementName.RightExternalCarotidArteryProximalEndDiastolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.RightCarotidArtery],
  },
  [MeasurementName.RightExternalCarotidArteryProximalMinimumDiastolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.RightCarotidArtery],
  },
  [MeasurementName.RightExternalCarotidArteryProximalPeakSystolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.RightCarotidArtery],
  },
  [MeasurementName.RightInternalCarotidArteryDistalEndDiastolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.RightCarotidArtery],
  },
  [MeasurementName.RightInternalCarotidArteryDistalMinimumDiastolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.RightCarotidArtery],
  },
  [MeasurementName.RightInternalCarotidArteryDistalPeakSystolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.RightCarotidArtery],
  },
  [MeasurementName.RightInternalCarotidArteryMidLongitudinalEndDiastolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.RightCarotidArtery],
  },
  [MeasurementName.RightInternalCarotidArteryMidLongitudinalMinimumDiastolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.RightCarotidArtery],
  },
  [MeasurementName.RightInternalCarotidArteryMidLongitudinalPeakSystolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.RightCarotidArtery],
  },
  [MeasurementName.RightInternalCarotidArteryProximalEndDiastolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.RightCarotidArtery],
  },
  [MeasurementName.RightInternalCarotidArteryProximalMinimumDiastolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.RightCarotidArtery],
  },
  [MeasurementName.RightInternalCarotidArteryProximalPeakSystolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.RightCarotidArtery],
  },
  [MeasurementName.RightInternalCarotidArteryToCommonCarotidArteryPeakSystolicVelocityRatio]: {
    functions: [MeasurementFunctions.VascularRatios],
    structures: [MeasurementStructures.RightCarotidArtery],
  },
  [MeasurementName.RightVertebralArteryEndDiastolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.RightCarotidArtery],
  },
  [MeasurementName.RightVertebralArteryMinimumDiastolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.RightCarotidArtery],
  },
  [MeasurementName.RightVertebralArteryPeakSystolicVelocity]: {
    functions: [MeasurementFunctions.VascularVelocities],
    structures: [MeasurementStructures.RightCarotidArtery],
  },
};

export function findMeasurementGroups(measurementName: MeasurementName): {
  functions: MeasurementFunctions[];
  structures: MeasurementStructures[];
} {
  return measurementGroups[measurementName];
}
