export const OBJECT_TYPE_KEY = "Type";

export enum ObjectType {
  Dicom = "dicom",
  MP4 = "mp4",
  NRRD = "nrrd",
  PDF = "pdf",
  Thumbnail = "thumbnail",
  ThumbnailAnimated = "thumbnail-animated",
  UploadedFile = "uploaded-file",
  WebpPacked = "webp-packed",
  WebpPackedSeries = "webp-packed-series",
}
