<template>
  <div
    class="clips-area"
    :class="{ 'top-header': slots.topHeader, 'left-header': slots.leftHeader }"
  >
    <div v-if="slots.topHeader" style="grid-area: top-header">
      <slot name="topHeader" />
    </div>

    <div v-if="slots.leftHeader" style="grid-area: left-header">
      <slot name="leftHeader" />
    </div>

    <div
      tabindex="-1"
      class="clips"
      :style="{
        gridTemplateColumns: `repeat(${columnCount}, 1fr)`,
        gridTemplateRows: `repeat(${rowCount}, 1fr)`,
      }"
    >
      <div v-for="(gridItem, index) in clipsGridItems" :key="index" class="clip-container">
        <div
          class="clip"
          :data-testid="`clip-${index}`"
          :data-test-uid="gridItem.clip?.sopInstanceUid"
          :data-test-focused="focusedClipIndex === index"
        >
          <RegularClipViewer
            v-if="gridItem.type === ClipsGridItemType.RegularClip"
            :study="study"
            :grid-item="gridItem"
            :show-measurements="showMeasurements"
            @mousedown="
              (onHandled, isContextMenu) => onClipViewerMouseDown(index, onHandled, isContextMenu)
            "
            @play-pause-button-click="playbackController.onPlayPauseButtonClick(index)"
            @scrub="playbackController.onScrub(index, $event)"
            @dblclick="emits('on-double-click', gridItem.clip?.id ?? '')"
            @scroll-to-measurement="
              (measurementId, openMeasurementPane) =>
                emits('scroll-to-measurement', measurementId, openMeasurementPane)
            "
            @highlight-measurement-card="emits('highlight-measurement-card', $event)"
            @measurement-value-hovered="emits('measurement-value-hovered', $event)"
          />

          <CTClipViewer
            v-else-if="gridItem.type === ClipsGridItemType.CTClip"
            :study="study"
            :grid-item="gridItem"
            :show-measurements="showMeasurements"
            @mousedown="emits('update:focused-clip-index', index)"
            @dblclick="emits('on-double-click', gridItem.clip?.id ?? '')"
          />

          <EncapsulatedPDFViewer
            v-else-if="gridItem.type === ClipsGridItemType.DicomEncapsulatedPDF"
            :study="study"
            :grid-item="gridItem"
          />

          <div
            class="drag-interceptor"
            :class="{ dragging: isDragging }"
            @drop="emits('on-drop', $event, index)"
            @dragenter="emits('on-drag-over', $event, index)"
            @dragover="emits('on-drag-over', $event, index)"
            @dragleave="emits('on-drag-leave', index)"
            @dragover.prevent
            @dragenter.prevent
          />
        </div>

        <!-- Show an outline when a clip thumbnail from the clip list is dragged over this one -->
        <div
          v-if="
            clipsGridItems[index].isDraggedOver.value ||
            (index === focusedClipIndex &&
              rowCount * columnCount > 1 &&
              crosshairsStore.state === CrosshairsStoreState.Inactive)
          "
          class="clip-overlay dragged-over"
          :style="getGridAreaToOverlayClip(index)"
        />

        <div
          class="clip-overlay overlay-slot"
          :style="getGridAreaToOverlayClip(index)"
          :data-testid="`clip-overlay-${index}`"
        >
          <slot name="clipOverlay" v-bind="{ index }" />
        </div>
      </div>
    </div>

    <div style="grid-area: toolbar; display: flex; flex-direction: column">
      <AssociatedMeasurementsBar v-if="isMeasuring" :study="study" style="justify-self: end" />

      <div
        class="bottom-toolbar"
        :class="{
          'clip-controls': !isMeasuring,
          'measurement-pane-handle-visible': isMeasurementPaneHandleVisible,
        }"
      >
        <Tooltip
          v-if="isMeasurementPaneHandleVisible"
          :content="isMeasurementPaneVisible ? 'Hide measurements pane' : 'Show measurements pane'"
          shortcut="M"
        >
          <button
            data-testid="measurement-pane-handle"
            class="accented measurement-pane-handle"
            @click="isMeasurementPaneVisible = !isMeasurementPaneVisible"
          >
            <FontAwesomeIcon
              :icon="isMeasurementPaneVisible ? 'chevron-left' : 'chevron-right'"
              size="lg"
            />
          </button>
        </Tooltip>

        <MeasurementToolbar
          v-if="isMeasuring"
          :study="study"
          @measurement-changed="onMeasurementChanged"
        />

        <template v-else>
          <div class="packed-controls">
            <MeasurementToolPopper :study="study" :clips-grid-items="clipsGridItems" />

            <MeasurementCalculationPopper @open-measurement-pane="emits('open-measurement-pane')" />

            <template v-if="getWindowType() !== WindowType.ExtraClips">
              <Tooltip
                :content="showMeasurements ? 'Hide measurements' : 'Show measurements'"
                shortcut="V"
              >
                <button
                  data-testid="toggle-measurement-visibility-btn"
                  class="outline-when-active"
                  @click="toggleMeasurementVisibility"
                >
                  <FontAwesomeIcon :icon="`${showMeasurements ? 'eye' : 'eye-slash'}`" />
                </button>
              </Tooltip>
            </template>
          </div>

          <template v-if="getWindowType() !== WindowType.ExtraClips">
            <div
              v-if="visibleMeasurementValuesWithContoursCount !== undefined"
              class="packed-controls"
              :class="{ disabled: visibleMeasurementValuesWithContoursCount === 0 }"
            >
              <Tooltip content="View previous measurements" shortcut="⇧">
                <button @click="onMeasurementValueGroupPrevious">
                  <FontAwesomeIcon icon="chevron-up" data-testid="show-previous-measurements-btn" />
                </button>
              </Tooltip>
              <Tooltip content="View next measurements" shortcut="⇩">
                <button @click="onMeasurementValueGroupNext">
                  <FontAwesomeIcon icon="chevron-down" data-testid="show-next-measurements-btn" />
                </button>
              </Tooltip>
            </div>

            <Tooltip
              v-if="isStressStudy"
              :content="`${isStressModeEnabled ? 'Leave' : 'Enter'} stress echo mode`"
              shortcut="S"
            >
              <button
                data-testid="stress-mode-btn"
                class="outline-when-active"
                :class="{ active: isStressModeEnabled }"
                style="width: max-content"
                @click="toggleStressMode"
              >
                <FontAwesomeIcon icon="heart-pulse" />
                Stress
              </button>
            </Tooltip>

            <Tooltip
              v-if="areRegularClipsPresent"
              :content="`${isClipSyncEnabled ? 'Disable' : 'Enable'} synchronized playback`"
              shortcut="C"
            >
              <button
                class="outline-when-active"
                :class="{ active: isClipSyncEnabled }"
                data-testid="clip-sync-toggle"
                @click="emits('update:is-clip-sync-enabled', !isClipSyncEnabled)"
              >
                <FontAwesomeIcon :icon="isClipSyncEnabled ? 'lock' : 'unlock'" />
              </button>
            </Tooltip>

            <PlaybackSpeedSlider
              v-if="areRegularClipsPresent"
              :model-value="playbackSpeedFactor"
              @update:model-value="(newValue) => emits('update:playback-speed-factor', newValue)"
            />
            <ColorMapSelector
              v-if="areRegularClipsPresent"
              :color-map="focusedClipColorMap"
              @update:clip-color-map="emits('update:clip-color-map', $event)"
            />

            <CTWindowingControls
              v-if="clipsGridItems.some((i) => i.type === ClipsGridItemType.CTClip)"
            />
          </template>

          <Tooltip
            v-if="!isStudyProcessed"
            style="margin-left: auto"
            :content="`Study is still being received and processed (${percentProcessed} complete)`"
          >
            <div class="study-incomplete-message">
              <LoadingIndicator :accented="false" />
              Study Processing
            </div>
          </Tooltip>

          <div style="margin-left: auto" />

          <slot name="toolbarItems" />

          <Tooltip
            v-if="getWindowType() === WindowType.Primary"
            :content="secondaryWindowTooltipContent"
          >
            <button
              :disabled="
                getSecondaryWindowState() !== SecondaryWindowState.Closed &&
                getSecondaryWindowType() === WindowType.ExtraClips
              "
              data-testid="open-secondary-window-btn"
              :data-test-state="getSecondaryWindowState()"
              @click="openSecondaryWindow({ type: WindowType.ExtraClips, isStressModeEnabled })"
            >
              <FontAwesomeIcon
                :icon="
                  getSecondaryWindowState() === SecondaryWindowState.Opening
                    ? 'spinner'
                    : 'up-right-from-square'
                "
                :spin="getSecondaryWindowState() === SecondaryWindowState.Opening"
              />
            </button>
          </Tooltip>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import Tooltip from "@/components/Tooltip.vue";
import PlaybackSpeedSlider from "@/study-view/PlaybackSpeedSlider.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useEventListener, useStorage } from "@vueuse/core";
import { computed, ref, watch } from "vue";
import { getClips } from "../../../backend/src/studies/study-helpers";
import AssociatedMeasurementsBar from "../measurements/AssociatedMeasurementsBar.vue";
import MeasurementToolbar from "../measurements/MeasurementToolbar.vue";
import { activeMeasurement, isMeasuring } from "../measurements/measurement-tool-state";
import { ColorMap } from "../utils/color-map";
import { onKeyboardShortcut } from "../utils/keyboard-shortcut";
import { Study, getStudyProcessedClipPercentage } from "../utils/study-data";
import ColorMapSelector from "./ColorMapSelector.vue";
import MeasurementCalculationPopper from "./MeasurementCalculationPopper.vue";
import MeasurementToolPopper from "./MeasurementToolPopper.vue";
import CTClipViewer from "./clip-viewer/CTClipViewer.vue";
import EncapsulatedPDFViewer from "./clip-viewer/EncapsulatedPDFViewer.vue";
import RegularClipViewer from "./clip-viewer/RegularClipViewer.vue";
import {
  ClipsGridItem,
  ClipsGridItemType,
  getRegularClipsGridItems,
} from "./clip-viewer/clips-grid-item";
import { createRegularClipPlaybackController } from "./clip-viewer/regular-clip-playback";
import CTWindowingControls from "./ct/CTWindowingControls.vue";
import { CrosshairsStoreState, crosshairsStore } from "./ct/ct-crosshairs";
import { postMessageToPrimaryWindow } from "./multi-window/primary-window-messages";
import {
  SecondaryWindowState,
  WindowType,
  getSecondaryWindowState,
  getSecondaryWindowType,
  getWindowType,
  openSecondaryWindow,
} from "./multi-window/secondary-window";
import { postMessageToSecondaryWindow } from "./multi-window/secondary-window-messages";
import { isStressClip } from "./study-clip-helpers";

interface Props {
  study: Study;
  clipsGridItems: ClipsGridItem[];
  focusedClipIndex: number;
  columnCount: number;
  rowCount: number;
  isClipSyncEnabled: boolean;
  playbackSpeedFactor: number;
  isStressModeEnabled: boolean;
  showMeasurements: boolean;
  visibleMeasurementValuesWithContoursCount?: number;
}

interface Emits {
  (event: "update:focused-clip-index", index: number): void;
  (event: "update:is-clip-sync-enabled", enabled: boolean): void;
  (event: "update:playback-speed-factor", value: number): void;
  (event: "update:is-stress-mode-enabled", enabled: boolean): void;
  (event: "update:show-measurements", enabled: boolean): void;
  (event: "update:clip-color-map", colorMap: ColorMap): void;
  (event: "on-double-click", clipId: string): void;
  (event: "on-drop", dragEvent: DragEvent, index: number): void;
  (event: "on-drag-over", dragEvent: DragEvent, index: number): void;
  (event: "on-drag-leave", index: number): void;
  (event: "open-measurement-pane"): void;
  (event: "scroll-to-measurement", measurementId: string, openMeasurementPane: boolean): void;
  (event: "measurement-values-show-next"): void;
  (event: "measurement-values-show-previous"): void;
  (event: "highlight-measurement-card", measurementId: string): void;
  (event: "measurement-value-hovered", measurementValueId: string | null): void;
}

const props = withDefaults(defineProps<Props>(), {
  visibleMeasurementValuesWithContoursCount: undefined,
});
const emits = defineEmits<Emits>();

const slots = defineSlots<{
  topHeader?: (props: unknown) => unknown;
  leftHeader?: (props: unknown) => unknown;
  toolbarItems?: (props: unknown) => unknown;
  clipOverlay: (props: { index: number }) => unknown;
}>();

const isStudyProcessed = computed(() =>
  getClips(props.study).every((clip) => clip.processedAt !== null)
);
const percentProcessed = computed(() => getStudyProcessedClipPercentage(props.study));

const isStressStudy = computed(() => getClips(props.study).some(isStressClip));

const isMeasurementPaneVisible = useStorage("measurement-pane-visible", true);

const isMeasurementPaneHandleVisible = computed(() => getWindowType() === WindowType.Primary);

const regularGridItems = computed(() => getRegularClipsGridItems(props.clipsGridItems));

function toggleStressMode(): void {
  if (!isStressStudy.value) {
    return;
  }

  const enabled = !props.isStressModeEnabled;

  // If this is an extra clips secondary window then send a message to the primary window to toggle
  // stress mode
  if (getWindowType() === WindowType.ExtraClips) {
    postMessageToPrimaryWindow({ type: "set-stress-mode-enabled", isStressModeEnabled: enabled });
    return;
  }

  emits("update:is-stress-mode-enabled", enabled);
  emits("update:is-clip-sync-enabled", enabled);
}

const playbackController = createRegularClipPlaybackController(
  props.clipsGridItems,
  computed(() => props.playbackSpeedFactor),
  computed(() => props.isClipSyncEnabled)
);

function getGridAreaToOverlayClip(index: number): string {
  return `grid-area: ${Math.floor(index / props.columnCount) + 1} / ${
    (index % props.columnCount) + 1
  } / span 1 / span 1`;
}

function onClipViewerMouseDown(
  clipIndex: number,
  onHandled: () => void,
  isContextMenu = false
): void {
  emits("update:focused-clip-index", clipIndex);

  if (isContextMenu) {
    // If a measurement type other than linear is currently active then swallow right-clicks so they
    // don't change the measurement tool and start a new linear measurement.
    if (
      activeMeasurement.value.displayName !== "null" &&
      activeMeasurement.value.displayName !== "linear"
    ) {
      return;
    }

    // Pause all clips on context menu creation of a linear measurement
    for (const item of regularGridItems.value) {
      item.isPlaying.value = false;
    }
  }

  onHandled();
}

function onMeasurementChanged(measurementId: string): void {
  emits("update:show-measurements", true);
  emits("scroll-to-measurement", measurementId, false);
}

function toggleMeasurementVisibility(): void {
  emits("update:show-measurements", !props.showMeasurements);

  // Toggling measurement visibility clears any solo'd measurement values
  for (const item of regularGridItems.value) {
    item.soloMeasurementValueId.value = undefined;
  }
}

onKeyboardShortcut("v", toggleMeasurementVisibility);

watch(
  () => props.showMeasurements,
  (isVisible) => postMessageToSecondaryWindow({ type: "set-measurement-visibility", isVisible }),
  { immediate: true }
);

const areRegularClipsPresent = computed(() => regularGridItems.value.length !== 0);

const secondaryWindowTooltipContent = computed(() => {
  const secondaryWindowState = getSecondaryWindowState();

  if (
    secondaryWindowState === SecondaryWindowState.Closed ||
    getSecondaryWindowType() !== WindowType.ExtraClips
  ) {
    return "Show more images in secondary window";
  }

  if (secondaryWindowState === SecondaryWindowState.Opening) {
    return "Secondary window is opening";
  }

  return "Secondary window is already open";
});

// Stop all clips playing when a measurement edit is started
watch(
  () => activeMeasurement.value.editingMeasurementBatchId.value,
  () => {
    if (activeMeasurement.value.editingMeasurementBatchId.value !== null) {
      regularGridItems.value.forEach((c) => (c.isPlaying.value = false));
    }
  }
);

function onMeasurementValueGroupNext(): void {
  emits("measurement-values-show-next");
}

function onMeasurementValueGroupPrevious(): void {
  emits("measurement-values-show-previous");
}

// Add up/down arrow keyboard shortcuts for moving through measurement value groups. The default
// behavior is prevented to stop the arrow keys also scrolling up/down inside any focused scrollable
// context (such as the clip list).
onKeyboardShortcut("ArrowDown", (event: KeyboardEvent) => {
  event.preventDefault();
  onMeasurementValueGroupNext();
});
onKeyboardShortcut("ArrowUp", (event: KeyboardEvent) => {
  event.preventDefault();
  onMeasurementValueGroupPrevious();
});

const focusedClipColorMap = computed(() => {
  const focusedClip = props.clipsGridItems.at(props.focusedClipIndex);
  if (focusedClip?.type !== ClipsGridItemType.RegularClip) {
    return undefined;
  }

  return focusedClip.colorMap.value;
});

/**
 * We need to keep track of the user's current window drag state to know whether or not to enable
 * pointer events to the drag event handler which sits on top of the clips. This is because the PDF
 * iframe isn't able to receive the drag events that would otherwise be on the grid item container,
 * so we need to put a element on top of the iframe which receives & handles drag/drop events. There
 * isn't a way for this element to only receive drag events and pass through others, so we need to
 * enable/disable pointer events on it based on the current drag state.
 */

const isDragging = ref(false);

useEventListener("dragstart", () => {
  isDragging.value = true;
});

useEventListener("dragend", () => {
  isDragging.value = false;
});

if (getWindowType() === WindowType.Primary) {
  // 'S' toggles stress mode
  onKeyboardShortcut("s", () => toggleStressMode());
}

onKeyboardShortcut("c", () => emits("update:is-clip-sync-enabled", !props.isClipSyncEnabled));
</script>

<style scoped lang="scss">
.clips-area {
  flex: 1;
  display: grid;
  background-color: black;

  grid-template-areas: "clips clips" "clips clips" "toolbar toolbar";
  grid-template-columns: 36px 1fr;
  grid-template-rows: min-content 1fr min-content;

  &.top-header {
    grid-template-areas: "top-header top-header" "clips clips" "toolbar toolbar";

    &.left-header {
      grid-template-areas: "top-header top-header" "left-header clips" "toolbar toolbar";
    }
  }

  [tabindex] {
    outline: none !important;
  }
}

.clips {
  flex: 1;
  display: grid;
  overflow: hidden;
  position: relative;
  grid-area: clips;

  .dragged-over {
    border: 1px solid var(--input-focus-border-color);
  }
}

.clip-container {
  display: contents;

  &:hover {
    > .overlay-slot {
      opacity: 1;
    }
  }
}

.clip {
  place-self: stretch;
  display: grid;
  place-items: stretch;
  position: relative;
  min-width: 0;
}

.clip-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: none;

  &.overlay-slot {
    opacity: 0;
    z-index: 2;
    transition: opacity 100ms ease;
  }
}

.bottom-toolbar {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 48px;
  background-color: var(--bg-color-1);
  border-top: 1px solid var(--border-color-1);
  color: var(--accent-color-1);
  padding: 0 8px;

  .measurement-pane-handle {
    width: 20px;
    border-radius: 0 var(--border-radius) var(--border-radius) 0;
  }

  &.measurement-pane-handle-visible {
    padding-left: 0;
  }
}

.clip-controls {
  :deep(button) {
    width: 36px;
    height: 32px;

    svg {
      font-size: 1.2em;
    }
  }

  :deep(.packed-controls) {
    display: flex;
    border-radius: var(--border-radius);
    overflow: hidden;

    button {
      border-radius: 0;
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}

.study-incomplete-message {
  font-weight: bold;
  border-radius: var(--border-radius);
  background-color: var(--accent-color-hot);
  color: var(--text-color-1);
  padding: 8px;
  display: flex;
  gap: 8px;
  white-space: nowrap;
}

.drag-interceptor {
  grid-area: 1 / 1;
  z-index: 10;
  position: absolute;
  height: 100%;
  width: 100%;
  pointer-events: none;

  &.dragging {
    pointer-events: auto;
  }
}
</style>
