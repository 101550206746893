import { getClips } from "../../../backend/src/studies/study-helpers";
import { toDateTime } from "../../../backend/src/studies/study-time";
import { removeNullish } from "../utils/array-helpers";
import type { Study, StudyClip, StudySeries } from "../utils/study-data";

function compareClipContentTimestamp(a: StudyClip, b: StudyClip): number {
  const aTakenAt = toDateTime(a.takenAt);
  const bTakenAt = toDateTime(b.takenAt);

  if (aTakenAt !== undefined && bTakenAt !== undefined) {
    return aTakenAt < bTakenAt ? -1 : 1;
  }

  // Sort all unprocessed clips to the end of the list
  if (a.processedAt === null || b.processedAt === null) {
    return Number(Boolean(b)) - Number(Boolean(a));
  }

  return a.sopInstanceUid.localeCompare(b.sopInstanceUid);
}

/** Returns the clips for the study sorted by their takenAt timestamp. */
export function getClipsSortedByContentTimestamp(clips: StudyClip[]): StudyClip[] {
  return clips
    .filter(
      (clip) =>
        (clip.height !== null && clip.width !== null) ||
        clip.processedAt === null ||
        clip.hasEncapsulatedPDF
    )
    .sort(compareClipContentTimestamp);
}

/** Returns the clips in the study that are valid, i.e. did not fail to process for some reason. */
export function getValidClips(study: Study): StudyClip[] {
  return getClipsSortedByContentTimestamp(getClips(study)).filter(
    (clip) => clip.errorDetails === null
  );
}

/** Returns the series in the study along with their valid clips. */
export function getValidClipsGroupedBySeries(
  study: Study
): { series: StudySeries; clips: StudyClip[] }[] {
  const allSeries = study.series.map((series) => ({
    series,
    clips: getClipsSortedByContentTimestamp(series.clips).filter(
      (clip) => clip.errorDetails === null
    ),
  }));

  // Sort the series by the content timestamp of their first clip
  return allSeries
    .filter((s) => s.clips.length > 0)
    .sort((a, b) => compareClipContentTimestamp(a.clips[0], b.clips[0]));
}

/** Returns the clips in the study that have failed to process for some reason. */
export function getErroredClips(study: Study): StudyClip[] {
  return getClipsSortedByContentTimestamp(getClips(study)).filter(
    (clip) => clip.errorDetails !== null
  );
}

/**
 * Describes a single stress clip, which is the same type as a normal clip but with the relevant
 * nulls removed.
 */
export type StudyClipStress = StudyClip & {
  stageName: string;
  stageNumber: number;
  viewName: string;
  viewNumber: number;
};

/** Returns whether the given clip is a stress clip from a stress echo. */
export function isStressClip(clip: StudyClip): clip is StudyClipStress {
  return (
    clip.stageName !== null &&
    clip.stageNumber !== null &&
    clip.viewName !== null &&
    clip.viewNumber !== null
  );
}

/** Returns the clips in the study that are from a stress echo. */
export function getStressClips(study: Study): StudyClipStress[] {
  return getValidClips(study).filter(isStressClip);
}

/** Returns the names of the stages for a stress echo, in the correct order. */
export function getStressStageNames(study: Study): string[] {
  const clips = getStressClips(study);

  const stageNames: (string | undefined)[] = [];

  for (const clip of clips) {
    stageNames[clip.stageNumber] ??= clip.stageName;
  }

  return removeNullish(stageNames);
}

/** Returns the names of the views for a stress echo, in the correct order. */
export function getStressViewNames(study: Study): string[] {
  const clips = getStressClips(study);

  const viewNames: (string | undefined)[] = [];

  for (const clip of clips) {
    if (!viewNames.includes(clip.viewName)) {
      viewNames[clip.viewNumber] ??= clip.viewName;
    }
  }

  return removeNullish(viewNames);
}

/**
 * Returns the stress clips for the study, sorted by view and stage. The first level of the
 * hierarchy is the view, the next is the stage, and for that combination of view and stage a
 * list of zero or more clip IDs is returned.
 */
export function getStressClipsSortedByViewAndStage(study: Study): string[][][] {
  const stressClips = getStressClips(study);

  const result: string[][][] = [];

  for (const viewName of getStressViewNames(study)) {
    result.push([]);

    for (const stageName of getStressStageNames(study)) {
      result[result.length - 1].push(
        stressClips
          .filter((clip) => clip.viewName === viewName && clip.stageName === stageName)
          .map((clip) => clip.id)
      );
    }
  }

  return result;
}
