<template>
  <div v-if="isLoaded" class="compared-study">
    <div class="toolbar">
      <div />

      <DropdownWidget
        class="study-selection-dropdown"
        :model-value="study.id"
        :items="studySelectionDropdownItems"
        @update:model-value="(id) => emits('update:studyId', id)"
      />

      <div class="toolbar-buttons">
        <OpenStudyInSecondaryWindow :study-id="study.id" />

        <Tooltip
          v-if="latestSignedReport"
          :content="
            isShowingLatestPdf
              ? 'Return to viewing study clips'
              : 'View latest signed report for study'
          "
          @click="isShowingLatestPdf = !isShowingLatestPdf"
        >
          <FontAwesomeIcon
            class="latest-pdf-btn"
            :class="{ active: isShowingLatestPdf }"
            icon="file-pdf"
          />
        </Tooltip>
      </div>
    </div>

    <ReportPDFViewer
      v-if="isShowingLatestPdf && latestSignedReport"
      :study-id="study.id"
      :study-report-id="latestSignedReport.id"
    />

    <template v-else>
      <div
        v-if="selectedGridItem !== undefined"
        class="clip"
        :data-testid="`comparison-clip-${props.gridItemIndex}`"
        :data-test-uid="selectedGridItem?.clip?.sopInstanceUid"
        @drop="onDrop($event, props.gridItemIndex)"
        @dragenter="onDragOver($event, props.gridItemIndex)"
        @dragover="onDragOver($event, props.gridItemIndex)"
        @dragleave="onDragLeave(props.gridItemIndex)"
        @dragover.prevent
        @dragenter.prevent
      >
        <RegularClipViewer
          v-if="selectedGridItem.type === ClipsGridItemType.RegularClip"
          :study="study"
          :grid-item="selectedGridItem"
          :show-measurements="true"
          :is-comparison-viewer="true"
          @mousedown="(onHandled) => onHandled()"
          @play-pause-button-click="playbackController.onPlayPauseButtonClick(gridItemIndex)"
          @scrub="playbackController.onScrub(gridItemIndex, $event)"
        />

        <CTClipViewer
          v-else-if="selectedGridItem.type === ClipsGridItemType.CTClip"
          :study="study"
          :grid-item="selectedGridItem"
          :show-measurements="true"
        />
      </div>

      <HorizontalClipList
        :data-testid="`comparison-clip-list-${props.gridItemIndex}`"
        :study="study"
        :selected-clip-ids="selectedClipIds"
        @clip-thumbnail-click="(clip) => onClickThumbnail(clip.id)"
        @measurement-icon-click="onJumpToMeasurementValue"
      />
    </template>
  </div>

  <div v-else class="loading-container">
    <LoadingIndicator size="4x" />
  </div>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import axios, { AxiosResponse } from "axios";
import { computed, ref, watch } from "vue";
import { formatDateTime } from "../../../../backend/src/shared/date-time-utils";
import type { PatientStudiesGetManyResponseDto } from "../../../../backend/src/studies/dto/patient-studies-get-many.dto";
import { StudyGetOneResponseDto } from "../../../../backend/src/studies/dto/study-get-one.dto";
import { getClips } from "../../../../backend/src/studies/study-helpers";
import { getStudyDateTime } from "../../../../backend/src/studies/study-time";
import DropdownWidget from "../../components/DropdownWidget.vue";
import LoadingIndicator from "../../components/LoadingIndicator.vue";
import Tooltip from "../../components/Tooltip.vue";
import ReportPDFViewer from "../../reporting/ReportPDFViewer.vue";
import { addNotification } from "../../utils/notifications";
import { getEmptyStudy, StudyMeasurementValue } from "../../utils/study-data";
import {
  ClipsGridItem,
  ClipsGridItemType,
  updateClipsGridItemsArrayFromSelectedClips,
  useGridItemDragDropEventHandlers,
} from "../clip-viewer/clips-grid-item";
import CTClipViewer from "../clip-viewer/CTClipViewer.vue";
import { RegularClipPlaybackController } from "../clip-viewer/regular-clip-playback";
import RegularClipViewer from "../clip-viewer/RegularClipViewer.vue";
import OpenStudyInSecondaryWindow from "../multi-window/OpenStudyInSecondaryWindow.vue";
import { getClipsSortedByContentTimestamp } from "../study-clip-helpers";
import HorizontalClipList from "./HorizontalClipList.vue";

interface Props {
  patientStudies: PatientStudiesGetManyResponseDto;
  studyId: string | null;
  currentStudyId: string | null;
  playbackController: RegularClipPlaybackController;
  clipsGridItems: ClipsGridItem[];
  selectedClipIds: string[];
  gridItemIndex: number;
}

interface Emits {
  (event: "update:studyId", studyId: string): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const study = ref(getEmptyStudy());
const isLoaded = ref(false);
const isShowingLatestPdf = ref(false);

const selectedGridItem = computed(() => props.clipsGridItems[props.gridItemIndex]);

// Patient studies come in descending takenAt order
const studySelectionDropdownItems = computed(() =>
  props.patientStudies.map((study) => ({
    value: study.id,
    text: `${formatDateTime(getStudyDateTime(study), { includeTime: true })}${study.id === props.currentStudyId ? " (current)" : ""}`,
  }))
);

const latestSignedReport = computed(() => study.value.reports.find((report) => report.isSigned));

async function loadStudy(): Promise<void> {
  study.value = getEmptyStudy();
  isLoaded.value = false;

  let response: AxiosResponse<StudyGetOneResponseDto> | undefined = undefined;
  try {
    response = await axios.get<StudyGetOneResponseDto>(`/api/studies/${props.studyId}`);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 403) {
        addNotification({ type: "error", message: "Unauthorized access to study" });
      } else {
        addNotification({ type: "error", message: "Failed loading study" });
      }
    }

    return;
  }

  study.value = response.data;
  isLoaded.value = true;

  const selectedClipIds = props.selectedClipIds;
  selectedClipIds[props.gridItemIndex] = getClipsSortedByContentTimestamp(
    getClips(study.value)
  )[0].id;
}

function updateClipsGridItems(): void {
  updateClipsGridItemsArrayFromSelectedClips(
    props.clipsGridItems,
    study.value,
    props.selectedClipIds,
    false,
    true
  );
}

const { onDrop, onDragOver, onDragLeave } = useGridItemDragDropEventHandlers(
  props.clipsGridItems,
  props.selectedClipIds
);

function onJumpToMeasurementValue(measurementValue: StudyMeasurementValue): void {
  const clip = getClips(study.value).find((c) => c.id === measurementValue.studyClipId);
  if (clip === undefined) {
    return;
  }

  const selectedClipIds = props.selectedClipIds;
  selectedClipIds[props.gridItemIndex] = clip.id;
  updateClipsGridItems();

  if (measurementValue.frame === null || measurementValue.contour === null) {
    return;
  }

  // Jump to the frame containing the measurement value
  const item = props.clipsGridItems[props.selectedClipIds.indexOf(clip.id)];
  if (item.type !== ClipsGridItemType.RegularClip) {
    return;
  }

  item.setCurrentFrame(measurementValue.frame);
  if (item.soloMeasurementValueId.value === measurementValue.id) {
    item.soloMeasurementValueId.value = undefined;
  } else {
    item.soloMeasurementValueId.value = measurementValue.id;
  }
}

function onClickThumbnail(clipId: string): void {
  const selectedClipIds = props.selectedClipIds;
  selectedClipIds[props.gridItemIndex] = clipId;
}

watch(() => props.selectedClipIds, updateClipsGridItems, { deep: true });

watch(() => props.studyId, loadStudy, { immediate: true });
</script>

<style scoped lang="scss">
.loading-container {
  display: grid;
  place-items: center;
  height: 100%;
  background-color: var(--bg-color-2);
}

.compared-study {
  display: grid;
  grid-template-rows: auto 1fr auto;
  gap: 1px;
}

.toolbar {
  background-color: var(--bg-color-2);
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  align-self: center;
}

.study-selection-dropdown {
  border: 0;
  background-color: var(--bg-color-2) !important;
}

.toolbar-buttons {
  display: flex;
  flex-direction: row-reverse;
  gap: 12px;
  align-items: center;
  padding-right: 12px;
}

.clip {
  min-width: 0;
  place-self: stretch;
  display: grid;
  place-items: stretch;
  position: relative;
}

.latest-pdf-btn {
  cursor: pointer;
  transition: color 100ms ease;

  &.active,
  &:hover {
    color: var(--text-color-2);
  }
}
</style>
