import type { PatientMetrics } from "../../../../../backend/src/measurements/measurement-display";
import type { StudyMeasurementDisplayOption } from "../../../../../backend/src/studies/study-measurement-enums";
import type { ReferenceRangeSetName } from "../../../reference-ranges/reference-range-sets";
import type { Study, StudyMeasurement, StudyMeasurementValue } from "../../../utils/study-data";

export interface TabEmits {
  (event: "update:is-measurement-card-expanded", id: string, value: boolean): void;
  (event: "update:opened-groups", value: string[]): void;
  (
    event: "update:measurement-display-option",
    id: string,
    value: StudyMeasurementDisplayOption
  ): void;
}

export enum TabType {
  Alphabetical = "alphabetical",
  Structure = "structure",
  Function = "function",
}

export type TabProps = CommonPaneSectionProps & {
  type: TabType;
  groups: { name: string; measurements: StudyMeasurement[] }[];
  openedGroups: string[];
  searchTerm: string;
};

export interface CommonPaneSectionProps {
  isMeasurementCardExpanded: Record<string, boolean>;
  study: Study;
  highlightedMeasurementId: string | null;
  hoveredMeasurementValueId: string | null;
  referenceRangeSetName: ReferenceRangeSetName;
  patientMetrics: PatientMetrics | undefined;
  visibleFrames: {
    studyClipId: string;
    frame: number;
    soloMeasurementValueId: string | undefined;
  }[];
  onJumpToValue: (value: StudyMeasurementValue) => void;
}
