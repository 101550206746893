<template>
  <div class="patient-info-container">
    <div
      class="patient-info"
      :class="{
        collapsed: !isExpanded,
        'report-open': isReportOpen,
        'is-extra-clips-secondary-window': getWindowType() === WindowType.ExtraClips,
      }"
      data-testid="patient-info-section"
    >
      <template v-if="isExpanded">
        <span class="field-name" style="grid-area: sex-label">Sex</span>
        <span class="field-value" style="grid-area: sex" data-testid="patient-sex">
          {{ getPatientSexDisplayText(study.patientSex) }}
        </span>

        <span class="field-name" style="grid-area: technician-label">Performed by</span>
        <span class="field-value" style="grid-area: technician" data-testid="patient-technician">
          {{ formatDicomName(study.performingPhysician) }}
        </span>

        <span class="field-name" style="grid-area: age-label">Age</span>
        <span class="field-value" style="grid-area: age" data-testid="patient-age">
          {{ getPatientAgeWhenScanned(study) }}
        </span>

        <span class="field-name" style="grid-area: modality-label">Modality</span>
        <span class="field-value" style="grid-area: modality" data-testid="patient-modality">
          {{ study.modality }}
        </span>

        <span class="field-name" style="grid-area: type-label">Type</span>
        <span class="field-value" style="grid-area: type" data-testid="patient-study-type">
          {{ studyTypeDisplayName[study.type] }}
        </span>

        <span class="field-name" style="grid-area: referrer-label">Referred by</span>
        <span class="field-value" style="grid-area: referrer" data-testid="patient-referred-by">
          {{ formatDicomName(study.referringPhysician) }}
        </span>

        <span class="field-name" style="grid-area: birthdate-label">DOB</span>
        <span class="field-value" style="grid-area: birthdate" data-testid="patient-dob">
          {{ formatDateTime(study.patientBirthdate) }}
        </span>

        <span class="field-name" style="grid-area: ethnicity-label">Ethnicity</span>
        <div style="grid-area: ethnicity">
          <DropdownInput
            :current-item="ethnicity"
            :items="currentTenant.ethnicityOptions"
            :enabled="isStudyUpdatePermitted(study)"
            @select-item="(item) => (ethnicity = item)"
          >
            <span
              class="field-value"
              :class="{ editable: isStudyUpdatePermitted(study) }"
              style="height: 25px"
            >
              <input
                v-model="ethnicity"
                name="ethnicities"
                :disabled="!isStudyUpdatePermitted(study)"
                data-testid="ethnicity-input"
                autocomplete="off"
                maxlength="16"
              />
            </span>
          </DropdownInput>
        </div>

        <span class="field-name" style="grid-area: height-label">Height</span>
        <span
          class="field-value"
          style="grid-area: height"
          data-testid="patient-height"
          :class="{ editable: isStudyUpdatePermitted(study) }"
        >
          <Tooltip
            style="width: 50%; padding-right: 8px"
            :content="!isHeightValid() ? 'Height value is unexpectedly large, please verify' : ''"
          >
            <input
              v-model="heightValue"
              type="number"
              min="0"
              data-testid="patient-height-input"
              :style="!isHeightValid() ? { borderColor: 'red' } : {}"
              :disabled="!isStudyUpdatePermitted(study)"
            />
          </Tooltip>
          m
        </span>

        <span class="field-name" style="grid-area: weight-label">Weight</span>
        <span
          class="field-value"
          style="grid-area: weight"
          data-testid="patient-weight"
          :class="{ editable: isStudyUpdatePermitted(study) }"
        >
          <Tooltip
            style="width: 50%; padding-right: 8px"
            :content="!isWeightValid() ? 'Weight value is unexpectedly large, please verify' : ''"
          >
            <input
              v-model="weightValue"
              type="number"
              min="0"
              data-testid="patient-weight-input"
              :style="!isWeightValid() ? { borderColor: 'red' } : {}"
              :disabled="!isStudyUpdatePermitted(study)"
            />
          </Tooltip>
          kg
        </span>

        <span class="field-name" style="grid-area: bsa-label">BSA</span>
        <span class="field-value" style="grid-area: bsa" data-testid="patient-bsa">
          <template v-if="patientMetrics">
            {{ patientMetrics?.bodySurfaceArea.toFixed(2) }} m²
          </template>
          <template v-else>–</template>
        </span>

        <span class="field-name" style="grid-area: indication-label">Indication</span>
        <div
          class="field-value"
          :class="{ editable: isStudyUpdatePermitted(study) }"
          style="grid-area: indication"
        >
          <input
            v-model="indicationValue"
            :disabled="!isStudyUpdatePermitted(study)"
            data-testid="indication-input"
          />
        </div>

        <span class="field-name" style="grid-area: medical-history-label">History</span>
        <div
          class="field-value medical-history"
          :class="{ editable: isStudyUpdatePermitted(study) }"
          style="grid-area: medical-history"
        >
          <textarea
            v-model="patientMedicalHistory"
            :disabled="!isStudyUpdatePermitted(study)"
            data-testid="medical-history-input"
          />
        </div>
      </template>

      <template v-else>
        <span class="field-name">Age</span>
        <span class="field-value" data-testid="patient-age">
          {{ getPatientAgeWhenScanned(study) }}
        </span>

        <span class="field-name">Sex</span>
        <span class="field-value" data-testid="patient-sex">
          {{ getPatientSexDisplayText(study.patientSex) }}
        </span>

        <span class="field-name">BSA</span>
        <span class="field-value" data-testid="patient-bsa">
          <template v-if="patientMetrics">
            {{ patientMetrics?.bodySurfaceArea.toFixed(2) }} m²
          </template>
          <template v-else>–</template>
        </span>

        <span class="field-name">Height</span>
        <span class="field-value" data-testid="patient-height">
          <template v-if="patientMetrics"> {{ patientMetrics?.height.toFixed(2) }} m </template>
          <template v-else>–</template>
        </span>

        <span class="field-name">Weight</span>
        <span class="field-value" data-testid="patient-weight">
          <template v-if="patientMetrics"> {{ patientMetrics?.weight.toFixed(0) }} kg </template>
          <template v-else>–</template>
        </span>

        <template v-if="getWindowType() !== WindowType.ExtraClips">
          <span class="field-name">Indication</span>
          <span
            class="field-value ellipsis-on-overflow indication"
            style="flex: 1"
            data-testid="indication"
          >
            {{ study.indication }}
          </span>
        </template>

        <Tooltip
          v-if="!isHeightValid() || !isWeightValid()"
          content="The patient's weight or height is unexpectedly large, please verify"
        >
          <FontAwesomeIcon
            icon="circle-exclamation"
            size="lg"
            style="color: red"
            data-testid="study-information-error-icon"
          />
        </Tooltip>
      </template>

      <FontAwesomeIcon
        v-if="getWindowType() !== WindowType.ExtraClips"
        class="expander"
        :icon="isExpanded ? 'chevron-up' : 'chevron-down'"
        size="lg"
        data-testid="patient-info-expander"
        @click="isExpanded = !isExpanded"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { formatDicomName } from "@/../../backend/src/shared/dicom-helpers";
import DropdownInput from "@/components/DropdownInput.vue";
import { getPatientAgeWhenScanned, type Study } from "@/utils/study-data";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { computed, ref } from "vue";
import { evaluateCalculatedMeasurements } from "../../../backend/src/measurements/measurement-calculation-evaluation";
import { getPatientMetrics } from "../../../backend/src/measurements/measurement-display";
import { formatDateTime } from "../../../backend/src/shared/date-time-utils";
import { mathjsInstance } from "../../../backend/src/shared/mathjs";
import { studyTypeDisplayName } from "../../../backend/src/studies/study-type";
import { isStudyUpdatePermitted } from "../auth/authorization";
import { currentTenant } from "../auth/current-session";
import Tooltip from "../components/Tooltip.vue";
import { getPatientSexDisplayText } from "../utils/patient-data";
import { WindowType, getWindowType } from "./multi-window/secondary-window";

interface Props {
  study: Study;
  isReportOpen: boolean;
}

interface Emits {
  (event: "update"): void;
}

const props = defineProps<Props>();

const emits = defineEmits<Emits>();

const patientMetrics = computed(() => getPatientMetrics(props.study));
const isExpanded = ref(false);

const indicationValue = computed({
  get: () => props.study.indication,
  set: (newValue: string) => {
    // eslint-disable-next-line vue/no-mutating-props
    props.study.indication = newValue;
    emits("update");
  },
});

const patientMedicalHistory = computed({
  get: () => props.study.patientMedicalHistory,
  set: (newValue: string) => {
    // eslint-disable-next-line vue/no-mutating-props
    props.study.patientMedicalHistory = newValue;
    emits("update");
  },
});

const ethnicity = computed({
  get: () => props.study.patientEthnicity,
  set: (newValue: string) => {
    // eslint-disable-next-line vue/no-mutating-props
    props.study.patientEthnicity = newValue;
    emits("update");
  },
});

const heightValue = computed({
  get: () => props.study.patientHeight,
  set: (newValue: number | string) => {
    // eslint-disable-next-line vue/no-mutating-props
    props.study.patientHeight = Math.max(Number(newValue), 0);

    emits("update");

    evaluateCalculatedMeasurements(mathjsInstance, props.study);
  },
});

const weightValue = computed({
  get: () => props.study.patientWeight,
  set: (newValue: number | string) => {
    // eslint-disable-next-line vue/no-mutating-props
    props.study.patientWeight = Math.max(Number(newValue), 0);

    emits("update");

    evaluateCalculatedMeasurements(mathjsInstance, props.study);
  },
});

function isHeightValid(): boolean {
  return heightValue.value < 3;
}

function isWeightValid(): boolean {
  return weightValue.value < 400;
}
</script>

<style scoped lang="scss">
.patient-info-container {
  width: 100%;
  padding: 4px 8px;
  border-bottom: 1px solid var(--border-color-1);
  background-color: var(--bg-color-2);
}

.patient-info {
  display: grid;
  position: relative;
  grid-template-areas:
    "age-label age sex-label sex height-label height modality-label modality technician-label technician indication-label indication expander"
    "birthdate-label birthdate ethnicity-label ethnicity weight-label weight type-label type referrer-label referrer medical-history-label medical-history ."
    ". . . . bsa-label bsa . . . . . medical-history .";
  grid-template-columns:
    max-content minmax(100px, auto) max-content minmax(100px, auto) max-content minmax(100px, auto)
    max-content minmax(100px, auto) max-content minmax(100px, auto) max-content minmax(150px, 1fr) 20px;
  grid-auto-rows: 25px;
  align-items: center;
  padding: 2px 8px;
  row-gap: 4px;
  column-gap: 8px;
  align-self: center;
  width: calc(100% - 28px);

  &.report-open {
    grid-template-areas:
      "age-label age height-label height technician-label technician referrer-label referrer expander"
      "birthdate-label birthdate weight-label weight modality-label modality indication-label indication ."
      "sex-label sex bsa-label bsa type-label type medical-history-label medical-history ."
      "ethnicity-label ethnicity . . . . . medical-history .";
    grid-template-columns:
      max-content minmax(80px, auto) max-content minmax(80px, auto) max-content minmax(80px, auto)
      max-content minmax(150px, 1fr) 20px;
  }

  &.collapsed {
    display: flex;

    .field-value {
      padding: 0 8px 0 0;
    }
  }

  &.is-extra-clips-secondary-window {
    display: flex;
    gap: 8px;
    line-height: 1em;
  }
}

.field-name {
  font-weight: bold;
  place-self: center end;
}

.expander {
  grid-area: expander;
  place-self: center;
  transition: color 100ms ease;
  cursor: pointer;

  &:hover {
    color: var(--text-color-2);
  }
}

.field-value {
  user-select: text;
  -webkit-user-select: text;
  display: flex;
  align-items: center;
  gap: 8px;

  input {
    padding: 4px;
    width: 100%;
  }

  &.ellipsis-on-overflow {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
  }
}

.medical-history {
  padding: 0;
  height: 100%;
  box-sizing: border-box;

  textarea {
    padding: 4px;
    align-self: stretch;
    resize: none;
    width: 100%;
  }
}
</style>
