<template>
  <div class="dropdown-widget" :class="[disabled, background]">
    <select
      v-model="value"
      :disabled="disabled"
      :class="{ placeholder: placeholder !== '' && value === '' }"
      style="padding-left: 8px"
      data-testid="select"
    >
      <option v-if="placeholder !== ''" value="" disabled selected>{{ placeholder }}</option>
      <option v-for="item of items" :key="item.value" :value="item.value">
        {{ item.text }}
      </option>
    </select>
    <FontAwesomeIcon class="down-icon" icon="chevron-down" />
  </div>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { computed } from "vue";

export type DropdownBackground = "green" | "purple" | "blue" | "";

interface Props {
  items: { value: string; text: string }[];
  disabled?: boolean;
  modelValue: string;
  accented?: boolean;
  background?: DropdownBackground;
  placeholder?: string;
}

interface Emits {
  (event: "update:modelValue", newValue: string): void;
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  accented: false,
  background: "",
  placeholder: "",
});
const emits = defineEmits<Emits>();

const value = computed({
  get: () => props.modelValue,
  set: (event) => emits("update:modelValue", event),
});
</script>

<style scoped lang="scss">
.dropdown-widget {
  flex: 1;
  height: 32px;
  border: 1px solid var(--border-color-1);
  border-radius: var(--border-radius);
  background-color: var(--bg-color-2);
  transition:
    color 100ms ease,
    background-color 100ms ease;
  display: grid;
  align-items: center;
  cursor: pointer;

  &:hover {
    color: var(--text-color-2);
    background-color: var(--bg-color-3);

    .down-icon {
      color: var(--accent-color-2);
    }
  }

  &:focus-within {
    border-color: var(--input-focus-border-color);
  }

  &.disabled {
    opacity: 0.6;
    color: var(--text-color-2);
    cursor: inherit;
    pointer-events: none;
  }

  &.accented {
    color: var(--accent-color-1);

    &:hover {
      color: var(--accent-color-2);
    }
  }

  &.purple {
    background-color: #7a41b2;
    border: none;
  }

  &.green {
    background-color: #00aa58;
    border: none;
  }

  &.blue {
    background-color: #2661ff;
    border: none;
  }
}

select {
  width: 100%;
  background: none;
  border: none;
  display: grid;
  justify-content: center;
  height: 24px;
  padding: 0 30px 0 10px;
  color: inherit;
  cursor: inherit;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  grid-column-start: 1;
  grid-row-start: 1;
  line-height: 1em;

  &:active,
  &:focus {
    outline: none;
  }

  &.placeholder {
    color: var(--accent-color-1);
    font-style: italic;
    transition: color 100ms ease;
  }
}

option {
  color: initial;
}

.down-icon {
  justify-self: end;
  padding-right: 10px;
  pointer-events: none;
  grid-column-start: 1;
  grid-row-start: 1;
  color: var(--accent-color-1);
  transition: color 100ms ease;
}
</style>
