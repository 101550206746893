<template>
  <Popper
    ref="addCustomMeasurementCardPopper"
    :offset-distance="-1"
    :offset-distance-cross-axis="-4"
    placement="right-start"
    class="add-custom-measurement-card-popper"
    @open="isAddCustomMeasurementCardVisible = true"
    @close="isAddCustomMeasurementCardVisible = false"
  >
    <IconButton
      icon="plus"
      tooltip="Create custom measurement"
      :style="'regular-rounded'"
      highlight
      data-testid="add-custom-measurement-button"
      :disabled="isAddCustomMeasurementCardVisible"
    />

    <template #content>
      <AddCustomMeasurementCard :study="study" @close="addCustomMeasurementCardPopper?.close()" />
    </template>
  </Popper>
</template>

<script setup lang="ts">
import IconButton from "@/components/IconButton.vue";
import Popper from "@/components/Popper.vue";
import AddCustomMeasurementCard from "@/measurements/AddCustomMeasurementCard.vue";
import { Study } from "@/utils/study-data";
import { ref } from "vue";

defineProps<{ study: Study }>();

const addCustomMeasurementCardPopper = ref<{ close(): void } | null>(null);
const isAddCustomMeasurementCardVisible = ref(false);
</script>
