<template>
  <BackButton
    back-destination-route-name="settings-global-admin-users-all"
    button-text="Back to Admins"
  />

  <div class="settings-title">
    User: {{ adminUser.name.trim().length !== 0 ? adminUser.name : adminUser.email }}
    <div style="font-size: 0.6em; font-weight: normal">
      Last seen
      {{ formatRelativeTime(adminUser.lastSeenAt) }}
    </div>
  </div>

  <div class="field">
    <strong>Name</strong>
    <div style="display: flex; justify-content: space-between">
      <input v-model="adminUser.name" type="text" :disabled="true" data-testid="username" />
    </div>
  </div>

  <div class="field">
    <strong>Email</strong>

    <div class="selectable-text" style="display: flex; align-items: center; gap: 16px">
      {{ adminUser.email }}
    </div>
  </div>

  <div class="field">
    <strong>Global Admin Roles</strong>
    <UserRolesToggles
      v-model="adminUser.globalAdminRoleIds"
      :enable-admin-toggle="!isCurrentUser"
      :enabled="hasAdminRoleManagePermission"
      :global-admin="true"
      @update:model-value="updateRoles"
    />
  </div>
  <ActivityOverlay v-if="activityText" :text="activityText" />
</template>

<script setup lang="ts">
import router from "@/router";
import { onKeyStroke } from "@vueuse/core";
import axios, { type AxiosResponse } from "axios";
import { computed, onMounted, ref } from "vue";
import { formatRelativeTime } from "../../../backend/src/shared/date-time-utils";
import type { GlobalAdminUserGetOneResponseDto } from "../../../backend/src/tenants/global-admin/dto/global-admin-user-get-one.dto";
import { hasAdminRoleManagePermission } from "../auth/authorization";
import { currentUser, fetchCurrentTenantAndUser } from "../auth/current-session";
import ActivityOverlay from "../components/ActivityOverlay.vue";
import { addNotification } from "../utils/notifications";
import BackButton from "./components/BackButton.vue";
import UserRolesToggles from "./UserRolesToggles.vue";

interface Props {
  id: string;
}

const props = defineProps<Props>();

const isLoaded = ref(false);
const activityText = ref("");

const adminUser = ref<GlobalAdminUserGetOneResponseDto>({
  id: "",
  email: "",
  name: "",
  roleIds: [],
  lastSeenAt: null,
  hasPassword: false,
  hasPin: false,
  onlyAssignedStudies: false,
  onboarded: false,
  globalAdminRoleIds: [],
  mfaEnabled: false,
  disabledAt: null,
  inviteExpiresAt: null,
});

onMounted(async () => {
  activityText.value = "Loading";

  let response: AxiosResponse<GlobalAdminUserGetOneResponseDto> | undefined = undefined;
  try {
    response = await axios.get<GlobalAdminUserGetOneResponseDto>(`/api/global/users/${props.id}`);
  } catch {
    addNotification({ type: "error", message: "Failed loading user data" });
    return;
  } finally {
    activityText.value = "";
  }

  adminUser.value = response.data;
  isLoaded.value = true;
});

async function updateRoles(newRoleIds: string[]): Promise<void> {
  try {
    await axios.patch(`/api/global/users/${props.id}/roles`, { roleIds: newRoleIds });
  } catch {
    addNotification({ type: "error", message: "Failed updating user roles" });
  }

  addNotification({ type: "info", message: "User roles updated" });

  if (adminUser.value.id === currentUser.id) {
    await fetchCurrentTenantAndUser();
  }
}

const isCurrentUser = computed(() => props.id === currentUser.id);

async function openUserList(): Promise<void> {
  await router.push({ name: "settings-global-admin-users-all" });
}

onKeyStroke("Escape", () => void openUserList());
</script>
<style scoped lang="scss">
input {
  width: 300px;
}
</style>
