import { DateTime } from "luxon";
import { formatDateTime } from "../shared/date-time-utils";
import {
  CALCULATION_REGEX,
  extractCalculationsFromTemplate,
  mathjsInstance,
} from "../shared/mathjs";

/** The minimum set of study details required to evaluate a PDF name */
interface StudyPdfNameDetails {
  id: string;
  takenAt: Date | null;
  type: string;
  patientName?: string[];
  patientId: string;
  patientBirthdate: string | null;
}

export interface ReportPdfNameDetails {
  completedBy: { userIdentity: { name: string } | null } | null;
}

export const DUMMY_REPORT_PDF_NAME_STUDY = {
  id: "01922cb1-3e46-772a-b404-11ce68a37803",
  takenAt: new Date("2022-01-01T12:00:00Z"),
  type: "TTE",
  patientId: "123456",
  patientName: ["Doe", "John"],
  patientBirthdate: "2000-01-01",
};

export const DUMMY_STUDY_REPORT_PDF = {
  completedBy: { userIdentity: { name: "Test Physician" } },
};

function createScope(
  study: StudyPdfNameDetails,
  report: ReportPdfNameDetails
): Record<string, string> {
  return {
    studyDate: study.takenAt
      ? DateTime.fromJSDate(study.takenAt, { zone: "utc" }).toFormat("yyyy-MMM-dd")
      : "Unknown Date",
    studyDateFormatted: formatDateTime(study.takenAt, { includeDate: true, includeTime: false }),
    studyDateTime: study.takenAt
      ? DateTime.fromJSDate(study.takenAt, { zone: "utc" }).toFormat("ddMMyyyyHHmmss")
      : "STUDYDATETIME",
    studyId: study.id,
    studyType: study.type,
    patientId: study.patientId,
    patientFirstName: study.patientName?.[1] ?? "Unknown",
    patientLastName: study.patientName?.[0] ?? "Unknown",
    patientBirthdate: study.patientBirthdate ?? "Unknown Date",
    patientBirthdateInDMY:
      study.patientBirthdate !== null
        ? DateTime.fromJSDate(new Date(study.patientBirthdate)).toFormat("ddMMyyyy")
        : "",
    completedBy: report.completedBy?.userIdentity?.name ?? "",
  };
}

function evaluateCalculation(calculation: string, scope: Record<string, unknown>): string {
  return String(mathjsInstance.evaluate(calculation, scope));
}

export function getPdfNameTemplateErrors(template: string): string[] | null {
  const calculations = extractCalculationsFromTemplate(template);

  const scope = createScope(DUMMY_REPORT_PDF_NAME_STUDY, DUMMY_STUDY_REPORT_PDF);

  const errors = [];
  for (const calculation of calculations) {
    try {
      evaluateCalculation(calculation, scope);
    } catch (error: unknown) {
      errors.push(String(error).replace("Error: ", ""));
    }
  }

  return errors.length > 0 ? errors : null;
}

export function evaluatePdfName(
  template: string,
  study: StudyPdfNameDetails,
  report: ReportPdfNameDetails
): string {
  const scope = createScope(study, report);

  const calculations = extractCalculationsFromTemplate(template);
  const replaceGroups = template.match(CALCULATION_REGEX);

  try {
    const calculationResults = calculations.map((c) => evaluateCalculation(c, scope));

    let outputFileName = template;

    replaceGroups?.forEach((group, index) => {
      outputFileName = outputFileName.replace(group, calculationResults[index]);
    });

    return `${outputFileName}.pdf`;
  } catch {
    // Fall back to the study ID if the calculation fails for some reason
    return `${study.id}.pdf`;
  }
}
